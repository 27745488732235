import React, { useEffect } from "react";
import { Dialog, Typography } from "@material-ui/core";
import { Button } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useUpdateAutomation } from "../../hooks";
import { Body, Footer } from "./styled";

interface CampaignContactsModalProps {
  data: {
    automationId: string;
    companyId?: string;
  };
  hideModal: () => void;
}

const AutomationChangeConfirmationModal: React.FC<
  CampaignContactsModalProps
> = ({ data: { companyId, automationId }, hideModal }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const {
    mutate: updateAutomation,
    isLoading,
    isSuccess,
    isError,
  } = useUpdateAutomation();

  useEffect(() => {
    if (isSuccess) {
      toast.error(
        intl.formatMessage({ id: "automations.update.modal.successOff" })
      );
      queryClient.invalidateQueries(["automation", companyId, automationId]);
      queryClient.invalidateQueries(["automations", companyId]);
      hideModal();
    }

    if (isError) {
      toast.error(
        intl.formatMessage({
          id: "automations.update.modal.error",
        })
      );
      hideModal();
    }
  }, [isSuccess, isError]);

  return (
    <Dialog open maxWidth='xs'>
      <Body>
        <Typography variant='h4'>
          <FormattedMessage id='automations.update.modal.title' />
        </Typography>

        <Typography
          style={{
            marginBottom: 0,
          }}
        >
          <FormattedMessage id='automations.update.modal.subtitle' />
        </Typography>
      </Body>
      <Footer>
        <Button
          color='default'
          variant='outlined'
          disabled={isLoading}
          type='button'
          onClick={() => {
            hideModal();
          }}
        >
          <FormattedMessage id='automations.update.modal.cancel' />
        </Button>

        <Button
          type='button'
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            updateAutomation({
              company_id: companyId,
              automation_id: automationId,
              data: {
                state: "draft",
              },
            });
          }}
        >
          <FormattedMessage id='automations.update.modal.submit' />
        </Button>
      </Footer>
    </Dialog>
  );
};

export default AutomationChangeConfirmationModal;
