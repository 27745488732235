import React, { useEffect, useState } from "react";
import WarningIcon from "@assets/icons/warning.svg";
import { AlertStyled, AlertContent } from "./styled";

type AlertProps = {
  variant?: "success" | "warning" | "error";
  sticky?: boolean;
  disappear?: boolean;
  fullWidth?: boolean;
};

const AlertComponent: React.FC<AlertProps & React.PropsWithChildren> = ({
  sticky = false,
  fullWidth = false,
  disappear = false,
  ...props
}) => {
  const [hideAlert, setHideAlert] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);

  // @ts-ignore-start
  useEffect(() => {
    if (sticky || disappear) {
      if (!timeLeft) {
        setHideAlert(true);
        return;
      }

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // eslint-disable-next-line
      return () => clearInterval(intervalId);
    }
  }, [timeLeft, sticky]);
  // @ts-ignore-end

  return (
    <AlertStyled
      {...props}
      sticky={sticky}
      hideAlert={hideAlert}
      fullWidth={fullWidth}
    >
      {props.variant === "error" && <WarningIcon />}
      <AlertContent>{props.children}</AlertContent>
    </AlertStyled>
  );
};

export default AlertComponent;
