import React, { useState, useMemo, useContext, useEffect } from "react";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Error,
  Loader,
  NoData,
  Pagination,
  useTable,
} from "@components/common";
import { COMMON } from "@constants/index";
import { formatMoney } from "@utils/common";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import WaitingIcon from "@assets/icons/clock.svg";
import NoTransactionIcon from "@assets/icons/addresses-empty.png";
import DownloadIcon from "@assets/icons/download.svg";
import DollarIcon from "@assets/icons/dollar.svg";

import { AuthContext } from "@providers/AuthProvider";
import { useCompanyDetails } from "../../hooks";
import * as S from "./styled";
import { showModal } from "../../../modal/actions";
import { useGetCompanyTransactions } from "./hooks";
import { WaitingForPaymentComponent } from "./components/WaitingForPayment";
import { ExportTransactionModal } from "./components/ExportTransactionModal";
import { CancelTopUp } from "./components/CancelTopUp";
import { TransactionType, PagingType } from "@types";

const renderTransactionId = (
  isCredit: boolean,
  invoiceId: string,
  orderId: string
) => {
  if ((isCredit && !invoiceId) || (!isCredit && !orderId)) {
    return null;
  }
  return isCredit ? (
    <FormattedMessage
      id='transactions.list.invoiceId'
      values={{ id: invoiceId?.toUpperCase() }}
    />
  ) : (
    <FormattedMessage
      id='transactions.list.orderId'
      values={{ id: orderId?.toUpperCase() }}
    />
  );
};

const Orders = () => {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [paginationOffset, setPaginationOffset] = useState(1);
  const [poolTries, setPoolTries] = useState(5);
  const intervalRef = React.useRef(null);
  const [cachedTransactions, setCachedTransactions] = useState<{
    items: TransactionType[];
    paging: PagingType;
  }>(null);
  const {
    data: companyDetails,
    isLoading,
    isError,
  } = useCompanyDetails(user.company_id);

  const {
    data: transactions,
    isLoading: transactionsIsLoading,
    isError: isTransactionError,
    refetch,
  } = useGetCompanyTransactions(user.company_id, {
    page: paginationOffset,
  });

  const pendingTransactions = useMemo(
    () =>
      cachedTransactions?.items.filter(
        (transaction) => transaction?.topup?.state === "requested"
      ),
    [cachedTransactions]
  );

  const allHasInvoicesReady = useMemo(() => {
    if (!pendingTransactions) return false;
    return pendingTransactions.every(
      (transaction) => transaction?.topup?.invoices?.[0]?.url
    );
  }, [pendingTransactions]);

  const intl = useIntl();
  const [toggle, setToggle] = useState(false);
  const [showExportTransactionModal, setShowExportTransactionModal] =
    useState(false);

  const tableHeadData = useMemo(
    () => [
      {
        field: "date",
        title: intl.formatMessage({
          id: "transactions.list.date",
        }),
      },
      {
        field: "invoice",
        title: intl.formatMessage({
          id: "transactions.list.invoice",
        }),
      },
      {
        field: "name",
        title: intl.formatMessage({
          id: "transactions.list.name",
        }),
      },
      {
        field: "credits",
        title: intl.formatMessage({
          id: "transactions.list.credits",
        }),
      },
      { field: "invoice1", title: "" },
      { field: "cancelTopup", title: "" },
    ],
    [intl]
  );

  const tableBodyData = useMemo(() => {
    if (cachedTransactions) {
      let filteredTransactions;
      if (toggle) {
        filteredTransactions = cachedTransactions.items?.filter(
          (item) => item.transaction_type === "credit"
        );
      } else {
        filteredTransactions = cachedTransactions.items;
      }

      return filteredTransactions.reduceRight(
        (acc, transaction) => [
          {
            date: {
              title: dayjs(
                transaction.transaction_datetime || transaction.created_at
              ).format(COMMON.DEFAULT_DATE_FORMAT),
            },
            invoice: {
              title: transaction.is_external
                ? transaction.delivery?.gift.name
                : renderTransactionId(
                    transaction.transaction_type === "credit",
                    transaction.topup?.invoices?.[0]?.external_id,
                    transaction.order?.readable_id
                  ),
            },
            name: {
              // eslint-disable-next-line
              component: () => (
                <div className='name'>
                  {transaction.is_external ? (
                    <>
                      {transaction?.delivery?.contact?.first_name}{" "}
                      {transaction?.delivery?.contact?.last_name}
                    </>
                  ) : (
                    <>
                      {transaction?.topup?.contact?.first_name}{" "}
                      {transaction?.topup?.contact?.last_name}
                    </>
                  )}
                </div>
              ),
            },
            credits: {
              // eslint-disable-next-line
              component: () => {
                if (transaction.is_external) return null;
                return transaction.transaction_type === "credit" ? (
                  <span className={transaction.amount > 0 ? "credit" : ""}>
                    +{formatMoney(transaction.amount)}
                  </span>
                ) : (
                  <span>-{formatMoney(transaction.amount)}</span>
                );
              },
            },
            ...(transaction?.topup?.invoices?.length > 0
              ? {
                  invoice1: {
                    // eslint-disable-next-line
                    component: () =>
                      transaction.topup?.state === "requested" ? (
                        <S.WaitingMessage>
                          <WaitingIcon />
                          <FormattedMessage id='credit.waiting.messageShort' />
                        </S.WaitingMessage>
                      ) : (
                        <S.ButtonStyled
                          variant='text'
                          color='primary'
                          component='a'
                          disableRipple
                          // @ts-expect-error
                          target='_blank'
                          disabled={!transaction.topup?.invoices[0]?.url}
                          href={transaction.topup?.invoices[0]?.url || "/url"}
                          startIcon={<DownloadIcon />}
                        >
                          <FormattedMessage id='credit.download.platforma' />
                        </S.ButtonStyled>
                      ),
                  },
                }
              : {
                  invoice1: {
                    component: null,
                  },
                }),
            ...(transaction?.topup?.invoices?.length > 0
              ? {
                  invoice2: {
                    // eslint-disable-next-line
                    component: () =>
                      transaction.topup?.state === "requested" ? (
                        <CancelTopUp
                          id={transaction.topup.id}
                          company_id={user.company_id}
                        />
                      ) : (
                        <S.ButtonStyled
                          variant='text'
                          color='primary'
                          component='a'
                          disableRipple
                          // @ts-expect-error
                          target='_blank'
                          disabled={!transaction.topup?.invoices[0]?.url}
                          href={transaction.topup?.invoices[0]?.url || "/url"}
                          startIcon={<DownloadIcon />}
                        >
                          <FormattedMessage id='credit.download.invoice' />
                        </S.ButtonStyled>
                      ),
                  },
                }
              : {
                  invoice2: {
                    component: null,
                  },
                }),
          },
          ...acc,
        ],
        []
      );
    }
    return [];
  }, [cachedTransactions, toggle]);

  const [RenderTable] = useTable({
    tableHeadData,
    tableBodyData,
    searchValue: "",
    isLoading,
  });

  useEffect(() => {
    if (transactions?.items) {
      setCachedTransactions(transactions);
    }
  }, [transactions]);

  useEffect(() => {
    clearInterval(intervalRef.current);
    if (!allHasInvoicesReady && poolTries > 0) {
      intervalRef.current = setInterval(() => {
        refetch();
        setPoolTries((state) => state - 1);
      }, 5000);
    }
    return () => clearInterval(intervalRef.current);
  }, [allHasInvoicesReady, poolTries]);

  if (isLoading || transactionsIsLoading || !cachedTransactions)
    return <Loader />;

  if (isError || isTransactionError) {
    return <Error />;
  }

  return (
    <S.WrapperStyled>
      <S.BalanceContainer>
        <Typography variant='h4'>
          <FormattedMessage id='credit.title' />
        </Typography>
        <S.BalanceValue>
          <DollarIcon />
          <Typography variant='h4'>
            {formatMoney(companyDetails.credit_balance)}
          </Typography>
        </S.BalanceValue>
        <div>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              dispatch(
                showModal({
                  type: "ADD_CREDIT_MODAL",
                  data: {
                    title: intl.formatMessage({
                      id: "credit.modal.title",
                    }),
                    ctaTitle: intl.formatMessage({
                      id: "credit.modal.submit",
                    }),
                    user,
                    companyDetails,
                  },
                })
              );
            }}
          >
            <FormattedMessage id='credit.add' />
          </Button>
        </div>
      </S.BalanceContainer>

      {cachedTransactions?.items
        .filter((transaction) => transaction?.topup?.state === "requested")
        .map((transaction) => (
          <WaitingForPaymentComponent
            key={transaction.id}
            transaction={transaction}
          />
        ))}

      {cachedTransactions?.items.length === 0 ? (
        <NoData>
          <img src={NoTransactionIcon} alt='' />
          <Typography component='h4' variant='h4'>
            <FormattedMessage id='transactions.list.empty' />
          </Typography>
          <Button
            color='secondary'
            variant='outlined'
            onClick={() => {
              dispatch(
                showModal({
                  type: "ADD_CREDIT_MODAL",
                  data: {
                    title: intl.formatMessage({
                      id: "credit.modal.title",
                    }),
                    ctaTitle: intl.formatMessage({
                      id: "credit.modal.submit",
                    }),
                    user,
                    companyDetails,
                  },
                })
              );
            }}
          >
            <FormattedMessage id='credit.add' />
          </Button>
        </NoData>
      ) : (
        <>
          <S.HeaderStyled>
            {/* @ts-expect-error */}
            <S.SubHeadingStyled variant='h3' component='h3'>
              <FormattedMessage id='transactions.history' />
            </S.SubHeadingStyled>
            <Typography>
              <FormattedMessage id='transactions.history.message' />
            </Typography>
          </S.HeaderStyled>
          <S.TableActionsContainer>
            <FormControlLabel
              control={
                <Switch
                  checked={toggle}
                  onChange={() => setToggle((state) => !state)}
                  name='checkedB'
                  color='primary'
                />
              }
              label={intl.formatMessage({
                id: "credit.toggle",
              })}
            />
            <Button
              variant='outlined'
              onClick={() => setShowExportTransactionModal(true)}
            >
              <FormattedMessage id='credit.export.title' />
            </Button>
          </S.TableActionsContainer>
          <RenderTable />
        </>
      )}
      {showExportTransactionModal && (
        <ExportTransactionModal
          show={showExportTransactionModal}
          handleClose={() => setShowExportTransactionModal(false)}
        />
      )}
      <Pagination
        onChange={setPaginationOffset}
        total={cachedTransactions?.paging?.total_records}
        page={paginationOffset}
      />
    </S.WrapperStyled>
  );
};

export default Orders;
