import { Typography } from "@material-ui/core";
import styled from "styled-components";

import { device } from "@constants/style";
import { Link } from "react-router-dom";

export const Container = styled.div`
  padding: 0 0 45px;
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 0 30px;
  flex: 1;
`;

export const LinkBack = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 50px;
  padding-top: 1rem;
  color: #000;
`;

export const GiftsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const GiftsContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const GiftsFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 250px;
  flex-shrink: 0;

  h6 {
    font-size: 15px;
    font-weight: 500;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

export const SelectedFilterBadge = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  border-radius: var(--8, 8px);
  background: #e53761;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  color: #fff;

  button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #fff;
    display: flex;

    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const GiftsStyled = styled.ul`
  flex-grow: 1;
  display: grid;
  gap: 32px 24px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  padding: 0;
  margin: 0;

  @media (${device.tablet}) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 310px));
  }
`;

export const SubHeading = styled(Typography)``;
