import React, { useContext, useEffect } from "react";
import { Box, Grid, MenuItem, Typography } from "@material-ui/core";
import { Button, Select, TextField } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { UserProfileType } from "@types";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router";
import { useRequestTopUp } from "../hooks";
import { DialogFooterStyled } from "../../../../modal/components/Modal/components/styled";
import { ModalContainer, CreditsInput, CreditsInputAdornment } from "../styled";
import { AuthContext } from "@providers/AuthProvider";

type AddModalDataType = {
  ctaTitle: string;
  user: UserProfileType;
};

interface AddCreditModalProps {
  data: AddModalDataType;
  hideModal: () => void;
}

const AddCreditModal: React.FC<AddCreditModalProps> = ({
  data: { ctaTitle },
  hideModal,
}) => {
  const methods = useForm();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const {
    mutate: requestTopUp,
    isError,
    isSuccess,
    isLoading,
  } = useRequestTopUp();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const onSubmit = ({
    sum,
    // company,
    language,
    email,
  }: {
    sum: string;
    // company: string;
    language: string;
    email: string;
  }) => {
    requestTopUp({
      company_id: user.company_id,
      data: {
        payment_type: "invoice",
        amount: parseFloat(sum.replace(/,/g, '.')),
        invoice_language: language,
        invoice_distribution_email: email,
        nonce: uuid(),
      },
    });
  };

  useEffect(() => {
    if (isError) {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      hideModal();
    }

    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: "credit.modal.submitSuccess",
        })
      );
      history.push("/settings/credits");
      hideModal();
    }
  }, [isError, isSuccess]);

  return (
    <Box display='flex' flexDirection='column'>
      <ModalContainer>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={6} alignItems='flex-start'>
              <Grid item md={7}>
                <CreditsInput>
                  <TextField
                    name='sum'
                    error={errors.sum}
                    label='1 credit = 1€'
                    type='text'
                    required
                    defaultValue=''
                    placeholder='0,00'
                    offsetbottom='false'
                    endAdornment={
                      <CreditsInputAdornment>€</CreditsInputAdornment>
                    }
                  />
                </CreditsInput>
                <TextField
                  name='company'
                  placeholder={intl.formatMessage({
                    id: "input.credit.billTo",
                  })}
                  label={intl.formatMessage({
                    id: "input.credit.billTo",
                  })}
                  error={errors.company}
                  disabled
                  required
                  defaultValue={user.company_name}
                />
                <Select
                  id='language'
                  name='language'
                  label={intl.formatMessage({
                    id: "input.credit.platforma",
                  })}
                  defaultValue={user.locale}
                >
                  <MenuItem value='en'>
                    <FormattedMessage id='shared.lang.eng' />
                  </MenuItem>
                  <MenuItem value='sk'>
                    <FormattedMessage id='shared.lang.sk' />
                  </MenuItem>
                  <MenuItem value='cs'>
                    <FormattedMessage id='shared.lang.cs' />
                  </MenuItem>
                </Select>
                <TextField
                  name='email'
                  label={intl.formatMessage({
                    id: "input.credit.sendTo",
                  })}
                  error={errors.email}
                  required
                  defaultValue={user?.email}
                />
              </Grid>
              <Grid item md={5}>
                <Typography variant='h4'>
                  <FormattedMessage id='credit.modal.info.title' />
                </Typography>
                <ol>
                  <li>
                    <FormattedMessage id='credit.modal.info.line1' />
                  </li>
                  <li>
                    <FormattedMessage id='credit.modal.info.line2' />
                  </li>
                  <li>
                    <FormattedMessage id='credit.modal.info.line3' />
                  </li>
                  <li>
                    <FormattedMessage id='credit.modal.info.line4' />
                  </li>
                </ol>
              </Grid>
            </Grid>
            <DialogFooterStyled>
              <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
                {ctaTitle}
              </Button>
            </DialogFooterStyled>
          </form>
        </FormProvider>
      </ModalContainer>
    </Box>
  );
};

export default AddCreditModal;
