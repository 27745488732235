import React from "react";
import { FormattedMessage } from "react-intl";
import { NoData } from "@components/common";
import { Typography } from "@material-ui/core";
import GiftImage from "@assets/icons/gifts-empty.png";

export const NoGifts: React.FC<{
  headingId: string;
  messageId?: string;
}> = ({ headingId, messageId }) => {
  return (
    <NoData style={{ gridColumn: "1 / 13" }} size='large'>
      {" "}
      <img src={GiftImage} alt='gifts' />
      <Typography component='h3' variant='h3'>
        <FormattedMessage id={headingId} values={{ br: <br /> }} />
      </Typography>
      {messageId && <FormattedMessage id={messageId} values={{ br: <br /> }} />}
    </NoData>
  );
};
