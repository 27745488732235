import { Badge, Button } from "@components/common";
import styled from "styled-components";

export const GiftPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  img {
    border-radius: 8px;
  }
`;

export const BadgeStyled = styled(Badge)`
  white-space: nowrap;
`;


export const ResetNoteButton = styled(Button)`
  text-decoration: underline;
  color: #000;
  padding: 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 1;
  height: auto;

  &:hover,
  &:focus {
    background-color: transparent;
    text-decoration: underline;
  }
`;
