import { Table } from "@material-ui/core";
import styled from "styled-components";

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const TableStyled = styled(Table)`
  tr > td {
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
    }
  }
  tr > td.action {
    width: 50px;
    padding-right: 0;
  }

  button {
    padding: 8px;
  }

  .MuiButton-text {
    padding: 0;
  }
`;
