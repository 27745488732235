import React from "react";
import { FormattedMessage } from "react-intl";
import { OrderPayload } from "@types";

type RenderStep1TitlesProps = {
  type: "contacts" | "tag" | "amount";
  payload: OrderPayload;
  tagName: string;
  selectedContactsCount: number;
};

export const StepTitle = React.memo(
  ({
    type,
    tagName,
    payload,
    selectedContactsCount,
  }: RenderStep1TitlesProps) => {
    switch (type) {
      case "contacts":
        return (
          <span>
            <u>
              <FormattedMessage
                id='orders.create.step1.recipients'
                values={{ count: payload.contacts.length }}
                tagName='b'
              />
            </u>
          </span>
        );
      case "tag":
        return (
          <span>
            <FormattedMessage
              tagName='b'
              id='orders.create.step1.allTagsTitle'
            />{" "}
            <FormattedMessage id='orders.create.step1.withTag' />
            <b>{tagName}. </b>
            <u>
              <FormattedMessage
                id='orders.create.step1.recipients'
                values={{ count: selectedContactsCount }}
                tagName='b'
              />
            </u>
          </span>
        );
      case "amount":
        return (
          <span>
            <FormattedMessage
              tagName='b'
              id='orders.create.step1.gifts'
              values={{ count: payload.volume }}
            />
          </span>
        );
      default:
        return null;
    }
  }
);

StepTitle.displayName = "StepTitle";
