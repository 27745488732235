import React, { ComponentType } from "react";
import ReactDOM from "react-dom/client";
import App from "./app";

const render = (Component: ComponentType) => {
  const renderEl = <Component />;

  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(renderEl);
};

render(App);
