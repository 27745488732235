import React from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import { Button, Checkbox } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { CompanyType, UserProfileType } from "@types";
import { toast } from "react-toastify";
import {
  DialogFooterStyled,
  DialogBodyStyled,
  DialogHeaderStyled,
} from "../../../modal/components/Modal/components/styled";
import * as S from "./styled";
import { useUpdateCompanyDetails } from "../../hooks";

interface TermsAndPrivacyModalProps {
  company: CompanyType;
  user: UserProfileType;
}

export const TermsAndPrivacyModal: React.FC<TermsAndPrivacyModalProps> = ({
  user,
}) => {
  const methods = useForm();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { isDirty },
    watch,
  } = methods;
  const { mutate: updateCompany, status } = useUpdateCompanyDetails();

  if (status === "success") {
    toast.success(
      intl.formatMessage({
        id: "modal.privacy.successMsg",
      })
    );
  }

  if (status === "error") {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
  }

  const onSubmit = ({ acceptTerms }: { acceptTerms: boolean }) => {
    if (!isDirty) return;

    updateCompany({
      company_id: user.company_id,
      data: {
        terms_of_service_accepted: acceptTerms,
        data_privacy_agreement_accepted: acceptTerms,
      },
    });
    // reset();
  };

  return (
    <Dialog open>
      <DialogHeaderStyled size='sm'>
        <Typography variant='h3'>
          <FormattedMessage id='modal.privacy.title' />
        </Typography>
      </DialogHeaderStyled>
      <DialogBodyStyled>
        <Box display='flex' flexDirection='column'>
          <S.TermsTextStyled>
            <FormattedMessage id='modal.privacy.message' />
          </S.TermsTextStyled>
          <S.TermsLinks>
            <div>
              <S.TermsLink
                target='_blank'
                href='https://www.boxday.app/legal'
                rel='noreferrer'
              >
                Terms of Service
              </S.TermsLink>
            </div>
            <div>
              <S.TermsLink
                target='_blank'
                href='https://www.boxday.app/legal'
                rel='noreferrer'
              >
                Privacy Policy
              </S.TermsLink>
            </div>
            <div>
              <S.TermsLink
                target='_blank'
                href='https://www.boxday.app/legal'
                rel='noreferrer'
              >
                Data Privacy Agreement
              </S.TermsLink>
            </div>
          </S.TermsLinks>
          <DialogFooterStyled>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <S.TermsCtaStyled>
                  <Checkbox
                    name='acceptTerms'
                    label={intl.formatMessage({
                      id: "modal.privacy.checkbox",
                    })}
                    required
                    checked={false}
                  />
                  <Button
                    disabled={!watch("acceptTerms")}
                    onClick={handleSubmit(onSubmit)}
                  >
                    <FormattedMessage id='modal.privacy.submit' />
                  </Button>
                </S.TermsCtaStyled>
              </form>
            </FormProvider>
          </DialogFooterStyled>
        </Box>
      </DialogBodyStyled>
    </Dialog>
  );
};
