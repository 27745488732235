import styled from "styled-components";

import CustomRequestIcon from "@assets/icons/custom-request.svg";

type GiftStatus = "in_stock" | "coming_soon" | "preparing";

export const GiftStatusStyled = styled.span<{ variant: GiftStatus }>`
  color: ${({ variant }) => (variant === "in_stock" ? "#00CA81" : "#A0A0A0")};
  font-weight: 600;
  display: block;
  margin-top: auto;
`;

export const GiftBodyStyled = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 4px;
  }

  > span {
    font-size: 12px;
    line-height: 22px;
  }
`;

export const GiftStyled = styled.li`
  list-style: none;
  border-radius: 12px;

  > a,
  > div {
    // width: 270px;
    min-height: 330px;
    height: 100%;
    border: 1px solid #ececec;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: #000;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  img {
    width: 100%;
    height: 196px;
    object-fit: cover;
  }

  .soon {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 196px;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 2;
      border-radius: 12px;
    }
  }

  &.promo {
    background-color: #f8f6f4;
    ${GiftBodyStyled} {
      align-items: center;
      justify-content: center;
      text-align: center;
      flex: 1;
    }

    img {
      height: 105px;
    }
  }
`;

export const Badge = styled.div`
  min-height: 30px;
  color: #fff;
  text-transform: uppercase;
  background-color: #e53761;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  position: absolute;
  left: 16px;
  top: 12px;
`;

export const RequestIcon = styled(CustomRequestIcon)`
  position: absolute;
  right: 16px;
  top: 68px;
`;
