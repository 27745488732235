import React, { useState, useEffect, useMemo, useContext } from "react";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { COMMON, ROUTES } from "@constants/index";
import {
  Search,
  ContentLoader,
  Link,
  Pagination,
  Dropdown,
  Checkbox,
  NoData,
  Error,
  useTable,
} from "@components/common";
import { ExpandMore } from "@material-ui/icons";
import { ContactType } from "@types";
import { Typography } from "@material-ui/core";
import AddProgramCTAIcon from "@assets/icons/contacts-empty.png";
import { AuthContext } from "@providers/AuthProvider";
import {
  EmptyListStyled,
  FilterEmptyStyled,
  FilterEmptyIconStyled,
  HeaderStyled,
  FilterToggleStyled,
  FilterListItemStyled,
  TableFilterContainerStyled,
  SubHeadingStyled,
} from "../../../styled";
import { useContacts, useAllTags } from "../../../hooks";
import { useDebounce } from "@ui/src/utils/useDebounce";

type FilterItem = {
  label: string;
  checked: boolean;
};

const EmptyListComponent = ({ searchValue }: { searchValue: string }) => (
  <EmptyListStyled>
    <FilterEmptyIconStyled />
    <FilterEmptyStyled align='center'>
      <FormattedMessage
        id='contacts.list.search.empty'
        values={{ name: searchValue, br: <br /> }}
      />
    </FilterEmptyStyled>
    <Link color='primary' to={ROUTES.CUSTOMERS_ADD_FORM}>
      <FormattedMessage id='contacts.main.add' />
    </Link>
  </EmptyListStyled>
);

const ContactsList: React.FC = () => {
  const { user } = useContext(AuthContext);
  const intl = useIntl();
  const [paginationPage, setPaginationPage] = useState(1);
  const { data: tags } = useAllTags(user.company_id);

  const [searchValue, setSearchValue] = useState("");
  const searchDebounced = useDebounce(searchValue);
  const allSegmentsTitle = intl.formatMessage({
    id: "input.segments.filter.all",
  });

  const {
    data: { items: contacts, paging } = {},
    isLoading,
    isError,
  } = useContacts(user.company_id, paginationPage, null, 30, searchDebounced);

  const [filterData, setFilterData] = useState<FilterItem[]>([]);
  const [filterSelected, setFilterSelected] = useState(tags?.length);

  // const [contactsSelected, setContactSelected] = useState([]);

  const onFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilterState = filterData.map((item) => {
      if (event.target.name === allSegmentsTitle) {
        return { ...item, checked: !(event.target.value === "true") };
      }
      if (item.label === event.target.name) {
        return { ...item, checked: !item.checked };
      }
      if (item.label === allSegmentsTitle && event.target.value === "true") {
        return { ...item, checked: false };
      }
      return item;
    });
    const selectedItems = newFilterState.filter(
      (item) => item.checked && item.label !== allSegmentsTitle
    ).length;

    setFilterData(newFilterState);
    setFilterSelected(selectedItems);
  };

  const tableHeadData = useMemo(
    () => [
      // {
      //   field: "name",
      //   title: "",
      // },
      {
        field: "name",
        title: intl.formatMessage({
          id: "contacts.list.name",
        }),
        sortable: true,
      },
      {
        field: "email",
        title: intl.formatMessage({
          id: "contacts.list.email",
        }),
        sortable: true,
      },
      {
        field: "department",
        title: intl.formatMessage({
          id: "contacts.list.department",
        }),
        sortable: true,
      },
      {
        field: "birthday",
        title: intl.formatMessage({
          id: "contacts.list.birthday",
        }),
        sortable: true,
      },
      {
        field: "created",
        title: intl.formatMessage({
          id: "contacts.list.created",
        }),
        sortable: true,
      },
    ],
    [intl]
  );

  const tableBodyData = useMemo(() => {
    if (contacts) {
      const fs = filterData.filter((item) => item.checked);
      let filteredCustomers: ContactType[];

      if (fs.find((item) => item.label === allSegmentsTitle)) {
        filteredCustomers = contacts;
      } else {
        filteredCustomers = contacts.filter((contact) =>
          fs.some((filterItem) =>
            contact.tags.find((tag) => tag.tag_string === filterItem.label)
          )
        );
      }

      return filteredCustomers.reduceRight(
        (acc, contact) => [
          {
            // edit: {
            //   component: () => (
            //     <CheckBoxWrapperStyled>
            //       <Checkbox
            //         name={contact.id}
            //         label=''
            //         color='secondary'
            //         checked={false}
            //         value={contact.id}
            //         hooked={false}
            //         onChange={onFilter}
            //       />
            //     </CheckBoxWrapperStyled>
            //   ),
            // },
            name: {
              title: `${contact.first_name} ${contact.last_name}`,
              link: `/people/${contact.id}/history`,
            },
            email: {
              title: contact.email,
            },
            department: {
              title: contact.tags.map((tag) => tag.tag_string).join(", "),
            },
            birthday: {
              title: contact.date_of_birth
                ? dayjs(contact.date_of_birth).format(
                    COMMON.DEFAULT_DATE_FORMAT
                  )
                : "--",
              type: "date",
            },
            created: {
              title: dayjs(contact.created_at).format(
                COMMON.DEFAULT_DATE_FORMAT
              ),
              type: "date",
            },
            // lastGift: {
            //   title: contact.last_order?.gift?.name,
            // },
          },
          ...acc,
        ],
        []
      );
    }
    return [];
  }, [filterData, contacts, allSegmentsTitle]);

  const [RenderTable] = useTable({
    tableHeadData,
    tableBodyData,
    searchValue: "",
    isLoading,
    EmptyList: EmptyListComponent,
  });

  useEffect(() => {
    if (tags && filterSelected === tags.length) {
      setFilterData((state) => {
        const newData = state.map((item) => {
          if (item.label === allSegmentsTitle) {
            return { ...item, checked: true };
          }
          return item;
        });
        return newData;
      });
    }
  }, [filterSelected, tags, allSegmentsTitle]);

  // prepare Filter data
  useEffect(() => {
    if (tags) {
      const data = tags.map((tag) => {
        const state = {
          label: tag.tag_string,
          checked: true,
        };
        return state;
      });
      data.unshift({
        label: allSegmentsTitle,
        checked: true,
      });
      setFilterData(data);
      setFilterSelected(data.length - 1);
    }
  }, [tags, allSegmentsTitle]);

  const filterTitle = useMemo(() => {
    if (filterSelected === tags?.length) return allSegmentsTitle;
    if (filterSelected === 0)
      return <FormattedMessage id='input.segments.filter.empty' />;
    return (
      <FormattedMessage
        id='input.segments.filter.selected'
        values={{
          tags: filterSelected,
        }}
      />
    );
  }, [filterSelected, tags, allSegmentsTitle]);

  useEffect(() => {
    if (searchValue) {
      setPaginationPage(1);
    }
  }, [searchValue]);

  if (isError) {
    return <Error />;
  }

  if (!tags) {
    return <ContentLoader />;
  }

  return (
    <>
      <HeaderStyled>
        {/* @ts-ignore */}
        <SubHeadingStyled component='h3' variant='h3'>
          <FormattedMessage id='contacts.list.title' />
        </SubHeadingStyled>
        <TableFilterContainerStyled>
          <Dropdown
            toggleComponent={
              <FilterToggleStyled
                error={tags?.length > 0 && filterSelected === 0}
              >
                {filterTitle}
                <ExpandMore />
              </FilterToggleStyled>
            }
            toggleButtonBase
            dropdownAlign='left'
            menuOverlapped='false'
            showArrow={false}
          >
            {filterData?.map((item) => (
              <FilterListItemStyled key={item.label}>
                <Checkbox
                  name={item.label}
                  label={item.label}
                  checked={item.checked}
                  value={item.checked}
                  hooked={false}
                  onChange={onFilter}
                />
              </FilterListItemStyled>
            ))}
          </Dropdown>
          <Search value={searchValue} onChange={setSearchValue} />
        </TableFilterContainerStyled>
      </HeaderStyled>
      {contacts?.length === 0 ? (
        <NoData>
          <img src={AddProgramCTAIcon} alt='' />
          <Typography component='h3' variant='h3'>
            <FormattedMessage
              id='contacts.list.empty.heading'
              values={{ br: <br /> }}
            />
          </Typography>
          <FormattedMessage
            id='contacts.list.empty.title'
            values={{ br: <br /> }}
          />
        </NoData>
      ) : (
        <RenderTable />
      )}
      <Pagination
        page={paginationPage}
        total={paging?.total_records}
        onChange={setPaginationPage}
      />
    </>
  );
};

export default ContactsList;
