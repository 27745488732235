import { accountActionTypes, AccountStateType, AccountTypes } from "./types";

export const defaultState: AccountStateType = {
  language: null,
  status: "idle",
  error: false,
};

export default (
  state = defaultState,
  action: accountActionTypes
): AccountStateType => {
  switch (action.type) {
    case AccountTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    default:
      return state;
  }
};
