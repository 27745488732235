import React from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import { FullPageLoader } from "@components/common";
import { ROUTES } from "@constants/index";
import history from "@utils/history";
import {
  SignIn,
  SignUp,
  ResetPassword,
  ForgotPassword,
  Verification,
  SetupPassword,
} from "../../modules/auth";
import { RecipientForm } from "../../modules/recipientForm";
import { Authenticated } from "./authenticated";
import { useAuth } from "../hooks";

const RootRoutes: React.FC = () => {
  const { user, synced } = useAuth();

  if (!synced) {
    return <FullPageLoader />;
  }

  return (
    <Router history={history}>
      {user ? (
        <Switch>
          <Route
            exact
            component={RecipientForm}
            path={ROUTES.RECIPIENT_FORM_PAGE}
          />
          <Route path='/' component={Authenticated} />
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            component={RecipientForm}
            path={ROUTES.RECIPIENT_FORM_PAGE}
          />
          <Route exact path={ROUTES.USER_SIGN_IN} component={SignIn} />
          <Route exact path={ROUTES.USER_SIGN_UP} component={SignUp} />
          <Route
            exact
            path={ROUTES.USER_NEW_PASSWORD}
            component={SetupPassword}
          />
          <Route
            exact
            path={ROUTES.USER_FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route
            exact
            path={ROUTES.USER_RESET_PASSWORD}
            component={ResetPassword}
          />
          <Route exact path={ROUTES.USER_VERIFY} component={Verification} />
          <Redirect to='/' />
        </Switch>
      )}
    </Router>
  );
};

export default RootRoutes;
