import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, MenuItem } from "@material-ui/core";
import { Error, Loader, Radio } from "@components/common";
import { useDispatch } from "react-redux";
import { TagType, AutomationPayload } from "@types";
import InfoIcon from "@assets/icons/info.svg";
import EyeIcon from "@assets/icons/eye.svg";
import { SelectContactsModal } from "@components/common/Order/Modals/SelectContactsModal";
import { TagsInfoModal } from "@components/common/Order/Modals/TagsInfoModal";
import * as S from "../../styled";
import { showModal } from "../../../../modal/actions";
import { useContacts } from "../../../../contacts/hooks";
import { FormType } from "../types";

type CreateStep1Props = {
  companyId: string;
  onClose: () => void;
  tags: TagType[];
  setPayload: Dispatch<SetStateAction<AutomationPayload>>;
  payload: AutomationPayload | null;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
  contactDateType: "birthday" | "anniversary";
};

export const CreateStep1: React.FC<CreateStep1Props> = ({
  companyId,
  onClose,
  tags,
  setPayload,
  payload,
  setFormState,
  formState,
}) => {
  const [sendTo, setSendTo] = useState<"contact_id" | "contact_tag">(
    payload?.tags ? "contact_tag" : "contact_id"
  );
  const [selectedTag, setSelectedTag] = useState<string | null>(
    payload?.tags ? payload.tags[0] : null
  );
  const [selectedTagTitle, setSelectedTagTitle] = useState<string | null>(null);
  const [selectedContacts, setSelectedContacts] = useState<string[]>(
    formState.selectedContacts?.map((contact) => contact.id)
  );

  const [showTagsInfoModal, setShowTagsInfoModal] = useState(false);

  const [showContactsModal, setShowContactsModal] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    data: contacts,
    isLoading,
    isError,
  } = useContacts(companyId, 1, selectedTagTitle);

  useEffect(() => {
    if (!payload?.tags && tags) {
      setSelectedTag(tags[0]?.id);
      setSelectedTagTitle(tags[0]?.tag_string);
    }
    if (payload?.tags) {
      setSelectedTagTitle(
        tags.find((tag) => tag.id === payload.tags[0]).tag_string
      );
    }
  }, []);

  useEffect(() => {
    if (sendTo === "contact_tag" && contacts) {
      setFormState((state) => ({
        ...state,
        selectedContactsCount: contacts.paging.total_records,
      }));
    }
  }, [contacts, sendTo, setFormState]);

  if (isError) return <Error />;

  return (
    <S.StepBody>
      <Radio
        value={sendTo}
        defaultValue={sendTo}
        onChange={(e) => setSendTo(e.target.value)}
        name='type'
        items={[
          {
            value: "contact_id",
            label: intl.formatMessage({
              id: "automations.create.step1.variantContacts",
            }),
          },
          {
            value: "contact_tag",
            label: intl.formatMessage({
              id: "automations.create.step1.variantTag",
            }),
          },
        ]}
      />
      {sendTo === "contact_id" && (
        <div>
          <Button
            color='primary'
            onClick={() => setShowContactsModal(true)}
            variant='outlined'
          >
            <FormattedMessage id='automations.create.step1.variantContacts' />
          </Button>
          {showContactsModal && (
            <SelectContactsModal
              ctaHandle={setSelectedContacts}
              selectedContacts={selectedContacts}
              setSelectedContactsList={setFormState}
              companyId={companyId}
              hideModal={() => setShowContactsModal(false)}
            />
          )}
        </div>
      )}
      {sendTo === "contact_id" && formState?.selectedContacts?.length > 0 && (
        <S.InfoTextStyled>
          <Button
            onClick={() => {
              dispatch(
                showModal({
                  type: "CAMPAIGN_CONTACTS_MODAL",
                  data: {
                    title: intl.formatMessage({
                      id: "automations.create.recipients.modal.title",
                    }),
                    contacts: formState?.selectedContacts,
                  },
                })
              );
            }}
            startIcon={<EyeIcon />}
            disableRipple
            disabled={formState?.selectedContacts?.length === 0}
          >
            <u>
              <FormattedMessage
                id='automations.create.step1.recipients'
                values={{
                  count: formState?.selectedContacts?.length,
                }}
              />
            </u>
          </Button>{" "}
          <FormattedMessage id='automations.create.step1.matchCriteria' />
        </S.InfoTextStyled>
      )}
      {sendTo === "contact_tag" && (
        <>
          <S.StepSelectRow>
            <S.SelectStyled
              id='tag'
              name='tag-input'
              label={intl.formatMessage({
                id: "automations.create.step1.selectTagLabel",
              })}
              value={selectedTag}
              defaultValue={selectedTag}
              hooked={false}
              disabled={tags.length === 0}
              error={tags.length === 0}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedTag(e.target.value);
                setSelectedTagTitle(
                  tags.find((tag) => tag.id === e.target.value)?.tag_string
                );
              }}
            >
              {tags.length > 0 ? (
                tags.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.tag_string}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value='' selected>
                  <FormattedMessage id='automations.create.step1.noTags' />
                </MenuItem>
              )}
            </S.SelectStyled>
            <S.TagInfoToggle onClick={() => setShowTagsInfoModal(true)}>
              <InfoIcon stroke='#A0A0A0' />
              <FormattedMessage id='automations.create.step1.aboutTags' />
            </S.TagInfoToggle>
          </S.StepSelectRow>

          {showTagsInfoModal && (
            <TagsInfoModal hideModal={() => setShowTagsInfoModal(false)} />
          )}

          <S.InfoTextStyled>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {contacts?.paging.total_records > 0 ? (
                  <Button
                    onClick={() => {
                      dispatch(
                        showModal({
                          type: "CAMPAIGN_CONTACTS_MODAL",
                          data: {
                            title: intl.formatMessage({
                              id: "automations.create.recipients.modal.title",
                            }),
                            tag: selectedTagTitle,
                            companyId,
                          },
                        })
                      );
                    }}
                    startIcon={<EyeIcon />}
                    disableRipple
                  >
                    <u>
                      <FormattedMessage
                        id='automations.create.step1.recipients'
                        values={{ count: contacts?.paging.total_records }}
                      />{" "}
                    </u>
                  </Button>
                ) : (
                  <FormattedMessage id='orders.noContacts' />
                )}
                <FormattedMessage id='automations.create.step1.matchCriteria' />
              </>
            )}
          </S.InfoTextStyled>
        </>
      )}
      <div>
        <Button
          variant='contained'
          color='primary'
          disabled={
            (tags?.length === 0 && sendTo === "contact_tag") ||
            (!selectedContacts?.length && sendTo === "contact_id") ||
            (sendTo === "contact_tag" && contacts?.paging.total_records === 0)
          }
          onClick={() => {
            setPayload((state) => {
              const newState: AutomationPayload = {
                ...state,
                contacts_filter_type: sendTo,
                ...(sendTo === "contact_id" && {
                  contacts: selectedContacts,
                }),
                ...(sendTo === "contact_tag" && {
                  tags: [selectedTag],
                }),
              };
              if (sendTo !== "contact_tag") {
                delete newState.tags;
              }
              if (sendTo !== "contact_id") {
                delete newState.contacts;
              }
              return newState;
            });
            setFormState((state) => ({
              ...state,
              ...(sendTo === "contact_id" && {
                selectedContactsCount: selectedContacts.length,
              }),
              toType: sendTo,
            }));
            onClose();
          }}
        >
          <FormattedMessage id='general.save' />
        </Button>
        <Button color='secondary' onClick={onClose}>
          <FormattedMessage id='general.cancel' />
        </Button>
      </div>
    </S.StepBody>
  );
};
