import styled, { css } from "styled-components";

export const FormDraggableTooltipStyled = styled.div`
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: #fff;
  border-radius: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  max-width: 400px;

  > svg {
    position: absolute;
    top: calc(50% - 13px);
    left: -13px;
  }

  a {
    margin-top: 12px;
  }
`;

export const FormDraggableStyled = styled.div<{ isDragAccept: boolean }>`
  padding: 48px;
  border: 2px dashed #ececec;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  position: relative;

  ${({ isDragAccept, theme }) =>
    isDragAccept &&
    css`
      border-color: ${theme.palette.primary.main};

      ${FormDraggableTooltipStyled} {
        display: none;
      }
    `}

  &:hover {
    ${FormDraggableTooltipStyled} {
      opacity: 1;
      visibility: visible;
      transform: translateY(-10px);
    }
  }
`;

export const FormFileLabelStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  svg {
    position: relative;
    top: 2px;
  }
`;

export const ImportedFiles = styled.div`
  padding-bottom: 32px;

  @media (min-width: 1200px) {
    width: auto;
  }

  table {
    width: auto;
  }

  table tr {
    border: 0;
  }

  table td {
    padding: 6px 0;
  }

  .file {
    text-decoration: underline;
    padding: 0 24px;
  }
`;

export const ImportedFilesStatus = styled.div<{
  status: "requested" | "processing" | "completed" | "failed";
}>`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  white-space: nowrap;

  ${({ status }) =>
    status === "requested" &&
    css`
      padding: 6px 8px;
      background: #f8e688;
      border-radius: 8px;
    `}
`;

export const ButtonStyled = styled.a`
  background-color: #fff;

  &:hover {
    background-color: #fff;
  }
`;
