import { Typography } from "@material-ui/core";
import { STYLE } from "@constants/index";
import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 100vh;

  h2 {
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
  }

  .MuiContainer-maxWidthSm {
    max-width: 620px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiInput-root,
  input {
    background-color: #fff;
  }
`;

export const FormLabel = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-right: 35px;
`;

export const FormSubmit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  @media (${STYLE.device.tablet}) {
    display: grid;
    gap: 0;
    grid-template-columns: 200px 1fr;
  }
`;

export const FormBlock = styled.div`
  padding: 16px;
  background: #f8f6f4;
  border-radius: 8px;

  > .MuiFormControl-root {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FormBlockRow = styled(FormBlock)`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;

  img {
    width: 130px;
  }
`;
