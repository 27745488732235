import React, {
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, MenuItem, Typography } from "@material-ui/core";
import { DatePicker, Radio } from "@components/common";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import {
  ContactType,
  CompanyAddressType,
  OrderPayload,
  DeliveryDetailsSourceType,
} from "@types";
import AddIcon from "@assets/icons/circle-plus.svg";
import AddressIcon from "@assets/icons/house.svg";
import { showModal } from "../../../../modal/actions";
import { isWeekend, handleWeekends } from "@utils/common";
import * as S from "../../styled";

type CreateStep4Props = {
  companyId: string;
  onClose: () => void;
  selectedAddress?: string;
  disableAsk: boolean;
  contacts: ContactType[];
  offices: CompanyAddressType[];
  setPayload: Dispatch<SetStateAction<OrderPayload>>;
  payload: OrderPayload | null;
};

const today = dayjs().startOf("date");

const minDate = dayjs().startOf("date").add(4, "days");
const defaultDate = handleWeekends(minDate);

export const CreateStep4: React.FC<CreateStep4Props> = ({
  companyId,
  onClose,
  selectedAddress,
  contacts,
  offices,
  setPayload,
  disableAsk,
  payload,
}) => {
  const [deliveryDetailsType, setDeliveryDetailsType] = useState<
    "predefined" | "requested"
  >(
    payload?.delivery_details_source_type === "requested_from_recipient"
      ? "requested"
      : "predefined"
  );
  const [deliveryDate, setDeliveryDate] = useState<Dayjs>(
    dayjs(payload?.delivery_datetime || defaultDate)
  );
  const [deliveryNotifyDate, setDeliveryNotifyDate] = useState<Dayjs>(
    dayjs(payload?.delivery_notification_date || today.add(1, "day"))
  );
  const [deliveryAddress, setDeliveryAddress] = useState<string | null>(
    payload?.delivery_address_id || ""
  );
  const [deliveryNotifyType, setDeliveryNotifyType] = useState<"sms" | "email">(
    "email"
  );
  const [deliveryAddressType, setDeliveryAddressType] =
    useState<DeliveryDetailsSourceType>(
      payload?.delivery_details_source_type === "requested_from_recipient"
        ? "office_address"
        : payload?.delivery_details_source_type || "office_address"
    );

  const dispatch = useDispatch();
  const intl = useIntl();

  const contactsWithoutEmails = useMemo(() => {
    if (contacts?.length > 0) {
      return contacts.filter((contact) => !contact.email?.length);
    }
    return [];
  }, [contacts]);

  const contactsWithoutPhones = useMemo(() => {
    if (contacts?.length > 0) {
      return contacts.filter((contact) => !contact.phone_number?.length);
    }
    return [];
  }, [contacts]);

  const selectedOffice = useMemo(() => {
    return offices?.find((office) => office.id === deliveryAddress);
  }, [offices, deliveryAddress]);

  useEffect(() => {
    if (offices && deliveryAddressType === "office_address") {
      if (selectedAddress) {
        setDeliveryAddress(
          offices.find((office) => office.id === selectedAddress).id
        );
      } else {
        setDeliveryAddress(offices[0]?.id);
      }
    }
  }, [offices, deliveryAddressType, selectedAddress]);

  return (
    <S.StepBody>
      <Radio
        value={deliveryDetailsType}
        defaultValue={deliveryDetailsType}
        onChange={(e) => setDeliveryDetailsType(e.target.value)}
        name='type'
        items={[
          {
            value: "predefined",
            label: intl.formatMessage({
              id: "orders.create.step4.variant1",
            }),
          },
          {
            value: "requested",
            label: intl.formatMessage({
              id: "orders.create.step4.variant2",
            }),
            disabled: disableAsk,
          },
        ]}
      />
      {deliveryDetailsType === "predefined" && (
        <>
          <div>
            <DatePicker
              hooked={false}
              value={deliveryDate}
              onChange={(value: Dayjs) => setDeliveryDate(dayjs(value))}
              defaultValue={minDate}
              label={intl.formatMessage({
                id: "orders.create.step4.predefined.dateLabel",
              })}
              shouldDisableDate={isWeekend}
              minDate={minDate}
              name='quantity'
              direction='row'
              disablePast
            />
          </div>

          <S.StepSelectRow>
            <S.SelectStyled
              id='addressType'
              name='addressType'
              label={intl.formatMessage({
                id: "orders.create.step4.predefined.addressLabel",
              })}
              defaultValue={deliveryAddressType}
              value={deliveryAddressType}
              hooked={false}
              // @ts-expect-error
              onChange={(e) => setDeliveryAddressType(e.target.value)}
            >
              <MenuItem value='office_address'>
                <FormattedMessage id='orders.create.step4.addressType.variant1' />
              </MenuItem>
              <MenuItem
                value='contact_custom_address'
                disabled={!!payload?.volume}
              >
                <FormattedMessage id='orders.create.step4.addressType.variant2' />
              </MenuItem>
              <MenuItem
                value='contact_office_address'
                disabled={!!payload?.volume}
              >
                <FormattedMessage id='orders.create.step4.addressType.variant3' />
              </MenuItem>
            </S.SelectStyled>
            {deliveryAddressType === "office_address" &&
              offices?.length > 0 && (
                <>
                  <S.SelectStyled
                    id='offices'
                    name='offices-input'
                    label=''
                    defaultValue={deliveryAddress}
                    value={deliveryAddress}
                    hooked={false}
                    // @ts-expect-error
                    onChange={(e: React.ChangeEvent) =>
                      setDeliveryAddress(e.target.value)
                    }
                  >
                    {offices.map((office) => (
                      <MenuItem key={office.id} value={office.id}>
                        {office.name}
                      </MenuItem>
                    ))}
                  </S.SelectStyled>
                  {selectedOffice && (
                    <S.AddressInfo>
                      <AddressIcon />
                      {`${selectedOffice?.street}, ${selectedOffice.zip}, ${selectedOffice.city}, contact: ${selectedOffice.contact_name}, phone: ${selectedOffice.contact_phone}, ${selectedOffice.contact_email}`}
                    </S.AddressInfo>
                  )}
                </>
              )}
            {offices.length === 0 &&
              deliveryAddressType === "office_address" && (
                <Button
                  onClick={() => {
                    dispatch(
                      showModal({
                        type: "ADD_OFFICE_ADDRESS_MODAL",
                        data: {
                          title: intl.formatMessage({
                            id: "settings.addresses.add.heading",
                          }),
                          ctaTitle: intl.formatMessage({
                            id: "settings.addresses.add.heading",
                          }),
                          companyId,
                        },
                      })
                    );
                  }}
                  startIcon={<AddIcon />}
                  variant='text'
                  color='primary'
                >
                  <FormattedMessage id='orders.create.createOfficeTitle' />
                </Button>
              )}
          </S.StepSelectRow>
        </>
      )}
      <div>
        {deliveryDetailsType === "requested" && (
          <S.AskRecipient>
            <Typography>
              <FormattedMessage
                tagName='b'
                id='orders.create.step4.askRecipient.notify'
              />
            </Typography>
            <DatePicker
              hooked={false}
              value={deliveryNotifyDate}
              onChange={(value: Dayjs) => setDeliveryNotifyDate(dayjs(value))}
              defaultValue={today.add(1, "day")}
              label={intl.formatMessage({
                id: "orders.create.step4.askRecipient.dateLabel",
              })}
              name='notifyDate'
              direction='row'
              required
              offsetbottom='false'
              minDate={today.add(1, "day")}
              disablePast
            />
            <S.SelectStyled
              id='offices'
              name='offices-input'
              label={intl.formatMessage({
                id: "orders.create.step4.askRecipient.howLabel",
              })}
              defaultValue={deliveryNotifyType}
              value={deliveryNotifyType}
              hooked={false}
              // @ts-expect-error
              onChange={(e) => setDeliveryNotifyType(e.target.value)}
            >
              <MenuItem value='email'>
                <FormattedMessage id='orders.create.step4.notify.option1' />
              </MenuItem>
              <MenuItem disabled value='sms'>
                <FormattedMessage id='orders.create.step4.notify.option2' />
              </MenuItem>
            </S.SelectStyled>
          </S.AskRecipient>
        )}
      </div>
      {/* {deliveryAddressType === "office" && selectedAddress && (
        <S.InfoTextStyled>
          <InfoIcon />
          <FormattedMessage id='orders.create.step4.officeInfoMsg' />
        </S.InfoTextStyled>
      )} */}
      {deliveryNotifyType === "email" && contactsWithoutEmails.length > 0 && (
        <S.AlertStyled variant='warning'>
          <div>
            <FormattedMessage
              id='orders.create.step1.recipients'
              values={{
                count: contactsWithoutEmails?.length,
              }}
              tagName='b'
            />
            <FormattedMessage id='orders.create.step4.emailIsMissingMsg' />
            <u>
              <FormattedMessage id='orders.create.step4.update' />
            </u>
          </div>
        </S.AlertStyled>
      )}
      {deliveryNotifyType === "sms" && contactsWithoutPhones.length > 0 && (
        <S.AlertStyled variant='warning'>
          <div>
            <FormattedMessage
              id='orders.create.step1.recipients'
              values={{
                count: contactsWithoutEmails?.length,
              }}
              tagName='b'
            />{" "}
            <FormattedMessage id='orders.create.step4.phoneIsMissingMsg' />
            <u>
              <FormattedMessage id='orders.create.step4.update' />
            </u>
          </div>
        </S.AlertStyled>
      )}
      {/* {(deliveryNotifyType === "email" && contactsWithoutEmails.length === 0) ||
      (deliveryNotifyType === "sms" && contactsWithoutPhones.length === 0) ? (
        <S.InfoTextStyled>
          <EyeIcon />
          <FormattedMessage id='orders.create.step4.finalMsg' />
        </S.InfoTextStyled>
      ) : null} */}
      <div>
        <Button
          variant='contained'
          color='primary'
          disabled={
            !dayjs(deliveryDate).isSameOrAfter(minDate) ||
            deliveryNotifyDate.isBefore(today.add(1, "day"))
          }
          onClick={() => {
            setPayload((state: OrderPayload) => {
              const newPayload = {
                ...state,
                delivery_details_source_type:
                  deliveryDetailsType === "predefined"
                    ? deliveryAddressType
                    : "requested_from_recipient",

                ...(deliveryDetailsType === "requested" && {
                  delivery_notification_date:
                    dayjs(deliveryNotifyDate).toISOString(),
                  delivery_notification_type: deliveryNotifyType,
                }),

                ...(deliveryDetailsType === "predefined" && {
                  delivery_datetime: dayjs(deliveryDate).toISOString(),
                  // delivery_address_id: deliveryAddress,
                }),

                ...(deliveryAddressType === "office_address" && {
                  delivery_address_id: deliveryAddress,
                }),
              };
              if (deliveryDetailsType === "requested") {
                delete newPayload.delivery_address_id;
              }

              if (deliveryDetailsType !== "requested") {
                delete newPayload.delivery_notification_type;
              }

              if (
                deliveryDetailsType === "predefined" &&
                deliveryAddressType !== "office_address"
              ) {
                delete newPayload.delivery_address_id;
              }

              if (
                deliveryDetailsType === "predefined" &&
                deliveryAddressType === "office_address" &&
                !deliveryAddress
              ) {
                delete newPayload.delivery_address_id;
                delete newPayload.delivery_details_source_type;
                delete newPayload.delivery_datetime;
              }

              return newPayload;
            });
            onClose();
          }}
        >
          <FormattedMessage id='general.save' />
        </Button>
        <Button color='secondary' onClick={onClose}>
          <FormattedMessage id='general.cancel' />
        </Button>
      </div>
    </S.StepBody>
  );
};
