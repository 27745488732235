import styled from "styled-components";

export const Footer = styled.footer`
  padding: 40px 0;
  background: #f8f6f4;
  color: #b9b9b9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  flex-direction: column;
  gap: 8px;
`;

export const HelpButton = styled.button`
  background: transparent;
  border: none;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: inherit;
  gap: 8px;
  padding: 0;
`;
