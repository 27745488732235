import React, { useContext } from "react";
import { Route, Switch } from "react-router";
import { AuthContext } from "@providers/AuthProvider";
import { List } from "./List";
import { Orders } from "./Orders";
import { CreateAutomation } from "./Create";

export const Automations: React.FC = () => {
  const { user } = useContext(AuthContext);

  return (
    <Switch>
      <Route exact path='/automations' component={List} />
      <Route
        exact
        path='/automations/:automationId/:page'
        render={(props) => <Orders companyId={user.company_id} {...props} />}
      />
      <Route
        exact
        path='/automations/create/:id?'
        component={CreateAutomation}
      />
    </Switch>
  );
};
