import { Loader, SectionNav, Button, Error } from "@components/common";
import React, { useContext, useState } from "react";
import { COMMON, ROUTES } from "@constants/index";
import { Switch, useParams, Route, useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import ContactIcon from "@assets/icons/user.svg";
import GiftIcon from "@assets/icons/gift.svg";
import BackIcon from "@assets/icons/arrow-back.svg";
// import TargetIcon from "@assets/icons/target.svg";
import { AuthContext } from "@providers/AuthProvider";
import {
  SubHeadingStyled,
  HeaderStyled,
  TagRowStyled,
  TagStyled,
  ButtonBackStyled,
  CtaList,
} from "../../../styled";
import { useContact } from "../../../hooks";
import EditContact from "../../EditContact/index";
import Deliveries from "./Deliveries";
import ContactAddress from "../../ContactAddress";
// import { AssignExternalGiftModal } from "./AssignExternalGiftModal";

const Contact: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { data: contact, isLoading } = useContact(user.company_id, id);
  // const [showAssignModal, setShowAssignModal] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  if (!id || !contact) {
    return <Error />;
  }

  return (
    <>
      <ButtonBackStyled to='/people/overview' variant='text'>
        <BackIcon />
        <FormattedMessage id='contacts.edit.back' />
      </ButtonBackStyled>
      <HeaderStyled>
        <SubHeadingStyled component='h2' variant='h2'>
          <ContactIcon />
          {contact.first_name} {contact.last_name}
          <TagRowStyled>
            {contact.tags.map((tag) => (
              <TagStyled key={tag.tag_string}>{tag.tag_string}</TagStyled>
            ))}
          </TagRowStyled>
          <CtaList>
            <Button
              startIcon={<GiftIcon />}
              color='primary'
              variant='text'
              disableRipple
              onClick={() => {
                history.push({
                  pathname: "/orders/create",
                  state: {
                    contact,
                  },
                });
              }}
            >
              <FormattedMessage id='contacts.main.createOrder' />
            </Button>
            {/* <Button
              startIcon={<TargetIcon />}
              color='primary'
              variant='text'
              disableRipple
              onClick={() => setShowAssignModal(true)}
            >
              <FormattedMessage id='contacts.main.assignExternalGift' />
            </Button> */}
          </CtaList>
        </SubHeadingStyled>
      </HeaderStyled>
      {/* {showAssignModal && (
        <AssignExternalGiftModal
          contact={contact}
          hideModal={() => setShowAssignModal(false)}
        />
      )} */}
      <SectionNav navItems={COMMON.CONTACT_NAV(id)} />
      <Switch>
        <Route
          path={ROUTES.CUSTOMERS_PROFILE_HISTORY}
          // eslint-disable-next-line
          component={() => <Deliveries contact={contact} userInfo={user} />}
        />
        <Route
          path={ROUTES.CUSTOMERS_PROFILE_OVERVIEW}
          // eslint-disable-next-line
          component={() => <EditContact contact={contact} userInfo={user} />}
        />
        <Route
          path={ROUTES.CUSTOMERS_PROFILE_ADDRESS}
          component={ContactAddress}
        />
      </Switch>
    </>
  );
};

export default Contact;
