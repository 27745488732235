import styled from "styled-components";

type GiftStatus = "in_stock" | "coming_soon" | "preparing";

export const Price = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`;

export const Description = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #a0a0a0;

  * {
    font-size: 12px;
    margin: 0;
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
`;

export const GiftStatusStyled = styled.span<{
  variant: GiftStatus | "external";
}>`
  color: ${({ variant }) => (variant === "in_stock" ? "#00CA81" : "#A0A0A0")};

  font-weight: 600;
  display: block;
  margin-top: auto;
`;

export const GiftBodyStyled = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;

  > span {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const GiftStyled = styled.li`
  list-style: none;
  border-radius: 12px;
  height: 100%;

  > a,
  > div {
    min-height: 330px;
    height: 100%;
    border: 1px solid #ececec;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: #000;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  .img-loader {
    width: 100%;
    height: 196px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 196px;
    position: relative;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 196px;
    object-fit: cover;
    display: block;
  }

  &.promo {
    background-color: #f8f6f4;
    ${GiftBodyStyled} {
      align-items: center;
      justify-content: center;
      text-align: center;
      flex: 1;
    }

    img {
      height: 105px;
    }
  }
`;

export const BadgeStyled = styled.span`
  font-weight: 600;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #a0a0a0;
`;

export const Badge = styled.div`
  min-height: 30px;
  color: #000;
  text-transform: uppercase;
  background-color: #fff9ea;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  position: absolute;
  left: 16px;
  top: 12px;
`;
