import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import { TagType, OrderPayload } from "@types";
import CompletedIcon from "@assets/icons/completed.svg";
import { StepTitle } from "@components/common/Order/StepTitle/StepTitle";
import * as S from "../../styled";
import { CreateStep1 } from "./Step1";
import { FormType } from "../types";

export const To: React.FC<{
  companyId: string;
  expanded: boolean;
  completed: boolean;
  tags: TagType[];
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  payload: OrderPayload | null;
  setPayload: Dispatch<SetStateAction<OrderPayload>>;
  disabled: boolean;
  invalid: boolean;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
}> = ({
  companyId,
  expanded,
  tags,
  handleShowStepContent,
  payload,
  setPayload,
  disabled,
  invalid,
  completed,
  formState,
  setFormState,
}) => {
  const [inProgress, setInProgress] = useState(false);

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {completed && <CompletedIcon />}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='orders.create.step1.title' />
            </Typography>
            {completed ? (
              <Typography>
                <StepTitle
                  payload={payload}
                  type={
                    // eslint-disable-next-line
                    payload.contact_tags
                      ? "tag"
                      : payload.contacts
                      ? "contacts"
                      : "amount"
                  }
                  tagName={
                    tags.find((tag) => tag.id === payload?.contact_tags?.[0])
                      ?.tag_string
                  }
                  selectedContactsCount={formState.selectedContactsCount}
                />
              </Typography>
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='orders.create.step1.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>

        {!expanded && (
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(0, true, false);
            }}
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='orders.create.step.edit' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>

      {expanded && (
        <CreateStep1
          companyId={companyId}
          onClose={() => {
            setInProgress(false);
            handleShowStepContent(0, false, false);
          }}
          tags={tags}
          setPayload={setPayload}
          payload={payload}
          formState={formState}
          setFormState={setFormState}
        />
      )}
    </S.CreateCampaignStep>
  );
};
