import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ROUTES } from "@constants/index";
import { Contacts } from "../../../../contacts";
import { Gifts } from "../../../../gifts";
import { Settings } from "../../../../account";
import { Automations } from "../../../../automations";
import { Orders } from "../../../../orders";
import { Header } from "./Header";
import { UserProfileType } from "@types";
import * as S from "./styled";

import "react-toastify/dist/ReactToastify.css";

const Main = ({ user }: { user: UserProfileType }) => (
  <S.Container isAdmin={user.is_admin}>
    <Header />
    <Switch>
      <Route path={ROUTES.MAIN_GIFTS} component={Gifts} />
      <Route path={ROUTES.MAIN_SETTINGS} component={Settings} />
      <Route path={ROUTES.MAIN_CUSTOMERS} component={Contacts} />
      <Route path={ROUTES.MAIN_CAMPAIGNS} component={Automations} />
      <Route path={ROUTES.MAIN_ORDERS} component={Orders} />
      <Redirect to={ROUTES.GIFTS_MY_GIFTS} />
    </Switch>
  </S.Container>
);

export default Main;
