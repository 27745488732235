export const DEFAULT_LANGUAGE = "en";

export const LANGUAGES = {
  en: "en",
  sk: "sk",
} as const;

export const MESSAGES_LANGUAGES = {
  en: "en",
  sk: "sk",
} as const;

export const DEFAULT_INPUT_DEBOUNCE_MILLISECONDS = 500;
export const DEFAULT_LOADING_ITEMS_COUNT = 10;
export const DEFAULT_DATE_FORMAT = "DD.MM.YYYY";
export const DEFAULT_DATE_FORMAT_SHORT = "DD.MM.YYYY";
export const DEFAULT_INPUT_DATE_FORMAT_SHORT = "YYYY-MM-DD";
export const DEFAULT_TABLE_SORTING_ORDER = "asc";

export const MAIN_TABLE_INFINITE_SCROLL_OFFSET = 100;

export const PAGINATION_ROWS_PER_PAGE = 30;
export const PAGINATION_ROWS_PER_PAGE_VARIANTS = [10, 20, 50] as const;

export const AUTH_RESEND_CODE_TIMER = 60;

export const TEXTAREA_MAX_LENGTH = 500;
export const TEXTAREA_SHORT_MAX_LENGTH = 30;

// sub navigation (tabs) TODO: add translations
export const CONTACTS_NAV = [
  {
    title: "contacts.nav.overview",
    link: "/people/overview",
  },
  {
    title: "contacts.nav.add",
    link: "/people/add",
  },
  {
    title: "contacts.nav.import",
    link: "/people/import",
  },
];

export const CONTACT_NAV = (id: string) => [
  {
    title: "contact.nav.history",
    link: `/people/${id}/history`,
    section: "history",
  },
  {
    title: "contact.nav.details",
    link: `/people/${id}/overview`,
    section: "overview",
  },
  {
    title: "contact.nav.addresses",
    link: `/people/${id}/address`,
    section: "address",
  },
];

export const ACCOUNT_NAV = [
  {
    title: "settings.myAccount",
    link: "/settings/my-account",
  },
  {
    title: "settings.company.details",
    link: "/settings/company-details",
  },
  {
    title: "settings.billing.history",
    link: "/settings/credits",
  },
  {
    title: "settings.office.addresses",
    link: "/settings/addresses",
  },
];

export const S3_ASSETS_URL = "https://s3-general-public-assets-boxday";
