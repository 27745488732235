import React from "react";
import { Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Button from "../Button";
import * as S from "./styled";

type ModalProps = {
  show?: boolean;
  handleClose: () => void;
  ctaTitle?: string;
  cancelTitle?: string;
  cta?: () => void;
  title: string;
  subTitle?: string;
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl";
  isLoading?: boolean;
  showCloseBtn?: boolean;
  stretchCta?: boolean;
};

const Modal: React.FC<ModalProps & React.PropsWithChildren> = ({
  show = false,
  handleClose,
  cta,
  ctaTitle = "Submit",
  cancelTitle,
  title,
  subTitle,
  children,
  maxWidth = "sm",
  isLoading = false,
  showCloseBtn = true,
  stretchCta = false,
}) => (
  <S.DialogStyled
    open={show}
    onClose={handleClose}
    maxWidth={maxWidth}
    disableEnforceFocus
    fullWidth
  >
    <S.DialogContentStyled>
      {showCloseBtn && (
        <S.IconButtonStyled
          aria-label='close modal'
          type='button'
          onClick={handleClose}
        >
          <Close fontSize='small' />
        </S.IconButtonStyled>
      )}

      {title && (
        <S.DialogHeaderStyled size='lg'>
          <Typography variant='h5' component='h5'>
            {title}
          </Typography>
          <div>{subTitle && subTitle}</div>
        </S.DialogHeaderStyled>
      )}
      {children && <S.DialogBodyStyled>{children}</S.DialogBodyStyled>}
      {(cancelTitle || cta) && (
        <S.DialogFooterStyled stretchCta={stretchCta}>
          {cta && (
            <Button disabled={isLoading} onClick={cta}>
              {ctaTitle}
            </Button>
          )}
          {cancelTitle && (
            <Button
              disabled={isLoading}
              color='default'
              variant='outlined'
              onClick={handleClose}
            >
              {cancelTitle}
            </Button>
          )}
        </S.DialogFooterStyled>
      )}
    </S.DialogContentStyled>
  </S.DialogStyled>
);

export default Modal;
