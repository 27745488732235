import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Loader, NoData, Pagination, Table, Error } from "@components/common";
import {
  Drawer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import EmptyListIcon from "@assets/icons/addresses-empty.png";
import { AuthContext } from "@providers/AuthProvider";
import { DeliveryDetails, SingleDelivery } from "../Delivery";
import { useOrderDeliveries } from "../../../hooks";
import * as S from "../../styled";

const RenderTableHead = ({ amount }: { amount?: boolean }) => (
  <TableHead>
    <TableRow>
      <TableCell>
        <FormattedMessage id='orders.list.table.date' />
      </TableCell>
      <TableCell>#</TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.status' />
      </TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.name' />
      </TableCell>
      {amount && (
        <TableCell>
          <FormattedMessage id='orders.list.table.volume' />
        </TableCell>
      )}
      <TableCell>
        <FormattedMessage id='orders.list.table.actions' />
      </TableCell>
    </TableRow>
  </TableHead>
);

export const Upcoming: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams<{ id: string }>();
  const [showDetailModalId, setDetailsModalId] = useState(null);
  const [paginationPage, setPaginationPage] = useState(1);
  const {
    data: deliveries,
    isLoading,
    isError,
  } = useOrderDeliveries(user.company_id, id, { page: paginationPage });

  if (isError) {
    return <Error />;
  }

  if (isLoading) return <Loader />;

  return (
    <S.Container>
      {deliveries?.items.length > 0 ? (
        <S.OrderSection>
          <Table>
            <RenderTableHead amount={!deliveries?.items[0]?.contact} />
            <TableBody>
              {deliveries.items.map((delivery) => (
                <TableRow
                  key={delivery.id}
                  onClick={() => {
                    setDetailsModalId(delivery.id);
                  }}
                >
                  <SingleDelivery
                    delivery={delivery}
                    companyId={user.company_id}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </S.OrderSection>
      ) : (
        <NoData>
          <img width='76' src={EmptyListIcon} alt='' />
          <Typography component='h2' variant='h3'>
            <FormattedMessage id='orders.empty.heading' />
          </Typography>
        </NoData>
      )}
      {showDetailModalId && (
        <Drawer open anchor='right' onClose={() => setDetailsModalId(null)}>
          <DeliveryDetails delivery_id={showDetailModalId} />
        </Drawer>
      )}
      <Pagination
        page={paginationPage}
        onChange={setPaginationPage}
        total={deliveries?.paging.total_records}
      />
    </S.Container>
  );
};
