import React from "react";
import AddAddressModal from "../../../../account/components/Addresses/components/AddAddressModal";
import AddCreditModal from "../../../../account/components/Orders/components/AddCreditModal";
import SelectGiftModal from "../../../../shared/components/common/Order/Modals/SelectGiftModal";
import CampaignContactsModal from "../../../../shared/components/common/Order/Modals/CampaignContactsModal";

type Modals = { [name: string]: React.FunctionComponent };

const MODAL_COMPONENTS: Modals = {
  ADD_OFFICE_ADDRESS_MODAL: AddAddressModal,
  ADD_CREDIT_MODAL: AddCreditModal,
  SELECT_GIFT_MODAL: SelectGiftModal,
  CAMPAIGN_CONTACTS_MODAL: CampaignContactsModal,
};

const ModalRoot = ({
  type,
  ...props
}: {
  type: keyof typeof MODAL_COMPONENTS;
}) => {
  if (!type) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];

  return <SpecificModal {...props} />;
};

export default ModalRoot;
