import React, { useContext, useEffect } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Loader, Select, TextField } from "@components/common";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import {
  useContact,
  useUpdateContactAddress,
  useContactAddress,
} from "../../hooks";
import { AuthContext } from "@providers/AuthProvider";
import { Section } from "./styled";
import {
  ReactGoogleAutocompleteProps,
  usePlacesWidget,
} from "react-google-autocomplete";

type placeType = {
  address_components: ReactGoogleAutocompleteProps["options"]["fields"];
};

type placeAddress = ReactGoogleAutocompleteProps["options"]["fields"];
const getStreet = (place: placeType) =>
  place?.address_components?.find((address: placeAddress) =>
    address.types.includes("route")
  )?.long_name || "";
const getCity = (place: placeType) =>
  place?.address_components?.find((address: placeAddress) =>
    address.types.includes("locality")
  )?.long_name ||
  place?.address_components?.find((address: placeAddress) =>
    address.types.includes("sublocality_level_1")
  )?.long_name ||
  "";
const getStreetNumber = (place: placeType) =>
  place?.address_components?.find((address: placeAddress) =>
    address.types.includes("street_number")
  )?.long_name || "";
const getCountry = (place: placeType) =>
  place?.address_components
    ?.find((address: placeAddress) => address.types.includes("country"))
    ?.short_name?.toLowerCase() || "";
const getPostalCode = (place: placeType) =>
  place?.address_components?.find((address: placeAddress) =>
    address.types.includes("postal_code")
  )?.long_name;

export const AssignCustomAddress = () => {
  const intl = useIntl();

  const { ref } = usePlacesWidget({
    onPlaceSelected: (place) => {
      if (!place.address_components) return;
      setValue(
        "address_line_1",
        `${getStreet(place)} ${getStreetNumber(place)}`?.trim() ||
          getCity(place)
      );
      // setValue("address_line_2", getStreetNumber(place));
      setValue("city", getCity(place));
      setValue("zip", getPostalCode(place));
      setValue("country_code", getCountry(place));
    },
    options: {
      fields: ["address_components", "name", "types"],
      types: ["geocode"],
      componentRestrictions: { country: ["sk", "cz"] },
    },
  });

  const { user } = useContext(AuthContext);

  const { id } = useParams<{ id: string }>();

  const { data: contact, isLoading: loading1 } = useContact(
    user.company_id,
    id
  );

  const { data: addresses, isLoading: loading2 } = useContactAddress(
    user.company_id,
    contact.id
  );

  const {
    mutate: updateContact,
    reset,
    isSuccess,
    isError,
    isLoading: loading3,
  } = useUpdateContactAddress();

  const methods = useForm();

  const {
    handleSubmit,
    formState: { errors },
    reset: resetForm,
    setValue,
  } = methods;

  const onSubmit = ({
    address_line_1,
    address_line_2,
    city,
    zip,
    country_code,
  }: {
    address_line_1: string;
    address_line_2: string;
    city: string;
    zip: string;
    country_code: string;
  }) => {
    updateContact({
      company_id: user.company_id,
      contact_id: contact?.id,
      data: {
        street: address_line_1,
        city,
        zip,
        name: "",
        country_code,
        contact_name: contact?.first_name + " " + contact?.last_name,
        contact_email: contact?.email,
        contact_phone: contact?.phone_number,
      },
    });
  };

  useEffect(() => {
    if (contact && addresses) {
      resetForm({
        address_line_1: addresses?.street,
        // address_line_2: addresses?.address_line_2,
        city: addresses?.city,
        zip: addresses?.zip,
        country_code: addresses?.country_code,
      });
    }
  }, [contact, resetForm, addresses]);

  if (isError) {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    reset();
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
    }
  }, [isSuccess]);

  if (!contact) {
    return <Loader />;
  }

  return (
    <Section>
      <FormProvider {...methods}>
        <form autoComplete='off'>
          <Grid container lg={9} xl={6} spacing={6}>
            <Grid md={6} lg={6} item>
              <TextField
                name='address_line_1'
                placeholder={intl.formatMessage({
                  id: "contacts.addresses.addressLine1",
                })}
                label={intl.formatMessage({
                  id: "contacts.addresses.addressLine1",
                })}
                type='text'
                error={errors.address_line_1}
                id='form-input-address_line_1'
                inputRef={ref}
                required
                autoComplete='off'
              />
              <TextField
                name='address_line_2'
                placeholder={intl.formatMessage({
                  id: "contacts.addresses.addressLine2",
                })}
                label={intl.formatMessage({
                  id: "contacts.addresses.addressLine2",
                })}
                type='text'
                error={errors.address_line_2}
                id='form-input-address_line_2'
              />
              <TextField
                name='city'
                placeholder={intl.formatMessage({
                  id: "contacts.addresses.city",
                })}
                label={intl.formatMessage({
                  id: "contacts.addresses.city",
                })}
                type='text'
                error={errors.city}
                id='form-input-city'
                autoComplete='city'
                required
              />
            </Grid>
            <Grid md={6} lg={6} item>
              <TextField
                name='zip'
                placeholder={intl.formatMessage({
                  id: "contacts.addresses.zip",
                })}
                label={intl.formatMessage({
                  id: "contacts.addresses.zip",
                })}
                type='text'
                error={errors.zip}
                id='form-input-zip'
                autoComplete='postal-code'
                required
              />
              <Select
                id='country_code'
                name='country_code'
                label={intl.formatMessage({
                  id: "contacts.addresses.country",
                })}
                defaultValue={addresses?.country_code || "sk"}
              >
                <MenuItem value='sk'>
                  <FormattedMessage id='shared.country.sk' />
                </MenuItem>
                <MenuItem value='cz'>
                  <FormattedMessage id='shared.country.cz' />
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Button
            disabled={loading1 || loading2 || loading3}
            loading={loading1 || loading2 || loading3}
            onClick={handleSubmit(onSubmit)}
            type='submit'
          >
            <FormattedMessage id='general.forms.buttons.save' />
          </Button>
        </form>
      </FormProvider>
    </Section>
  );
};
