import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Loader, NoData, Pagination, Table, Error } from "@components/common";
import {
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import { OrderType } from "@types";
import EmptyListIcon from "@assets/icons/addresses-empty.png";
import { filterOrdersByMonth } from "@utils/common";
import { AuthContext } from "@providers/AuthProvider";
import { sortByDateFiled } from "@utils/sorting";
import { Order } from "../Order";
import { useOrders } from "../../../hooks";
import * as S from "../../styled";

const RenderTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell>
        <FormattedMessage id='orders.list.table.date' />
      </TableCell>
      <TableCell>#</TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.status' />
      </TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.name' />
      </TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.volume' />
      </TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.gift' />
      </TableCell>
    </TableRow>
  </TableHead>
);

export const Upcoming: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [paginationPage, setPaginationPage] = useState(1);
  const {
    data: orders,
    isLoading,
    isError,
  } = useOrders(user.company_id, "upcoming", paginationPage);
  const [filteredOrders, setFilteredOrders] = useState<{
    [date: string]: OrderType[];
  }>();
  const [filtered, setFiltered] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (orders?.items) {
      const sorted = sortByDateFiled(
        orders.items,
        "requested_delivery_datetime",
        "asc"
      );
      setFilteredOrders(filterOrdersByMonth(sorted));
      setFiltered(true);
    }
  }, [orders]);

  if (isError) {
    return <Error />;
  }

  if (isLoading || !filtered) return <Loader />;

  return (
    <S.Container>
      {orders?.items.length > 0 ? (
        <S.OrderSection>
          {filteredOrders && Object.keys(filteredOrders)?.length > 0 ? (
            Object.keys(filteredOrders)?.map((date, key) => (
              <div key={uuid()}>
                <Typography variant='body2' color='textSecondary'>
                  {dayjs(date).format("MMMM YYYY")}
                </Typography>
                <Table>
                  {key === 0 && <RenderTableHead />}
                  <TableBody>
                    {filteredOrders[date].map((order) => (
                      <TableRow
                        key={order.id}
                        onClick={() =>
                          history.push(`/orders/details/${order.id}`)
                        }
                      >
                        <Order order={order} companyId={user.company_id} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ))
          ) : (
            <NoData size='small'>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <Grid item>
                  <img src={EmptyListIcon} alt='' />
                </Grid>
                <Grid item>
                  <Typography component='h4' variant='h4'>
                    <FormattedMessage id='orders.noUpcoming.title' />
                  </Typography>
                </Grid>
              </Grid>
            </NoData>
          )}
        </S.OrderSection>
      ) : (
        <NoData>
          <img width='76' src={EmptyListIcon} alt='' />
          <Typography component='h2' variant='h3'>
            <FormattedMessage id='orders.empty.heading' />
          </Typography>
          <Button
            variant='outlined'
            color='primary'
            component={Link}
            to='/orders/create'
          >
            <FormattedMessage id='orders.empty.button' />
          </Button>
        </NoData>
      )}
      <Pagination
        page={paginationPage}
        onChange={setPaginationPage}
        total={orders?.paging.total_records}
      />
    </S.Container>
  );
};
