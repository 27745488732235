import React, { useContext } from "react";
import { Summary } from "@components/common/Order/Summary/Summary";
import { AuthContext } from "@providers/AuthProvider";
import { Loader, Table } from "@components/common";
import { Typography, TableRow, TableCell, TableBody } from "@material-ui/core";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { useOrder } from "../../../hooks";
import * as S from "./styled";
import { FormattedMessage } from "react-intl";

export const OrderDetailsSummary: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams<{ id: string }>();

  const { data: order, isLoading, isError } = useOrder(user.company_id, id);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <>
      <Typography variant='h4' component='h4'>
        <FormattedMessage id='order.details.title' />
      </Typography>
      <S.SummaryTableWrapper>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage id='shared.orderId' />
                </b>
              </TableCell>
              <TableCell>#{order.readable_id?.toUpperCase()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage id='shared.created' />
                </b>
              </TableCell>
              <TableCell>
                {dayjs(order.created_at).format("DD.M.YYYY")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage id='shared.totalAmount' />
                </b>
              </TableCell>
              <TableCell>{order.volume}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage id='shared.gift' />
                </b>
              </TableCell>
              <TableCell>{order.gift?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage id='shared.gift.note' />
                </b>
              </TableCell>
              <TableCell>
                <span
                  dangerouslySetInnerHTML={{ __html: order.gift_note_text }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </S.SummaryTableWrapper>
      <S.FinSummaryWrapper>
        <Summary
          vat={(order.price?.vat / order.volume || 1) || 0}
          shippingQty={order.deliveries_total_count}
          giftsQty={order.volume || order.deliveries_total_count}
          giftPrice={(order.price?.amount / order.volume || 1) || 0}
          doCreditCheck={false}
          freeShipping={order.gift?.flags?.price_includes_shipping}
        />
      </S.FinSummaryWrapper>
    </>
  );
};
