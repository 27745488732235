import styled from "styled-components";
import { FormLabel, Select } from "@material-ui/core";

export const WrapperStyled = styled.div`
  margin-bottom: 24px;
`;

interface SelectStyleProps {
  invalid?: 1 | 0;
  empty?: 1 | 0;
}

export const FormLabelStyled = styled(FormLabel)<SelectStyleProps>`
  display: block;
  color: ${({ invalid, theme }) =>
    invalid ? theme.palette.error.main : "#000"};
`;

export const SelectStyled = styled(Select)<SelectStyleProps>`
  display: flex;
  padding: 0;

  .MuiSelect-select {
    border-color: ${({ invalid, theme }) =>
      invalid ? theme.palette.error.main : "transparent"};
    color: ${({ empty, theme }) => (empty ? theme.palette.grey[200] : "#000")};
    padding: 0 32px 0 16px;

    &:focus {
      border-radius: 12px;
    }
  }

  &.Mui-disabled {
    opacity: 0.5;
  }

  > svg {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`;

export const RequiredTitleStyled = styled.span`
  color: ${({ theme }) => theme.palette.grey[200]};
`;
