import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { Button } from "@ui/components";

export const WrapperStyled = styled.div`
  padding-bottom: 20px;
  max-width: 1100px;

  a,
  .name,
  .credit {
    white-space: nowrap;
  }

  .name {
    text-overflow: ellipsis;
    max-width: 120px;
    overflow: hidden;
  }

  .credit {
    color: #3aa757;
    font-weight: 600;
  }
`;

export const CreditsInput = styled.div`
  .MuiInput-root {
    height: 64px;
    line-height: 64px;

    input {
      text-align: right;
      font-size: 18px;
    }
  }
  margin-bottom: 8px;
`;

export const CreditsInputAdornment = styled.div`
  font-size: 18px;
  padding: 0 10px;
`;

export const HeaderStyled = styled.div`
  margin-bottom: 24px;
  min-height: 40px;
  position: relative;
`;

export const SubHeadingStyled = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const BalanceContainer = styled.div`
  padding: 24px;
  border: 1px solid #ececec;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 40px;
`;

export const BalanceValue = styled.div`
  display: flex;
  gap: 20px;
`;

export const ModalContainer = styled.div`
  .ballance {
    background: #fff9ea;
    border: 1px solid #000000;
    border-radius: 12px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .MuiInput-root {
      background-color: transparent;
      padding: 0;
      height: auto;

      input {
        text-align: right;
        height: 22px;
        line-height: 22px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .MuiFormControl-root {
      width: 100px;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 2px;

      > div {
        &:nth-child(1) {
          font-size: 20px;
          line-height: 20px;
          height: 24px;
        }
        &:nth-child(2) {
          font-weight: 400;
          font-size: 11px;
          line-height: 11px;
          color: #a0a0a0;
        }
      }

      + div {
        align-items: flex-end;

        div:nth-child(2) {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100px;
          text-align: right;
        }
      }
    }
  }

  .alert {
    color: #cf930a;
    margin-bottom: 18px;
    font-size: 14px;
    display: flex;
    font-weight: 500;
    gap: 4px;
  }

  ol {
    padding: 0;
    list-style-position: inside;

    li {
      font-size: 14px;
      line-height: 31px;
    }
  }
`;

export const WaitingMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #a0a0a0;
`;

export const WaitingForPayment = styled.div`
  display: flex;
  align-items: center;
  background: #fff9ea;
  border-radius: 12px;
  gap: 32px;
  padding: 32px;
  margin-bottom: 48px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  button {
    min-width: 222px;
  }
`;

export const TableActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectContainer = styled.div`
  > div {
    margin-bottom: 0;
    width: 190px;
  }
`;

export const ExportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;

  > * {
    margin-bottom: 0;
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 346px;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 12px;
  }
`;

export const DatePickerRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 80%;

  label {
    font-weight: 400;
  }
`;

export const ButtonStyled = styled(Button)`
  font-weight: 400;
`
