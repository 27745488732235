import { Button, Loader } from "@components/common";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Divider, Grid, Typography } from "@material-ui/core";
import {
  getGiftDescriptionByLanguageCode,
  getGiftPriceByCountryCode,
  getGiftPriceWithoutVatByCountryCode,
} from "@utils/common";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import TickIcon from "@assets/icons/gift-tick.svg";
import GiftPlaceholder from "@assets/icons/gift-placeholder.svg";
import FileIcon from "@assets/icons/file.svg";
import ComingSoonIcon from "@assets/icons/coming-soon.svg";
import { S3_ASSETS_URL } from "@constants/common";
import * as S from "./styled";
import { AuthContext } from "@providers/AuthProvider";
import { useGift } from "../../hooks";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";
import { SupplierInfoModal } from "./SupplierInfoModal";

const env = process.env.NODE_ENV === "development" ? "test" : "prod";

const getGiftBrochureURL = (gift_id: string) =>
  `${S3_ASSETS_URL}-${env}.s3.eu-west-1.amazonaws.com/${gift_id}.pdf`;

export const GiftDetails = () => {
  const [showAsset, setShowAsset] = useState(false);
  const { id, tab, gift_id } = useParams<{
    id: string;
    tab: "external" | "my-gifts" | "all";
    gift_id: string;
  }>();
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const isExternal = tab === "external";

  const [isBrandedAssetLoading, setIsBrandedAssetLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [galleryItems, setGalleryItems] = useState<string[]>([]);

  const brandedAssetUrl = `https://s3-images-boxday-${env}.s3.amazonaws.com/${user.company_id}/${gift_id}.jpg`;

  const [galleryIndex, setGalleryIndex] = useState(0);

  const { data: gift, isLoading } = useGift(
    id,
    gift_id,
    isExternal ? "company" : "merchant"
  );

  const brochureUrl = getGiftBrochureURL(gift_id);

  useEffect(() => {
    // TODO: move to utils
    const checkAsset = async () => {
      try {
        const res = await axios.get(brochureUrl);

        if (res) {
          setShowAsset(true);
        }
      } catch (e) {
        setShowAsset(false);
      }
    };
    checkAsset();
  }, [user]);

  useEffect(() => {
    if (gift?.flags.instant_purchase_available) {
      setIsBrandedAssetLoading(true);
      const checkAsset = async () => {
        try {
          const res = await axios.get(brandedAssetUrl);

          if (res) {
            setGalleryItems([
              brandedAssetUrl,
              ...(gift?.images?.map((image) => image.url) || []),
            ]);

            setIsBrandedAssetLoading(false);
          }
        } catch (e) {
          setGalleryItems(gift?.images?.map((image) => image.url) || []);
          setIsBrandedAssetLoading(false);
        }
      };
      checkAsset();
    } else {
      setGalleryItems(gift?.images?.map((image) => image.url) || []);
    }
  }, [gift]);

  if (
    !gift ||
    isLoading ||
    (gift?.flags.instant_purchase_available && isBrandedAssetLoading)
  ) {
    return <Loader />;
  }

  return (
    <Grid container md={12} xl={10}>
      <Grid item xs={12}>
        <S.Container>
          <S.Info>
            <Typography variant='h2'>{gift.name}</Typography>
            {gift.merchant && (
              <S.MerchantInfo
                as={gift?.merchant ? "button" : "div"}
                onClick={() => setShowSupplierModal(true)}
              >
                {gift.merchant?.country_code && (
                  <ReactCountryFlag countryCode={gift.merchant.country_code} />
                )}
                <u>{gift.merchant?.name}</u>
              </S.MerchantInfo>
            )}
            <Typography
              variant='body1'
              component='p'
              dangerouslySetInnerHTML={{
                __html: getGiftDescriptionByLanguageCode(
                  gift.descriptions,
                  user.locale
                ),
              }}
            />
            {!isExternal && showAsset && (
              <div>
                <Button
                  variant='text'
                  component='a'
                  href={brochureUrl}
                  // @ts-expect-error ignore
                  target='__blank'
                  startIcon={<FileIcon />}
                >
                  <FormattedMessage id='gifts.gift.brochureLink' />
                </Button>
              </div>
            )}
            <Divider />
            {gift.availability_state === "in_stock" && (
              <>
                <div>
                  <Typography variant='h3'>
                    <FormattedMessage
                      id='general.price.VAT'
                      values={{
                        amount: getGiftPriceByCountryCode(
                          gift.prices,
                          user.currency
                        ),
                      }}
                    />
                  </Typography>
                  <S.NetPrice>
                    <FormattedMessage
                      id='general.price.noVAT'
                      values={{
                        amount: getGiftPriceWithoutVatByCountryCode(
                          gift.prices,
                          user.currency
                        ),
                      }}
                    />
                  </S.NetPrice>
                </div>
                <S.SubRow>
                  {gift.flags.is_branded ? (
                    <S.BadgeStyled>
                      <TickIcon stroke='red' />{" "}
                      <FormattedMessage id='gifts.gift.branded' />
                    </S.BadgeStyled>
                  ) : null}
                  {gift.flags.has_handwritten_note ? (
                    <S.BadgeStyled>
                      <TickIcon />
                      <FormattedMessage id='gifts.gift.handNote' />
                    </S.BadgeStyled>
                  ) : null}
                </S.SubRow>
              </>
            )}
            {!isExternal && gift.availability_state === "in_stock" && (
              <div>
                <Button component={Link} to={`/orders/create/${gift_id}`}>
                  <FormattedMessage id='header.send.gift' />
                </Button>
              </div>
            )}
            <div>
              {gift.availability_state === "coming_soon" && (
                <S.AlertStyled variant='warning'>
                  <ComingSoonIcon />
                  <div>
                    <Typography variant='h3'>
                      <FormattedMessage id='gifts.status.comingSoon' />
                    </Typography>
                    <FormattedMessage id='gifts.status.comingSoonInfo' />
                  </div>
                </S.AlertStyled>
              )}
            </div>
          </S.Info>
          {galleryItems.length > 0 && !isBrandedAssetLoading && (
            <S.Gallery>
              {galleryItems[galleryIndex] ? (
                <img src={galleryItems[galleryIndex]} alt='' className='hero' />
              ) : (
                <GiftPlaceholder className='hero' />
              )}

              {galleryItems.map((src, index) => (
                <button
                  disabled={index === galleryIndex}
                  onClick={() => setGalleryIndex(index)}
                  type='button'
                  key={src}
                >
                  <img src={src || GiftPlaceholder} alt='' />
                </button>
              ))}
            </S.Gallery>
          )}
        </S.Container>
      </Grid>
      <SupplierInfoModal
        merchantInfo={gift.merchant}
        onClose={() => setShowSupplierModal(false)}
        show={showSupplierModal}
      />
    </Grid>
  );
};
