import { IconButton, DialogContent } from "@material-ui/core";
import styled from "styled-components";

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 7px;
  right: 14px;
  background-color: #fff;
  z-index: 999;
`;

export const DialogContentStyled = styled(DialogContent)`
  &.MuiDialogContent-root:first-child {
    padding: 0;
  }
  @media (min-width: 992px) {
    min-width: 520px;
  }
`;

export const DialogBodyStyled = styled.div`
  padding: 24px;
`;

export const DialogFooterStyled = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #ececec;
  margin: 0 -24px -24px -24px;
`;

export const DialogHeaderStyled = styled.div<{ size: "sm" | "lg" }>`
  padding: ${({ size }) => (size === "sm" ? "14px 24px" : "20px 24px")};
  box-shadow: inset 0px -1px 0px #ececec;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: center;
  gap: 24px;

  h5 {
    font-size: 1rem;
    line-height: 22px;
    margin: 0;
    padding-right: 25px;
  }
`;
