import React from "react";
import { FormattedMessage } from "react-intl";
import { NoDataStyled } from "./styled";

const NoData: React.FC<
  { size?: "large" | "small" } & React.PropsWithChildren
> = ({ children, size = "large", ...props }) => (
    <NoDataStyled size={size} {...props}>
      {children || <FormattedMessage id='general.placeholders.noData' />}
    </NoDataStyled>
  );

export default NoData;
