import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import {
  AutomationPayload,
  CompanyAddressType,
  ContactShortType,
} from "@types";
import CompletedIcon from "@assets/icons/completed.svg";
import CalendarIcon from "@assets/icons/calendar.svg";
import PinIcon from "@assets/icons/map-pin.svg";
import * as S from "../../styled";
import { CreateStep4 } from "./Step4";
import { ContactsListModal } from "../../Modals/ContactsListModal";
import { formatBoldTag } from "@utils/common";

export const DateAndDelivery: React.FC<{
  companyId: string;
  expanded: boolean;
  invalid: boolean;
  missingInfoContacts: ContactShortType[];
  offices: CompanyAddressType[];
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  payload: AutomationPayload | null;
  setPayload: Dispatch<SetStateAction<AutomationPayload>>;
  disabled: boolean;
  completed: boolean;
}> = ({
  companyId,
  expanded,
  invalid,
  handleShowStepContent,
  offices,
  missingInfoContacts,
  payload,
  setPayload,
  disabled,
  completed,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const statusMessages = {
    contact_office_address: (
      <FormattedMessage id='automations.create.step4.addressType.variant3' />
    ),
    contact_custom_address: (
      <FormattedMessage id='automations.create.step4.addressType.variant2' />
    ),
    requested_from_recipient: (
      <FormattedMessage id='automations.create.step4.resultFromAskMsg' />
    ),
  };

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {completed && <CompletedIcon />}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='automations.create.step4.title' />
            </Typography>
            {completed && payload?.delivery_details_source_type ? (
              <S.DateAndLocationRow>
                <div>
                  <CalendarIcon />
                  {payload?.delivery_date_type === "birthday" ? (
                    <FormattedMessage id='automations.create.step4.whenNotify.title1' />
                  ) : (
                    <FormattedMessage id='automations.create.step4.whenNotify.title2' />
                  )}
                </div>
                <div>
                  <PinIcon />
                  {payload.delivery_details_source_type === "office_address" ? (
                    <>
                      <FormattedMessage id='automations.create.step4.resultFromOfficeMsg' />{" "}
                      {payload.delivery_address_id &&
                        offices.find(
                          (office) => office.id === payload.delivery_address_id
                        )?.name}
                    </>
                  ) : (
                    statusMessages[payload.delivery_details_source_type]
                  )}
                </div>
              </S.DateAndLocationRow>
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='automations.create.step4.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!expanded && (
          <Button
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(2, true);
            }}
            variant='outlined'
            color='primary'
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='automations.create.step.edit' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>
      {expanded && (
        <CreateStep4
          companyId={companyId}
          onClose={() => {
            setInProgress(false);
            handleShowStepContent(2, false);
          }}
          selectedAddress={payload?.delivery_address_id}
          offices={offices}
          setPayload={setPayload}
          payload={payload}
        />
      )}
      {missingInfoContacts?.length > 0 && (
        <S.AlertStyled>
          <div>
            <FormattedMessage
              id='automations.create.step4.addressValidationMessage'
              values={{
                count: missingInfoContacts?.length,
                b: formatBoldTag,
                button: (chunks: string[]) => (
                  <button onClick={() => setShowModal(true)}>{chunks}</button>
                ),
              }}
            />
          </div>
        </S.AlertStyled>
      )}
      {showModal && (
        <ContactsListModal
          contacts={missingInfoContacts}
          onClose={() => setShowModal(false)}
        />
      )}
    </S.CreateCampaignStep>
  );
};
