import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import { AutomationType } from "@types";
import { GiftPreview } from "@components/common/Order/Create/Gift/GiftPreview";
import * as S from "../../../styled";
import { CreateStep2 } from "./Step2";
import { GiftType } from "../../../../../gifts/types";
import { CreateStep3 } from "../Personalization/Step3";

export const Gift: React.FC<{
  companyId: string;
  gifts: GiftType[];
  expanded: boolean;
  disabled: boolean;
  invalid: boolean;
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  payload: AutomationType;
}> = ({
  companyId,
  gifts,
  expanded,
  handleShowStepContent,
  disabled,
  invalid,
  payload,
}) => {
  const [inProgress, setInProgress] = useState(expanded);
  const [selectedGift, setSelectedGift] = useState<GiftType>(payload.gift);
  const intl = useIntl();
  const exampleText = intl.formatMessage({
    id: "orders.create.step3.giftTextExample",
  });
  const [noteType, setNoteType] = useState<
    "note_with_text" | "blank_note" | "none"
  >(payload?.gift_note_type || "note_with_text");
  const [giftText, setGiftText] = useState<string | null>(
    payload?.note || payload.gift_note_text || exampleText
  );

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='automations.create.step2.title' />
            </Typography>
            {selectedGift && !inProgress ? (
              <GiftPreview selectedGift={selectedGift} />
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='automations.create.step2.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!inProgress && (
          <Button
            variant='outlined'
            color='default'
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(1, true);
            }}
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='automations.create.step.overview' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>
      {inProgress && (
        <CreateStep2
          companyId={companyId}
          onSave={setSelectedGift}
          gifts={gifts}
          giftId={selectedGift?.id}
          selectedGift={selectedGift}
        />
      )}
      {inProgress && selectedGift && (
        <CreateStep3
          noteType={noteType}
          setNoteType={setNoteType}
          giftText={giftText}
          setGiftText={setGiftText}
        />
      )}
      {inProgress && (
        <div>
          <Button
            variant='outlined'
            color='default'
            onClick={() => {
              setInProgress(false);
              handleShowStepContent(1, false);
            }}
          >
            <FormattedMessage id='general.close' />
          </Button>
        </div>
      )}
    </S.CreateCampaignStep>
  );
};
