import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const TermsTextStyled = styled(Typography)`
  max-height: 50vh;
  overflow: auto;
`;

export const TermsCtaStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    min-width: 172px;
    margin-top: 1rem;
  }
`;

export const TermsLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const TermsLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  color: #000;
  font-size: 14px;
`;
