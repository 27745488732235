import React, { useState, useEffect } from "react";
import { ROUTES, COMMON } from "@constants/index";
import { TextField, Alert } from "@components/common";
import { Grid, Hidden } from "@material-ui/core";
import { FormattedMessage, useIntl, WrappedComponentProps } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { AuthRouterStateType, UserProfileType } from "@types";
import cognitoId from "../../../../../cognitoId";
import {
  WrapperStyled,
  FormStyled,
  TitleStyled,
  SubmitButtonStyled,
  SecondaryButtonStyled,
  LogoStyled,
} from "../../styled";

interface EmailVerificationProps {
  signedInSucceeded(data: UserProfileType): void;
}

const EmailVerification: React.FC<
  EmailVerificationProps &
    RouteComponentProps<{}, {}, AuthRouterStateType> &
    WrappedComponentProps
> = ({ history, location, signedInSucceeded }) => {
  const methods = useForm();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [resentMsg, setResentMsg] = useState("");

  const [timeToResend, setTimeToResend] = useState(0);
  const [resendCodeDisabled, setResendCodeDisabled] = useState(false);

  useEffect(() => {
    if (!timeToResend) {
      setResendCodeDisabled(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeToResend(timeToResend - 1);
    }, 1000);

    // eslint-disable-next-line
    return () => {
      clearInterval(intervalId);
    };
  }, [timeToResend]);

  useEffect(() => {
    if (!timeToResend) {
      setResendCodeDisabled(false);
    }
  }, [timeToResend]);

  useEffect(() => {
    if (!location.state?.email) {
      history.push({
        pathname: "/",
        state: {
          errorMsg: intl.formatMessage({
            id: "verify.canceled.error",
          }),
        },
      });
    }
  }, [location]);

  const onSubmit = ({ verifyCode }) => {
    setIsLoading(true);

    if (!location.state?.email) return;

    if (location.state.email && location.state.password) {
      const cognitoUser = cognitoId.setCognitoUser(location.state.email);
      const { email, password } = location.state;

      if (cognitoUser) {
        cognitoUser.confirmRegistration(verifyCode, true, (err) => {
          if (err) {
            setErrorMsg(err.message);
            setIsLoading(false);
            reset();
            return;
          }
          cognitoId.authenticateUser(
            email,
            password,
            (result) => {
              const {
                email: user_email,
                name,
                sub,
                email_verified,
              } = result.idToken.payload;
              signedInSucceeded({
                id: sub,
                verified: email_verified,
                email: user_email,
                name,
              });
              history.push("/");
            },
            (error) => {
              setErrorMsg(error.message);
              setIsLoading(false);
              reset();
            }
          );
        });
        return;
      }
      history.push(ROUTES.USER_SIGN_IN);
    }
  };

  const onResend = () => {
    setResetIsLoading(true);

    if (!location.state.email) return;

    const cognitoUser = cognitoId.setCognitoUser(location.state.email);

    if (cognitoUser) {
      cognitoUser.resendConfirmationCode((err) => {
        if (err) {
          setErrorMsg(err.message);
        } else {
          setResentMsg(
            intl.formatMessage({
              id: "verify.resend.succeeded",
            })
          );
        }
        setResetIsLoading(false);
        setIsLoading(false);
      });
    } else {
      history.push({
        pathname: "/",
        state: {
          errorMsg: intl.formatMessage({
            id: "general.errors.auth",
          }),
        },
      });
    }

    setTimeToResend(COMMON.AUTH_RESEND_CODE_TIMER);
    setResendCodeDisabled(true);
  };

  return (
    <WrapperStyled
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <LogoStyled />
      <TitleStyled variant='h2'>
        <FormattedMessage id='verify.heading' />
      </TitleStyled>
      <FormProvider {...methods}>
        <FormStyled>
          <TitleStyled variant='body1'>
            <FormattedMessage id='verify.subheading' />
          </TitleStyled>
          <TextField
            name='verifyCode'
            type='text'
            placeholder={intl.formatMessage({
              id: "input.verifyCode.placeholder",
            })}
            label={intl.formatMessage({ id: "input.verifyCode.label" })}
            error={errors.verifyCode}
            minLength={6}
            required
          />
          <Grid container justifyContent='space-between' alignItems='center'>
            <Hidden mdDown>
              <SecondaryButtonStyled
                onClick={onResend}
                color='default'
                type='button'
                loading={resetIsLoading}
                disabled={resendCodeDisabled || resetIsLoading}
              >
                <FormattedMessage id='verify.resendCode' />
              </SecondaryButtonStyled>
            </Hidden>
            <SubmitButtonStyled
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              loading={isLoading}
              type='submit'
            >
              <FormattedMessage id='verify.submit' />
            </SubmitButtonStyled>
            <Hidden mdUp>
              <SecondaryButtonStyled
                onClick={onResend}
                color='default'
                type='button'
                loading={resetIsLoading}
                disabled={resendCodeDisabled || resetIsLoading}
              >
                <FormattedMessage id='verify.resendCode' />
              </SecondaryButtonStyled>
            </Hidden>
          </Grid>
        </FormStyled>
      </FormProvider>
      {errorMsg && <Alert variant='warning'>{errorMsg}</Alert>}
      {resentMsg && <Alert variant='success'>{resentMsg}</Alert>}
    </WrapperStyled>
  );
};

export default EmailVerification;
