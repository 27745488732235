import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { DeliverySlipDetails, SetDeliveryAddressPayloadType } from "@types";
import { QueryKey } from "react-query";

const { API } = CONSTANTS;

export const getDeliverySlipDetails = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: DeliverySlipDetails }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/delivery-slips/${queryKey[2]}`)
    .then((res) => res.data);

export const setDeliverySlipAddress = ({
  company_id,
  delivery_slip_id,
  data,
}: {
  company_id: string;
  delivery_slip_id: string;
  data: SetDeliveryAddressPayloadType;
}): Promise<
  DeliverySlipDetails & {
    errors?: Array<{
      code: string;
      message: string;
    }>;
  }
> =>
  baseApi
    .patch(
      `${API.COMPANIES}/${company_id}/delivery-slips/${delivery_slip_id}`,
      data
    )
    .then((res) => res.data);
