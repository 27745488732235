import { UseQueryResult, useMutation, useQuery } from "react-query";
import { automationAddressesValidate, contactValidate } from "./axios";
import {
  DeliveryType,
  AutomationDeliveryDateType,
  DeliveryDetailsSourceType,
} from "@types";

export const useContactValidate = () => {
  return useMutation({
    mutationFn: async ({
      company_id,
      data,
    }: {
      company_id: string;
      data: {
        contacts_filter_type: "contact_id" | "contact_tag";
        contacts: string[];
        contact_date_type?: AutomationDeliveryDateType;
        contact_address_type?: DeliveryDetailsSourceType;
      };
    }) =>
      contactValidate({
        company_id,
        data,
      }),
  });
};

export const useAutomationAddressesValidate = (
  companyId: string,
  automation_id: string
): UseQueryResult<{ items: DeliveryType[] }> =>
  useQuery(
    ["automation-validate", companyId, automation_id],
    automationAddressesValidate,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );
