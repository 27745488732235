import styled, { css } from "styled-components";
import { Typography } from "@material-ui/core";
import { device } from "@constants/style";

export const ErrorPageContainer = styled.div<{ fullSize: boolean }>`
  ${({ fullSize = true }) =>
    fullSize &&
    css`
      min-height: 100vh;
    `};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px;

  button {
    margin-top: 16px;
  }
  a {
    color: inherit;
  }
`;

export const ErrorCode = styled.div`
  font-weight: 700;
  font-size: 142px;
  line-height: 282px;
  color: #efefef;
  position: relative;
  position: relative;
  z-index: -1;
  margin-bottom: -240px;

  @media (${device.laptop}) {
    font-size: 282px;
  }
`;

export const TypographyStyled = styled(Typography)`
  max-width: 560px;
  text-align: center;
  margin: 0;
`;
