import React, { useState, useEffect } from "react";
import { BaseTextFieldProps } from "@material-ui/core";
import {
  useFormContext,
  Controller,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";
import * as S from "./styled";

interface PatternInterface {
  value: RegExp;
  message: string;
}
/* eslint-disable */
interface TextFieldComponentProps extends Omit<BaseTextFieldProps, "error"> {
  name: string;
  pattern?: PatternInterface;
  minLength?: number;
  min?: number;
  max?: number;
  valueAsNumber?: boolean;
  invalid?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  hooked?: boolean;
  secondary?: boolean;
  hideRequiredMsg?: boolean;
  value?: string;
  validate?: any;
  validateMessage?: string;
  offsetbottom?: "true" | "false";
  variant?: "standard" | "outlined" | "filled";
  rounded?: "true" | "false";
  direction?: "column" | "row";
  autocomplete?: "on" | "off";
  mask?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  name,
  label,
  disabled,
  error,
  required,
  pattern,
  type,
  invalid,
  minLength,
  placeholder,
  defaultValue = "",
  min,
  max,
  id,
  valueAsNumber,
  autoComplete = "on",
  helperText,
  hooked = true,
  hideRequiredMsg = false,
  value,
  style,
  variant,
  secondary,
  validate,
  validateMessage,
  offsetbottom = "true",
  rounded,
  startAdornment,
  endAdornment,
  direction = "column",
  inputRef,
  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState(null);

  // Show input validation errors
  useEffect(() => {
    if (error?.type === "minLength") {
      setErrorMsg(`${label} must be at least ${minLength} character long`);
      return;
    }

    if (error?.type === "validate") {
      setErrorMsg(validateMessage || "");
      return;
    }
    setErrorMsg(error?.message);
  }, [error]);

  return (
    <S.WrapperStyled
      fullWidth
      error={!!error || invalid}
      offsetbottom={offsetbottom}
      direction={direction}
    >
      {label && (
        <S.LabelStyled htmlFor={id}>
          {label} {!required && <span>(optional)</span>}
        </S.LabelStyled>
      )}
      {hooked ? (
        <Controller
          render={({ field }) => (
            <S.TextFieldStyled
              {...field}
              inputProps={{ min, max }}
              InputProps={{
                disableUnderline: true,
                autoComplete,
                startAdornment,
                endAdornment,
              }}
              fullWidth
              error={!!error || invalid}
              helperText={errorMsg || helperText}
              secondary={secondary}
              variant='standard'
              placeholder={placeholder}
              type={type}
              id={id}
              rounded={type === "search" ? "true" : "false"}
              disabled={disabled}
              inputRef={inputRef}
              {...props}
            />
          )}
          defaultValue={defaultValue}
          name={name}
          control={useFormContext().control}
          rules={{
            required,
            minLength,
            min,
            max,
            // valueAsNumber,
            pattern,
            validate,
          }}
        />
      ) : (
        <S.TextFieldStyled
          inputProps={{ min, max, style }}
          InputProps={{
            disableUnderline: true,
            autoComplete,
            type,
            placeholder,
          }}
          fullWidth
          rounded={type === "search" ? "true" : "false"}
          error={!!error || invalid}
          helperText={errorMsg || helperText}
          value={value}
          style={style}
          secondary={secondary}
          inputRef={inputRef}
          disabled={disabled}
          {...props}
        />
      )}
    </S.WrapperStyled>
  );
};

/* eslint-enable */

export default TextFieldComponent;
