import React from "react";
import { FormattedMessage } from "react-intl";
import { TypographyStyled } from "./styled";

interface ErrorComponentProps {
  message?: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ message }) => (
  <TypographyStyled variant='h3' color='error'>
    {message || <FormattedMessage id='general.notifications.error' />}
  </TypographyStyled>
);

export default ErrorComponent;
