import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  padding-top: 56px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 0;
    grid-template-columns: 280px 1fr;
  }
`;

export const ToastContainer = styled.div`

  .toast {
    .Toastify__toast {
      font-family: inherit !important;
      color: #fff !important;
      font-weight: 600;
      border-radius: 36px;
      padding: 10px 40px 10px 16px;
      min-height: 42px !important;
      width: max-content;

      .Toastify__close-button {
        right: 20px;
        top: 14px;
        position: absolute;
        opacity: 1;
        path {
          fill: #fff;
        }
      }

      &.Toastify__toast--success {
        background-color: #00ca81;
      }

      &.Toastify__toast--error {
        background-color: #e53761;
        white-space: nowrap;
        width: 390px;
      }

      .Toastify__toast-body {
        margin: 0;
        padding: 0;
      }

      .Toastify__toast-icon {
        display: none;
      }
    }
  }
`;
