import React from "react";
import styled from "styled-components";
import StripeIcon from "@assets/icons/stripe.svg";

interface CategoryBannerProps {
  description: string;
  img: string;
  color: string;
}

const CategoryBannerContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 154px;
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  img {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    aspect-ratio: 1;
    display: block;
    border-radius: 8px;
  }

  svg {
    position: absolute;
    right: 35%;
    bottom: 0;
  }
`;

const Content = styled.div`
  color: #000;
  font-style: normal;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  p {
    font-size: 15px;
  }
`;

const CategoryBanner: React.FC<CategoryBannerProps> = ({
  description,
  img,
  color,
}) => {
  return (
    <CategoryBannerContainer style={{ backgroundColor: color }}>
      <img src={img} alt='' />
      <Content dangerouslySetInnerHTML={{ __html: description }} />
      <StripeIcon />
    </CategoryBannerContainer>
  );
};

export default CategoryBanner;
