import styled from "styled-components";

export const Header = styled.header`
  padding: 16px 0;
  border-bottom: 1px solid #d9d9d9;

  .language-select > div {
    margin-bottom: 0;
  }
`;
