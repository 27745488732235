import { Link } from "react-router-dom";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ButtonBackStyled = styled(Link)`
  display: inline-flex;
  gap: 10px;
  color: rgba(17, 17, 17, 0.48);
  text-decoration: none;
  align-items: center;
`;

export const AutomationNotifyWrapper = styled.div`
  position: relative;
`;

export const AutomationNotify = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  align-items: center;
`;

export const Tooltip = styled.div`
  width: 440px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: calc(100% + 12px);
  transform: translateX(-50%);
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 22px;
    height: 22px;
    background: #fff;
    transform: rotate(-45deg);
  }
`;
