import React, { useContext, useEffect, useState } from "react";
import { DatePicker, Loader, Modal, Radio, Select } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { FormProvider, useForm } from "react-hook-form";
import { Button, MenuItem } from "@material-ui/core";
import dayjs from "dayjs";
import { AuthContext } from "@providers/AuthProvider";
import { toast } from "react-toastify";
import SuccessIcon from "@assets/icons/success-download.svg";
import {
  DatePickerRange,
  ExportContainer,
  SelectContainer,
  ResultsContainer,
} from "../styled";
import { useGenerateTransactionExport } from "../hooks";

const currentMonthIndex = dayjs().month() + 1;
const currentYear = dayjs().year();

export const ExportTransactionModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) => {
  const intl = useIntl();
  const methods = useForm();
  const [minDateTo, setMinDateTo] = useState(null);
  const [maxDateFrom, setMaxDateFrom] = useState(null);
  const { user } = useContext(AuthContext);

  const { handleSubmit, watch } = methods;

  const {
    mutate: generateTransactionExport,
    isLoading,
    data: exportedFile,
    isError,
  } = useGenerateTransactionExport();

  const onSubmit = ({
    type,
    format,
  }: {
    type: "all" | "debit" | "credit";
    format: "csv";
  }) => {
    generateTransactionExport({
      company_id: user.company_id,
      data: {
        format_type: format,
        transaction_type: type,
        ...(watch("period") === "custom" && {
          not_before: dayjs(new Date(watch("from"))).format("YYYY-MM-DD"),
          not_after: dayjs(new Date(watch("to"))).format("YYYY-MM-DD"),
        }),
        ...(watch("period") === "month" && {
          period_month: Number(watch("month")),
          period_year: Number(currentYear),
        }),
      },
    });
  };

  useEffect(() => {
    setMinDateTo(watch("from") ? dayjs(new Date(watch("from"))) : null);
    setMaxDateFrom(watch("to") ? dayjs(new Date(watch("to"))) : null);
  }, [watch]);

  if (isError) {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
  }

  return (
    <Modal
      show={show}
      title={intl.formatMessage({
        id: "credit.export.title",
      })}
      handleClose={handleClose}
    >
      <FormProvider {...methods}>
        {/* eslint-disable-next-line */}
        {isLoading ? (
          <ResultsContainer>
            <Loader />
          </ResultsContainer>
        ) : exportedFile ? (
          <ResultsContainer>
            <SuccessIcon />
            <FormattedMessage id='credit.export.ready' />
            {/* @ts-expect-error */}
            <Button
              as='a'
              // @ts-expect-error
              href={URL.createObjectURL(new Blob([exportedFile.file]))}
              color='primary'
              variant='outlined'
              // @ts-expect-error
              download={exportedFile.fileName}
            >
              <FormattedMessage id='credit.export.download' />
            </Button>
          </ResultsContainer>
        ) : (
          <ExportContainer>
            <Radio
              name='period'
              label={intl.formatMessage({
                id: "credit.export.period",
              })}
              defaultValue='month'
              items={[
                {
                  value: "month",
                  label: intl.formatMessage({
                    id: "credit.export.month",
                  }),
                },
                {
                  value: "custom",
                  label: intl.formatMessage({
                    id: "credit.export.custom",
                  }),
                },
              ]}
              hooked
            />
            {watch("period") === "custom" ? (
              <DatePickerRange>
                <DatePicker
                  label={intl.formatMessage({
                    id: "credit.export.from",
                  })}
                  name='from'
                  hooked
                  maxDate={maxDateFrom}
                  direction='row'
                />
                <DatePicker
                  label={intl.formatMessage({
                    id: "credit.export.to",
                  })}
                  name='to'
                  hooked
                  minDate={minDateTo}
                  direction='row'
                />
              </DatePickerRange>
            ) : (
              <SelectContainer>
                <Select
                  fullWidth={false}
                  id='month'
                  name='month'
                  label=''
                  defaultValue={currentMonthIndex}
                >
                  <MenuItem value='1' disabled={currentMonthIndex <= 1}>
                    <FormattedMessage id='month.january' />
                  </MenuItem>
                  <MenuItem value='2' disabled={currentMonthIndex < 2}>
                    <FormattedMessage id='month.february' />
                  </MenuItem>
                  <MenuItem value='3' disabled={currentMonthIndex < 3}>
                    <FormattedMessage id='month.march' />
                  </MenuItem>
                  <MenuItem value='4' disabled={currentMonthIndex < 4}>
                    <FormattedMessage id='month.april' />
                  </MenuItem>
                  <MenuItem value='5' disabled={currentMonthIndex < 5}>
                    <FormattedMessage id='month.may' />
                  </MenuItem>
                  <MenuItem value='6' disabled={currentMonthIndex < 6}>
                    <FormattedMessage id='month.june' />
                  </MenuItem>
                  <MenuItem value='7' disabled={currentMonthIndex < 7}>
                    <FormattedMessage id='month.july' />
                  </MenuItem>
                  <MenuItem value='8' disabled={currentMonthIndex < 8}>
                    <FormattedMessage id='month.august' />
                  </MenuItem>
                  <MenuItem value='9' disabled={currentMonthIndex < 9}>
                    <FormattedMessage id='month.september' />
                  </MenuItem>
                  <MenuItem value='10' disabled={currentMonthIndex < 10}>
                    <FormattedMessage id='month.october' />
                  </MenuItem>
                  <MenuItem value='11' disabled={currentMonthIndex < 11}>
                    <FormattedMessage id='month.november' />
                  </MenuItem>
                  <MenuItem value='12' disabled={currentMonthIndex < 12}>
                    <FormattedMessage id='month.december' />
                  </MenuItem>
                </Select>
              </SelectContainer>
            )}

            <div>
              <Radio
                name='type'
                label={intl.formatMessage({
                  id: "credit.export.type",
                })}
                defaultValue='all'
                items={[
                  {
                    value: "all",
                    label: intl.formatMessage({
                      id: "credit.export.all",
                    }),
                  },
                  {
                    value: "debit",
                    label: intl.formatMessage({
                      id: "credit.export.debit",
                    }),
                  },
                  {
                    value: "credit",
                    label: intl.formatMessage({
                      id: "credit.export.credit",
                    }),
                  },
                ]}
                hooked
              />
            </div>

            <div>
              <Radio
                name='format'
                defaultValue='csv'
                label={intl.formatMessage({
                  id: "credit.export.format",
                })}
                items={[
                  {
                    value: "csv",
                    label: "CSV",
                  },
                ]}
                hooked
              />
            </div>
            <div>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                <FormattedMessage id='credit.export.download' />
              </Button>
            </div>
          </ExportContainer>
        )}
      </FormProvider>
    </Modal>
  );
};
