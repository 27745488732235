import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField, Radio } from "@components/common";
import {
  TEXTAREA_MAX_LENGTH,
  TEXTAREA_SHORT_MAX_LENGTH,
} from "@constants/common";
import * as S from "../../styled";

type CreateStep3Props = {
  noteType: "note_with_text" | "blank_note" | "none";
  setNoteType: (type: "note_with_text" | "blank_note" | "none") => void;
  giftText: string;
  setGiftText: (text: string) => void;
  hideDynamicFields: boolean;
  isSignature: boolean;
};

export const CreateStep3: React.FC<CreateStep3Props> = ({
  noteType,
  setNoteType,
  giftText,
  setGiftText,
  hideDynamicFields,
  isSignature,
}) => {
  const intl = useIntl();

  const handwrittenRadioOptions = [
    {
      value: "note_with_text",
      label: intl.formatMessage({
        id: "orders.create.step3.variant4",
      }),
    },
    {
      value: "none",
      label: intl.formatMessage({
        id: "orders.create.step3.variant5",
      }),
    },
  ];

  const noteRadioOptions = [
    {
      value: "note_with_text",
      label: intl.formatMessage({
        id: "orders.create.step3.variant1",
      }),
    },
    {
      value: "blank_note",
      label: intl.formatMessage({
        id: "orders.create.step3.variant2",
      }),
    },
    {
      value: "none",
      label: intl.formatMessage({
        id: "orders.create.step3.variant3",
      }),
    },
  ];

  const maxLength = useMemo(
    () => (isSignature ? TEXTAREA_SHORT_MAX_LENGTH : TEXTAREA_MAX_LENGTH),
    [isSignature]
  );

  return (
    <S.StepBody>
      <div style={{ marginBottom: "8px" }}>
        <Radio
          value={noteType}
          defaultValue={noteType}
          // @ts-expect-error
          onChange={(e) => setNoteType(e.target.value)}
          name='type'
          items={isSignature ? handwrittenRadioOptions : noteRadioOptions}
        />
      </div>
      {noteType === "note_with_text" && (
        <div>
          <S.DynamicFieldsWrapper>
            <TextField
              label={
                isSignature
                  ? intl.formatMessage({
                      id: "orders.create.step3.description2",
                    })
                  : intl.formatMessage({
                      id: "orders.create.step3.giftTextLabel",
                    })
              }
              name='giftText'
              value={giftText}
              // @ts-expect-error
              onChange={(e) => setGiftText(e.target.value)}
              type='text'
              multiline
              minRows={isSignature ? 1 : 6}
              invalid={giftText?.length > maxLength}
              max={maxLength}
              fullWidth
              required
              offsetbottom='false'
              hooked={false}
            />
            <S.DynamicFieldsInfo>
              <div>
                <FormattedMessage
                  id='orders.create.step3.minMaxChars'
                  values={{
                    min: giftText?.length,
                    max: maxLength,
                  }}
                />
              </div>
              {/* <div>
                <InfoIcon />{" "}
                <FormattedMessage id='orders.create.step3.previewCard' />
              </div> */}
            </S.DynamicFieldsInfo>
          </S.DynamicFieldsWrapper>
          {!hideDynamicFields && !isSignature && (
            <S.DynamicFieldsDemo>
              <div>
                <b>
                  <FormattedMessage id='orders.create.step3.dynamicFieldsTitle' />
                </b>{" "}
                <br />
                <FormattedMessage
                  id='orders.create.step3.dynamicFieldsText'
                  values={{
                    br: <br />,
                  }}
                />
              </div>
            </S.DynamicFieldsDemo>
          )}
        </div>
      )}
    </S.StepBody>
  );
};
