import { OrderType, OrderPayload, PagingType, DeliveryType } from "@types";
import {
  useQuery,
  UseQueryResult,
  useMutation,
  useQueryClient,
} from "react-query";
import {
  fetchOrders,
  createOrder,
  deleteOrder,
  fetchOrderDeliveries,
  fetchOrder
} from "./api";

export const useOrders = (
  companyId: string,
  type: "upcoming" | "past",
  page: number
): UseQueryResult<{ items: OrderType[]; paging: PagingType }> =>
  useQuery(["orders", companyId, type, page], fetchOrders, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useOrderDeliveries = (
  companyId: string,
  orderId: string,
  params: {
    type?: "upcoming" | "past";
    page: number;
  }
): UseQueryResult<{ items: DeliveryType[]; paging: PagingType }> =>
  useQuery(
    ["order-deliveries", companyId, orderId, params],
    fetchOrderDeliveries,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );

  export const useOrder = (
    companyId: string,
    orderId: string,
  ): UseQueryResult<OrderType> =>
    useQuery(
      ["order", companyId, orderId],
      fetchOrder,
      {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        retry: 2,
        enabled: !!companyId,
      }
    );

export const useCreateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      data,
    }: {
      company_id: string;
      data: OrderPayload;
    }) =>
      createOrder({
        company_id,
        data,
      }),
    onSuccess: async (response) => {
      console.log(response);
      if (response.payment) return;
      queryClient.invalidateQueries(["orders"]);
      queryClient.invalidateQueries(["contactOrders"]);
      queryClient.invalidateQueries(["companyDetails"]);
    },
  });
};

export const useDeleteOrder = () => {
  return useMutation({
    mutationFn: async ({
      company_id,
      order_id,
    }: {
      company_id: string;
      order_id: string;
    }) =>
      deleteOrder({
        company_id,
        order_id,
      }),
  });
};
