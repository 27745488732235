import { IconButton, DialogContent, Dialog } from "@material-ui/core";
import styled, { css } from "styled-components";

export const DialogStyled = styled(Dialog)``;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 7px;
  right: 14px;
  background-color: #fff;
  z-index: 999;
`;

export const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;

  &.MuiDialogContent-root:first-child {
    padding: 0;
  }

  /* @media (min-width: 992px) {
    min-width: 480px;
  } */
`;

export const DialogBodyStyled = styled.div`
  padding: 24px;
  overflow: auto;
`;

export const DialogFooterStyled = styled.div<{ stretchCta?: boolean }>`
  padding: 16px 24px;
  border-top: 1px solid #ececec;
  display: flex;
  gap: 12px;

  button {
    white-space: nowrap;
  }

  ${({ stretchCta }) =>
    stretchCta &&
    css`
      flex-direction: row-reverse;
      button {
        flex: 1;
      }
    `}

  .MuiButton-outlined {
    color: #a0a0a0;
    border-color: #a0a0a0;
  }
`;

export const DialogHeaderStyled = styled.div<{ size: "sm" | "lg" }>`
  padding: ${({ size }) => (size === "sm" ? "14px 24px" : "20px 24px")};
  box-shadow: inset 0px -1px 0px #ececec;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;

  & + ${DialogFooterStyled} {
    border-top: 0;
  }

  h5 {
    font-size: 1rem;
    line-height: 22px;
    margin: 0;
    padding-right: 25px;
  }
`;
