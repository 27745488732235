import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Divider, Typography } from "@material-ui/core";
import { DatePicker, Radio } from "@components/common";
import { AutomationPayload } from "@types";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { AuthContext } from "@providers/AuthProvider";
import * as S from "../../styled";
import { FormType } from "../types";
import { formatBoldTag } from "@utils/common";

dayjs.extend(isSameOrAfter);

type CreateStep5Props = {
  onClose: () => void;
  setPayload: Dispatch<SetStateAction<AutomationPayload>>;
  payload: AutomationPayload | null;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
};

const today = new Date();

export const CreateStep5: React.FC<CreateStep5Props> = ({
  onClose,
  setPayload,
  payload,
  formState,
  setFormState,
}) => {
  const [notificationsEnabled, setNotificationsEnabled] = useState<
    "true" | "false"
  >(
    payload?.notifications_enabled !== undefined &&
      !payload?.notifications_enabled
      ? "false"
      : "true"
  );
  const [notificationsEndingEnabled, setNotificationsEndingEnabled] = useState<
    "true" | "false"
  >("true");

  const { user } = useContext(AuthContext);

  const [notifyEndDate, setNotifyEndDate] = useState<Dayjs>(null as Dayjs);

  const formDisabled =
    notificationsEndingEnabled === "true" &&
    (!notifyEndDate || !notifyEndDate.isSameOrAfter(dayjs(today)));

  const intl = useIntl();

  useEffect(() => {
    setFormState((state) => ({
      ...state,
      automationStop:
        notificationsEndingEnabled === "true" ? "date" : "manually",
    }));
  }, [notificationsEndingEnabled]);

  return (
    <S.StepBody>
      <Divider variant='fullWidth' />
      <Typography variant='h6'>
        <FormattedMessage id='automations.create.step5.notificationsTitle' />
      </Typography>
      <Radio
        value={notificationsEnabled}
        defaultValue='true'
        onChange={(e) => setNotificationsEnabled(e.target.value)}
        name='type'
        items={[
          {
            value: "true",
            label: intl.formatMessage({
              id: "automations.create.step5.variant1",
            }),
          },
          {
            value: "false",
            label: intl.formatMessage({
              id: "automations.create.step5.variant2",
            }),
          },
        ]}
      />
      {notificationsEnabled === "true" && (
        <S.AlertStyled variant='warning'>
          <div>
            <FormattedMessage
              id='automations.create.step5.notifyMessage'
              values={{
                b: formatBoldTag,
                email: user?.email,
              }}
            />
          </div>
        </S.AlertStyled>
      )}

      <Divider variant='fullWidth' />
      <Typography variant='h6'>
        <FormattedMessage id='automations.create.step5.durationTitle' />
      </Typography>

      <Radio
        label={intl.formatMessage({
          id: "automations.create.step5.ending.label",
        })}
        value={notificationsEndingEnabled}
        defaultValue={formState?.automationStop === "date" ? "true" : "false"}
        onChange={(e) => setNotificationsEndingEnabled(e.target.value)}
        name='type'
        items={[
          {
            value: "true",
            label: intl.formatMessage({
              id: "automations.create.step5.ending.variant1",
            }),
          },
          {
            value: "false",
            label: intl.formatMessage({
              id: "automations.create.step5.ending.variant2",
            }),
          },
        ]}
      />

      {notificationsEndingEnabled === "true" && (
        <DatePicker
          value={notifyEndDate}
          onChange={setNotifyEndDate}
          name='datepicker'
          minDate={dayjs(today)}
          disablePast
          hooked={false}
          label={intl.formatMessage({
            id: "automations.create.step5.ending.label",
          })}
          direction='row'
        />
      )}

      <Divider variant='fullWidth' />
      <div>
        <Button
          variant='contained'
          color='primary'
          disabled={formDisabled}
          onClick={() => {
            setPayload((state: AutomationPayload) => {
              const newPayload = {
                ...state,
                notifications_enabled: notificationsEnabled === "true",
                ...(notificationsEndingEnabled === "true" && {
                  end_date: notifyEndDate.toISOString(),
                }),
              };
              if (notificationsEnabled === "false") {
                delete newPayload.end_date;
              }
              return newPayload;
            });
            onClose();
          }}
        >
          <FormattedMessage id='general.save' />
        </Button>
        <Button color='secondary' onClick={onClose}>
          <FormattedMessage id='general.cancel' />
        </Button>
      </div>
    </S.StepBody>
  );
};
