import React, { useContext } from "react";
import * as S from "./styled";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";
import { GiftButton } from "@ui/components";
import { FormattedMessage } from "react-intl";
import { DeliveryStatus } from "./Status";

import { useDelivery } from "../../../../../../api/deliveries";
import { AuthContext } from "@providers/AuthProvider";
import { Alert, Loader } from "@components/common";
import { useParams } from "react-router";
import { DeliveryDetailsSourceType } from "@types";
import { formatBoldTag } from "@utils/common";
import { TrackingLink } from "./TrackingLink";

// import ChangeStatus from "./ChangeStatus";

const statusMessages: {
  [key in DeliveryDetailsSourceType]: any;
} = {
  contact_office_address: (
    <FormattedMessage id='automations.create.step4.addressType.variant3' />
  ),
  contact_custom_address: (
    <FormattedMessage id='automations.create.step4.addressType.variant2' />
  ),
  requested_from_recipient: (
    <FormattedMessage id='automations.create.step4.resultFromAskMsg' />
  ),
  office_address: (
    <FormattedMessage id='automations.create.step4.addressType.variant1' />
  ),
};

interface DeliveryDetailsProps {
  delivery_id: string;
}

export const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({
  delivery_id,
}) => {
  const notAvailableText = "Not available";
  const { user } = useContext(AuthContext);
  const { id } = useParams<{ id: string }>();

  const { data: delivery, isLoading } = useDelivery(
    user.company_id,
    id,
    delivery_id
  );

  if (!delivery)
    return (
      <S.Container>
        <Loader />
      </S.Container>
    );

  return (
    <S.Container>
      <S.Title>
        <FormattedMessage
          id='Order id'
          values={{
            id: delivery.order.readable_id?.toUpperCase(),
          }}
        />
      </S.Title>
      <S.Heading>
        <h2>
          {delivery.contact?.first_name || delivery?.address?.contact_name}{" "}
          {delivery.contact?.last_name}
        </h2>
        <div>
          <DeliveryStatus state={delivery.state} />
          {/* <ChangeStatus delivery={delivery} orderId={delivery.order.id} /> */}
        </div>
      </S.Heading>
      <S.HeadingContent>
        <div>
          <S.Label>
            {delivery.state === "scheduled" ? (
              <FormattedMessage id='deliveries.details.label1' />
            ) : (
              <FormattedMessage id='Notification date' />
            )}
          </S.Label>
          <S.Value>
            {delivery.state === "scheduled"
              ? dayjs(delivery.scheduled_for_date).format(
                  DEFAULT_DATE_FORMAT_SHORT
                )
              : dayjs(
                  delivery.notification.time || delivery.scheduled_for_date
                ).format(DEFAULT_DATE_FORMAT_SHORT)}
          </S.Value>
        </div>
        <div>
          <S.Label>
            <FormattedMessage id='deliveries.details.label2' />
          </S.Label>
          <S.Value>
            {statusMessages[delivery.delivery_details_source_type]}
          </S.Value>
        </div>

        <div>
          <S.Label>
            <FormattedMessage id='deliveries.details.label3' />
          </S.Label>
          <S.Value>{delivery.shipping?.provider || notAvailableText}</S.Value>
        </div>
      </S.HeadingContent>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <S.MainContent>
            {delivery.delivery_details_source_type === "office_address" &&
              delivery.contact && (
                <>
                  <S.DeliveryAlert>
                    <Alert variant='warning'>
                      <FormattedMessage
                        id='deliveries.officeInfo'
                        values={{
                          name: `${delivery.contact?.first_name} ${delivery.contact?.last_name}`,
                          b: formatBoldTag,
                        }}
                      />
                    </Alert>
                  </S.DeliveryAlert>
                  <S.DeliveryAlert>
                    <Alert variant='error'>
                      <FormattedMessage
                        id='deliveries.officeAlert'
                        values={{
                          name: `${delivery.contact?.first_name} ${delivery.contact?.last_name}`,
                          b: formatBoldTag,
                        }}
                      />
                    </Alert>
                  </S.DeliveryAlert>
                </>
              )}
            {/* <div>
              <S.Label>
                <FormattedMessage id='Recipient' />
              </S.Label>
              <S.Value>
                {delivery.address?.contact_name ||
                  `${delivery.contact?.first_name} ${delivery.contact?.last_name}`}
              </S.Value>
              <S.Value>{delivery.address?.contact_phone}</S.Value>
            </div> */}
            <div>
              <S.Label>
                <FormattedMessage id='deliveries.details.label2' />
              </S.Label>
              {delivery?.address?.name && (
                <S.Value>
                  <b>{delivery?.address?.name}</b>
                </S.Value>
              )}
              <S.Value>{delivery?.address?.street || notAvailableText}</S.Value>
              <S.Value>
                {delivery?.address?.zip} {delivery?.address?.city}
              </S.Value>
              <S.Value>
                {delivery?.address?.country_code?.toUpperCase()}
              </S.Value>
              {delivery?.delivery_details_source_type === "office_address" ||
              delivery?.delivery_details_source_type ===
                "contact_office_address" ? (
                <S.Value>Contact: {delivery?.address?.contact_name}</S.Value>
              ) : (
                <S.Value>{delivery?.address?.contact_name}</S.Value>
              )}
              <S.Value>{delivery?.address?.contact_phone}</S.Value>
            </div>
            <div>
              <S.Label>
                <FormattedMessage id='deliveries.details.label5' />
              </S.Label>
              <S.Value>
                {delivery.shipping?.shipping_note || notAvailableText}
              </S.Value>
            </div>
          </S.MainContent>
          <S.GiftContent>
            <div>
              <S.Label>
                <FormattedMessage id='orders.create.step2.title' />
              </S.Label>
              <GiftButton
                gift={delivery.order.gift}
                disabled
                selected={false}
                onClick={() => false}
                disableRipple
              />
            </div>
            <div>
              <S.Label>
                <FormattedMessage id='orders.create.step3.description' />
              </S.Label>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    delivery.custom_gift_note_text?.replace(
                      /\n\r?/g,
                      "<br />"
                    ) ||
                    delivery.personalised_gift_note_text?.replace(
                      /\n\r?/g,
                      "<br />"
                    ) ||
                    notAvailableText,
                }}
              />
            </div>
          </S.GiftContent>
          <S.Content>
            <TrackingLink id={delivery_id} />
          </S.Content>
        </>
      )}
    </S.Container>
  );
};
