import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ButtonRow,
  FullPageLoader,
  Select,
  TextField,
  Checkbox,
  DatePicker,
  ErrorPage,
  Radio,
} from "@components/common";
import { VALIDATION } from "@constants/index";
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Submitted } from "./components/Submitted";
import { CompanyLogo } from "./components/CompanyLogo";
import { Delivered } from "./components/Delivered";
import * as S from "./styled";
import { useDeliverySlipDetails, useSetDeliverySlipAddress } from "./hooks";
import { AuthContext } from "@providers/AuthProvider";
import { LanguageCodeType } from "@types";
import { useDeliverySlipAddress } from "../../api/recipientForm";
import { useCustomFeatures } from "../../hooks/useCustomFeatures";
import { SupportChat } from "@ui/components";
import { isWeekend, handleWeekends } from "@utils/common";

const minDate = dayjs().startOf("date").add(4, "days");

const availableLocales = {
  sk: "sk",
  cs: "cs",
  en: "en",
};

const defaultDate = handleWeekends(minDate);

const GLEAP_DELIVERY_SLIP__KEY = "ItW2gIKuwDLeuDjgVd6o37g0aIp3dmUU";

export const RecipientForm = () => {
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const location = useLocation();

  // @ts-expect-error can't type location search params
  const pageLang: LanguageCodeType =
    new URLSearchParams(location.search).get("language") || "sk";

  const { setUser } = useContext(AuthContext);

  const { company_id, delivery_slip_id } = useParams<{
    company_id?: string;
    delivery_slip_id?: string;
  }>();

  const { additionalCountries } = useCustomFeatures(company_id);

  const {
    mutate: setDeliveryAddress,
    isSuccess,
    isLoading: submitLoading,
    data: setDeliveryResponse,
    error: setDeliveryAddressError,
  } = useSetDeliverySlipAddress();

  const {
    data: deliverySlipDetails,
    isLoading,
    error: slipDetailsError,
  } = useDeliverySlipDetails(company_id, delivery_slip_id);

  const {
    data: officeAddressResponse,
    isLoading: addressLoading,
    error: addressError,
  } = useDeliverySlipAddress(company_id, delivery_slip_id);

  const officeAddress = useMemo(
    () => officeAddressResponse?.items?.[0],
    [officeAddressResponse?.items]
  );

  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = methods;

  const onSubmit = ({
    address,
    postCode,
    phone,
    note,
    datepicker,
    city,
    country,
    addressType = "custom",
  }: {
    address: string;
    postCode: string;
    phone: string;
    note: string;
    datepicker: string;
    city: string;
    country: string;
    addressType: "custom" | "office";
  }) => {
    setDeliveryAddress({
      company_id,
      delivery_slip_id,
      data: {
        ...(addressType === "custom"
          ? {
              address_type: addressType,
              street: address,
              zip: postCode,
              contact_phone: phone,
              shipping_note: note,
              shipping_datetime: datepicker,
              country_code: country,
              city,
            }
          : {
              address_type: addressType,
              address_id: officeAddress?.id,
              shipping_datetime: datepicker,
              shipping_note: note,
              contact_phone: phone,
            }),
      },
    });
  };

  useEffect(() => {
    // @ts-expect-error
    setUser((user) => ({
      ...user,
      locale: availableLocales[pageLang] || "sk",
    }));
  }, [pageLang, setUser]);

  useEffect(() => {
    if (isSuccess) {
      setIsSubmitted(true);
      reset();
    }
  }, [isSuccess, reset, setIsSubmitted]);

  useEffect(() => {
    if (officeAddress) {
      reset({
        addressType: "office",
      });
    }
  }, [officeAddress]);

  if (isLoading || addressLoading) return <FullPageLoader />;

  if (
    slipDetailsError ||
    !company_id ||
    !delivery_slip_id ||
    setDeliveryAddressError
  ) {
    return (
      <S.Page>
        <Header />
        <ErrorPage
          fullSize={false}
          codeError={
            slipDetailsError?.response?.status ||
            setDeliveryAddressError?.response?.status
          }
          errorMessage={intl.formatMessage({
            id: "recipient.page.error.message",
          })}
          // @ts-expect-error
          infoMessage={intl.formatMessage(
            {
              id: "recipient.page.error.info",
            },
            {
              deliveryId: delivery_slip_id,
              a: (str: string[]) => <a href='mailto:support@boxday.app'>{str}</a>,
            }
          )}
          showInfo={false}
        />
        <Footer />
        <SupportChat apiKey={GLEAP_DELIVERY_SLIP__KEY} />
      </S.Page>
    );
  }

  if (deliverySlipDetails?.state === "delivered") {
    return (
      <>
        <Delivered delivery={deliverySlipDetails} />
        <SupportChat apiKey={GLEAP_DELIVERY_SLIP__KEY} />
      </>
    );
  }

  if (
    deliverySlipDetails?.state === "in_transit" ||
    deliverySlipDetails?.state === "waiting_for_shipment"
  ) {
    return (
      <>
        <Submitted {...deliverySlipDetails} short />
        <SupportChat apiKey={GLEAP_DELIVERY_SLIP__KEY} />
      </>
    );
  }

  return (
    <S.Page>
      <Header />
      {isSubmitted ? (
        <Submitted {...setDeliveryResponse} />
      ) : (
        <Container maxWidth='sm'>
          <S.FormContainer>
            <div>
              <Typography variant='h2'>
                <FormattedMessage
                  id='recipient.form.welcome.text'
                  values={{
                    name: deliverySlipDetails.recipient.name,
                    companyName: deliverySlipDetails.sender.name,
                  }}
                />
              </Typography>
            </div>

            <S.FormRow>
              <S.FormLabel>
                <FormattedMessage id='recipient.form.sender' />
              </S.FormLabel>
              <S.FormBlockRow>
                <Typography>
                  {deliverySlipDetails.sender.name}
                  <br />
                  {deliverySlipDetails.sender.street}
                  <br />
                  {deliverySlipDetails.sender.zip}{" "}
                  {deliverySlipDetails.sender.city}
                </Typography>
                <CompanyLogo company_id={company_id} />
              </S.FormBlockRow>
            </S.FormRow>

            <S.FormRow>
              <S.FormLabel>
                <FormattedMessage id='recipient.form.recipient' />
              </S.FormLabel>
              <S.FormBlock>
                <Typography>
                  {deliverySlipDetails.recipient.name}
                  <br />
                  {deliverySlipDetails.recipient.email}
                </Typography>
              </S.FormBlock>
            </S.FormRow>

            <FormProvider {...methods}>
              <S.FormContainer>
                <S.FormRow>
                  <S.FormLabel>
                    <FormattedMessage id='recipient.form.deliveryAddress' />
                  </S.FormLabel>
                  <S.FormBlock>
                    {officeAddress && (
                      <div style={{ marginBottom: "16px" }}>
                        <Radio
                          defaultValue='office'
                          name='addressType'
                          hooked
                          items={[
                            {
                              value: "office",
                              label: intl.formatMessage({
                                id: "recipient.form.address.type1",
                              }),
                              disabled:
                                addressLoading ||
                                addressError ||
                                !officeAddress,
                            },
                            {
                              value: "custom",
                              label: intl.formatMessage({
                                id: "recipient.form.address.type2",
                              }),
                            },
                          ]}
                        />
                      </div>
                    )}
                    {watch("addressType") === "office" ? (
                      <Select
                        id='office-address'
                        name='officeAddress'
                        label={intl.formatMessage({
                          id: "recipient.form.address.choose1",
                        })}
                        defaultValue={officeAddress?.id}
                      >
                        <MenuItem
                          key={officeAddress.id}
                          value={officeAddress.id}
                        >
                          {`${officeAddress.name}, ${officeAddress.street}, ${officeAddress.zip}, ${officeAddress.city}`}
                        </MenuItem>
                      </Select>
                    ) : (
                      <div>
                        <TextField
                          name='address'
                          placeholder={intl.formatMessage({
                            id: "input.address.label",
                          })}
                          label={intl.formatMessage({
                            id: "input.address.label",
                          })}
                          error={errors.address}
                          required
                          id='form-street'
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name='postCode'
                              placeholder={intl.formatMessage({
                                id: "input.zip.label",
                              })}
                              label={intl.formatMessage({
                                id: "input.zip.label",
                              })}
                              type='text'
                              error={errors.postCode}
                              id='form-input-postCode'
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <TextField
                              name='city'
                              placeholder={intl.formatMessage({
                                id: "input.city.label",
                              })}
                              label={intl.formatMessage({
                                id: "input.city.label",
                              })}
                              type='text'
                              error={errors.city}
                              id='form-input-city'
                              required
                            />
                          </Grid>
                        </Grid>
                        <Select
                          id='country'
                          name='country'
                          label={intl.formatMessage({
                            id: "input.country.label",
                          })}
                          defaultValue='sk'
                        >
                          <MenuItem value='sk'>
                            <FormattedMessage id='shared.country.sk' />
                          </MenuItem>
                          <MenuItem value='cz'>
                            <FormattedMessage id='shared.country.cz' />
                          </MenuItem>
                          {additionalCountries?.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              <FormattedMessage
                                id={`shared.country.${country.code}`}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    )}
                    <TextField
                      name='note'
                      placeholder={intl.formatMessage({
                        id: "input.courierNote.label",
                      })}
                      label={intl.formatMessage({
                        id: "input.courierNote.label",
                      })}
                      type='text'
                      error={errors.note}
                      id='form-input-note'
                    />
                    <TextField
                      name='phone'
                      placeholder={intl.formatMessage({
                        id: "input.phone.label",
                      })}
                      label={intl.formatMessage({
                        id: "input.phone.label",
                      })}
                      type='tel'
                      error={errors.phone}
                      pattern={{
                        value: VALIDATION.PHONE_VALIDATION_REGEXP,
                        message: intl.formatMessage({
                          id: "input.phone.pattern.error",
                        }),
                      }}
                      id='form-input-phone'
                      required
                    />
                  </S.FormBlock>
                </S.FormRow>

                <S.FormRow>
                  <S.FormLabel>
                    <FormattedMessage id='recipient.form.deliveryDate' />
                  </S.FormLabel>
                  <S.FormBlock>
                    <DatePicker
                      error={errors.datepicker}
                      name='datepicker'
                      shouldDisableDate={isWeekend}
                      validate={(value) => {
                        const selectedDate = dayjs(value);
                        const today = dayjs().startOf("date");

                        if (selectedDate.diff(today, "day") <= 2) {
                          return intl.formatMessage({
                            id: "input.date.minDeliveryDate",
                          });
                        }
                        return true;
                      }}
                      defaultValue={defaultDate}
                      hooked
                    />
                  </S.FormBlock>
                </S.FormRow>
                <S.FormRow>
                  <S.FormLabel />
                  <S.FormSubmit>
                    <Checkbox
                      name='acceptTerms'
                      label={intl.formatMessage(
                        {
                          id: "input.terms.label",
                        },
                        {
                          // eslint-disable-next-line
                          link: (str) => (
                            <a
                              target='__blank'
                              href='https://www.boxday.app/legal'
                            >
                              {str}
                            </a>
                          ),
                        }
                      )}
                      error={errors.acceptTerms}
                      required
                      checked={false}
                    />
                    <ButtonRow>
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={submitLoading}
                        onClick={handleSubmit(onSubmit)}
                        type='submit'
                        style={{ marginTop: "24px" }}
                      >
                        <FormattedMessage id='recipient.form.submit' />
                      </Button>
                    </ButtonRow>
                  </S.FormSubmit>
                </S.FormRow>
              </S.FormContainer>
            </FormProvider>
          </S.FormContainer>
        </Container>
      )}
      <Footer />
      <SupportChat apiKey={GLEAP_DELIVERY_SLIP__KEY} />
    </S.Page>
  );
};
