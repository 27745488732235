// import logger from "redux-logger";
// import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import routerMiddleware from "./router";

const middlewares = [routerMiddleware];

// if (process.env.NODE_ENV !== "production") {
//   middlewares.push(logger);
// }

// const middleware = composeWithDevTools(applyMiddleware(...middlewares));

export default middlewares;
