import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButton, Menu, MenuItem, TableCell } from "@material-ui/core";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { DeliveryType, DeliveryStateType } from "@types";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";
import { BadgeTypes } from "@components/common/Badge";
import { toast } from "react-toastify";
import CancelIcon from "@assets/icons/remove.svg";
import { Modal } from "@components/common";
import * as S from "../styled";
import { useDeleteDelivery } from "../../../../../../api/deliveries";
import { useQueryClient } from "react-query";
import ClockIcon from "@assets/icons/clock.svg";

export const SingleDelivery: React.FC<{
  delivery: DeliveryType;
  companyId: string;
}> = ({ delivery, companyId }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showCtaModal, setShowCtaModal] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();

  const { mutate: updateOrder, status, reset } = useDeleteDelivery();

  const orderStatusTitle: { [key in DeliveryStateType]: string } = {
    draft: intl.formatMessage({
      id: "order.status.title.draft",
    }),
    scheduled: intl.formatMessage({
      id: "order.status.title.scheduled",
    }),
    delivered: intl.formatMessage({
      id: "order.status.title.delivered",
    }),
    canceled: intl.formatMessage({
      id: "order.status.title.canceled",
    }),
    waiting_for_information: intl.formatMessage({
      id: "order.status.title.waitingForInput",
    }),
    waiting_for_shipment: intl.formatMessage({
      id: "order.status.title.waitingForShipment",
    }),
    in_transit: intl.formatMessage({
      id: "order.status.title.inTransit",
    }),
  };

  const statusBadge: {
    [K in DeliveryStateType]: BadgeTypes;
  } = {
    scheduled: "default",
    in_transit: "primary",
    waiting_for_shipment: "warning",
    waiting_for_information: "warningSecondary",
    delivered: "success",
    canceled: "danger",
    draft: "secondary",
  };

  useEffect(() => {
    if (status === "success") {
      toast.success(
        intl.formatMessage({
          id: "orders.list.cancelSuccess",
        })
      );
      queryClient.invalidateQueries(["orders"]);
      queryClient.invalidateQueries(["contactOrders"]);
      setShowCtaModal(false);
      reset();
    }
    if (status === "error") {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      setShowCtaModal(false);
      reset();
    }
  }, [status]);

  if (!delivery) {
    return null;
  }

  return (
    <>
      <TableCell>
        {dayjs(
          delivery.scheduled_for_date ||
            delivery.requested_delivery_datetime ||
            delivery.created_at
        ).format(DEFAULT_DATE_FORMAT_SHORT)}
      </TableCell>
      <TableCell>
        <span style={{ textTransform: "uppercase" }}>
          {delivery.order.readable_id}
        </span>
      </TableCell>
      <TableCell>
        <S.BadgeStyled type={statusBadge[delivery.state]}>
          {delivery.state === "scheduled" && <ClockIcon />}
          {orderStatusTitle[delivery.state]}
        </S.BadgeStyled>
      </TableCell>
      <TableCell>
        {delivery.contact
          ? `${delivery.contact.first_name} ${delivery.contact.last_name}`
          : delivery.order?.name}
      </TableCell>
      {!delivery.contact && <TableCell>{delivery.order.volume}</TableCell>}
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton onClick={handleClick} type='button'>
          <MoreHoriz />
        </IconButton>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setShowCtaModal(true);
              handleClose();
            }}
            disabled={status === "loading"}
          >
            <CancelIcon />
            <FormattedMessage id='deliveries.cancel.single' />
          </MenuItem>
        </Menu>
        {showCtaModal && (
          <Modal
            show
            stretchCta
            title={intl.formatMessage({
              id: "deliveries.modal.cancel.title",
            })}
            ctaTitle={intl.formatMessage({
              id: "deliveries.modal.cancel.submit",
            })}
            cancelTitle={intl.formatMessage({
              id: "general.cancel",
            })}
            maxWidth='xs'
            cta={() => {
              updateOrder({
                order_id: delivery.order.id,
                company_id: companyId,
                delivery_id: delivery.id,
              });
            }}
            handleClose={() => setShowCtaModal(false)}
            isLoading={status === "loading"}
          />
        )}
      </TableCell>
    </>
  );
};
