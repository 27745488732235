import React from "react";

import { Modal } from "@components/common";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { OrderType } from "@types";
import { useUpdateOrder } from "../../../../hooks";

export const CancelModal: React.FC<{
  handleClose: () => void;
  order: OrderType;
  companyId: string;
}> = ({ handleClose, order, companyId }) => {
  const intl = useIntl();
  const { mutate: updateOrder, status } = useUpdateOrder();

  if (status === "success") {
    toast.success(
      intl.formatMessage({
        id: "orders.list.cancelSuccess",
      })
    );
    handleClose();
  }
  if (status === "error") {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    handleClose();
  }
  return (
    <Modal
      show
      stretchCta
      title={intl.formatMessage({
        id: "orders.modal.cancel.title",
      })}
      ctaTitle={intl.formatMessage({
        id: "orders.modal.cancel.submitBtn",
      })}
      cancelTitle={intl.formatMessage({
        id: "orders.modal.cancel.cancelBtn",
      })}
      maxWidth='xs'
      cta={() => {
        updateOrder({
          order_id: order.id,
          company_id: companyId,
          data: {
            state: "canceled",
          },
        });
      }}
      handleClose={handleClose}
      isLoading={status === "loading"}
    />
  );
};
