import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Divider, Typography } from "@material-ui/core";
import { DatePicker, Radio } from "@components/common";
import { AutomationStateType } from "@types";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { AuthContext } from "@providers/AuthProvider";
import * as S from "../../../styled";
import { FormType } from "../types";
import { formatBoldTag } from "@utils/common";

dayjs.extend(isSameOrAfter);

const today = new Date();

type CreateStep5Props = {
  onClose: () => void;
  payload: AutomationStateType | null;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
};

export const CreateStep5: React.FC<CreateStep5Props> = ({
  onClose,
  payload,
  formState,
  setFormState,
}) => {
  const [notificationsEnabled, setNotificationsEnabled] = useState<
    "true" | "false"
  >(payload?.notifications_enabled ? "true" : "false");
  const [notificationsEndingEnabled, setNotificationsEndingEnabled] = useState<
    "true" | "false"
  >("true");

  const { user } = useContext(AuthContext);

  const [notifyEndDate, setNotifyEndDate] = useState(
    dayjs(payload.end_date || today)
  );

  // const formDisabled = !notifyEndDate.isSameOrAfter(dayjs(today));

  const intl = useIntl();

  useEffect(() => {
    setFormState((state) => ({
      ...state,
      automationStop:
        notificationsEndingEnabled === "true" ? "date" : "manually",
    }));
  }, [notificationsEndingEnabled]);

  return (
    <S.StepBody>
      <Divider variant='fullWidth' />
      <Typography variant='h6'>
        <FormattedMessage id='automations.create.step5.notificationsTitle' />
      </Typography>
      <Radio
        value={notificationsEnabled}
        defaultValue={notificationsEnabled}
        // @ts-expect-error
        onChange={(e) => setNotificationsEnabled(e.target.value)}
        name='type'
        disabled
        items={[
          {
            value: "true",
            label: intl.formatMessage({
              id: "automations.create.step5.variant1",
            }),
          },
          {
            value: "false",
            label: intl.formatMessage({
              id: "automations.create.step5.variant2",
            }),
          },
        ]}
      />
      {notificationsEnabled === "true" && (
        <S.AlertStyled variant='warning'>
          <div>
            <FormattedMessage
              id='automations.create.step5.notifyMessage'
              values={{
                b: formatBoldTag,
                email: user?.email,
              }}
            />
          </div>
        </S.AlertStyled>
      )}

      <Divider variant='fullWidth' />
      <Typography variant='h6'>
        <FormattedMessage id='automations.create.step5.durationTitle' />
      </Typography>

      <Radio
        label={intl.formatMessage({
          id: "automations.create.step5.ending.label",
        })}
        value={notificationsEndingEnabled}
        defaultValue={formState?.automationStop === "date" ? "true" : "false"}
        onChange={(e) => setNotificationsEndingEnabled(e.target.value)}
        name='type'
        disabled
        items={[
          {
            value: "true",
            label: intl.formatMessage({
              id: "automations.create.step5.ending.variant1",
            }),
          },
          {
            value: "false",
            label: intl.formatMessage({
              id: "automations.create.step5.ending.variant2",
            }),
          },
        ]}
      />

      {notificationsEndingEnabled === "true" && (
        <DatePicker
          value={notifyEndDate}
          onChange={setNotifyEndDate}
          name='datepicker'
          defaultValue={dayjs(payload?.delivery_date || today)}
          hooked={false}
          disabled
          label={intl.formatMessage({
            id: "automations.create.step5.ending.label",
          })}
          direction='row'
        />
      )}

      <Divider variant='fullWidth' />
      <div>
        <Button variant='outlined' color='default' onClick={onClose}>
          <FormattedMessage id='general.close' />
        </Button>
      </div>
    </S.StepBody>
  );
};
