import React from "react";
import {
  CheckboxProps,
  FormControl,
  Checkbox,
  FormControlLabel,
  SvgIcon,
} from "@material-ui/core";
import {
  useFormContext,
  Controller,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";
import CheckedIcon from "@assets/icons/checkbox-checked.svg";
import UnCheckedIcon from "@assets/icons/checkbox-unchecked.svg";
import { checkboxStyles } from "./styled";

type CheckBoxComponentProps = {
  label: React.ReactNode;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  name: string;
  hooked?: boolean;
};

const CheckBoxComponent = ({
  disabled,
  color = "primary",
  label,
  name,
  error,
  required,
  defaultValue = "",
  checked = false,
  hooked = true,
  className,
  ...rest
}: CheckBoxComponentProps & CheckboxProps) => {
  const classes = checkboxStyles({ error: !!error });

  return (
    <FormControl
      component='div'
      error={!!error}
      className={`${className} ${classes.root}`}
    >
      {hooked ? (
        <FormControlLabel
          disabled={disabled}
          control={
            <Controller
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={!!field.value || checked}
                  color={color}
                  disabled={disabled}
                  name={name}
                  className={classes.invalid}
                  icon={
                    <SvgIcon viewBox='0 0 16 16' component={UnCheckedIcon} />
                  }
                  checkedIcon={
                    <SvgIcon viewBox='0 0 16 16' component={CheckedIcon} />
                  }
                  {...rest}
                />
              )}
              name={name}
              // eslint-disable-next-line
              control={useFormContext().control}
              defaultValue={defaultValue}
              rules={{ required }}
            />
          }
          label={label}
          className={classes.invalid}
        />
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              color={color}
              icon={<SvgIcon viewBox='0 0 16 16' component={UnCheckedIcon} />}
              checkedIcon={
                <SvgIcon viewBox='0 0 16 16' component={CheckedIcon} />
              }
              disabled={disabled}
              name={name}
              className={classes.invalid}
              checked={checked}
              {...rest}
            />
          }
          disabled={disabled}
          label={label}
          className={classes.invalid}
        />
      )}
    </FormControl>
  );
};

export default CheckBoxComponent;
