import React, { useState } from "react";
import { ROUTES, VALIDATION } from "@constants/index";
import { TextField, Checkbox } from "@components/common";
import { Typography, Grid } from "@material-ui/core";
import { FormattedMessage, useIntl, WrappedComponentProps } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { TenantType, UserProfileType, AuthRouterStateType } from "@types";
import cognitoId from "../../../../../cognitoId";
import {
  WrapperStyled,
  FormStyled,
  SubmitButtonStyled,
  LogoStyled,
  LinkStyled,
} from "../../styled";

interface SignUpProps {
  userSignedUp: (userData: UserProfileType) => void;
  createTenant: (tenantData: TenantType) => void;
}

const SignUp = ({
  userSignedUp,
  history,
  createTenant,
}: SignUpProps &
  RouteComponentProps<{}, {}, AuthRouterStateType> &
  WrappedComponentProps) => {
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const intl = useIntl();

  const onSubmit = ({ email, field1, password }) => {
    setIsLoading(true);

    const userPool = cognitoId.getUserPool();

    const attributeList = [
      new CognitoUserAttribute({
        Name: "name",
        Value: field1,
      }),
    ];

    userPool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        setErrorMsg(err.message);
        setIsLoading(false);
        return;
      }

      if (result) {
        createTenant({
          id: result.userSub,
          email,
        });

        userSignedUp({
          id: result.userSub,
          email: result.user.getUsername(),
          name: result.user.getUsername(),
          verified: result.userConfirmed,
        });

        history.push({
          pathname: ROUTES.USER_VERIFY,
          state: {
            email,
            password,
          },
        });
      }
    });
  };

  return (
    <WrapperStyled
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <LogoStyled />
      <FormProvider {...methods}>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name='field1'
            type='text'
            placeholder={intl.formatMessage({
              id: "input.username.placeholder",
            })}
            label={intl.formatMessage({ id: "input.username.label" })}
            error={errors.username}
            required
            autoComplete='off'
            data-lpignore='true'
          />
          <TextField
            name='email'
            placeholder={intl.formatMessage({
              id: "input.email.placeholder",
            })}
            type='email'
            label={intl.formatMessage({ id: "input.email.label" })}
            error={errors.email}
            pattern={{
              value: VALIDATION.EMAIL_VALIDATION_REGEXP,
              message: intl.formatMessage({ id: "input.email.pattern.error" }),
            }}
            autoComplete='username'
            data-lpignore='true'
            required
          />
          <TextField
            name='password'
            placeholder={intl.formatMessage({
              id: "input.password.placeholder",
            })}
            type='password'
            label={intl.formatMessage({ id: "input.password.label" })}
            error={errors.password}
            autoComplete='password'
            data-lpignore='true'
            // minLength={8}
            required
          />
          <Checkbox
            required
            name='terms'
            error={errors.terms}
            label={intl.formatMessage({ id: "sign.up.terms" })}
          />
          <SubmitButtonStyled
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            loading={isLoading}
            type='submit'
          >
            <FormattedMessage id='sign.up.submit' />
          </SubmitButtonStyled>
        </FormStyled>
      </FormProvider>
      {errorMsg && (
        <Typography variant='caption' paragraph color='error'>
          {errorMsg}
        </Typography>
      )}
      <Grid container justifyContent='center'>
        <Typography variant='body1' paragraph>
          <FormattedMessage id='sign.up.accountExist' />
          <LinkStyled color='primary' to={ROUTES.USER_SIGN_IN}>
            <FormattedMessage id='sign.up.linkToSignIn' />
          </LinkStyled>
        </Typography>
      </Grid>
    </WrapperStyled>
  );
};

export default SignUp;
