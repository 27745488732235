import {
  ContactType,
  TagType,
  DeliveryType,
  PagingType,
  ContactImport,
  ContactAddressType,
} from "@types";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import {
  fetchContacts,
  fetchContact,
  fetchContactAddress,
  fetchContactDeliveries,
  cancelContactDelivery,
  getTags,
  getAllTags,
  createContact,
  updateContact,
  importContacts,
  getContactsImports,
  deleteContact,
  createGiftDelivery,
  updateContactAddress,
} from "./api";

export const useContacts = (
  companyId: string,
  page: number,
  tagId?: string,
  perPage?: number,
  searchValue?: string
): UseQueryResult<{ items: ContactType[]; paging: PagingType }> =>
  useQuery(
    ["contacts", companyId, page, tagId, perPage, searchValue],
    fetchContacts,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );

export const useContact = (
  companyId: string,
  contactId: string
): UseQueryResult<ContactType> =>
  useQuery(["contact", companyId, contactId], fetchContact, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useContactAddress = (
  companyId: string,
  contactId: string
): UseQueryResult<ContactAddressType> =>
  useQuery(["contactAddresses", companyId, contactId], fetchContactAddress, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useCreateContact = () => {
  return useMutation({
    mutationFn: async ({
      company_id,
      data,
    }: {
      company_id: string;
      data: any;
    }) =>
      createContact({
        company_id,
        data,
      }),
  });
};

export const useUpdateContact = () => {
  return useMutation({
    mutationFn: async ({
      company_id,
      contact_id,
      data,
    }: {
      company_id: string;
      contact_id: string;
      data: any;
    }) =>
      updateContact({
        company_id,
        contact_id,
        data,
      }),
  });
};

export const useUpdateContactAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      company_id,
      contact_id,
      data,
    }: {
      company_id: string;
      contact_id: string;
      data: ContactAddressType;
    }) =>
      updateContactAddress({
        company_id,
        contact_id,
        data,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["contactAddresses"]);
    },
  });
};

export const useDeleteContact = () =>
  useMutation({
    mutationFn: async ({
      company_id,
      contact_id,
    }: {
      company_id: string;
      contact_id: string;
    }) =>
      deleteContact({
        company_id,
        contact_id,
      }),
  });

export const useImportContacts = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      data,
    }: {
      company_id: string;
      data: {
        file: File;
      };
    }) =>
      importContacts({
        company_id,
        data,
      }),

    onSuccess: async () => {
      queryClient.invalidateQueries(["contactImports"]);
    },
  });
};

export const useTags = (companyId: string): UseQueryResult<TagType[]> =>
  useQuery(["tags", companyId], getTags, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useAllTags = (companyId: string): UseQueryResult<TagType[]> =>
  useQuery(["allTags", companyId], getAllTags, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: 0,
    retry: 2,
    enabled: !!companyId,
  });

export const useContactsImports = (
  companyId: string
): UseQueryResult<ContactImport[]> =>
  useQuery(["contactImports", companyId], getContactsImports, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useContactDeliveries = (
  companyId: string,
  contactId: string,
  page: number
): UseQueryResult<{ items: DeliveryType[]; paging: PagingType }> =>
  useQuery(
    ["contactOrders", companyId, contactId, page],
    fetchContactDeliveries,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );

export const useCancelContactDelivery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      order_id,
      delivery_id,
    }: {
      company_id: string;
      order_id: string;
      delivery_id: string;
    }) =>
      cancelContactDelivery({
        company_id,
        order_id,
        delivery_id,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["contactOrders"]);
    },
  });
};

export const useCreateGiftDelivery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      contact_id,
      data,
    }: {
      company_id: string;
      contact_id: string;
      data: {
        gift_id: string;
        delivered_at: string;
        signature_external_id: string;
      };
    }) =>
      createGiftDelivery({
        company_id,
        contact_id,
        data,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["contactOrders"]);
    },
  });
};
