import React, { useEffect, useState } from "react";
import { Modal } from "@ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import CancelIcon from "@assets/icons/remove-red.svg";
import { useCancelTopUp } from "../hooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import {ButtonStyled} from '../styled';

export const CancelTopUp = ({
  id,
  company_id,
}: {
  id: string;
  company_id: string;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const intl = useIntl();

  const queryClient = useQueryClient();

  const { mutate: cancelTopUp, status } = useCancelTopUp();

  const handleCancel = () => {
    cancelTopUp({
      company_id: company_id,
      topup_id: id,
    });
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (status === "success") {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
      queryClient.invalidateQueries(["companyTransaction"]);
    }

    if (status === "error") {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
    }
  }, [status]);

  return (
    <div>
      <ButtonStyled startIcon={<CancelIcon />} variant='text' onClick={showModal}>
        <FormattedMessage id='credit.cancel.messageShort' />
      </ButtonStyled>
      <Modal
        maxWidth='xs'
        stretchCta
        title={intl.formatMessage({ id: "credit.cancel.message" })}
        show={isModalVisible}
        handleClose={handleModalCancel}
        cta={handleCancel}
        ctaTitle={intl.formatMessage({ id: "credit.cancel.modal.yes" })}
        cancelTitle={intl.formatMessage({ id: "credit.cancel.modal.no" })}
      />
    </div>
  );
};
