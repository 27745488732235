import { Badge } from "@components/common";
import styled from "styled-components";

export const GiftPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

export const BadgeRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
  }
`;

export const BadgeStyled = styled(Badge)`
  white-space: nowrap;
`;
