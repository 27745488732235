import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, TextField, Select } from "@components/common";
import { MenuItem } from "@material-ui/core";
import { toast } from "react-toastify";
import { AuthContext } from "@providers/AuthProvider";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { UserProfileType } from "../../../../../types";
import { TopFormStyled } from "../../styled";
import cognitoUser from "../../../../../cognitoId";

interface AccountFormProps {
  user: UserProfileType;
  setIsEmailVerified: Dispatch<SetStateAction<boolean>>;
}

const AccountForm: React.FC<AccountFormProps> = ({
  user,
  setIsEmailVerified,
}) => {
  const { setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: useMemo(
      () => ({
        name: user.name,
        locale: user.locale,
        email: user.email,
      }),
      [user]
    ),
  });
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = methods;

  const onSubmit = ({
    email,
    name,
    locale,
  }: {
    name: string;
    email: string;
    locale: "sk" | "en" | "cs";
  }) => {
    if (!isDirty) {
      return;
    }

    setIsLoading(true);
    const isEmailChanged = email !== user.email;

    const FullName = {
      Name: "name",
      Value: name,
    };

    const Locale = {
      Name: "locale",
      Value: locale,
    };

    const Email = {
      Name: "email",
      Value: email,
    };

    const attributes = [];

    if (name !== user.name) {
      attributes.push(FullName);
    }

    if (isEmailChanged) {
      attributes.push(Email);
    }

    if (locale !== user.locale) {
      attributes.push(Locale);
    }

    cognitoUser.getCognitoUser().updateAttributes(attributes, (error) => {
      if (error) {
        toast.error(
          intl.formatMessage({
            id: "general.notifications.error",
          })
        );
        setIsLoading(false);
        return;
      }

      setUser((state) => ({
        ...state,
        locale,
        name,
        email,
      }));

      cognitoUser
        .getCognitoUser()
        .getSession((err: Error, session: CognitoUserSession) => {
          if (err) {
            setUser(null);
            return;
          }

          cognitoUser
            .getCognitoUser()
            .refreshSession(session.getRefreshToken(), (sessionErr: Error) => {
              if (sessionErr) {
                setUser(null);
                return;
              }

              toast.success(
                intl.formatMessage({
                  id: "settings.account.form.success",
                })
              );
            });
        });

      cognitoUser.getCognitoUser()?.getUserAttributes((err, result) => {
        if (err) {
          throw err;
        }
        const isVerified = result.find(
          (attr) => attr.Name === "email_verified"
        )?.Value;
        setIsEmailVerified(isVerified === "true");
      });
      if (isEmailChanged) {
        setIsEmailVerified(true);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    reset({
      name: user.name,
      locale: user.locale,
      email: user.email,
    });
  }, [user, reset]);

  return (
    <FormProvider {...methods}>
      <TopFormStyled>
        <TextField
          name='name'
          placeholder={intl.formatMessage({
            id: "settings.account.form.fullName.label",
          })}
          label={intl.formatMessage({
            id: "settings.account.form.fullName.label",
          })}
          error={errors.name}
          required
        />
        <TextField
          name='email'
          placeholder={intl.formatMessage({
            id: "input.email.placeholder",
          })}
          label={intl.formatMessage({ id: "input.email.label" })}
          type='email'
          required
          // disabled={!isEmailVerified}
        />
        <Select
          id='locale'
          name='locale'
          label={intl.formatMessage({
            id: "input.language.label",
          })}
        >
          <MenuItem value='en'>
            <FormattedMessage id='shared.lang.eng' />
          </MenuItem>
          <MenuItem value='sk'>
            <FormattedMessage id='shared.lang.sk' />
          </MenuItem>
          <MenuItem value='cs'>
            <FormattedMessage id='shared.lang.cs' />
          </MenuItem>
        </Select>
        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          <FormattedMessage id='settings.account.form.submit' />
        </Button>
      </TopFormStyled>
    </FormProvider>
  );
};

export default AccountForm;
