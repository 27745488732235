import { AxiosPromise } from "axios";
import { DeliveryType } from "@types";
import { QueryKey, UseQueryResult, useMutation, useQuery } from "react-query";
import { baseApi } from "@utils/apiService";
import { COMPANIES } from "@constants/api";

export const fetchDelivery = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<DeliveryType> => {
  return baseApi
    .get(
      `${COMPANIES}/${queryKey[1]}/orders/${queryKey[2]}/deliveries/${queryKey[3]}`
    )
    .then((res) => res.data?.items?.[0]);
};

export const deleteDelivery = ({
  company_id,
  order_id,
  delivery_id,
}: {
  company_id: string;
  order_id: string;
  delivery_id: string;
}): AxiosPromise<DeliveryType> => {
  return baseApi
    .delete(
      `${COMPANIES}/${company_id}/orders/${order_id}/deliveries/${delivery_id}`
    )
    .then((res) => res.data);
};

export const useDelivery = (
  company_id: string,
  order_id: string,
  delivery_id: string
): UseQueryResult<DeliveryType> =>
  useQuery(["delivery", company_id, order_id, delivery_id], fetchDelivery, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!company_id,
    staleTime: 0,
  });

export const useDeleteDelivery = () => {
  return useMutation({
    mutationFn: async ({
      company_id,
      order_id,
      delivery_id,
    }: {
      company_id: string;
      order_id: string;
      delivery_id: string;
    }) =>
      deleteDelivery({
        company_id,
        order_id,
        delivery_id,
      }),
  });
};
