import { Alert, Button, Select, Checkbox } from "@components/common";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const YoutubeLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;

  span {
    margin: 0;
    font-weight: 500;
  }
`;

export const Container = styled.div`
  padding: 0 0 45px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 894px;
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 32px;
  justify-content: space-between;
`;

export const SubHeading = styled.div`
  margin-bottom: 0;
`;

export const CreateCampaignSteps = styled.div`
  border: 1px solid #ececec;
  border-radius: 12px;
  overflow: hidden;
`;

export const CreateCampaignStep = styled.div<{
  invalid?: boolean;
  expanded: boolean;
}>`
  padding: 28px 32px;
  background-color: #fcfcfc;

  ${({ expanded = false }) =>
    expanded &&
    css`
      background-color: #fff;
    `}

  ${({ invalid = false, theme }) =>
    invalid &&
    css`
      h4,
      h4 + p {
        color: ${theme.palette.primary.main};
      }
    `}

  & + & {
    border-top: 1px solid #ececec;
  }
`;

export const CreateCampaignStepHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 18px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const SelectedGiftWrapperStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
`;

export const CreateCampaignStepHeaderLeft = styled.div`
  display: flex;
  gap: 16px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  ${SelectedGiftWrapperStyled} {
    margin-top: 12px;
  }
`;

export const CreateCampaignStepHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StepBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 0;
  align-items: flex-start;
  gap: 10px;
`;

export const StepSelectRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  /* button {
    margin-left: -18px;
  } */
`;

export const SelectStyled = styled(Select)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0;

  label {
    margin-bottom: 0;
    font-weight: 500;
    white-space: nowrap;
  }
`;

export const AddressInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 15px;
`;

export const AskRecipient = styled.div`
  padding: 16px 24px;
  background: #fff9ea;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .MuiOutlinedInput-root,
  .MuiSelect-selectMenu {
    background-color: #fff;
  }
`;

export const TagInfoToggle = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #a0a0a0;
  margin-left: 0 !important;
  font-weight: 600;
`;

export const InfoTextStyled = styled(Typography)`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  display: flex;
  max-width: 538px;
  margin-bottom: 0;

  svg {
    flex-shrink: 0;
  }

  button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const SelectGiftStyled = styled(Button)`
  width: 230px;
  height: 84px;
  border: 2px solid #f8f6f4;
  border-radius: 12px;
  color: #000;
  margin-bottom: 12px;

  .MuiButton-label {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .MuiButton-startIcon {
    margin: 0;
  }

  path {
    stroke: currentColor;
  }
`;

export const SelectGiftListStyled = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SelectGiftListItemStyled = styled.li`
  list-style: none;

  > button {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    img {
      border-radius: 12px;
      object-fit: cover;
    }
  }
`;

export const SelectedGiftStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  border-radius: 12px;
  text-decoration: none;
  color: #263238;

  img {
    border-radius: 12px;
    object-fit: cover;
  }
`;

export const SelectGiftInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SelectGiftInfoSRowStyled = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #263238;
  align-items: center;
`;

export const SelectGiftFlagStyled = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

// STEP 3 Personalization

export const DynamicFieldsWrapper = styled.div`
  max-width: 670px;
  min-width: 400px;
  width: 100%;

  label {
    font-weight: 400;
    font-size: 15px;
  }

  .MuiInput-input {
    line-height: 22px;
    font-size: 15px;
  }
`;

export const DynamicFieldsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const DynamicFieldsDemo = styled.div`
  padding: 16px 24px;
  background-color: #fff9ea;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 12px;
`;

export const DynamicFieldsDemoLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }

  p {
    margin-bottom: 0;
  }
`;

export const DateAndLocationRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  align-items: center;

  > div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export const AlertStyled = styled(Alert)`
  align-self: flex-start;
  display: block;
  max-width: 630px;
  background-color: #fed8cb;
  font-weight: normal;
  margin-top: 12px;
  font-size: 15px;

  a {
    color: inherit;
    font-size: 15px;
  }

  button {
    border: 0;
    background-color: transparent;
    padding: 0;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15px;
    font-family: inherit;
  }
`;

export const OrderList = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const Order = styled.div`
  max-width: 300px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  border-radius: 12px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #ececec;

  img {
    height: 208px;
    width: 100%;
    object-fit: cover;
    display: block;
  }
`;

export const CheckBoxComponentStyled = styled(Checkbox)`
  label {
    margin: 0;
  }
`;

export const MenuEmpty = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 14px;

  a {
    margin-top: 16px;
  }
`;

export const OrderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 48px;

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }

  > div {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
`;

export const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const WaitingAlertWrapper = styled.div`
  &:empty {
    display: none;
  }
`;
export const WaitingAlert = styled.div`
  width: 100%;

  > div > div {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-weight: normal;
    }
  }

  svg {
    vertical-align: sub;
  }
`;

export const PaymentSuccessModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 450px;
  padding: 12px 0;
`;


