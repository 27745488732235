// import * as Sentry from "@sentry/react";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "../modules/reducer";
import middlewares from "../middlewares";

// const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: reducers,
  middleware: middlewares,
  devTools: process.env.NODE_ENV === "development",
});

export default store;
