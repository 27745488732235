import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as S from "./styled";
import ArrowRightIcon from "@assets/icons/arrow-right.svg";
import Modal from "../Modal";
import TextField from "../TextField";
import { FormattedMessage, useIntl } from "react-intl";
import { UserProfileType, CompanyType, PagingType } from "@types";
import { ButtonBase } from "@material-ui/core";
import Pagination from "../Pagination";
import Loader from "../LoaderCentered";

interface AdminMenuProps {
  companies: CompanyType[];
  user: UserProfileType;
  cta: (id: string) => void;
  paging: PagingType;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  isLoading: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  withSearch?: boolean;
}

const AdminMenu: React.FC<AdminMenuProps & React.PropsWithChildren> = ({
  companies,
  user,
  cta,
  paging,
  page,
  setPage,
  showModal,
  setShowModal,
  isLoading,
  searchValue,
  setSearchValue,
  withSearch = true,
}) => {
  const selectedCompany = user.company_id;
  const [newCompany, setNewCompany] = useState<string>(null);

  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setNewCompany(companies?.find((c) => c.id !== user.company_id)?.id);
  }, [companies]);

  return (
    <S.Container>
      <S.Title>Admin menu</S.Title>
      <ul>
        <li>
          <ButtonBase onClick={() => setShowModal(true)}>
            <FormattedMessage id='Switch company' />
          </ButtonBase>
        </li>
        <li style={{ fontSize: 12 }}>v2023-11-22</li>
        <li style={{ marginLeft: "auto", marginRight: "80px" }}>
          {user.company_name} : {user.company_id}
        </li>
        {/* <li>
          <ButtonBase>
            <CopyIcon /> Copy token
          </ButtonBase>
        </li> */}
      </ul>
      <Modal
        show={showModal}
        title={intl.formatMessage({
          id: "Choose company",
        })}
        ctaTitle={intl.formatMessage(
          {
            id: "Switch to",
          },
          {
            value: companies?.find((c) => c.id === newCompany)?.business_name,
          }
        )}
        cancelTitle={intl.formatMessage({
          id: "general.cancel",
        })}
        maxWidth='sm'
        cta={() => {
          setLoading(true);
          cta(newCompany);
          setLoading(false);
        }}
        handleClose={() => {
          setShowModal(false);
          setShowModal(false);
        }}
        isLoading={isLoading || loading}
      >
        <>
          {withSearch && (
            <TextField
              value={searchValue}
              // @ts-expect-error
              onChange={(e) => setSearchValue(e.target.value)}
              hooked={false}
              placeholder={intl.formatMessage({ id: "Search companies" })}
            />
          )}
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <S.CompanyList>
                {companies?.map((company) => (
                  <li key={company.id}>
                    <S.CompanySwitchButton
                      disableRipple
                      disabled={company.id === selectedCompany}
                      onClick={() => setNewCompany(company.id)}
                      isActive={company.id === selectedCompany}
                      isSelected={company.id === newCompany}
                    >
                      <div>
                        <ArrowRightIcon />
                      </div>
                      {company.business_name}
                      {company.id === selectedCompany && (
                        <FormattedMessage id='Active' />
                      )}
                      <span>{company.notification_email}</span>
                    </S.CompanySwitchButton>
                  </li>
                ))}
              </S.CompanyList>
              <Pagination
                total={paging?.total_records}
                page={page}
                onChange={setPage}
              />
            </>
          )}
        </>
      </Modal>
    </S.Container>
  );
};

export default AdminMenu;
