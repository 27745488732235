import { Typography } from "@material-ui/core";
import { DeliverySlipDetails } from "@types";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import BoxImageSrc from "@assets/icons/box.png";
import * as S from "../styled";
import { Page } from "../../styled";
import { Header } from "../Header";
import { Footer } from "../Footer";

export const Submitted: React.FC<DeliverySlipDetails & { short?: boolean }> = ({
  readable_id,
  sender,
  recipient,
  short,
}) => {
  const intl = useIntl();
  return (
    <Page>
      {short && <Header />}
      <S.Container>
        <div>
          <Typography variant='h5'>
            <FormattedMessage
              id='recipient.form.deliveryTitle'
              values={{ id: `#${readable_id?.toUpperCase()}` }}
            />
          </Typography>
          <Typography variant='h2'>
            <FormattedMessage id='recipient.form.thanks' />
          </Typography>
        </div>

        <div>
          <Typography>
            {short ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    {
                      id: "recipient.form.submitted.textShort",
                    },
                    {
                      date: `<b>${dayjs(recipient?.shipping_datetime).format(
                        DEFAULT_DATE_FORMAT_SHORT
                      )}</b>`,
                      address: `<b>${recipient?.address?.city}, ${recipient.address.zip} ${recipient?.address?.street}</b>`,
                    }
                  ),
                }}
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    {
                      id: "recipient.form.submitted.text",
                    },
                    {
                      name: `<b>${sender.name}</b>`,
                      date: `<b>${dayjs(recipient?.shipping_datetime).format(
                        DEFAULT_DATE_FORMAT_SHORT
                      )}</b>`,
                      address: `<b>${recipient?.address?.city}, ${recipient.address.zip} ${recipient?.address?.street}</b>`,
                      phone: `<b>${recipient?.address?.contact_phone}</b>`,
                    }
                  ),
                }}
              />
            )}
          </Typography>
          <Typography>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {
                    id: "recipient.form.contact",
                  },
                  {
                    email: `<a href='mailto:support@boxday.app'>support@boxday.app</a>`,
                  }
                ),
              }}
            />
          </Typography>
          <Typography variant='h4'>#{readable_id?.toUpperCase()}</Typography>
          <img src={BoxImageSrc} alt='' width='200px' />
        </div>
      </S.Container>
      <Footer />
    </Page>
  );
};
