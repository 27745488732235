import React, { useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";
import { Switch, Route, useParams, useLocation } from "react-router-dom";
import { Upcoming } from "./Upcoming";
import { OrderDetailsSummary } from "./Summary";
import { Invoices } from "./Invoices";
import * as S from "../styled";
import { Alert, Button, LinkBack, Modal, SectionNav } from "@ui/components";
import { useOrder } from "../../hooks";
import { AuthContext } from "@providers/AuthProvider";
import { ChangeOrderStatus } from "./ChangeStatus";
import ClockIcon from "@assets/icons/clock.png";
import ConfettiIcon from "@assets/icons/confetti.png";
import InfoIcon from "@assets/icons/info.svg";

export const OrderDetails: React.FC = () => {
  const { user } = useContext(AuthContext);

  const { id } = useParams<{ id: string }>();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const paymentStatus = searchParams.get("payment");

  const [showInfoModal, setShowInfoModal] = useState(
    paymentStatus === "success"
  );

  const showSummary = useMemo(
    () => searchParams.get("tab") === "summary",
    [searchParams]
  );
  const showInvoices = useMemo(
    () => searchParams.get("tab") === "invoices",
    [searchParams]
  );

  const { data: orderDetails } = useOrder(user.company_id, id);

  return (
    <S.Container>
      <S.SubHeading>
        <LinkBack to='/orders/list'>
          <FormattedMessage id='deliveries.back.title' />
        </LinkBack>
        <S.HeaderTopStyled>
          <Typography variant='h2'>{orderDetails?.name}</Typography>
          <ChangeOrderStatus orderId={orderDetails?.id} />
        </S.HeaderTopStyled>
        <SectionNav
          navItems={[
            {
              title: "deliveries.title",
              link: `/orders/details/${id}`,
              isActive: !showSummary && !showInvoices,
            },
            {
              title: "invoices.title",
              link: `/orders/details/${id}?tab=invoices`,
              isActive: showInvoices,
            },
            {
              title: "order.details.title",
              link: `/orders/details/${id}?tab=summary`,
              isActive: showSummary,
              disabled: true,
            },
          ]}
        />
      </S.SubHeading>
      <S.WaitingAlertWrapper>
        {orderDetails?.state === "scheduled" && (
          <S.WaitingAlert>
            <Alert variant='warning'>
              <img src={ClockIcon} alt='' width={84} />
              <div>
                <Typography component='h3' variant='h4'>
                  <FormattedMessage id='deliveries.confirm.alert' />
                </Typography>
                <div>
                  <InfoIcon />
                  <FormattedMessage id='deliveries.confirm.message' />
                </div>
              </div>
            </Alert>
          </S.WaitingAlert>
        )}
      </S.WaitingAlertWrapper>
      <Switch>
        {showSummary ? (
          <Route
            exact
            component={OrderDetailsSummary}
            path={`/orders/details/:id`}
          />
        ) : showInvoices ? (
          <Route
            exact
            component={() => (
              <Invoices invoice_id={orderDetails?.readable_id} />
            )}
            path={`/orders/details/:id`}
          />
        ) : (
          <Route exact component={Upcoming} path={`/orders/details/:id`} />
        )}
      </Switch>
      <Modal
        title=''
        subTitle=''
        show={showInfoModal}
        handleClose={() => setShowInfoModal(false)}
        showCloseBtn={false}
        maxWidth='xs'
      >
        <S.PaymentSuccessModal>
          <img src={ConfettiIcon} alt='' width='54' />
          <Typography variant='h3'>
            <FormattedMessage id='orders.card.create.success.title' />
          </Typography>
          <Typography variant='body1'>
            <FormattedMessage id='orders.card.create.success.msg' />
          </Typography>
          <Button type='button' onClick={() => setShowInfoModal(false)}>
            <FormattedMessage id='orders.card.create.success.cta' />
          </Button>
        </S.PaymentSuccessModal>
      </Modal>
    </S.Container>
  );
};
