import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import { HeaderTopStyled, SubHeading, GiftsStyled, Container } from "../styled";
import { Gift } from "../Gift";
import { AuthContext } from "@providers/AuthProvider";
import {
  getGiftDescriptionByLanguageCode,
  getGiftPriceByCountryCode,
} from "@utils/common";
import { useGifts } from "../../hooks";
import { Error, Loader, Pagination } from "@components/common";
import { NoGifts } from "../NoGifts";
import { GiftPromo } from "../GiftPromo";

export const MyGifts: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const {
    data: gifts,
    isError,
    isLoading,
  } = useGifts(user.company_id, {
    origin_type: "boxday_merchant",
    is_company_specific: true,
    page,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <Container>
      <HeaderTopStyled>
        <SubHeading variant='h3'>
          <FormattedMessage id='myGifts.title' />
        </SubHeading>
      </HeaderTopStyled>

      <GiftsStyled>
        {gifts.items?.map((gift) => (
          <Gift
            key={gift.id}
            id={gift.id}
            title={gift.name}
            status={gift.availability_state}
            badges={gift.flags}
            imgSrc={gift.images[0]?.url || GiftPlaceholder}
            price={getGiftPriceByCountryCode(gift.prices, user.currency)}
            description={getGiftDescriptionByLanguageCode(
              gift.descriptions,
              user.locale
            )}
            href={`/gifts/my-gifts/merchant/${gift.merchant.id}/${gift.id}`}
            comingSoon={gift.availability_state === "coming_soon"}
            checkForBrandedImage={gift.flags.instant_purchase_available}
          />
        ))}
        {gifts?.items?.length === 0 && (
          <NoGifts
            headingId='myGifts.list.empty.heading'
            messageId='myGifts.list.empty.message'
          />
        )}
        <GiftPromo />
      </GiftsStyled>
      <Pagination
        page={page}
        onChange={setPage}
        total={gifts?.paging?.total_records || 0}
      />
    </Container>
  );
};
