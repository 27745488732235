import React, { useEffect, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, MenuItem, Typography } from "@material-ui/core";
import { Radio } from "@components/common";
import { useDispatch } from "react-redux";
import {
  ContactType,
  CompanyAddressType,
  AutomationPayload,
  AutomationNonOfficeHoursType,
  DeliveryDetailsSourceType,
  DeliveryNotificationType,
} from "@types";
import AddIcon from "@assets/icons/circle-plus.svg";
import { showModal } from "../../../../../modal/actions";
import * as S from "../../../styled";
import { formatBoldTag } from "@utils/common";

type CreateStep4Props = {
  companyId: string;
  onClose: () => void;
  selectedAddress?: string;
  contacts: ContactType[];
  offices: CompanyAddressType[];
  payload: AutomationPayload | null;
};

export const CreateStep4: React.FC<CreateStep4Props> = ({
  companyId,
  onClose,
  selectedAddress,
  contacts,
  offices,
  payload,
}) => {
  const [deliveryDetailsType, setDeliveryDetailsType] =
    useState<DeliveryDetailsSourceType>(
      payload?.delivery_details_source_type || "predefined"
    );
  const [deliveryNonOfficeHours, setDeliveryNonOfficeHours] =
    useState<AutomationNonOfficeHoursType>(
      payload?.delivery_non_office_hours_strategy || "next_working_day"
    );
  const [deliveryAddress, setDeliveryAddress] = useState<string | null>(
    payload?.delivery_address_id || ""
  );
  const [deliveryNotifyType, setDeliveryNotifyType] =
    useState<DeliveryNotificationType>(
      payload?.delivery_notification_type || "email"
    );

  // const [deliveryNotifyWhen, setDeliveryNotifyWhen] = useState<number>(
  //   payload?.delivery_notification_number_of_days_in_advance || 0
  // );
  const [deliveryAddressType, setDeliveryAddressType] = useState<
    "office" | "custom"
  >("office");

  const dispatch = useDispatch();
  const intl = useIntl();

  const contactsWithoutEmails = useMemo(() => {
    // TODO: Adjust logic with API
    if (contacts?.length > 0) {
      return contacts.filter((contact) => !contact.email?.length);
    }
    return [];
  }, [contacts]);

  const contactsWithoutPhones = useMemo(() => {
    // TODO: Adjust logic with API
    if (contacts?.length > 0) {
      return contacts.filter((contact) => !contact.phone_number?.length);
    }
    return [];
  }, [contacts]);

  useEffect(() => {
    if (offices) {
      if (selectedAddress) {
        setDeliveryAddress(
          offices.find((office) => office.id === selectedAddress).id
        );
      } else {
        setDeliveryAddress(offices[0]?.id);
      }
    }
  }, [offices, deliveryAddressType, selectedAddress]);

  return (
    <S.StepBody>
      <Radio
        value={deliveryDetailsType}
        defaultValue={deliveryDetailsType}
        // @ts-expect-error
        onChange={(e) => setDeliveryDetailsType(e.target.value)}
        name='type'
        disabled
        items={[
          {
            value: "predefined",
            label: intl.formatMessage({
              id: "automations.create.step4.variant1",
            }),
          },
          {
            value: "requested_from_recipient",
            label: intl.formatMessage({
              id: "automations.create.step4.variant2",
            }),
          },
        ]}
      />
      {deliveryDetailsType === "predefined" && (
        <>
          <div>
            <S.SelectStyled
              disabled
              value={deliveryNonOfficeHours}
              // @ts-expect-error
              onChange={(e) => setDeliveryNonOfficeHours(e.target.value)}
              label={
                payload.delivery_date_type === "birthday"
                  ? intl.formatMessage(
                      {
                        id: "automations.create.step4.nonOfficeHours.birthdayLabel",
                      },
                      {
                        b: (chunks) => formatBoldTag(chunks),
                      }
                    )
                  : intl.formatMessage(
                      {
                        id: "automations.create.step4.nonOfficeHours.anniversaryLabel",
                      },
                      {
                        b: (chunks) => formatBoldTag(chunks),
                      }
                    )
              }
              id='non-office-hours'
              name='non-office-hours'
              hooked={false}
            >
              <MenuItem value='next_working_day'>
                <FormattedMessage id='automations.create.step4.nonOfficeHours.variant1' />
              </MenuItem>
              <MenuItem value='previous_working_day'>
                <FormattedMessage id='automations.create.step4.nonOfficeHours.variant2' />
              </MenuItem>
              <MenuItem value='same_day'>
                <FormattedMessage id='automations.create.step4.nonOfficeHours.variant3' />
              </MenuItem>
            </S.SelectStyled>
          </div>

          {offices.length > 0 ? (
            <S.StepSelectRow>
              <S.SelectStyled
                disabled
                id='addressType'
                name='addressType'
                label={intl.formatMessage({
                  id: "automations.create.step4.predefined.addressLabel",
                })}
                defaultValue={deliveryAddressType}
                value={deliveryAddressType}
                hooked={false}
                // @ts-expect-error
                onChange={(e) => setDeliveryAddressType(e.target.value)}
              >
                <MenuItem value='office'>
                  <FormattedMessage id='automations.create.step4.addressType.variant1' />
                </MenuItem>
                <MenuItem value='custom' disabled>
                  <FormattedMessage id='automations.create.step4.addressType.variant2' />
                </MenuItem>
              </S.SelectStyled>
              <S.SelectStyled
                id='offices'
                name='offices-input'
                label=''
                defaultValue={deliveryAddress}
                value={deliveryAddress}
                hooked={false}
                disabled
                // @ts-expect-error
                onChange={(e) => setDeliveryAddress(e.target.value)}
              >
                {offices.map((office) => (
                  <MenuItem key={office.id} value={office.id}>
                    {office.name}
                  </MenuItem>
                ))}
              </S.SelectStyled>
            </S.StepSelectRow>
          ) : (
            <Button
              onClick={() => {
                dispatch(
                  showModal({
                    type: "ADD_OFFICE_ADDRESS_MODAL",
                    data: {
                      title: intl.formatMessage({
                        id: "settings.addresses.add.heading",
                      }),
                      ctaTitle: intl.formatMessage({
                        id: "settings.addresses.add.heading",
                      }),
                      companyId,
                    },
                  })
                );
              }}
              startIcon={<AddIcon />}
              variant='text'
              color='primary'
            >
              <FormattedMessage id='automations.create.createOfficeTitle' />
            </Button>
          )}
        </>
      )}
      <div>
        {deliveryDetailsType === "requested_from_recipient" && (
          <S.AskRecipient>
            <Typography>
              <FormattedMessage
                tagName='b'
                id='automations.create.step4.askRecipient.notify'
              />
            </Typography>
            {/* <S.SelectStyled
              id='notify-when-input'
              name='notify-when-input'
              label={intl.formatMessage({
                id: "automations.create.step4.askRecipient.whenLabel",
              })}
              defaultValue={deliveryNotifyWhen}
              value={deliveryNotifyWhen}
              hooked={false}
              onChange={(e) => setDeliveryNotifyWhen(Number(e.target.value))}
            >
              <MenuItem value='3'>
                <FormattedMessage id='automations.create.step4.whenNotify.option1' />
              </MenuItem>
              <MenuItem value='0'>
                <FormattedMessage id='automations.create.step4.whenNotify.option2' />
              </MenuItem>
            </S.SelectStyled> */}
            <S.SelectStyled
              disabled
              id='notify-type-input'
              name='notify-type-input'
              label={intl.formatMessage({
                id: "automations.create.step4.askRecipient.howLabel",
              })}
              defaultValue={deliveryNotifyType}
              value={deliveryNotifyType}
              hooked={false}
              // @ts-expect-error
              onChange={(e) => setDeliveryNotifyType(e.target.value)}
            >
              <MenuItem value='email'>
                <FormattedMessage id='automations.create.step4.notify.option1' />
              </MenuItem>
              <MenuItem value='sms'>
                <FormattedMessage id='automations.create.step4.notify.option2' />
              </MenuItem>
            </S.SelectStyled>
          </S.AskRecipient>
        )}
      </div>
      {deliveryNotifyType === "email" && contactsWithoutEmails.length > 0 && (
        <S.AlertStyled variant='warning'>
          <div>
            <FormattedMessage
              id='automations.create.step1.recipients'
              values={{
                count: contactsWithoutEmails?.length,
              }}
              tagName='b'
            />
            <FormattedMessage id='automations.create.step4.emailIsMissingMsg' />
            <u>
              <FormattedMessage id='automations.create.step4.update' />
            </u>
          </div>
        </S.AlertStyled>
      )}
      {deliveryNotifyType === "sms" && contactsWithoutPhones.length > 0 && (
        <S.AlertStyled variant='warning'>
          <div>
            <FormattedMessage
              id='automations.create.step1.recipients'
              values={{
                count: contactsWithoutEmails?.length,
              }}
              tagName='b'
            />{" "}
            <FormattedMessage id='automations.create.step4.phoneIsMissingMsg' />
            <u>
              <FormattedMessage id='automations.create.step4.update' />
            </u>
          </div>
        </S.AlertStyled>
      )}
      {/* {(deliveryNotifyType === "email" && contactsWithoutEmails.length === 0) ||
      (deliveryNotifyType === "sms" && contactsWithoutPhones.length === 0) ? (
        <S.InfoTextStyled>
          <EyeIcon />
          <FormattedMessage id='automations.create.step4.finalMsg' />
        </S.InfoTextStyled>
      ) : null} */}
      <div>
        <Button variant='outlined' color='default' onClick={onClose}>
          <FormattedMessage id='general.close' />
        </Button>
      </div>
    </S.StepBody>
  );
};
