import React, { useState, useCallback, useEffect } from "react";
import { Chip, Grid, InputAdornment, MenuItem } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, ButtonRow, Select, TextField } from "@components/common";
import { VALIDATION } from "@constants/index";
import { UserProfileType, ContactType, TagType } from "@types";
import { toast } from "react-toastify";
import RemoveTagIcon from "@assets/icons/remove.svg";
import TagInputIcon from "@assets/icons/tag.svg";
import DatePicker from "@components/common/DatePicker";
import { createTags } from "../../api";
import {
  SubHeadingStyled,
  HeaderStyled,
  FormSubmitStyled,
  TagsDropdownWrapper,
} from "../../styled";
import { useCreateContact } from "../../hooks";
import { RenderTagsDropdown } from "./components/Tags";
import { useQueryClient } from "react-query";

interface AddCustomerProps {
  userInfo: UserProfileType;
  contacts: ContactType[];
  companyTags: TagType[];
}

const AddContact = ({ userInfo, companyTags }: AddCustomerProps) => {
  const intl = useIntl();
  const [newTags, setNewTags] = useState<Set<string>>(() => new Set());
  const [userTags, setUserTags] = useState<Set<string>>(() => new Set());

  const queryClient = useQueryClient();

  const {
    mutate: createContact,
    isError,
    isSuccess,
    isLoading,
    reset: resetMutation,
  } = useCreateContact();
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    resetField,
  } = methods;

  const addNewTag = useCallback((newTag: string) => {
    setNewTags((tags) => new Set(tags).add(newTag));
  }, []);

  const addUserTag = useCallback((newTag: string) => {
    setUserTags((tags) => new Set(tags).add(newTag));
  }, []);

  const handleTags = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error
    if (e.key === "Enter") {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (!newTag) return;
      if (
        !companyTags.find(
          (tag) => tag.tag_string.toLowerCase() === newTag.toLowerCase()
        )
      ) {
        addNewTag(newTag);
        resetField("tags");
      }
    }
  }, []);

  const handleDeleteTag = useCallback(
    (tag: string) => {
      if (newTags.has(tag)) {
        setNewTags((tags) => {
          tags.delete(tag);
          return new Set(tags);
        });
      }
      if (userTags.has(tag)) {
        setUserTags((tags) => {
          tags.delete(tag);
          return new Set(tags);
        });
      }
    },
    [newTags, userTags]
  );

  const onSubmit = ({
    firstName,
    lastName,
    email,
    phone,
    birthday,
    jobStart,
    external_id,
    language_code,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    birthday: string;
    jobStart: string;
    external_id: string;
    language_code: string;
  }) => {
    const formData = {
      first_name: firstName,
      last_name: lastName,
      date_of_birth: birthday,
      email,
      employment_start_date: jobStart,
      phone_number: phone?.replace(/\s/g, ""),
      external_id,
      language_code,
    };

    const usersTagsIds = companyTags
      ?.filter((tag) =>
        [...Array.from(userTags)].some((item) => item === tag.tag_string)
      )
      .map((item) => item.id);

    if (Array.from(newTags).length) {
      createTags({
        company_id: userInfo?.company_id,
        data: Array.from(newTags),
        // @ts-expect-error
      }).then((res: TagType[]) => {
        createContact({
          company_id: userInfo?.company_id,
          data: {
            ...formData,
            tags: [...usersTagsIds, ...res.map((item) => item.id)],
          },
        });
      });
    } else {
      createContact({
        company_id: userInfo?.company_id,
        data: {
          ...formData,
          tags: usersTagsIds,
        },
      });
    }
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: "contacts.add.successAlert",
        })
      );

      queryClient.invalidateQueries(["contacts"]);
      queryClient.invalidateQueries(["tags"]);
      queryClient.invalidateQueries(["allTags"]);
      resetMutation();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      resetMutation();
    }
  }, [isError]);

  return (
    <>
      <HeaderStyled>
        {/*  @ts-expect-error */}
        <SubHeadingStyled component='h3' variant='h3'>
          <FormattedMessage id='contacts.add.title' />
        </SubHeadingStyled>
      </HeaderStyled>
      <FormProvider {...methods}>
        <form>
          <Grid container>
            <Grid item xs={12} md={9} xl={6}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name='firstName'
                    placeholder={intl.formatMessage({
                      id: "contacts.add.form.firstName",
                    })}
                    label={intl.formatMessage({
                      id: "contacts.add.form.firstName",
                    })}
                    error={errors.firstName}
                    required
                    id='form-input-full-name'
                  />
                  <TextField
                    name='lastName'
                    placeholder={intl.formatMessage({
                      id: "contacts.add.form.lastName",
                    })}
                    label={intl.formatMessage({
                      id: "contacts.add.form.lastName",
                    })}
                    type='text'
                    error={errors.lastName}
                    id='form-input-lastName'
                    required
                  />
                  <TextField
                    name='email'
                    placeholder={intl.formatMessage({
                      id: "input.email.placeholder",
                    })}
                    type='email'
                    label={intl.formatMessage({ id: "input.email.label" })}
                    error={errors.email}
                    pattern={{
                      value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                      message: intl.formatMessage({
                        id: "input.email.pattern.error",
                      }),
                    }}
                    id='form-input-email'
                  />
                  <TextField
                    name='phone'
                    placeholder={intl.formatMessage({
                      id: "contacts.add.form.phone",
                    })}
                    label={intl.formatMessage({
                      id: "contacts.add.form.phone",
                    })}
                    type='tel'
                    error={errors.phone}
                    pattern={{
                      value: VALIDATION.PHONE_VALIDATION_REGEXP,
                      message: intl.formatMessage({
                        id: "input.phone.pattern.error",
                      }),
                    }}
                    id='form-input-phone'
                  />
                  <Select
                    id='language_code'
                    name='language_code'
                    label={intl.formatMessage({
                      id: "contacts.add.form.lang",
                    })}
                    defaultValue={userInfo.locale}
                  >
                    <MenuItem value='en'>
                      <FormattedMessage id='shared.lang.eng' />
                    </MenuItem>
                    <MenuItem value='sk'>
                      <FormattedMessage id='shared.lang.sk' />
                    </MenuItem>
                    <MenuItem value='cs'>
                      <FormattedMessage id='shared.lang.cs' />
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name='external_id'
                    placeholder={intl.formatMessage({
                      id: "contacts.add.form.externalId",
                    })}
                    label={intl.formatMessage({
                      id: "contacts.add.form.externalId",
                    })}
                    type='text'
                    error={errors.external_id}
                    id='form-input-external_id'
                  />
                  <DatePicker
                    name='birthday'
                    label={intl.formatMessage({
                      id: "contacts.add.form.birthday",
                    })}
                    error={errors.birthday}
                    hooked
                    // defaultValue={dayjs(new Date())}
                    disableFuture
                    offsetbottom='true'
                  />
                  <DatePicker
                    name='jobStart'
                    label={intl.formatMessage({
                      id: "contacts.add.form.jobStart",
                    })}
                    hooked
                    // defaultValue={dayjs(new Date())}
                    error={errors.jobStart}
                    offsetbottom='true'
                  />
                  <TagsDropdownWrapper>
                    <TextField
                      name='tags'
                      placeholder={intl.formatMessage({
                        id: "contacts.add.form.tagsPlaceholder",
                      })}
                      label={intl.formatMessage({
                        id: "contacts.add.form.tags",
                      })}
                      type='text'
                      error={errors.tags}
                      id='form-input-tags'
                      // @ts-expect-error
                      onKeyPress={handleTags}
                      autoComplete='off'
                      offsetbottom='false'
                      startAdornment={
                        <InputAdornment position='start'>
                          <TagInputIcon />
                        </InputAdornment>
                      }
                    />
                    <RenderTagsDropdown
                      tags={companyTags?.map((item) => item.tag_string)}
                      newTags={newTags}
                      value={watch("tags")?.trim()}
                      userTags={userTags}
                      resetField={resetField}
                      addUserTag={addUserTag}
                      addNewTag={addNewTag}
                    />
                    {[...Array.from(newTags), ...Array.from(userTags)].map(
                      (tag) => (
                        <Chip
                          deleteIcon={<RemoveTagIcon />}
                          label={tag}
                          key={tag}
                          onDelete={() => handleDeleteTag(tag)}
                        />
                      )
                    )}
                  </TagsDropdownWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormSubmitStyled>
            <ButtonRow>
              <Button
                disabled={isLoading}
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                type='submit'
              >
                <FormattedMessage id='contacts.add.form.submit' />
              </Button>
            </ButtonRow>
          </FormSubmitStyled>
        </form>
      </FormProvider>
    </>
  );
};

export default AddContact;
