import React, { useState, useEffect } from "react";
import { MenuItem, ListSubheader } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Dropdown from "../Dropdown";
import * as S from "./styled";

type SectionNavSubItemType = {
  title: string;
  link: string;
  id: string;
  section: string;
  primary?: boolean;
};

type SectionNavItemType = {
  title: string;
  link: string | null;
  disabled?: boolean;
  sub?: SectionNavSubItemType[] | null;
  section?: string;
  icon?: React.ReactElement;
  isActive?: boolean;
};

interface SectionNavProps {
  navItems: SectionNavItemType[];
  variant?: "primary" | "secondary";
  titleBefore?: string;
  titleAfter?: string;
  disabled?: boolean;
}

// Section Navigation (looks like tabs), transformed into dropdown on mobile
const SectionNav: React.FC<SectionNavProps> = ({
  navItems,
  variant = "primary",
  titleBefore,
  titleAfter,
  disabled = false,
}) => {
  const [navSelectedValue, setNavSelectedValue] = useState(navItems[0].link);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const { location } = history;

  const handleNavSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    history.push(event.target.value as string);
  };

  useEffect(() => {
    const path = location.pathname;
    setNavSelectedValue(
      navItems.find((item) => item?.link?.includes(location.pathname))?.link ||
        navItems
          .find((item) => item.sub)
          ?.sub?.find((item) => item?.link?.includes(path))?.link ||
        navItems[0].link
    );
  }, [location]);

  return isMobile ? (
    <S.SelectStyled
      onChange={handleNavSelectChange}
      value={navSelectedValue}
      displayEmpty
      disableUnderline
    >
      {navItems.map((item: SectionNavItemType) =>
        item.link ? (
          <MenuItem
            key={item.title}
            value={item.link}
            disabled={item.disabled || disabled}
          >
            <FormattedMessage id={item.title} />
          </MenuItem>
        ) : (
          [
            <ListSubheader key={item.link}>{item.title}</ListSubheader>,
            item.sub?.map((subLink) => (
              <MenuItem key={subLink.id} value={subLink.link}>
                {subLink.title}
              </MenuItem>
            )),
          ]
        )
      )}
    </S.SelectStyled>
  ) : (
    <S.TabHeaderStyled variant={variant}>
      {titleBefore && <S.NavTitleBefore>{titleBefore}</S.NavTitleBefore>}
      {navItems.map((item: SectionNavItemType) => (
        <S.TabHeaderItemStyled key={item.title} variant={variant}>
          {item.link &&
            (item.disabled || disabled ? (
              <S.TabLinkDisabledStyled key={item.title}>
                {item.icon && item.icon}
                <FormattedMessage id={item.title} />
              </S.TabLinkDisabledStyled>
            ) : (
              <S.TabLinkStyled
                variant={variant}
                key={item.title}
                to={item.link}
                className={
                  item.isActive !== undefined && item.isActive
                    ? "is-active"
                    : item.isActive !== undefined && !item.isActive
                    ? "not-active"
                    : ""
                }
              >
                {item.icon && item.icon}
                <FormattedMessage id={item.title} />
              </S.TabLinkStyled>
            ))}
          {item.sub && (
            <Dropdown
              key={item.title}
              toggleComponent={
                <S.TabButtonStyled
                  active={
                    item.isActive !== undefined
                      ? item.isActive
                      : location.pathname?.includes(item.section)
                  }
                >
                  {item.title}
                </S.TabButtonStyled>
              }
              toggleButtonBase
              dropdownAlign='left'
              isActive={location.pathname?.includes(item.section)}
            >
              {item.sub.map((subItem) => (
                <S.NavSubItemStyled
                  key={subItem.id}
                  component={Link}
                  to={subItem.link}
                  selected={location.pathname.includes(
                    subItem.link.split("/").slice(0, 4).join("/")
                  )}
                  color={subItem.primary ? "primary" : "secondary"}
                >
                  {subItem.title}
                </S.NavSubItemStyled>
              ))}
            </Dropdown>
          )}
        </S.TabHeaderItemStyled>
      ))}
      {titleAfter && <S.NavTitleAfter>{titleAfter}</S.NavTitleAfter>}
    </S.TabHeaderStyled>
  );
};

export default SectionNav;
