import React, { useContext } from "react";
import { Modal } from "@ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import * as S from "./styled";
import { AuthContext } from "@providers/AuthProvider";
import ReactCountryFlag from "react-country-flag";
import { ImageType, LanguageCodeType, CountryCodeType } from "@types";
import { useGifts, useMerchant } from "../../../hooks";
import { Loader } from "@components/common";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

type Description = {
  language_code: LanguageCodeType;
  text: string;
};

type MerchantInfo = {
  descriptions: Description[];
  name: string;
  country_code: CountryCodeType;
  images: ImageType[];
  id: string;
};

type SupplierInfoModalProps = {
  onClose: () => void;
  merchantInfo: MerchantInfo;
  show: boolean;
};

// generate SupplierInfoModal component
export const SupplierInfoModal = ({
  merchantInfo,
  onClose,
  show,
}: SupplierInfoModalProps) => {
  const intl = useIntl();

  const { user } = useContext(AuthContext);

  const { data: merchantGifts, isLoading } = useGifts(user.company_id, {
    merchant_id: merchantInfo?.id,
  });

  const { data: companySpecificGifts } = useGifts(user.company_id, {
    merchant_id: merchantInfo?.id,
    is_company_specific: true,
  });

  const { data: merchantInfoData } = useMerchant(
    user.company_id,
    merchantInfo?.id
  );

  if (!merchantInfo) {
    return null;
  }

  return (
    <Modal
      title={intl.formatMessage({ id: "gifts.supplierModal.title" })}
      show={show}
      handleClose={onClose}
    >
      <S.Container>
        {merchantInfo?.images?.length > 0 && (
          <S.HeroImg
            src={
              merchantInfo.images?.find((img) => img.is_featured)?.url ||
              merchantInfo.images[0]?.url
            }
            alt='supplier promo'
          />
        )}
        <S.InfoRow>
          <S.InfoName>{merchantInfo?.name}</S.InfoName>
          <S.InfoCountry>
            <ReactCountryFlag countryCode={merchantInfo?.country_code} />
            <FormattedMessage
              id={`shared.country.${merchantInfo?.country_code.toLowerCase()}`}
            />{" "}
          </S.InfoCountry>
        </S.InfoRow>
        <S.Description
          dangerouslySetInnerHTML={{
            __html: merchantInfoData?.descriptions?.find(
              (item) => item.language_code === user.locale
            )?.text,
          }}
        />
        {companySpecificGifts?.items?.length > 0 && (
          <>
            <Typography variant='h6'>
              <FormattedMessage
                id='gifts.supplierModal.myGiftsByTitle'
                values={{ supplier: merchantInfo?.name }}
              />
            </Typography>
            <S.ImagesCarousel>
              {companySpecificGifts?.items?.map(
                (gift, index) =>
                  gift.images?.length > 0 && (
                    <Link to={`/gifts/my-gifts/${gift.id}`} key={index}>
                      <img
                        src={
                          gift.images?.find((img) => img.is_featured)?.url ||
                          gift.images[0]?.url
                        }
                        alt='gift'
                      />
                    </Link>
                  )
              )}
            </S.ImagesCarousel>
          </>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {merchantGifts?.items?.length > 0 && (
              <Typography variant='h6'>
                <FormattedMessage
                  id='gifts.supplierModal.giftsByTitle'
                  values={{ supplier: merchantInfo?.name }}
                />
              </Typography>
            )}
            <S.ImagesCarousel>
              {merchantGifts?.items?.map(
                (gift, index) =>
                  gift.images?.length > 0 && (
                    <Link to={`/gifts/my-gifts/${gift.id}`} key={index}>
                      <img
                        src={
                          gift.images?.find((img) => img.is_featured)?.url ||
                          gift.images[0]?.url
                        }
                        alt='gift'
                      />
                    </Link>
                  )
              )}
            </S.ImagesCarousel>
          </>
        )}
      </S.Container>
    </Modal>
  );
};
