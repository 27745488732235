import React from "react";
import { Modal } from "@components/common";
import { FormattedMessage } from "react-intl";
import { InfoText } from "./styled";

type TagsInfoModalProps = { hideModal: () => void };

export const TagsInfoModal: React.FC<TagsInfoModalProps> = ({ hideModal }) => (
  <Modal
    show
    title=''
    handleClose={() => {
      hideModal();
    }}
    maxWidth='sm'
  >
    <InfoText>
    <FormattedMessage id='orders.create.aboutTags' />
    </InfoText>
  </Modal>
);
