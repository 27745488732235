import styled from "styled-components";

export const NoDataStyled = styled.div<{ size: "small" | "large" }>`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  padding: ${({ size }) => (size === "large" ? "65px 18px" : "40px 18px")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  border-radius: 12px;
`;
