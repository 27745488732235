import React from "react";
import styled from "styled-components";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Link, LinkProps } from "@material-ui/core";

const LinkStyled = styled(Link)`
  gap: 8px;
  display: inline-flex;
  align-items: center;

  ${({ theme, color = "primary" }) => {
    switch (color) {
      case "primary":
        return `
          color: ${theme.palette[color].main};
        `;
      case "secondary":
        return `
          color: ${theme.palette.grey[200]};
        `;
      default:
        return `
          color: inherit;
        `;
    }
  }}
`;

const LinkComponent = ({
  children,
  underline = "hover",
  ...props
}: RouterLinkProps & LinkProps) => (
  <LinkStyled component={RouterLink} underline={underline} {...props}>
    {children}
  </LinkStyled>
);

export default LinkComponent;
