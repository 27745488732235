import React, { useContext } from "react";
import { Radio } from "@ui/components";
import { AuthContext } from "@providers/AuthProvider";
import { formatMoney } from "@utils/common";
import { PaymentRadios, Container } from "./styled";
import { FormattedMessage, useIntl } from "react-intl";

export const PaymentMethod = ({
  setPaymentMethod,
  paymentMethod,
  disableCard,
}: {
  setPaymentMethod: (paymentMethod: "card" | "credit") => void;
  paymentMethod: "card" | "credit";
  disableCard: boolean;
}) => {
  const { user } = useContext(AuthContext);
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value as "card" | "credit");
  };

  const intl = useIntl();

  return (
    <Container variant='warning'>
      <h2>
        <FormattedMessage id='orders.card.create.title' />
      </h2>
      <PaymentRadios>
        <Radio
          name='paymentMethod'
          defaultValue={disableCard ? "credit" : "card"}
          value={paymentMethod}
          onChange={handleOptionChange}
          label=''
          items={[
            {
              value: "card",
              label: intl.formatMessage({
                id: "orders.card.create.card",
              }),
              disabled: disableCard,
            },
            {
              value: "credit",
              // @ts-expect-error
              label: intl.formatMessage(
                {
                  id: "orders.card.create.balance",
                },
                {
                  amount: formatMoney(user.credit_balance),
                  b: (chunks: string[]) => <b>{chunks}</b>,
                }
              ),
            },
          ]}
          hooked={false}
        />
      </PaymentRadios>
    </Container>
  );
};
