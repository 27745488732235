import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  ICognitoUserAttributeData,
  CognitoUserSession,
  NodeCallback,
  IAuthenticationCallback,
} from "amazon-cognito-identity-js";

const config = {
  userPool: {
    UserPoolId: process.env.REACT_AWS_POOL_ID,
    ClientId: process.env.REACT_AWS_APP_CLIENT_ID,
  },
};

class User {
  email: string;

  password: string;

  userPool: CognitoUserPool;

  cognitoUser: CognitoUser;

  constructor() {
    this.userPool = new CognitoUserPool(config.userPool);
    this.cognitoUser = this.userPool.getCurrentUser();
    this.email = "";
    this.password = "";
  }

  setCognitoUser(email: string) {
    this.email = email;

    const userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    this.cognitoUser = new CognitoUser(userData);

    return this.cognitoUser;
  }

  getUserPool(): CognitoUserPool {
    return this.userPool;
  }

  getCognitoUser(): CognitoUser | null {
    const user = this.cognitoUser;
    if (!user) return null;

    return user.getSession((err: Error, session: CognitoUserSession | null) => {
      if (err) {
        throw Error();
      }

      if (session && session.isValid()) {
        return user;
      } else {
        this.userSignOut();
        window.location.href = "/";
        return null;
      }
    });
  }

  getCurrentCognitoUser(): CognitoUser {
    return this.cognitoUser;
  }

  authenticateUser(
    email: string,
    password: string,
    success: (session: CognitoUserSession) => void,
    failure: (err: any) => void
  ) {
    this.email = email;
    this.password = password;

    const authenticationDetails = new AuthenticationDetails({
      Username: this.email,
      Password: this.password,
    });

    const userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    this.cognitoUser = new CognitoUser(userData);

    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(session) {
        success(session);
      },
      onFailure(err) {
        failure(err);
      },
      newPasswordRequired() {
        throw new Error("NewPassRequired");
      },
    });
  }

  userSignOut() {
    if (this.getCognitoUser()) {
      this.getCognitoUser().signOut();
      this.email = "";
      this.password = "";
    }
  }

  updateAttributes(
    attributes: ICognitoUserAttributeData[],
    error: () => void,
    success: () => void
  ) {
    if (this.cognitoUser) {
      this.getCognitoUser().updateAttributes(attributes, (err) => {
        if (err) {
          error();
        }
        success();
      });
    }
  }

  verifyEmail(
    code: string,
    onSuccess: (success: string) => void,
    onFailure: (err: Error) => void
  ) {
    this.getCognitoUser().verifyAttribute("email", code, {
      onSuccess,
      onFailure,
    });
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    callback: NodeCallback<Error, "SUCCESS">
  ) {
    this.getCognitoUser().changePassword(oldPassword, newPassword, callback);
  }

  completeNewPasswordChallenge(
    email: string,
    oldPass: string,
    newPass: string,
    data: any,
    callback: IAuthenticationCallback
  ) {
    this.email = email;
    this.password = oldPass;

    const authenticationDetails = new AuthenticationDetails({
      Username: this.email,
      Password: this.password,
    });

    const userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    this.cognitoUser = new CognitoUser(userData);

    const user = this.cognitoUser;

    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess() {},
      onFailure(err) {
        throw Error(err);
      },
      newPasswordRequired() {
        user.completeNewPasswordChallenge(newPass, data, callback);
      },
    });
  }

  getUserAttributes() {
    this.getCognitoUser()?.getUserAttributes((err, result) => {
      if (err) {
        throw err;
      }
      return result;
    });
  }
}

const cognitoUser = new User();

export default cognitoUser;
