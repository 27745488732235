import React, { useState } from "react";
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination, Table } from "@components/common";
import { FormattedMessage } from "react-intl";
import { ContactType } from "@types";
import { useContacts } from "../../../../../contacts/hooks";

interface CampaignContactsModalProps {
  data: {
    contacts: ContactType[];
    tag?: string;
    companyId?: string;
  };
}

const CampaignContactsModal: React.FC<CampaignContactsModalProps> = ({
  data: { contacts, tag, companyId },
}) => {
  const [page, setPage] = useState(1);

  const { data: contactsFilteredByTag } = useContacts(companyId, page, tag);

  return (
    <Box display='flex' flexDirection='column'>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id='contacts.list.name' />
            </TableCell>
            <TableCell>
              <FormattedMessage id='contacts.list.email' />
            </TableCell>
            <TableCell>
              <FormattedMessage id='contacts.list.tags' />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts?.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell>
                {contact.first_name} {contact.last_name}
              </TableCell>
              <TableCell>{contact.email}</TableCell>

              <TableCell>
                {contact.tags
                  .map((contactTag) => contactTag.tag_string)
                  .join(", ")}
              </TableCell>
            </TableRow>
          ))}
          {!contacts &&
            contactsFilteredByTag?.items?.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>
                  {contact.first_name} {contact.last_name}
                </TableCell>
                <TableCell>{contact.email}</TableCell>

                <TableCell>
                  {contact.tags
                    .map((contactTag) => contactTag.tag_string)
                    .join(", ")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {!contacts && contactsFilteredByTag && (
        <Pagination
          page={page}
          onChange={setPage}
          total={contactsFilteredByTag?.paging.total_records}
        />
      )}
    </Box>
  );
};

export default CampaignContactsModal;
