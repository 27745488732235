import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { QueryKey } from "react-query";
import { GiftType } from "./types";
import { MerchantType } from "@types";

const { API } = CONSTANTS;

const PROSIGHT_ID = "4572eb77-872f-4b99-be8a-6ff93457fd91";
const isProsight = (gifts: GiftType[]) =>
  gifts.some((gift) => gift.merchant?.id === PROSIGHT_ID);

const FETURED_GIFTS_IDS = [
  "b27cfaf5-7af4-4715-87fc-3156fefeccfc",
  "0ceef82a-804b-40a3-a906-bb1de6645d31",
  "3bb81b50-6b1f-48de-acf2-4006d033a967",
  "2d726145-fd65-409d-9da8-eecf9f6a2a87",
  "6cf842f9-984d-4256-8324-524c3b78f98b",
  "53a8c1c6-8ca7-4518-8e29-c0bec4b55078",
];

export const fetchGifts = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: GiftType[] }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/gifts`, {
      params: queryKey[2] || {
        origin_type: "boxday_merchant",
      },
    })
    .then((res) => {
      let data = res.data;

      if (isProsight(res.data?.items)) {
        const sortedItems = data?.items.sort(
          (a: GiftType, b: GiftType) =>
            FETURED_GIFTS_IDS.indexOf(b.id) - FETURED_GIFTS_IDS.indexOf(a.id)
        );
        data = { ...data, items: sortedItems };
      }

      return data;
    });

export const fetchMerchantGifts = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: GiftType[] }> =>
  baseApi
    .get(`${API.MERCHANTS}/${queryKey[1]}/gifts`, {
      params: queryKey[2] || {
        origin_type: "boxday_merchant",
      },
    })
    .then((res) => res.data);

export const fetchMerchantGift = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: GiftType[] }> =>
  baseApi
    .get(`${API.MERCHANTS}/${queryKey[1]}/gifts/${queryKey[2]}`, {
      params: queryKey[3],
    })
    .then((res) => res.data?.items[0]);

export const fetchCompanyGift = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: GiftType[] }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/gifts/${queryKey[2]}`, {
      params: queryKey[3],
    })
    .then((res) => res.data?.items[0]);

export const fetchAllGifts = (): AxiosPromise<{ items: GiftType[] }> =>
  baseApi.get(`/gifts`).then((res) => {
    let data = res.data;

    if (isProsight(res.data?.items)) {
      const sortedItems = data?.items.sort(
        (a: GiftType, b: GiftType) =>
          FETURED_GIFTS_IDS.indexOf(b.id) - FETURED_GIFTS_IDS.indexOf(a.id)
      );
      data = { ...data, items: sortedItems };
    }

    return data;
  });

export const fetchMerchant = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<MerchantType> =>
  baseApi
    .get(`/api/merchants/${queryKey[2]}`)
    .then((res) => res.data?.items[0]);
