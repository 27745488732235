import { Button } from "@components/common";
import { Typography } from "@material-ui/core";
import styled, { css } from "styled-components";

export const CreateCampaignStep = styled.div<{
  invalid?: boolean;
  expanded: boolean;
}>`
  padding: 28px 32px;
  background-color: #fcfcfc;

  ${({ expanded = false }) =>
    expanded &&
    css`
      background-color: #fff;
    `}

  ${({ invalid = false, theme }) =>
    invalid &&
    css`
      h4,
      h4 + p {
        color: ${theme.palette.primary.main};
      }
    `}

  & + & {
    border-top: 1px solid #ececec;
  }
`;

export const CreateCampaignStepHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 18px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const SelectedGiftWrapperStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
`;

export const CreateCampaignStepHeaderLeft = styled.div`
  display: flex;
  gap: 16px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  ${SelectedGiftWrapperStyled} {
    margin-top: 12px;
  }
`;

export const CreateCampaignStepHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StepBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 0;
  align-items: flex-start;
  gap: 10px;
`;

export const AskRecipient = styled.div`
  padding: 16px 24px;
  background: #fff9ea;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .MuiOutlinedInput-root,
  .MuiSelect-selectMenu {
    background-color: #fff;
  }
`;

export const InfoTextStyled = styled(Typography)`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  display: flex;
  max-width: 538px;
  margin-bottom: 0;

  svg {
    flex-shrink: 0;
  }

  button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const SelectGiftStyled = styled(Button)`
  width: 230px;
  height: 84px;
  border: 2px solid #f8f6f4;
  border-radius: 12px;
  color: #000;
  margin-bottom: 12px;

  .MuiButton-label {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .MuiButton-startIcon {
    margin: 0;
  }

  path {
    stroke: currentColor;
  }
`;

export const SelectGiftListStyled = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SelectGiftListItemStyled = styled.li`
  list-style: none;

  > button {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    img {
      border-radius: 12px;
      object-fit: cover;
    }
  }
`;

export const SelectedGiftStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  border-radius: 12px;
  text-decoration: none;
  color: #263238;

  img {
    border-radius: 12px;
    object-fit: cover;
  }
`;

export const SelectGiftInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SelectGiftInfoSRowStyled = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #263238;
  align-items: center;
`;

export const SelectGiftFlagStyled = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
