import React from "react";
import { FormattedMessage } from "react-intl";
import { SvgIcon } from "@material-ui/core";
import { ICONS } from "@constants/index";
import * as S from "./styled";
import type { MainNavItemType } from "./index";

export const NavItem = ({ item }: { item: MainNavItemType }) => (
  <S.NavItemLinkStyled key={item.locale} to={item.link} color='inherit'>
    <SvgIcon
      aria-label='Filter'
      fill='none'
      fontSize='large'
      viewBox='0 0 20 20'
      // eslint-disable-next-line
      component={(componentProps) => (
        <svg {...componentProps}>
          <path
            // @ts-expect-error
            d={ICONS[item.icon.toUpperCase()]}
            strokeLinecap='round'
            strokeLinejoin='round'
            stroke='#000'
            strokeWidth='2'
            fill='none'
          />
        </svg>
      )}
    />
    <FormattedMessage id={item.locale} />
  </S.NavItemLinkStyled>
);
