import React, { useContext } from "react";
import { TableBody, TableRow, Typography } from "@material-ui/core";
import { useAutomationAddressesValidate } from "../../../../../api/automation";
import * as S from "../../styled";
import { FormattedMessage } from "react-intl";
import { Alert, Table } from "@components/common";
import { Order } from "../Order";
import { AuthContext } from "@providers/AuthProvider";

type MissingDetailsProps = {
  automationId: string;
};

export const MissingDetails: React.FC<MissingDetailsProps> = ({
  automationId,
}) => {
  const { user } = useContext(AuthContext);

  const { data: missingDetails, isLoading } = useAutomationAddressesValidate(
    user.company_id,
    automationId
  );

  if (missingDetails?.items?.length === 0 || isLoading) return null;

  return (
    <S.Container>
      <Typography variant='h3'>
        <FormattedMessage id='orders.missing.title' />
      </Typography>
      <S.AlertWrapper>
        <Alert variant='error' fullWidth>
          <div>
            <FormattedMessage
              id='orders.missing.message'
              values={{
                b: (chunks: string[]) => <b>{chunks}</b>,
              }}
            />
          </div>
        </Alert>
      </S.AlertWrapper>
      {missingDetails?.items?.length > 0 && (
        <S.MissingDetailsWrapper>
          <S.OrderSection>
            <Table>
              <TableBody>
                {missingDetails.items.map((order) => (
                  <TableRow key={order.id}>
                    <Order
                      order={order}
                      companyId={user.company_id}
                      missingInfo
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </S.OrderSection>
        </S.MissingDetailsWrapper>
      )}
    </S.Container>
  );
};
