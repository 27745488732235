import React from "react";
import { FormattedMessage } from "react-intl";
import GiftImage from "@assets/icons/gift-image.svg";
import { useHistory } from "react-router";
import Button from "../Button";
import * as S from "./styled";

interface ErrorPageProps {
  codeError?: string;
  errorMessage?: string;
  infoMessage?: string;
  showInfo?: boolean;
  fullSize?: boolean;
  // cta?: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  codeError = "404",
  errorMessage,
  infoMessage,
  showInfo = true,
  fullSize = true,
  // cta,
}) => {
  const history = useHistory();

  return (
    <S.ErrorPageContainer fullSize={fullSize}>
      <S.ErrorCode>{codeError}</S.ErrorCode>
      <GiftImage />
      <S.TypographyStyled variant='h4'>
        {showInfo && <FormattedMessage id='errorPage.title' />}
      </S.TypographyStyled>
      <S.TypographyStyled variant='h3'>
        {errorMessage || <FormattedMessage id='errorPage.message' />}
      </S.TypographyStyled>
      {infoMessage && (
        <S.TypographyStyled variant="body1">{infoMessage}</S.TypographyStyled>
      )}
      <Button
        onClick={() => {
          history.go(-1);
        }}
      >
        <FormattedMessage id='errorPage.cta' />
      </Button>
    </S.ErrorPageContainer>
  );
};

export default ErrorPage;
