import currency from 'currency.js';

type SummaryCalculationsProps = {
  giftsQty: number;
  giftPrice: number;
  shippingQty: number;
  vat: number;
  freeShipping?: boolean;
};

type SummaryCalculationsResult = {
  subtotal: number;
  calculatedVat: number;
  total: number;
  shippingPrice: number;
  giftTotalPrice: number;
};

export const SHIPPING_PRICE = 3.16;
export const SHIPPING_VAT = 0.64;

const calculateShippingPrice = (shippingQty: number): number => {
  return currency(SHIPPING_PRICE).multiply(shippingQty).value;
};

export const useSummaryCalculations = ({
  giftsQty,
  giftPrice,
  shippingQty,
  vat,
  freeShipping = false,
}: SummaryCalculationsProps): SummaryCalculationsResult => {
  const shippingPrice = freeShipping ? 0 : calculateShippingPrice(shippingQty);
  const giftTotalPrice = currency(giftsQty).multiply(giftPrice);
  const subtotal = currency(giftPrice).multiply(giftsQty).add(shippingPrice);

  const giftsShippingVat = freeShipping ? 0 : currency(shippingQty).multiply(SHIPPING_VAT);

  const calculatedVat = currency(vat).multiply(giftsQty).add(giftsShippingVat);
  const total = subtotal.add(calculatedVat);

  return {
    subtotal: subtotal.value,
    calculatedVat: calculatedVat.value,
    total: total.value,
    shippingPrice: shippingPrice,
    giftTotalPrice: giftTotalPrice.value,
  };
};
