import { ContactAddressType } from "@types";
import { baseApi } from "@utils/apiService";
import { COMPANIES } from "@constants/api";
import { QueryKey } from "react-query";
import { AxiosPromise } from "axios";

export const deliverySlipAddress = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: ContactAddressType }> =>
  // @ts-ignore
  baseApi
    .get(`${COMPANIES}/${queryKey[1]}/delivery-slips/${queryKey[2]}`)
    .then(() => ({ items: [] }));

// export const deliverySlipAddress = ({
//   queryKey,
// }: {
//   queryKey: QueryKey;
// }): AxiosPromise<{ items: ContactAddressType }> =>
//   baseApi
//     .get(`${COMPANIES}/${queryKey[1]}/delivery-slips/${queryKey[2]}/addresses`)
//     .then((res) => res.data);
