import { Error, Loader, SectionNav, Button } from "@components/common";
import {
  FormControlLabel,
  Typography,
  Switch as MuiSwitch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect, Route, Switch, useParams } from "react-router";
import BackIcon from "@assets/icons/arrow-back.svg";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Upcoming } from "./Upcoming";
import { Past } from "./Past";
import { Settings } from "./Settings";
import AutomationChangeConfirmationModal from "../Modals/AutomationChangeConfirmationModal";
import {
  ButtonBackStyled,
  Header,
  HeaderRow,
  Tooltip,
  AutomationNotify,
  AutomationNotifyWrapper,
} from "./styled";
import { useAutomation, useUpdateAutomation } from "../../hooks";

export const Orders: React.FC<{ companyId: string }> = ({ companyId }) => {
  const { automationId } = useParams<{ automationId: string }>();
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [hideNotify, setHideNotify] = useState(
    localStorage.getItem("hideNotify")
  );

  const {
    mutate: updateAutomation,
    isLoading: updateIsLoading,
    isError: updateErrored,
    isSuccess: updateSuccess,
    reset,
  } = useUpdateAutomation();

  const {
    data: automation,
    isLoading,
    isError,
  } = useAutomation(companyId, automationId);

  useEffect(() => {
    if (updateSuccess) {
      queryClient.invalidateQueries(["automation", companyId, automationId]);
      queryClient.invalidateQueries(["automations", companyId]);
      queryClient.invalidateQueries(["automationOrders"]);
      toast.success(
        intl.formatMessage({
          id: "automations.update.modal.successOn",
        })
      );
      reset();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setChecked(automation?.state === "active");
  }, [automation]);

  useEffect(() => {
    if (updateErrored) {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      reset();
    }
  }, [updateErrored]);

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header>
        <ButtonBackStyled to='/automations'>
          <BackIcon />
          <FormattedMessage id='automations.backToOrders' />
        </ButtonBackStyled>
        <HeaderRow>
          <Typography variant='h2'>{automation?.name}</Typography>
          <AutomationNotifyWrapper>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={automation?.state === "active"}
                  onChange={() => {
                    setChecked((state) => {
                      if (state) {
                        setShowModal(true);
                        return state;
                      }
                      updateAutomation({
                        company_id: companyId,
                        automation_id: automationId,
                        data: {
                          state: "active",
                        },
                      });
                      return !state;
                    });
                  }}
                  name='checkedB'
                  color='primary'
                  disabled={updateIsLoading}
                />
              }
              label={
                checked
                  ? intl.formatMessage({
                      id: "automation.toggleOn.title",
                    })
                  : intl.formatMessage({
                      id: "automation.toggleOff.title",
                    })
              }
            />
            {automation?.updated_at === null && !hideNotify && (
              <Tooltip>
                <AutomationNotify>
                  <Typography variant='h2'>🎉</Typography>
                  <Typography variant='h4'>
                    <FormattedMessage id='automations.tooltip.title' />
                  </Typography>
                  <Typography>
                    <FormattedMessage id='automations.tooltip.subtitle' />
                  </Typography>
                  <Button
                    onClick={() => {
                      localStorage.setItem("hideNotify", "true");
                      setHideNotify(localStorage.getItem("hideNotify"));
                    }}
                  >
                    <FormattedMessage id='automations.tooltip.cta' />
                  </Button>
                </AutomationNotify>
              </Tooltip>
            )}
          </AutomationNotifyWrapper>
        </HeaderRow>
        <SectionNav
          navItems={[
            {
              title: "automations.nav.upcomingOrders",
              link: `/automations/${automationId}/upcoming-orders`,
            },
            {
              title: "automations.nav.pastOrders",
              link: `/automations/${automationId}/past-orders`,
            },
            {
              title: "automations.nav.settings",
              link: `/automations/${automationId}/settings`,
            },
          ]}
        />
      </Header>
      <Switch>
        <Route
          exact
          path='/automations/:automationId/upcoming-orders'
          render={(props) => (
            <Upcoming
              // @ts-ignore
              deliveryDateType={automation.delivery_date_type}
              deliverySourceType={automation.delivery_details_source_type}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/automations/:automationId/past-orders'
          render={(props) => (
            // @ts-ignore
            <Past deliveryDateType={automation.delivery_date_type} {...props} />
          )}
        />
        <Route
          exact
          path='/automations/:automationId/settings'
          render={(props) => <Settings companyId={companyId} {...props} />}
        />
        <Redirect to='/automations/:automationId/upcoming-orders' />
      </Switch>
      {showModal && (
        <AutomationChangeConfirmationModal
          data={{
            automationId,
            companyId,
          }}
          hideModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};
