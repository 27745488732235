import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { getGiftPriceByCountryCode } from "@utils/common";
import TickIcon from "@assets/icons/gift-tick.svg";
import { GiftType } from "@types";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import * as S from "./styled";
import { AuthContext } from "@providers/AuthProvider";

export const GiftPreview: React.FC<{
  selectedGift: GiftType;
}> = ({ selectedGift }) => {
  const { user } = useContext(AuthContext);

  return (
    <S.SelectedGiftWrapperStyled>
      <S.SelectedGiftStyled>
        <img
          width='56'
          height='56'
          src={selectedGift.images?.[0]?.url || GiftPlaceholder}
          alt=''
        />
        <div>
          <div>
            <b>
              <u>{selectedGift.name}</u>
            </b>
          </div>
          <S.SelectGiftInfoSRowStyled>
            {selectedGift.flags?.is_branded ? (
              <S.SelectGiftFlagStyled>
                <TickIcon /> <FormattedMessage id='gifts.gift.branded' />
              </S.SelectGiftFlagStyled>
            ) : null}
            {selectedGift.flags?.has_handwritten_note ? (
              <S.SelectGiftFlagStyled>
                <TickIcon />
                <FormattedMessage id='gifts.gift.handNote' />
              </S.SelectGiftFlagStyled>
            ) : null}
            {getGiftPriceByCountryCode(selectedGift.prices, user.currency)}
          </S.SelectGiftInfoSRowStyled>
        </div>
      </S.SelectedGiftStyled>
    </S.SelectedGiftWrapperStyled>
  );
};
