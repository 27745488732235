import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { IconButton, Menu, MenuItem } from "@material-ui/core";

import { useDeleteOrder } from "../../../hooks";
import { AuthContext } from "@providers/AuthProvider";
import { MoreHoriz } from "@material-ui/icons";
import CancelIcon from "@assets/icons/remove.svg";
import { Modal } from "@components/common";
import { toast } from "react-toastify";

interface ChangeStatusProps {
  orderId: string;
}

export const ChangeOrderStatus: React.FC<ChangeStatusProps> = ({ orderId }) => {
  const { user } = useContext(AuthContext);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const intl = useIntl();

  const { mutate: deleteOrder, status } = useDeleteOrder();

  useEffect(() => {
    if (status === "error") {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
    }

    if (status === "success") {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
    }
  }, [status]);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHoriz fontSize='large' color='inherit' />
      </IconButton>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            setShowConfirmationModal(true);
            handleClose();
          }}
        >
          <CancelIcon />
          <FormattedMessage id='deliveries.cancel' />
        </MenuItem>
      </Menu>
      <Modal
        show={showConfirmationModal}
        stretchCta
        title={intl.formatMessage({
          id: "orders.modal.cancel.title",
        })}
        ctaTitle={intl.formatMessage({
          id: "orders.modal.cancel.submitBtn",
        })}
        cancelTitle={intl.formatMessage({
          id: "general.forms.buttons.cancel",
        })}
        maxWidth='xs'
        cta={() => {
          deleteOrder({
            company_id: user.company_id,
            order_id: orderId,
          });
          setShowConfirmationModal(false);
        }}
        handleClose={() => setShowConfirmationModal(false)}
        isLoading={status === "loading"}
      />
    </>
  );
};
