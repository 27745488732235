// Auth
export const USER_SIGN_IN = "/";
export const USER_SIGN_UP = "/sign-up";
export const USER_VERIFY = "/verify";
export const USER_RESET_PASSWORD = "/reset-password";
export const USER_FORGOT_PASSWORD = "/forgot-password";
export const USER_NEW_PASSWORD = "/new-password";

// Account settings
export const SETTINGS_PERSONAL_ROUTE = "/settings/my-account";
export const SETTINGS_COMPANY_ROUTE = "/settings/company-details";
export const SETTINGS_ORDERS = "/settings/credits";
export const SETTINGS_BILLING_HISTORY = "/settings/billing-history";
export const SETTINGS_ADDRESSES = "/settings/addresses";
export const SETTINGS_INTEGRATION_ROUTE = "/settings/integrations";

// Main
export const MAIN_SETTINGS = "/settings";
export const MAIN_CUSTOMERS = "/people";
export const MAIN_ORDERS = "/orders/:tab?/:id?";
export const MAIN_CAMPAIGNS = "/automations";
export const MAIN_CUSTOMER_PROFILE = "/customers/:id/:tab";
export const MAIN_PROGRAMS = "/programs/:id/:tab";
export const MAIN_PROGRAMS_ADD = "/add-program";
export const MAIN_TRANSACTIONS = "/transactions/:tab";
export const MAIN_ADD_COMPANY = "/add-company";
export const MAIN_WELCOME = "/welcome";
export const MAIN_GIFTS = "/gifts/:tab?/:id?";
export const MAIN_DASHBOARD = "/dashboard";

export const RECIPIENT_FORM_PAGE =
  "/companies/:company_id/delivery-slips/:delivery_slip_id";

// Transactions
export const TRANSACTIONS_OVERVIEW = "/transactions/overview";

// Gifts
export const GIFTS_MY_GIFTS = "/gifts/my-gifts";
export const GIFTS_EXTERNAL = "/gifts/external";
export const GIFTS_ALL = "/gifts/all";
export const GIFTS_DETAILS = "/gifts/:tab/:type/:id/:gift_id";

// Customers
export const CUSTOMERS_PROFILE_OVERVIEW = "/people/:id/overview";
export const CUSTOMERS = "/people/:tab";
export const CUSTOMERS_ADD_FORM = "/people/add";
export const CUSTOMERS_OVERVIEW = "/people/overview";
export const CUSTOMERS_IMPORT = "/people/import";
export const CUSTOMERS_PROFILE = "/people/:id/:tab";
export const CUSTOMERS_PROFILE_HISTORY = "/people/:id/history";
export const CUSTOMERS_PROFILE_DETAILS = "/people/:id/details";
export const CUSTOMERS_PROFILE_ADDRESS = "/people/:id/address";
