import { makeStyles } from "@material-ui/styles";

export const checkboxStyles =
  makeStyles({
    invalid: (error) => ({
      color: error ? "#E72076!important" : "",
    }),
    root: {
      '& a': {
        color: 'currentColor',
        textDecoration: 'underline',
      }
    }
  });
