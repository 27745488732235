import React, { useEffect, useState } from "react";
import * as S from "../../styled";
import { Typography } from "@material-ui/core";
import { S3_ASSETS_URL } from "@constants/common";
import axios from "axios";
import { InvoiceTable, InvoiceTableRow, InvoiceTableCell } from "./styled";
import { Button } from "@ui/components";
import Icon from "@assets/icons/download.svg";
import { FormattedMessage } from "react-intl";

const env = process.env.NODE_ENV === "development" ? "test" : "prod";

export const Invoices: React.FC<{ invoice_id: string }> = ({ invoice_id }) => {
  const [showAsset, setShowAsset] = useState(false);
  useEffect(() => {
    if (!invoice_id) return;
    const checkAsset = async () => {
      try {
        const res = await axios.get(
          `${S3_ASSETS_URL}-${env}.s3.eu-west-1.amazonaws.com/tax_invoice_${invoice_id}.pdf`
        );

        if (res) {
          setShowAsset(true);
        }
      } catch (e) {
        setShowAsset(false);
      }
    };
    checkAsset();
  }, [invoice_id]);
  return (
    <div>
      <S.HeaderTopStyled>
        <Typography variant='h4' component='h2'>
          <FormattedMessage id='invoices.title' />
        </Typography>
      </S.HeaderTopStyled>
      <div>
        {showAsset ? (
          <InvoiceTable>
            <InvoiceTableRow>
              <InvoiceTableCell>
                <Typography variant='subtitle1'>
                  <b>
                    <FormattedMessage id='invoices.title' /> #
                  </b>
                </Typography>
              </InvoiceTableCell>
              <InvoiceTableCell>
                <Typography variant='subtitle1'>
                  <b>
                    <FormattedMessage id='invoices.actions' />
                  </b>
                </Typography>
              </InvoiceTableCell>
            </InvoiceTableRow>
            <InvoiceTableRow>
              <InvoiceTableCell>{invoice_id?.toUpperCase()}</InvoiceTableCell>
              <InvoiceTableCell>
                <Button
                  startIcon={<Icon />}
                  variant='text'
                  as='a'
                  href={`${S3_ASSETS_URL}-${env}.s3.eu-west-1.amazonaws.com/tax_invoice_${invoice_id}.pdf`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <FormattedMessage id='invoices.download' />
                </Button>
              </InvoiceTableCell>
            </InvoiceTableRow>
          </InvoiceTable>
        ) : (
          <Typography variant='body1' component='p'>
            <FormattedMessage id='invoices.empty' />
          </Typography>
        )}
      </div>
    </div>
  );
};
