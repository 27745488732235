import { UseQueryResult, useQuery } from "react-query";
import { deliverySlipAddress } from "./axios";
import { ContactAddressType } from "@types";

export const useDeliverySlipAddress = (
  companyId: string,
  delivery_slip_id: string
): UseQueryResult<{ items: ContactAddressType[] }> =>
  useQuery(
    ["delivery-slip-address", companyId, delivery_slip_id],
    deliverySlipAddress,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );
