import React from "react";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Table } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { ContactShortType } from "@types";
import { Link } from "react-router-dom";
import { Modal } from "@ui/components";

interface ContactsListModalProps {
  contacts: ContactShortType[];
  onClose: () => void;
}

export const ContactsListModal: React.FC<ContactsListModalProps> = ({
  contacts,
  onClose,
}) => {
  const intl = useIntl();
  return (
    <Modal
      show
      handleClose={onClose}
      title={intl.formatMessage({ id: "contacts.list.secondaryTitle" })}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id='contacts.list.name' />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts?.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell>
                <Link
                  target='_blank'
                  to={`/people/${contact.id}/overview`}
                  style={{ color: "#000" }}
                >
                  {contact.first_name} {contact.last_name}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Modal>
  );
};
