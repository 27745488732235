import React from "react";
import { useIntl } from "react-intl";
import { DeliveryStateType, BadgeTypes } from "@types";
import ClockIcon from "@assets/icons/clock.svg";
import * as S from "../../styled";

export const DeliveryStatus: React.FC<{
  state: DeliveryStateType;
}> = ({ state }) => {
  const intl = useIntl();

  const orderStatusTitle: { [key in DeliveryStateType]: string } = {
    draft: intl.formatMessage({
      id: "order.status.title.draft",
    }),
    scheduled: intl.formatMessage({
      id: "order.status.title.scheduled",
    }),
    delivered: intl.formatMessage({
      id: "order.status.title.delivered",
    }),
    canceled: intl.formatMessage({
      id: "order.status.title.canceled",
    }),
    waiting_for_information: intl.formatMessage({
      id: "order.status.title.waitingForInput",
    }),
    waiting_for_shipment: intl.formatMessage({
      id: "order.status.title.waitingForShipment",
    }),
    in_transit: intl.formatMessage({
      id: "order.status.title.inTransit",
    }),
  };

  const statusBadge: {
    [K in DeliveryStateType]: BadgeTypes;
  } = {
    scheduled: "default",
    in_transit: "primary",
    waiting_for_shipment: "warning",
    waiting_for_information: "warningSecondary",
    delivered: "success",
    canceled: "danger",
    draft: "secondary",
  };

  return (
    <S.BadgeStyled type={statusBadge[state]}>
      {state === "scheduled" && <ClockIcon />}
      {orderStatusTitle[state]}
    </S.BadgeStyled>
  );
};
