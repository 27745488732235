import React, { useContext, useEffect, useMemo, useState } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { Button, TextField, Error, Loader, Select } from "@components/common";
import { AuthContext } from "@providers/AuthProvider";
import { S3_ASSETS_URL } from "@constants/common";
import { toast } from "react-toastify";
import {
  HeadingStyled,
  TopFormStyled,
  SecondaryHeadingStyled,
  Container,
  CompanySettingsTop,
} from "../styled";
import { useCompanyDetails, useUpdateCompanyDetails } from "../../hooks";
import axios from "axios";

const env = process.env.NODE_ENV === "development" ? "test" : "prod";

export const CompanySettings: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [showLogo, setShowLogo] = useState(false);
  const { data: companyDetails, status } = useCompanyDetails(user.company_id);

  const methods = useForm({
    defaultValues: useMemo(
      () => ({
        country: companyDetails?.country_code.toLowerCase(),
        zip: companyDetails?.zip,
        city: companyDetails?.city,
        address: companyDetails?.street,
        regNumber: companyDetails?.registration_number,
        vatId: companyDetails?.vat_id,
        businessName: companyDetails?.business_name,
        companyName: companyDetails?.name,
        notificationEmail: companyDetails?.notification_email,
      }),
      [companyDetails]
    ),
  });
  const intl = useIntl();
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = methods;
  const {
    mutate: updateCompany,
    status: updateStatus,
    reset: resetUpdate,
  } = useUpdateCompanyDetails();

  const onSubmit = ({
    companyName,
    businessName,
    vatId,
    address,
    zip,
    city,
    country,
    regNumber,
  }: {
    companyName: string;
    businessName: string;
    vatId: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    regNumber: string;
    // notificationEmail: string;
  }) => {
    if (!isDirty) return;
    updateCompany({
      company_id: user.company_id,
      // @ts-expect-error payload is not properly typed
      data: {
        name: companyName,
        business_name: businessName,
        vat_id: vatId,
        street: address,
        zip,
        city,
        country_code: country,
        registration_number: regNumber,
        // notification_email: notificationEmail,
      },
    });
  };

  useEffect(() => {
    if (companyDetails) {
      reset({
        companyName: companyDetails.name,
        businessName: companyDetails.business_name,
        vatId: companyDetails.vat_id,
        address: companyDetails.street,
        zip: companyDetails.zip,
        city: companyDetails.city,
        country: companyDetails.country_code?.toLowerCase(),
        regNumber: companyDetails.registration_number,
        notificationEmail: companyDetails.notification_email,
      });
    }
  }, [companyDetails, reset]);

  useEffect(() => {
    const checkAsset = async () => {
      try {
        const res = await axios.get(
          `${S3_ASSETS_URL}-${env}.s3.eu-west-1.amazonaws.com/${user.company_id}.png`
        );

        if (res) {
          setShowLogo(true);
        }
      } catch (e) {
        setShowLogo(false);
      }
    };
    checkAsset();
  }, [user]);

  useEffect(() => {
    if (updateStatus === "success") {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
      resetUpdate();
    }

    if (updateStatus === "error") {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      resetUpdate();
    }
  }, [updateStatus]);

  if (status === "loading") {
    return <Loader />;
  }

  if (status === "error") {
    return <Error />;
  }

  return (
    <Container>
      {companyDetails && (
        <FormProvider {...methods}>
          <div>
            <HeadingStyled variant='h3'>
              <FormattedMessage id='settings.company.details' />
            </HeadingStyled>
            <TopFormStyled onSubmit={handleSubmit(onSubmit)}>
              <CompanySettingsTop>
                <Grid container spacing={8}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      name='businessName'
                      label={intl.formatMessage({
                        id: "input.company.name.label",
                      })}
                      error={errors.businessName}
                      required
                      helperText={intl.formatMessage({
                        id: "input.company.name.info",
                      })}
                    />
                    <Button onClick={handleSubmit(onSubmit)}>
                      <FormattedMessage id='settings.account.form.submit' />
                    </Button>
                  </Grid>
                </Grid>
              </CompanySettingsTop>
              <HeadingStyled variant='h3'>
                <FormattedMessage id='settings.company.billing' />
              </HeadingStyled>
              <Grid container spacing={8} alignItems='flex-end'>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    name='companyName'
                    label={intl.formatMessage({
                      id: "input.company.name.business.label",
                    })}
                    error={errors.companyName}
                    minLength={2}
                    required
                    helperText={intl.formatMessage({
                      id: "input.company.name.info",
                    })}
                  />
                  <TextField
                    name='regNumber'
                    placeholder={intl.formatMessage({
                      id: "input.company.id.label",
                    })}
                    label={intl.formatMessage({
                      id: "input.company.id.label",
                    })}
                    error={errors.regNumber}
                    required
                  />
                  <TextField
                    name='address'
                    placeholder={intl.formatMessage({
                      id: "input.company.address.label",
                    })}
                    label={intl.formatMessage({
                      id: "input.company.address.label",
                    })}
                    error={errors.address}
                    required
                  />
                  <TextField
                    name='city'
                    placeholder={intl.formatMessage({
                      id: "input.city.label",
                    })}
                    label={intl.formatMessage({
                      id: "input.city.label",
                    })}
                    error={errors.city}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    name='vatId'
                    placeholder={intl.formatMessage({
                      id: "input.vat.label",
                    })}
                    label={intl.formatMessage({
                      id: "input.vat.label",
                    })}
                    error={errors.vatId}
                    required
                  />
                  <TextField
                    name='zip'
                    placeholder={intl.formatMessage({
                      id: "input.zip.label",
                    })}
                    label={intl.formatMessage({
                      id: "input.zip.label",
                    })}
                    error={errors.zip}
                    required
                  />
                  <Select
                    id='country'
                    name='country'
                    label={intl.formatMessage({
                      id: "input.country.label",
                    })}
                  >
                    <MenuItem value='sk'>
                      <FormattedMessage id='shared.country.sk' />
                    </MenuItem>
                    <MenuItem value='cz'>
                      <FormattedMessage id='shared.country.cz' />
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Typography variant='body1' component='p' color='textSecondary'>
                <FormattedMessage id='settings.company.details.form.info' />
              </Typography>
              <Button onClick={handleSubmit(onSubmit)}>
                <FormattedMessage id='settings.account.form.submit' />
              </Button>
            </TopFormStyled>
          </div>
          <div>
            <SecondaryHeadingStyled variant='h4'>
              <FormattedMessage id='settings.account.form.logoTitle' />
            </SecondaryHeadingStyled>
            {showLogo ? (
              <Typography color='textSecondary'>
                <FormattedMessage id='settings.account.form.logoInfo' />
              </Typography>
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage
                  values={{
                    br: <br />,
                  }}
                  id='settings.account.form.logoRequest'
                />
              </Typography>
            )}
            {showLogo && (
              <img
                src={`${S3_ASSETS_URL}-${env}.s3.eu-west-1.amazonaws.com/${user.company_id}.png`}
                onError={() => {
                  setShowLogo(false);
                }}
              />
            )}
          </div>
          <div>
            <SecondaryHeadingStyled variant='h4'>
              <FormattedMessage id='settings.account.form.notificationTitle' />
            </SecondaryHeadingStyled>
            <Typography color='textSecondary'>
              <FormattedMessage id='settings.account.form.notificationInfo' />
            </Typography>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  name='notificationEmail'
                  placeholder={intl.formatMessage({
                    id: "settings.account.form.emailLabel",
                  })}
                  label={intl.formatMessage({
                    id: "settings.account.form.emailLabel",
                  })}
                  type='email'
                  disabled
                  required
                />
              </Grid>
            </Grid>
          </div>
        </FormProvider>
      )}
    </Container>
  );
};
