import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TableCell } from "@material-ui/core";
import { OrderType, OrderStateType } from "@types";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";
import { BadgeTypes } from "@components/common/Badge";
import ClockIcon from "@assets/icons/clock.svg";
import RepeatIcon from "@assets/icons/repeat.svg";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import * as S from "./styled";

export const Order: React.FC<{ order: OrderType; companyId: string }> = ({
  order,
}) => {
  const intl = useIntl();

  const orderStatusTitle: { [K in OrderStateType]: string } = {
    scheduled: intl.formatMessage({
      id: "order.status.title.scheduled",
    }),
    confirmed: intl.formatMessage({
      id: "order.status.title.confirmed",
    }),
    fulfillment_in_progress: intl.formatMessage({
      id: "order.status.title.inProgress",
    }),
    delivered: intl.formatMessage({
      id: "order.status.title.delivered",
    }),
    canceled: intl.formatMessage({
      id: "order.status.title.canceled",
    }),
    draft: intl.formatMessage({
      id: "order.status.title.draft",
    }),
  };

  const statusBadge: { [K in OrderStateType]: BadgeTypes } = {
    scheduled: "default",
    confirmed: "warning",
    fulfillment_in_progress: "warningSecondary",
    delivered: "success",
    canceled: "danger",
    draft: "secondary",
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <TableCell>
        {dayjs(order.requested_delivery_datetime || order.created_at).format(
          DEFAULT_DATE_FORMAT_SHORT
        )}
      </TableCell>
      <TableCell>
        <span style={{ textTransform: "uppercase" }}>{order.readable_id}</span>
      </TableCell>
      <TableCell>
        <S.BadgeStyled type={statusBadge[order.state]}>
          {order.state === "scheduled" && <ClockIcon />}
          {order.state === "fulfillment_in_progress" ? (
            <FormattedMessage
              id='orders.list.table.delivered'
              values={{
                delivered: order.deliveries_delivered_count,
                total: order.deliveries_total_count,
              }}
            />
          ) : (
            orderStatusTitle[order.state]
          )}
        </S.BadgeStyled>
      </TableCell>
      <TableCell>{order.name}</TableCell>
      <TableCell>
        <S.BadgeRow>
          <S.BadgeStyled>
            <FormattedMessage
              id='orders.list.table.count'
              values={{
                count: order.volume,
              }}
            />
          </S.BadgeStyled>
          {order.automation?.delivery_date_type === "birthday" && (
            <S.BadgeStyled>
              <RepeatIcon width={12} height={12} />
              <FormattedMessage
                id='orders.list.table.birthday'
                values={{
                  count: order.volume,
                }}
              />
            </S.BadgeStyled>
          )}
          {order.automation?.delivery_date_type === "anniversary" && (
            <S.BadgeStyled>
              <RepeatIcon width={12} height={12} />
              <FormattedMessage
                id='orders.list.table.anniversary'
                values={{
                  count: order.volume,
                }}
              />
            </S.BadgeStyled>
          )}
        </S.BadgeRow>
      </TableCell>
      <TableCell>
        <S.GiftPreview>
          <img
            width='32'
            height='32'
            src={order.gift.images?.[0]?.url || GiftPlaceholder}
            alt=''
          />
          {order.gift.name}
        </S.GiftPreview>
      </TableCell>
    </>
  );
};
