import React, { useState } from "react";
import { VALIDATION, ROUTES } from "@constants/index";
import { Alert, TextField } from "@components/common";
import { Typography, Grid } from "@material-ui/core";
import {
  FormattedMessage,
  injectIntl,
  useIntl,
  WrappedComponentProps,
} from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { AuthRouterStateType } from "@types";
import cognitoId from "../../../../cognitoId";
import * as S from "../styled";

const ForgotPassword = ({
  history,
}: RouteComponentProps<{}, {}, AuthRouterStateType> &
  WrappedComponentProps) => {
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const intl = useIntl();

  const onSubmit = ({ email }: { email: string }) => {
    setIsLoading(true);

    const cognitoUser = cognitoId.setCognitoUser(email);

    cognitoUser.forgotPassword({
      onSuccess: () => history.push(ROUTES.USER_RESET_PASSWORD),
      onFailure: (err) => {
        setErrorMsg(err.message);
        setIsLoading(false);
      },
      inputVerificationCode: () => {
        history.push({
          pathname: ROUTES.USER_RESET_PASSWORD,
          state: {
            email,
            successMsg: intl.formatMessage({
              id: "forgot.success",
            }),
          },
        });
      },
    });
  };

  return (
    <S.WrapperStyled
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <S.LogoStyled />

      <FormProvider {...methods}>
        <S.FormStyled>
          <S.TitleStyled variant='h2'>
            <FormattedMessage id='forgot.heading' />
          </S.TitleStyled>
          <S.TitleStyled variant='body1'>
            <FormattedMessage id='forgot.subheading' />
          </S.TitleStyled>
          <TextField
            name='email'
            type='text'
            placeholder={intl.formatMessage({
              id: "input.email.placeholder",
            })}
            label={intl.formatMessage({
              id: "input.email.label",
            })}
            error={errors.email}
            pattern={{
              value: VALIDATION.EMAIL_VALIDATION_REGEXP,
              message: intl.formatMessage({ id: "input.email.pattern.error" }),
            }}
            offsetbottom='false'
            required
          />
          <S.SubmitButtonStyled
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            loading={isLoading}
            type='submit'
          >
            <FormattedMessage id='forgot.submit' />
          </S.SubmitButtonStyled>
          {errorMsg && <Alert variant='error'>{errorMsg}</Alert>}
          <Grid
            container
            justifyContent='center'
            direction='column'
            alignItems='center'
          >
            <Typography variant='body1' paragraph>
              <S.LinkStyled color='secondary' to={ROUTES.USER_SIGN_IN}>
                <FormattedMessage id='forgot.link' />
              </S.LinkStyled>
            </Typography>
          </Grid>
        </S.FormStyled>
      </FormProvider>
    </S.WrapperStyled>
  );
};

export default injectIntl(ForgotPassword);
