import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { WrappedComponentProps, FormattedMessage } from "react-intl";
import { SectionNav } from "@components/common";
import { COMMON, ROUTES } from "@constants/index";
import AccountSettings from "../../AccountSettings";
import { CompanySettings } from "../../CompanySettings";
import Addresses from "../../Addresses";
import Orders from "../../Orders";
import { SubHeading } from "../../styled";

const Account: React.FC<WrappedComponentProps> = () => (
  <div>
    <SubHeading variant='h2'>
      <FormattedMessage id='settings.title' />
    </SubHeading>
    <SectionNav navItems={COMMON.ACCOUNT_NAV} />
    <Switch>
      <Route
        path={ROUTES.SETTINGS_PERSONAL_ROUTE}
        component={AccountSettings}
      />
      <Route path={ROUTES.SETTINGS_COMPANY_ROUTE} component={CompanySettings} />
      <Route path={ROUTES.SETTINGS_ORDERS} component={Orders} />
      <Route path={ROUTES.SETTINGS_ADDRESSES} component={Addresses} />
      <Redirect to={ROUTES.SETTINGS_PERSONAL_ROUTE} />
    </Switch>
  </div>
);

export default Account;
