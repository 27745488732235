import { useQuery, UseQueryResult } from "react-query";
import { GiftType } from "./types";
import { MerchantType, PagingType } from "@types";

import {
  fetchAllGifts,
  fetchGifts,
  fetchMerchant,
  fetchMerchantGift,
  fetchCompanyGift,
} from "./api";

export const useGifts = (
  companyId: string,
  params?: {
    origin_type?: "company" | "boxday_merchant";
    is_company_specific?: boolean;
    merchant_id?: string;
    per_page?: number;
    page: number;
    category_id?: string;
  }
): UseQueryResult<{ items: GiftType[]; paging: PagingType }> =>
  useQuery(["gifts", companyId, params], fetchGifts, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useGift = (
  id: string,
  giftId: string,
  type: "company" | "merchant",
  params?: {
    origin_type?: "company" | "boxday_merchant";
    is_company_specific?: boolean;
  }
): UseQueryResult<GiftType> => {
  return useQuery(
    ["gift", id, giftId, params],
    type === "company" ? fetchCompanyGift : fetchMerchantGift,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
    }
  );
};

export const useMerchant = (
  companyId: string,
  merchantId: string
): UseQueryResult<MerchantType> =>
  useQuery(["gifts", companyId, merchantId], fetchMerchant, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
  });

export const useAllGifts = (): UseQueryResult<{ items: GiftType[] }> =>
  useQuery(["allGifts"], fetchAllGifts, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
  });
