import styled from "styled-components";
import { STYLE } from "@constants/index";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;

  @media (${STYLE.device.tablet}) {
  }
`;

export const HeroImg = styled.img`
  display: block;
  width: 100%;
  max-height: 240px;
  object-fit: cover;
  border-radius: 12px;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;

export const InfoName = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;

export const InfoCountry = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Description = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-size: 14px;
  }
`;

export const ImagesCarousel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow-x: auto;

  img {
    width: 165px;
    min-width: 165px;
    height: 120px;
    object-fit: cover;
    border-radius: 12px;
  }
`;
