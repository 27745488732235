import React, { useContext, useEffect } from "react";
import { ToastContainer, Slide } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import { Error, ErrorPage, FullPageLoader } from "@components/common";
import { useLocation } from "react-router";
import { Main } from "../../../modules/main";
import { Header } from "../../../modules/header";
import { Modal } from "../../../modules/modal";
import * as S from "../styled";
import { TermsAndPrivacyModal } from "../../../modules/account/components/TermsAndPrivacyAgreement";
import { usePrefetchedData } from "../../hooks";
import { Admin } from "../../../modules/admin";
import { AuthContext } from "@providers/AuthProvider";
import { SupportChat } from "@ui/components";

const GLEAP_CUSTOMER__KEY = "4oIgLz73XFh4GhBLxWfN5a7EXrrzZUfF";

export const Authenticated: React.FC = () => {
  const { user, setUser } = useContext(AuthContext);
  const {
    isLoading,
    companyData: company,
    isError,
  } = usePrefetchedData(user?.company_id);

  const location = useLocation();

  useEffect(() => {
    if (company) {
      setUser((state) => ({
        ...state,
        company_email: company.notification_email,
        company_name: company.name,
        credit_balance: company.credit_balance,
        business_name: company.business_name,
      }));
    }
  }, [company]);

  if (isError) {
    return (
      <S.Container>
        <Header profileFixed />
        <div style={{ padding: "30px" }}>
          <Error />
        </div>
      </S.Container>
    );
  }

  if (isLoading || !company) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <ErrorBoundary fallback={<ErrorPage />} key={location.pathname}>
        <S.Container>
          <Header profileFixed={!company.terms_of_service_accepted} />
          {company.terms_of_service_accepted ? (
            <Main user={user} />
          ) : (
            <TermsAndPrivacyModal user={user} company={company} />
          )}
          <Modal />
          {user.is_admin && <Admin />}
        </S.Container>
      </ErrorBoundary>
      <S.ToastContainer>
        <ToastContainer
          position='bottom-center'
          hideProgressBar
          autoClose={3000}
          transition={Slide}
          className='toast'
        />
      </S.ToastContainer>
      <SupportChat apiKey={GLEAP_CUSTOMER__KEY} user={user} />
    </div>
  );
};
