import { useState, useEffect } from "react";
import type { CountryItemType } from "@constants/features";
import { features } from "@constants/features";

export const useCustomFeatures = (userId: string) => {
  const [additionalCountries, setAdditionalCountries] = useState<
    CountryItemType[]
  >([]);

  useEffect(() => {
    const userAdditionalCountries = features.countries[userId];

    if (userAdditionalCountries) {
      setAdditionalCountries(userAdditionalCountries);
    }
  }, [userId]);

  return {
    additionalCountries,
  };
};
