import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import dayjs from "dayjs";
import { IntlProvider } from "react-intl";
import { AuthContext } from "@providers/AuthProvider";
import localeData from "./messages";

import "dayjs/locale/sk";
import "dayjs/locale/cs";
import "dayjs/locale/en";

const browserLocale = "sk";

interface IntlContextProps {
  locale: keyof typeof localeData;
  setLocale: Dispatch<SetStateAction<keyof typeof localeData>>;
}

export const IntlContext = createContext<IntlContextProps>({
  locale: {} as keyof typeof localeData,
  setLocale: () => {},
});

const IntlProviderComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState<keyof typeof localeData>(browserLocale);

  const { user } = useContext(AuthContext);

  const localeMemo = useMemo(() => ({ locale, setLocale }), [locale]);

  dayjs.locale(locale);

  useEffect(() => {
    if (user) {
      setLocale(user.locale);
      dayjs.locale(user.locale);
    }
  }, [user]);

  return (
    <IntlContext.Provider value={localeMemo}>
      <IntlProvider
        locale={locale}
        defaultLocale='sk'
        messages={localeData[locale]}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export default IntlProviderComponent;
