import styled from "styled-components";

export const Header = styled.header`
  padding: 16px 0;
  border-bottom: 1px solid #d9d9d9;
`;

export const Container = styled.div`
  padding: 46px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 38px;
  padding: 3rem 1rem 2rem;

  max-width: 510px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-weight: 400;
  }

  a {
    color: #e53760;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    img {
      position: relative;
      margin-top: 20px;
      left: 20px;
    }
  }
`;
