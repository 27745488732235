import axios, { AxiosError, AxiosResponse } from "axios";

import cognitoUser from "../cognitoId";
import { PAGINATION_ROWS_PER_PAGE } from "@ui/src/constants/common";

// Set up default Axios headers
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["User-Agent"] = window.navigator.userAgent;

// configure base Axios instance
const baseAxiosInstance = axios.create({
  params: {
    per_page: PAGINATION_ROWS_PER_PAGE,
  },
});

baseAxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error?.response?.status === 401 || error.request.status === 401) {
      cognitoUser.userSignOut();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export const baseApi = baseAxiosInstance;
