import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { QueryKey } from "react-query";

const { API } = CONSTANTS;

export const searchContacts = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise =>
  baseApi
    .get(
      `${API.COMPANIES}/${queryKey[1]}/contacts?page=1&search=${queryKey[2]}`
    )
    .then((res) => res.data);
