import React, { useCallback, useContext, useEffect, useState } from "react";
import { AdminMenu } from "@ui/components";
import { useCompanies } from "./hooks";
import { AuthContext } from "@providers/AuthProvider";
import cognitoUser from "../../cognitoId";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useDebounce } from "@ui/src/utils/useDebounce";

export const Admin = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const searchDebounced = useDebounce(searchValue);
  const [showModal, setShowModal] = useState(false);
  const { data: companies, isLoading: fetchLoading } = useCompanies(
    page,
    searchDebounced,
    showModal
  );
  const { user, setUser } = useContext(AuthContext);
  const intl = useIntl();

  const handleChange = useCallback((id: string) => {
    const attributes = [
      {
        Name: "custom:organization_id",
        Value: id,
      },
    ];

    cognitoUser.getCognitoUser().updateAttributes(attributes, (error) => {
      if (error) {
        toast.error(
          intl.formatMessage({
            id: "general.notifications.error",
          })
        );
        return;
      }

      setUser((state) => ({
        ...state,
        company_id: id,
      }));

      cognitoUser
        .getCognitoUser()
        .getSession((err: Error, session: CognitoUserSession) => {
          if (err) {
            setUser(null);
            return;
          }

          cognitoUser
            .getCognitoUser()
            .refreshSession(session.getRefreshToken(), (sessionErr: Error) => {
              if (sessionErr) {
                setUser(null);
                return;
              }

              toast.success(
                intl.formatMessage({
                  id: "Company was switched",
                })
              );
              setShowModal(false);
            });
        });
    });
  }, []);

  useEffect(() => {
    if (!showModal) {
      setSearchValue("");
    }
  }, [showModal]);

  return (
    <AdminMenu
      companies={companies?.items}
      user={user}
      cta={(id: string) => handleChange(id)}
      page={page}
      setPage={setPage}
      paging={companies?.paging}
      showModal={showModal}
      setShowModal={setShowModal}
      isLoading={fetchLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};
