import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { OrderList } from "./OrderList";
import { OrderDetails } from "./OrderDetails";
import { CreateOrder } from "./OrderCreate";

export const Orders: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/orders/details/:id' component={OrderDetails} />
      <Route exact path='/orders/list' component={OrderList} />
      <Route exact path='/orders/create/:id?' component={CreateOrder} />
      <Redirect to='/orders/list' />
    </Switch>
  );
};
