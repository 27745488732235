import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
// import { Typography } from "@material-ui/core";
import { TextField, Radio } from "@components/common";
// import InfoIcon from "@assets/icons/eye.svg";
// import LinkIcon from "@assets/icons/link.svg";
import { TEXTAREA_MAX_LENGTH } from "@constants/common";
import * as S from "../../styled";

type CreateStep3Props = {
  noteType: "note_with_text" | "blank_note" | "none";
  setNoteType: (type: "note_with_text" | "blank_note" | "none") => void;
  giftText: string;
  setGiftText: (text: string) => void;
};

export const CreateStep3: React.FC<CreateStep3Props> = ({
  noteType,
  setNoteType,
  giftText,
  setGiftText,
}) => {
  const intl = useIntl();

  return (
    <S.StepBody>
      <div style={{ marginBottom: "8px" }}>
        <Radio
          value={noteType}
          defaultValue={noteType}
          // @ts-expect-error
          onChange={(e) => setNoteType(e.target.value)}
          name='type'
          items={[
            {
              value: "note_with_text",
              label: intl.formatMessage({
                id: "orders.create.step3.variant1",
              }),
            },
            {
              value: "blank_note",
              label: intl.formatMessage({
                id: "orders.create.step3.variant2",
              }),
            },
            {
              value: "none",
              label: intl.formatMessage({
                id: "orders.create.step3.variant3",
              }),
            },
          ]}
        />
      </div>
      {noteType === "note_with_text" && (
        <div>
          <S.DynamicFieldsWrapper>
            <TextField
              label={intl.formatMessage({
                id: "orders.create.step3.giftTextLabel",
              })}
              value={giftText}
              // @ts-expect-error
              onChange={(e) => setGiftText(e.target.value)}
              hooked={false}
              type='text'
              multiline
              minRows={6}
              invalid={giftText?.length > TEXTAREA_MAX_LENGTH}
              max={TEXTAREA_MAX_LENGTH}
              fullWidth
              required
              offsetbottom='false'
            />
            <S.DynamicFieldsInfo>
              <div>
                <FormattedMessage
                  id='orders.create.step3.minMaxChars'
                  values={{
                    min: giftText?.length,
                    max: TEXTAREA_MAX_LENGTH,
                  }}
                />
              </div>
              {/* <div>
                <InfoIcon />{" "}
                <FormattedMessage id='orders.create.step3.previewCard' />
              </div> */}
            </S.DynamicFieldsInfo>
          </S.DynamicFieldsWrapper>
          <S.DynamicFieldsDemo>
            <div>
              <b>
                <FormattedMessage id='orders.create.step3.dynamicFieldsTitle' />
              </b>{" "}
              <br />
              <FormattedMessage
                id='orders.create.step3.dynamicFieldsText'
                values={{
                  br: <br />,
                }}
              />
            </div>
            {/* <S.DynamicFieldsDemoLink href='https://www.google.com'>
              <LinkIcon />
              <Typography>
                <FormattedMessage id='orders.create.step3.dynamicFieldsHwoToLink' />
              </Typography>
            </S.DynamicFieldsDemoLink> */}
          </S.DynamicFieldsDemo>
        </div>
      )}
    </S.StepBody>
  );
};
