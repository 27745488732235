import { NessDeliverySlipCountryList } from "@constants/customCountries";

export type FeaturesNamesType = "countries";
export type CountryItemType = {
  code: string;
  locale: string;
};

export type Features = {
  [key in FeaturesNamesType]: {
    [key: string]: CountryItemType[];
  };
};

export const features: Features = {
  countries: {
    "ness-id": NessDeliverySlipCountryList,
  },
};
