import React, { useContext } from "react";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { Alert, Loader, Table } from "@components/common";
import { COMMON } from "@constants/index";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { AuthContext } from "@providers/AuthProvider";
import { SubHeadingStyled, HeaderStyled, FormSubmitStyled } from "../../styled";
import { useImportContacts, useContactsImports } from "../../hooks";
import { ImportedFiles, ImportedFilesStatus } from "./styled";
import ImportDraggable from "./ImportDraggble";

const ImportContacts = () => {
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const {
    mutate: importContacts,
    isError,
    isSuccess,
    isLoading,
    reset: resetImports,
  } = useImportContacts();
  const { data: imports, isLoading: loadingImports } = useContactsImports(
    user.company_id
  );
  const methods = useForm();
  const { handleSubmit, reset, watch, setValue } = methods;

  const onSubmit = ({ csvFile }: { csvFile: File }) => {
    if (csvFile) {
      importContacts({
        company_id: user.company_id,
        data: {
          file: csvFile,
        },
      });
    }
    reset();
  };

  if (isError) {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
  }

  if (isSuccess) {
    toast.success(
      intl.formatMessage({
        id: "contacts.import.success",
      })
    );
    resetImports();
  }

  return (
    <>
      <HeaderStyled>
        <SubHeadingStyled component='h3' variant='h3'>
          <FormattedMessage id='contacts.import.title' />
        </SubHeadingStyled>
      </HeaderStyled>
      <FormProvider {...methods}>
        <Alert variant='warning'>
          <FormattedMessage id='contacts.import.message' />
        </Alert>
        <FormSubmitStyled>
          <ImportDraggable
            fileName={watch("csvFile")?.name}
            disabled={false}
            reset={reset}
            onSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            setValue={setValue}
          />
        </FormSubmitStyled>
      </FormProvider>
      {loadingImports && <Loader />}
      {imports?.length > 0 && (
        <>
          <HeaderStyled>
            <SubHeadingStyled component='h4' variant='h4'>
              <FormattedMessage id='contacts.import.uploaded.title' />
            </SubHeadingStyled>
          </HeaderStyled>
          <ImportedFiles>
            <Table>
              <TableBody>
                {imports.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell>
                      <div>
                        {dayjs(file.created_at).format(
                          COMMON.DEFAULT_DATE_FORMAT
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='file'>{file.file_name}</div>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <ImportedFilesStatus status={file.state}>
                        {file.state === "completed" ? (
                          <FormattedMessage id='contacts.processed' />
                        ) : (
                          <FormattedMessage id='contacts.toBeProcessed' />
                        )}
                      </ImportedFilesStatus>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ImportedFiles>
        </>
      )}
    </>
  );
};

export default ImportContacts;
