import {
  Loader,
  NoData,
  Table,
  Button,
  Badge,
  Pagination,
  Modal,
} from "@components/common";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import React, { useMemo, useState } from "react";
import {
  UserProfileType,
  ContactType,
  DeliveryType,
  DeliveryStateType,
  BadgeTypes,
} from "@types";
import { useHistory, useParams } from "react-router";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import NoDataIcon from "@assets/icons/addresses-empty.png";
import GiftIcon from "@assets/icons/gift.svg";
import { sortByDateFiled } from "@utils/sorting";
import { useContactDeliveries, useCancelContactDelivery } from "../../../hooks";
import { SubHeadingStyled, HeaderStyled } from "../../../styled";
import ClockIcon from "@assets/icons/clock.svg";

const SectionStyled = styled.div`
  margin-bottom: 32px;

  table a {
    color: #000000;
  }

  table button {
    position: relative;
    left: -12px;
    height: auto;
  }
`;

const statusBadge: { [key in DeliveryStateType]: BadgeTypes } = {
  scheduled: "default",
  waiting_for_information: "primary",
  waiting_for_shipment: "primary",
  in_transit: "warning",
  delivered: "success",
  canceled: "danger",
  draft: "secondary",
};

interface OrdersProps {
  userInfo: UserProfileType;
  contact: ContactType;
}

const Deliveries: React.FC<OrdersProps> = ({ userInfo, contact }) => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const { data: contactDeliveries, isLoading: isOrderLoading } =
    useContactDeliveries(userInfo.company_id, id, page);
  const intl = useIntl();
  const history = useHistory();
  const [showCtaModal, setShowCtaModal] = useState<string>(null);

  const { mutate: cancelDelivery, status, reset } = useCancelContactDelivery();

  const orderStatusTitle: { [key in DeliveryStateType]: string } = {
    draft: intl.formatMessage({
      id: "order.status.title.draft",
    }),
    scheduled: intl.formatMessage({
      id: "order.status.title.scheduled",
    }),
    delivered: intl.formatMessage({
      id: "order.status.title.delivered",
    }),
    canceled: intl.formatMessage({
      id: "order.status.title.canceled",
    }),
    waiting_for_information: intl.formatMessage({
      id: "order.status.title.waitingForInput",
    }),
    waiting_for_shipment: intl.formatMessage({
      id: "order.status.title.waitingForShipment",
    }),
    in_transit: intl.formatMessage({
      id: "order.status.title.inTransit",
    }),
  };

  const sortedDeliveries: DeliveryType[] = useMemo(
    () =>
      sortByDateFiled(
        contactDeliveries?.items,
        "delivery.requested_delivery_datetime",
        "asc"
      ),
    [contactDeliveries]
  );

  if (status === "success") {
    toast.success(
      intl.formatMessage({
        id: "delivery.modal.cancelSuccess",
      })
    );
    setShowCtaModal(null);
    reset();
  }
  if (status === "error") {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    setShowCtaModal(null);
    reset();
  }

  if (!userInfo || !contact || isOrderLoading) {
    return <Loader />;
  }

  return (
    <div>
      {contactDeliveries.items.length === 0 ? (
        <NoData>
          <img src={NoDataIcon} alt='' />
          <Typography component='h4' variant='h4'>
            No gifts yet for {contact.first_name} {contact.last_name} :(
          </Typography>
          <Button
            startIcon={<GiftIcon />}
            color='primary'
            variant='text'
            onClick={() => {
              history.push({
                pathname: "/orders/create",
                state: {
                  contact,
                },
              });
            }}
          >
            <FormattedMessage id='contacts.main.createOrder' />
          </Button>
        </NoData>
      ) : (
        <SectionStyled>
          <HeaderStyled>
            <SubHeadingStyled component='h3' variant='h3'>
              <FormattedMessage id='contacts.deliveries.title' />
            </SubHeadingStyled>
          </HeaderStyled>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id='contacts.deliveries.order' />
                </TableCell>
                <TableCell>
                  <FormattedMessage id='contacts.deliveries.date' />
                </TableCell>
                <TableCell>
                  <FormattedMessage id='contacts.deliveries.gift' />
                </TableCell>
                <TableCell>
                  <FormattedMessage id='contacts.deliveries.campaign' />
                </TableCell>
                <TableCell>
                  <FormattedMessage id='contacts.deliveries.status' />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDeliveries?.map((delivery) => (
                <TableRow key={delivery.id}>
                  <TableCell>
                    {delivery.order?.readable_id?.toUpperCase() || "N/A"}
                  </TableCell>
                  <TableCell>
                    {dayjs(
                      delivery.scheduled_for_date ||
                        delivery.requested_delivery_datetime ||
                        delivery.created_at
                    ).format(DEFAULT_DATE_FORMAT_SHORT)}
                  </TableCell>
                  <TableCell>
                    {delivery.order?.gift?.name || delivery.gift?.name}
                  </TableCell>
                  <TableCell>
                    {delivery.order?.automation?.name || ""}
                  </TableCell>
                  <TableCell>
                    <Badge type={statusBadge[delivery.state]}>
                      {delivery.state === "scheduled" && <ClockIcon />}
                      {orderStatusTitle[delivery.state]}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {(delivery.state === "draft" ||
                      delivery.state === "scheduled") && (
                      <Button
                        color='primary'
                        variant='text'
                        style={{
                          textTransform: "uppercase",
                        }}
                        onClick={() => {
                          setShowCtaModal(delivery.id);
                        }}
                        disabled={status === "loading"}
                      >
                        <FormattedMessage id='contacts.deliveries.cancel' />
                      </Button>
                    )}
                  </TableCell>
                  {showCtaModal === delivery.id && (
                    <Modal
                      show
                      title={intl.formatMessage({
                        id: "orders.modal.cancel.title",
                      })}
                      ctaTitle={intl.formatMessage({
                        id: "orders.modal.cancel.submitBtn",
                      })}
                      cancelTitle={intl.formatMessage({
                        id: "orders.modal.cancel.cancelBtn",
                      })}
                      maxWidth='xs'
                      cta={() => {
                        cancelDelivery({
                          order_id: delivery.order.id,
                          delivery_id: delivery.id,
                          company_id: userInfo.company_id,
                        });
                      }}
                      handleClose={() => setShowCtaModal(null)}
                      isLoading={status === "loading"}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            page={page}
            onChange={setPage}
            total={contactDeliveries?.paging.total_records}
          />
        </SectionStyled>
      )}
    </div>
  );
};

export default Deliveries;
