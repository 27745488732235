import styled from "styled-components";

export type BadgeTypes =
  | "success"
  | "successFilled"
  | "danger"
  | "warning"
  | "warningSecondary"
  | "primary"
  | "secondary"
  | "default";

type BadgeProps = {
  type?: BadgeTypes;
}

export default styled.div<BadgeProps>`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  padding: 0 10px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  border: 1px solid transparent;
  white-space: nowrap;

  ${(props) => {
    switch (props.type) {
      case "success":
        return `
          background-color: #CBF7D6;
          color: #3C9E53;
        `;

      case "successFilled":
        return `
          background-color: #3AB557;
          color: #fff;
        `;

      case "danger":
        return `
          background-color: #FFEFEF;
          color: #E53761;
        `;

      case "warning":
        return `
          background-color: #FFF7E6;
          color: #EBA000;
        `;

      case "warningSecondary":
        return `
          background-color: #FFF2D3;
          color: #B27433;
        `;

      case "primary":
        return `
          background-color: #ecf2fb;
          color: #115ccc;
        `;

      case "secondary":
        return `
            background-color: #F7F7F7;
            color: #515151;
          `;

      default:
        return `
          border-color: #eee;
          color: #000;
        `;
    }
  }};
`;
