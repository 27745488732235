import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Pagination from "@material-ui/lab/Pagination";
import styled from "styled-components";
import { COMMON } from "@constants/index";
import { useHistory } from "react-router";

const { PAGINATION_ROWS_PER_PAGE } = COMMON;

const PaginationStyled = styled(Pagination)`
  margin-bottom: 24px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

const PaginationComponent = ({
  total,
  onChange,
  page = 1,
  ...props
}: {
  total: number;
  page: number;
  onChange: Dispatch<SetStateAction<number>>;
}) => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(page);
  const handlePaginationChange = (_, value) => {
    onChange(value);
    setCurrentPage(value);
    history.push({
      search: `?page=${value}`,
    });
  };

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  if (total <= PAGINATION_ROWS_PER_PAGE) {
    return null;
  }

  return (
    <PaginationStyled
      shape='round'
      page={currentPage}
      onChange={handlePaginationChange}
      count={Math.ceil(total / PAGINATION_ROWS_PER_PAGE)}
      {...props}
    />
  );
};

export default PaginationComponent;
