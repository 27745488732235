import React, { useContext, useState } from "react";
import { Container, Grid, MenuItem } from "@material-ui/core";
import { Logo, Select } from "@components/common";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "@providers/AuthProvider";
import * as S from "./styled";
import { LanguageCodeType } from "@types";

export const Header = () => {
  const { user, setUser } = useContext(AuthContext);
  const [langSelect, setLangSelect] = useState(user?.locale || "sk");

  return (
    <S.Header>
      <Container maxWidth='sm'>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item className='logo'>
            <Logo />
          </Grid>
          <Grid item className='language-select'>
            <Select
              id='country'
              name='country'
              label=''
              defaultValue={user?.locale || "sk"}
              value={langSelect}
              hooked={false}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const { value } = e.target;
                setLangSelect(value as LanguageCodeType);
                setUser((state) => ({
                  ...state,
                  locale: value as LanguageCodeType,
                }));
              }}
            >
              <MenuItem value='en'>
                <FormattedMessage id='shared.lang.eng' />
              </MenuItem>
              <MenuItem value='sk'>
                <FormattedMessage id='shared.lang.sk' />
              </MenuItem>
              <MenuItem value='cs'>
                <FormattedMessage id='shared.lang.cs' />
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Container>
    </S.Header>
  );
};
