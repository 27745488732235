import { Typography, MenuItem, Paper } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import EmptyFilteredIcon from "@assets/icons/empty-list.svg";

export const WrapperStyled = styled.div`
  padding-bottom: 20px;
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 0 30px;
  gap: 48px;
  flex: 1;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 26px;
  margin-bottom: 24px;
  min-height: 40px;
  position: relative;
`;

export const SubHeadingLeftStyled = styled.div`
  flex: 1;
`;

export const SubHeadingStyled = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

export const CtaList = styled.div`
  display: flex;
  gap: 18px;
  margin-left: 14px;
`;

export const Counter = styled.div`
  margin-left: 18px;
  font-size: 15px;
  font-weight: 500;
`;

export const SubHeadingInfoStyled = styled(Typography)`
  font-size: 15px;
  margin: 0 0 0 20px;
  white-space: nowrap;
`;

export const AddCustomersToggleStyled = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-transform: none;
`;

export const EmptyListStyled = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const FilterEmptyStyled = styled(Typography)`
  margin-bottom: 24px;
`;

export const FilterEmptyIconStyled = styled(EmptyFilteredIcon)`
  display: inline-flex;
  margin-bottom: 24px;
`;

export const FormSubmitStyled = styled.div`
  margin-top: 16px;
`;

export const FilterToggleStyled = styled.div<{ error: boolean }>`
  line-height: 40px;
  height: 40px;
  background-color: #f8f6f4;
  border-radius: 4px;
  padding: 0 12px 0 14px;
  font-size: 15px;
  min-width: 220px;
  margin-right: 16px;
  border: 1px solid transparent;
  border-color: ${({ error }) => (error ? "red" : "transparent")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableFilterContainerStyled = styled.div`
  display: flex;
`;

export const FilterListItemStyled = styled.li`
  margin: 0 16px;

  &:first-child {
    border-bottom: 1px solid #ececec;
    margin-top: -8px;
  }

  label {
    margin-bottom: 0;
  }
`;

export const CheckBoxWrapperStyled = styled.div`
  label {
    margin: 0;
  }
`;

// Contact

export const TagsDropdownWrapper = styled.div`
  position: relative;

  > div {
    margin-bottom: 10px;
  }
`;

export const TagsPaperStyled = styled(Paper)`
  position: absolute;
  top: 68px;
  width: 100%;
`;

export const TagMenuItemStyled = styled(MenuItem)`
  display: flex;
  gap: 6px;

  span {
    color: #a0a0a0;
  }
`;

export const ButtonBackStyled = styled(Link)`
  margin-bottom: 32px;
  display: inline-flex;
  gap: 10px;
  color: rgba(17, 17, 17, 0.48);
  text-decoration: none;
  align-items: center;
`;

export const TagRowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  margin-left: 14px;
`;

export const TagStyled = styled.div`
  padding: 6px;
  border: 1px solid #ececec;
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  flex: 0;
  white-space: nowrap;
`;
