import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { SubHeadingStyled, HeaderStyled } from "../../styled";
import { AssignToCompanyAddress } from "./AssignToCompanyAddress";
import { AssignCustomAddress } from "./AssignCustomAddress";
import { Loader } from "@components/common";

const ContactAddress = () => {
  const [isApiLoaded, setIsApiLoaded] = React.useState(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isApiLoaded) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places`;
      document.body.appendChild(script);
      script.onload = () => {
        setIsApiLoaded(true);
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  if (!isApiLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HeaderStyled style={{ marginBottom: 0 }}>
        {/* @ts-expect-error */}
        <SubHeadingStyled component='h4' variant='h4'>
          <FormattedMessage id='contacts.addresses.title' />
        </SubHeadingStyled>
      </HeaderStyled>
      <AssignToCompanyAddress />
      <HeaderStyled>
        {/* @ts-expect-error */}
        <SubHeadingStyled component='h4' variant='h4'>
          <FormattedMessage id='contacts.addresses.custom' />
        </SubHeadingStyled>
      </HeaderStyled>
      <AssignCustomAddress />
    </>
  );
};

ContactAddress.displayName = "ContactAddress";

export default ContactAddress;
