import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import * as S from "./styled";
import { GiftStateType, GiftFlags } from "../../types";
import { AuthContext } from "@providers/AuthProvider";
import axios from "axios";

const MAX_GIFT_DESCRIPTION_LENGTH = 100;

const trimmedString = (string: string, length: number) =>
  string.length > length ? `${string.substring(0, length - 3)}...` : string;

type GiftProps = {
  id: string;
  title: string;
  price?: string;
  description?: string;
  status: GiftStateType | "external";
  badges: GiftFlags;
  imgSrc?: string;
  external?: boolean;
  href: string;
  comingSoon: boolean;
  checkForBrandedImage: boolean;
};

const renderStatusText = (
  giftStatus: GiftStateType | "external"
): ReactElement => {
  switch (giftStatus) {
    case "coming_soon":
      return <FormattedMessage id='gifts.status.comingSoon' />;
    case "preparing":
      return <FormattedMessage id='gifts.status.preparing' />;
    case "external":
      return null;
    case "out_of_stock":
      return <FormattedMessage id='gifts.status.outOfStock' />;
    default:
      return <FormattedMessage id='gifts.status.inStock' />;
  }
};
const env = process.env.NODE_ENV === "development" ? "test" : "prod";

export const Gift: React.FC<GiftProps> = ({
  title,
  status,
  imgSrc,
  price,
  description,
  comingSoon = false,
  href,
  checkForBrandedImage = false,
  id,
}) => {
  const [isBrandAssetLoaded, setIsBrandAssetLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(checkForBrandedImage);
  const { user } = useContext(AuthContext);

  const assetUrl = `https://s3-images-boxday-${env}.s3.amazonaws.com/${user.company_id}/${id}.jpg`;

  useEffect(() => {
    if (checkForBrandedImage) {
      const checkAsset = async () => {
        try {
          const res = await axios.get(assetUrl);

          if (res) {
            setIsBrandAssetLoaded(true);
            setIsLoading(false);
          }
        } catch (e) {
          setIsBrandAssetLoaded(false);
          setIsLoading(false);
        }
      };
      checkAsset();
    }
  }, [checkForBrandedImage]);

  return (
    <S.GiftStyled>
      <Link to={href}>
        {isLoading ? (
          <div className='img-loader' />
        ) : (
          <div>
            {isBrandAssetLoaded ? (
              <img src={assetUrl} alt={title} />
            ) : (
              <img src={imgSrc} alt={title} />
            )}
          </div>
        )}
        <S.GiftBodyStyled>
          <S.Title>{title}</S.Title>
          {description && (
            <S.Description
              dangerouslySetInnerHTML={{
                __html: trimmedString(description, MAX_GIFT_DESCRIPTION_LENGTH),
              }}
            />
          )}
          {price && <S.Price>{price}</S.Price>}
          <S.GiftStatusStyled variant={status}>
            {renderStatusText(status)}
          </S.GiftStatusStyled>
        </S.GiftBodyStyled>
        {comingSoon && (
          <S.Badge>
            <FormattedMessage id='gifts.status.comingSoon' />
          </S.Badge>
        )}
      </Link>
    </S.GiftStyled>
  );
};
