import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { IconButton, Menu } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  Menu as MenuIcon,
  Close as MenuExpandedIcon,
} from "@material-ui/icons";
import { ROUTES } from "@constants/index";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { UserProfileType } from "@types";
import { NavItem } from "./NavItem";
import Logo from "../Logo";
import * as S from "./styled";
import HelpIcon from "@assets/icons/help.svg";
import SupportIcon from "@assets/icons/support.svg";
import LogoutIcon from "@assets/icons/logout.svg";
import Gleap from "gleap";

export type MainNavItemType = {
  link: string;
  icon: string;
  locale: string;
};

interface HeaderProps {
  handleSignOut: () => void;
  profileFixed: boolean;
  user: UserProfileType;
  showSwitchCompany?: boolean;
  navLinks: MainNavItemType[];
  isAdmin: boolean;
}

const MobileMenuToggler = ({ expanded }: { expanded: boolean }) =>
  expanded ? (
    <MenuExpandedIcon color='primary' aria-label='menu' />
  ) : (
    <MenuIcon color='primary' aria-label='menu' />
  );

const Header: React.FC<HeaderProps & PropsWithChildren> = ({
  handleSignOut,
  profileFixed,
  user,
  navLinks,
  isAdmin,
  children,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [isSubMenuOpened, setIsSubMenuOpened] = useState(false);
  const intl = useIntl();
  const location = useLocation();

  const toggleSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsSubMenuOpened((state) => !state);
  };

  useEffect(() => {
    setMenuExpanded(false);
  }, [location]);

  return (
    <S.HeaderWrapperStyled isOpened={menuExpanded} isAdmin={isAdmin}>
      <S.HeaderTopStyled>
        <S.LogoLinkStyled to={ROUTES.MAIN_WELCOME} aria-label='home-link'>
          <Logo />
        </S.LogoLinkStyled>
        {isMobile && (
          <S.IconButtonStyled
            onClick={() => setMenuExpanded((state) => !state)}
          >
            <MobileMenuToggler expanded={menuExpanded} />
          </S.IconButtonStyled>
        )}
      </S.HeaderTopStyled>
      {menuExpanded || !isMobile ? (
        <S.NavContainer>
          <S.HeaderStyled>
            {navLinks.map((item: MainNavItemType) => (
              <NavItem key={item.locale} item={item} />
            ))}
          </S.HeaderStyled>

          {children}

          <div>
            <IconButton
              onClick={toggleSubMenu}
              size='small'
              aria-controls={isSubMenuOpened ? "account-menu" : undefined}
              aria-haspopup='true'
              aria-expanded={isSubMenuOpened ? "true" : undefined}
              style={
                profileFixed
                  ? {
                      position: "fixed",
                      zIndex: "999999",
                      bottom: "32px",
                    }
                  : {}
              }
            >
              <S.AvatarStyled>
                {user.name?.split(" ")?.[0]?.[0]}
                {user.name?.split(" ")?.[1]?.[0]}
              </S.AvatarStyled>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={isSubMenuOpened}
              onClose={() => setIsSubMenuOpened(false)}
              onClick={() => setIsSubMenuOpened(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
              <S.SubMenuItemStyled disabled>{user.email}</S.SubMenuItemStyled>
              <S.DividerStyled variant='middle' />
              <S.SubMenuItemStyled>
                <a
                  href='https://support.boxday.app'
                  target='_blank'
                  rel='noreferrer'
                >
                  <HelpIcon />
                  {intl.formatMessage({
                    id: "main.nav.help",
                  })}
                </a>
              </S.SubMenuItemStyled>
              <S.SubMenuItemStyled
                onClick={() => {
                  Gleap.open();
                }}
                disabled={!Gleap.isUserIdentified()}
              >
                <SupportIcon />

                {intl.formatMessage({
                  id: "main.nav.support",
                })}
              </S.SubMenuItemStyled>
              <S.DividerStyled variant='middle' />
              <S.SubMenuItemStyled onClick={handleSignOut}>
                <LogoutIcon />

                {intl.formatMessage({
                  id: "main.nav.logout",
                })}
              </S.SubMenuItemStyled>
            </Menu>
          </div>
        </S.NavContainer>
      ) : null}
    </S.HeaderWrapperStyled>
  );
};

export default Header;
