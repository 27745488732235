import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, TextField } from "@components/common";
import { toast } from "react-toastify";
import { TopFormStyled } from "../../styled";
import cognitoUser from "../../../../../cognitoId";

const ResetPassForm: React.FC = () => {
  const methods = useForm();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = methods;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = ({
    currentPass,
    newPass,
    confirmPass,
  }: {
    currentPass: string;
    newPass: string;
    confirmPass: string;
  }) => {
    setIsLoading(true);

    if (newPass === confirmPass) {
      cognitoUser.changePassword(currentPass, newPass, (err) => {
        if (err) {
          toast.error(
            intl.formatMessage({
              id: "reset.incorrectPass",
            })
          );
          return;
        }
        toast.success(
          intl.formatMessage({
            id: "reset.success",
          })
        );
      });
    }

    setIsLoading(false);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <TopFormStyled onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name='currentPass'
          placeholder={intl.formatMessage({
            id: "input.password.current.label",
          })}
          label={intl.formatMessage({
            id: "input.password.current.label",
          })}
          error={errors.currentPass}
          type='password'
          required
          // pattern={{
          //   value: VALIDATION.PASSWORD_VALIDATION_REGEXP,
          //   message: intl.formatMessage({
          //     id: "input.password.pattern.error",
          //   }),
          // }}
        />
        <TextField
          name='newPass'
          placeholder={intl.formatMessage({
            id: "input.password.new.label",
          })}
          label={intl.formatMessage({
            id: "input.password.new.label",
          })}
          type='password'
          required
          // minLength={8}
          // pattern={{
          //   value: VALIDATION.PASSWORD_VALIDATION_REGEXP,
          //   message: intl.formatMessage({
          //     id: "input.password.pattern.error",
          //   }),
          // }}
        />
        <TextField
          name='confirmPass'
          placeholder={intl.formatMessage({
            id: "input.password.confirm.label",
          })}
          label={intl.formatMessage({
            id: "input.password.confirm.label",
          })}
          type='password'
          validate={(val: string) => {
            if (watch("newPass") !== val) {
              return false;
            }
            return true;
          }}
          error={errors.confirmPass}
          validateMessage={intl.formatMessage({
            id: "change.doNotMatch",
          })}
          required
          // minLength={8}
          // pattern={{
          //   value: VALIDATION.PASSWORD_VALIDATION_REGEXP,
          //   message: intl.formatMessage({
          //     id: "input.password.pattern.error",
          //   }),
          // }}
        />
        <Button
          type='submit'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          <FormattedMessage id='settings.changePassword' />
        </Button>
      </TopFormStyled>
    </FormProvider>
  );
};

export default ResetPassForm;
