import React from "react";
import { FormattedMessage } from "react-intl";
import { AutomationType, AutomationStateType } from "@types";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import * as S from "../styled";

const StateTitles: { [k in AutomationStateType]: string } = {
  active: "Active",
  inactive: "Inactive",
  draft: "Draft",
  completed: "Completed",
};

export const Item: React.FC<{
  automation: AutomationType;
}> = ({ automation }) => (
  <S.Item to={`/automations/${automation.id}/upcoming-orders`}>
    <img src={automation.gift.images?.[0]?.url || GiftPlaceholder} alt='' />
    <S.ItemContent>
      <S.ItemName>{automation.name}</S.ItemName>
      <S.BadgeStyled type='warningSecondary'>
        {`${automation.orders_delivered_count}/${automation.orders_total_count}`}{" "}
        <FormattedMessage id='automations.list.deliveredCountTitle' />
      </S.BadgeStyled>
    </S.ItemContent>
    <S.BadgeStatusStyled
      type={automation.state === "active" ? "successFilled" : "secondary"}
    >
      {StateTitles[automation.state]}
    </S.BadgeStatusStyled>
  </S.Item>
);
