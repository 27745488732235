import React from "react";
import { ButtonBase, ButtonBaseProps, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { getGiftPriceByCountryCode } from "@utils/common";
import TickIcon from "@assets/icons/gift-tick.svg";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import { GiftType, CountryCodeType } from "@types";
import * as S from "./styled";

type GiftProps = {
  gift: GiftType;
  onClick: (data: GiftType) => void;
  selected: boolean;
  disableRipple?: boolean;
  currency?: CountryCodeType;
};

const GiftButton: React.FC<GiftProps & ButtonBaseProps> = ({
  gift,
  onClick,
  selected,
  currency,
  disableRipple,
  ...props
}) => (
  <S.SelectGiftListItemStyled selected={selected}>
    <ButtonBase type='button' onClick={() => onClick(gift)} disableRipple={disableRipple} {...props}>
      <img
        width='56'
        height='56'
        src={gift.images?.[0]?.url || GiftPlaceholder}
        alt=''
      />
      <S.SelectGiftInfoStyled>
        <Typography variant='h5'>{gift.name}</Typography>
        <S.SelectGiftInfoSRowStyled>
          {gift.flags?.is_branded ? (
            <S.SelectGiftFlagStyled>
              <TickIcon /> <FormattedMessage id='gifts.gift.branded' />
            </S.SelectGiftFlagStyled>
          ) : null}
          {gift.flags?.has_handwritten_note ? (
            <S.SelectGiftFlagStyled>
              <TickIcon />
              <FormattedMessage id='gifts.gift.handNote' />
            </S.SelectGiftFlagStyled>
          ) : null}
          {getGiftPriceByCountryCode(gift.prices, currency)}
        </S.SelectGiftInfoSRowStyled>
      </S.SelectGiftInfoStyled>
    </ButtonBase>
  </S.SelectGiftListItemStyled>
);

export default GiftButton;
