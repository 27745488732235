import React, { memo, useEffect, useState } from "react";
import { Chip, Grid, InputAdornment, MenuItem } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Button,
  ButtonRow,
  DatePicker,
  Loader,
  Modal,
  Select,
  TextField,
} from "@components/common";
import { VALIDATION } from "@constants/index";
import { UserProfileType, ContactType, TagType } from "@types";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import RemoveTagIcon from "@assets/icons/remove.svg";
import TagInputIcon from "@assets/icons/tag.svg";
import { useQueryClient } from "react-query";
import RemoveContactIcon from "@assets/icons/bin.svg";
import { useHistory } from "react-router";
import { createTags } from "../../api";
import {
  SubHeadingStyled,
  HeaderStyled,
  FormSubmitStyled,
  TagsDropdownWrapper,
} from "../../styled";
import { useTags, useUpdateContact, useDeleteContact } from "../../hooks";
import { RenderTagsDropdown } from "../AddContact/components/Tags";

interface AddCustomerProps {
  userInfo: UserProfileType;
  contact: ContactType;
}

const EditContact = memo(({ userInfo, contact }: AddCustomerProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [newTags, setNewTags] = useState<Set<string>>(() => new Set());
  const [userTags, setUserTags] = useState<Set<string>>(() => new Set());
  const { data: companyTags } = useTags(userInfo.company_id);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [tagsDirty, setTagsDirty] = useState(false);
  const {
    mutate: updateContact,
    reset,
    isSuccess,
    isError,
    isLoading,
  } = useUpdateContact();

  const { mutate: deleteContact, status: deleteStatus } = useDeleteContact();

  const methods = useForm();

  const {
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    reset: resetForm,
    resetField,
  } = methods;

  const addNewTag = (newTag: string) => {
    setNewTags((tags) => new Set(tags).add(newTag));
    setTagsDirty(true);
  };

  const addUserTag = (newTag: string) => {
    setUserTags((tags) => new Set(tags).add(newTag));
    setTagsDirty(true);
  };

  const handleTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error
    if (e.key === "Enter") {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (!newTag) return;
      if (
        !companyTags.find(
          (tag) => tag.tag_string.toLowerCase() === newTag.toLowerCase()
        )
      ) {
        addNewTag(newTag);
        resetField("tags");
      }
    }
  };

  const handleDeleteTag = (tag: string) => {
    if (newTags.has(tag)) {
      setNewTags((tags) => {
        tags.delete(tag);
        return new Set(tags);
      });
    }
    if (userTags.has(tag)) {
      setUserTags((tags) => {
        tags.delete(tag);
        return new Set(tags);
      });
    }
    setTagsDirty(true);
  };

  const onSubmit = ({
    firstName,
    lastName,
    email,
    phone,
    birthday,
    jobStart,
    external_id,
    language_code,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    birthday: string;
    jobStart: string;
    external_id: string;
    language_code: string;
  }) => {
    if (!isDirty && !tagsDirty) return;
    const usersTagsIds = companyTags
      ?.filter((tag) =>
        [...Array.from(userTags)].some(
          (item) => item.toLowerCase() === tag.tag_string.toLowerCase()
        )
      )
      .map((item) => item.id);

    const formData = {
      first_name: firstName,
      last_name: lastName,
      date_of_birth: birthday,
      email,
      employment_start_date: jobStart,
      phone_number: phone?.replace(/\s/g, ""),
      external_id,
      language_code,
    };

    if (Array.from(newTags).length) {
      createTags({
        company_id: userInfo?.company_id,
        data: Array.from(newTags),
      })
        // @ts-expect-error
        .then((res: TagType[]) => {
          updateContact({
            company_id: userInfo?.company_id,
            contact_id: contact.id,
            data: {
              ...formData,
              tags: [...usersTagsIds, ...res.map((tag: TagType) => tag.id)],
            },
          });
        })
        .catch(() => {
          toast.error(
            intl.formatMessage({
              id: "general.notifications.error",
            })
          );
        });
    } else {
      updateContact({
        company_id: userInfo?.company_id,
        contact_id: contact.id,
        data: {
          ...formData,
          tags: usersTagsIds,
        },
      });
    }
  };

  useEffect(() => {
    if (contact) {
      resetForm({
        firstName: contact.first_name,
        lastName: contact.last_name,
        email: contact.email,
        phone: contact.phone_number,
        birthday: dayjs(contact.date_of_birth),
        jobStart: dayjs(contact.employment_start_date),
        tags: "",
        external_id: contact.external_id,
        language_code: contact.language_code || userInfo.locale,
      });

      if (contact.tags) {
        contact.tags.map((tag) => {
          addUserTag(tag.tag_string);
        });
      }
    }
  }, [contact, resetForm, isSuccess]);

  if (isError) {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    reset();
    setTagsDirty(false);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
      queryClient.invalidateQueries([
        "contact",
        userInfo.company_id,
        contact.id,
      ]);
      queryClient.invalidateQueries(["tags"]);
      queryClient.invalidateQueries(["allTags"]);
      queryClient.invalidateQueries(["contacts"]);
      setTagsDirty(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (deleteStatus === "success") {
      toast.success(
        intl.formatMessage({
          id: "contacts.delete.successMsg",
        })
      );
      queryClient.invalidateQueries(["contacts"]);
      history.push("/people/overview");
    }

    if (deleteStatus === "error") {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      setShowRemoveModal(false);
    }
  }, [deleteStatus]);

  if (!contact) {
    return <Loader />;
  }

  return (
    <>
      <HeaderStyled>
        <Grid
          container
          lg={9}
          xl={6}
          spacing={6}
          justifyContent='space-between'
        >
          <Grid item>
            {/* @ts-expect-error */}
            <SubHeadingStyled component='h3' variant='h3'>
              <FormattedMessage id='contacts.edit.title' />
            </SubHeadingStyled>
          </Grid>
          <Grid item>
            <Button
              startIcon={<RemoveContactIcon />}
              color='primary'
              variant='text'
              onClick={() => {
                setShowRemoveModal(true);
              }}
            >
              <FormattedMessage id='contacts.delete.cta' />
            </Button>
          </Grid>
          {showRemoveModal && (
            <Modal
              show
              stretchCta
              title={intl.formatMessage(
                {
                  id: "contacts.delete.modalTitle",
                },
                {
                  name: `${contact.first_name} ${contact.last_name}`,
                }
              )}
              subTitle={intl.formatMessage({
                id: "contacts.delete.modalText",
              })}
              ctaTitle={intl.formatMessage({
                id: "contacts.delete.cta",
              })}
              cancelTitle={intl.formatMessage({
                id: "general.cancel",
              })}
              maxWidth='xs'
              cta={() => {
                deleteContact({
                  company_id: userInfo.company_id,
                  contact_id: contact.id,
                });
              }}
              handleClose={() => setShowRemoveModal(false)}
              isLoading={deleteStatus === "loading"}
            />
          )}
        </Grid>
      </HeaderStyled>
      <FormProvider {...methods}>
        <form>
          <Grid container lg={9} xl={6} spacing={6}>
            <Grid md={6} lg={6} item>
              <TextField
                name='firstName'
                placeholder={intl.formatMessage({
                  id: "contacts.add.form.firstName",
                })}
                label={intl.formatMessage({
                  id: "contacts.add.form.firstName",
                })}
                error={errors.firstName}
                required
                id='form-input-full-name'
              />
              <TextField
                name='lastName'
                placeholder={intl.formatMessage({
                  id: "contacts.add.form.lastName",
                })}
                label={intl.formatMessage({
                  id: "contacts.add.form.lastName",
                })}
                type='text'
                error={errors.lastName}
                id='form-input-lastName'
                required
              />
              <TextField
                name='email'
                placeholder={intl.formatMessage({
                  id: "input.email.placeholder",
                })}
                type='email'
                label={intl.formatMessage({ id: "input.email.label" })}
                error={errors.email}
                pattern={{
                  value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                  message: intl.formatMessage({
                    id: "input.email.pattern.error",
                  }),
                }}
                id='form-input-email'
              />
              <TextField
                name='phone'
                placeholder={intl.formatMessage({
                  id: "contacts.add.form.phone",
                })}
                label={intl.formatMessage({ id: "contacts.add.form.phone" })}
                type='tel'
                error={errors.phone}
                pattern={{
                  value: VALIDATION.PHONE_VALIDATION_REGEXP,
                  message: intl.formatMessage({
                    id: "input.phone.pattern.error",
                  }),
                }}
                id='form-input-phone'
              />
              <Select
                id='language_code'
                name='language_code'
                label={intl.formatMessage({
                  id: "contacts.add.form.lang",
                })}
              >
                <MenuItem value='en'>
                  <FormattedMessage id='shared.lang.eng' />
                </MenuItem>
                <MenuItem value='sk'>
                  <FormattedMessage id='shared.lang.sk' />
                </MenuItem>
                <MenuItem value='cs'>
                  <FormattedMessage id='shared.lang.cs' />
                </MenuItem>
              </Select>
            </Grid>
            <Grid md={6} lg={6} item>
              <TextField
                name='external_id'
                placeholder={intl.formatMessage({
                  id: "contacts.add.form.externalId",
                })}
                label={intl.formatMessage({
                  id: "contacts.add.form.externalId",
                })}
                type='text'
                error={errors.external_id}
                id='form-input-external_id'
              />
              <DatePicker
                name='birthday'
                label={intl.formatMessage({
                  id: "contacts.add.form.birthday",
                })}
                error={errors.birthday}
                hooked
                disableFuture
                offsetbottom='true'
                {...(contact.date_of_birth && {
                  defaultValue: dayjs(contact.date_of_birth),
                })}
              />
              <DatePicker
                name='jobStart'
                label={intl.formatMessage({
                  id: "contacts.add.form.jobStart",
                })}
                hooked
                {...(contact.employment_start_date && {
                  defaultValue: dayjs(contact.employment_start_date),
                })}
                error={errors.jobStart}
                offsetbottom='true'
              />
              <TagsDropdownWrapper>
                <TextField
                  name='tags'
                  placeholder='Add tag and press Enter'
                  label={intl.formatMessage({
                    id: "contacts.add.form.tags",
                  })}
                  type='text'
                  error={errors.tags}
                  id='form-input-tags'
                  // @ts-expect-error
                  onKeyPress={handleTags}
                  autoComplete='off'
                  offsetbottom='false'
                  startAdornment={
                    <InputAdornment position='start'>
                      <TagInputIcon />
                    </InputAdornment>
                  }
                />
                <RenderTagsDropdown
                  tags={companyTags?.map((item) => item.tag_string)}
                  newTags={newTags}
                  userTags={userTags}
                  value={watch("tags")?.trim()}
                  resetField={resetField}
                  addUserTag={addUserTag}
                  addNewTag={addNewTag}
                />
                {[...Array.from(newTags), ...Array.from(userTags)].map(
                  (tag) => (
                    <Chip
                      deleteIcon={<RemoveTagIcon />}
                      label={tag}
                      key={tag}
                      onDelete={() => handleDeleteTag(tag)}
                    />
                  )
                )}
              </TagsDropdownWrapper>
            </Grid>
          </Grid>
          <FormSubmitStyled>
            <ButtonRow>
              <Button
                disabled={isLoading}
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                type='submit'
              >
                <FormattedMessage id='general.forms.buttons.save' />
              </Button>
            </ButtonRow>
          </FormSubmitStyled>
        </form>
      </FormProvider>
    </>
  );
});

EditContact.displayName = "EditContact";

export default EditContact;
