import styled, { css } from "styled-components";
import { FormControl } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface WrapperProps {
  offsetbottom?: "true" | "false";
  direction?: "column" | "row";
}

export const DatePickerStyled = styled(DatePicker)<{
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
}>`
  > div {
    border: 1px solid;
    border-color: ${({ error, theme }) =>
      error ? theme.palette.error.main : "transparent"};
    border-radius: 12px;
    overflow: hidden;
    background-color: #f8f6f4;
    margin-bottom: 0;

    &.Mui-error {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  }

  input {
    height: 38px;
    line-height: 38px;
    padding: 0 1rem;
    font-family: "Elina", "Helvetica", "Arial", sans-serif;
  }

  fieldset {
    display: none !important;
  }

  label {
    position: relative;
    font-family: "Elina";
    margin-bottom: 6px;
    color: ${({ error, theme }) =>
      error ? theme.palette.error.main : "#263238"};
    display: block;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 10px;
    line-height: 1;
    transform: none !important;
    overflow: visible;

    &.Mui-focused {
      color: ${({ error, theme }) =>
        error ? theme.palette.error.main : "#263238"};
    }
  }
`;

export const WrapperStyled = styled(FormControl)<WrapperProps>`
  > div {
    display: flex;
    ${({ offsetbottom }) =>
      offsetbottom === "true" &&
      `
    margin-bottom: 24px;
  `}
    flex-direction: ${({ direction }) => direction};

    ${({ direction }) =>
      direction === "row" &&
      css`
        align-items: center;

        label {
          white-space: nowrap;
          margin-bottom: 0;
          margin-right: 8px;
        }
      `}
  }

  p {
    font-size: 0.8571428571428571rem;
    margin-bottom: 3px;
  }
`;
