import React, { useCallback, useContext, useState } from "react";
import { Typography } from "@material-ui/core";
import * as S from "./styled";
import { Button } from "..";
import { CompanySwitchModal } from "@ui/src/components/common/AdminMenu/CompanySwitchModal";
import cognitoUser from "../../../../../cognitoId";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthContext } from "@providers/AuthProvider";
import { useCompaniesDetails } from "../../../../account/hooks";

export const CompanyToolbar: React.FC = () => {
  const { user, setUser } = useContext(AuthContext);

  const showSwitchCompany = user?.company_ids?.split(",").length > 1;
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  const { data: companies, isLoading } = useCompaniesDetails(
    user.company_ids?.split(","),
    showModal
  );

  const handleChange = useCallback((id: string) => {
    const attributes = [
      {
        Name: "custom:organization_id",
        Value: id,
      },
    ];

    cognitoUser.getCognitoUser().updateAttributes(attributes, (error) => {
      if (error) {
        toast.error(
          intl.formatMessage({
            id: "general.notifications.error",
          })
        );
        return;
      }

      setUser((state) => ({
        ...state,
        company_id: id,
      }));

      cognitoUser
        .getCognitoUser()
        .getSession((err: Error, session: CognitoUserSession) => {
          if (err) {
            setUser(null);
            return;
          }

          cognitoUser
            .getCognitoUser()
            .refreshSession(session.getRefreshToken(), (sessionErr: Error) => {
              if (sessionErr) {
                setUser(null);
                return;
              }

              toast.success(
                intl.formatMessage({
                  id: "Company was switched",
                })
              );
              setShowModal(false);
            });
        });
    });
  }, []);

  return (
    <S.CompanyToolbar>
      <Typography variant='h4'>{user?.company_name}</Typography>
      {showSwitchCompany && (
        <Button variant='text' disableRipple onClick={() => setShowModal(true)}>
          <FormattedMessage id='Switch company' />
        </Button>
      )}
      <CompanySwitchModal
        user={user}
        showModal={showModal}
        companies={companies}
        setShowModal={setShowModal}
        page={0}
        paging={{
          next_page: null,
          previous_page: null,
          total_pages: 0,
          total_records: 0,
        }}
        withSearch={false}
        cta={handleChange}
        isLoading={isLoading}
      />
    </S.CompanyToolbar>
  );
};
