import styled, { css } from "styled-components";
import { FormControl, Typography } from "@material-ui/core";
import Button from "../Button";

export const FormControlStyled = styled(FormControl)`
  margin-bottom: 24px;
`;

export const TypographyStyled = styled(Typography)`
  margin-bottom: 8px;
  font-size: 13px;
`;

export const ButtonStyled = styled(Button)<{error?: boolean}>`
  display: inline-flex;
  align-self: flex-start;

  ${({error}) => error && css`
    color: ${({ theme }) => theme.palette.error.main};
  `}
`;

export const ButtonClearStyled = styled(Button)`
  margin-left: 8px;
`;

export const ButtonRowStyled = styled.div<{error?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({error}) => error && css`
    color: ${({ theme }) => theme.palette.error.main};
  `}

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  input {
    font-family: inherit;
  }

  + div {
    margin-top: 16px;
  }
`;
