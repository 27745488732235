import React, { useContext, useEffect } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Loader, Select } from "@components/common";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { useUpdateContact, useContact } from "../../hooks";
import { AuthContext } from "@providers/AuthProvider";
import { Checkbox } from "@ui/src/components";
import { useAddresses } from "../../../account/components/Addresses/hooks";
import { Row, Section } from "./styled";

export const AssignToCompanyAddress = () => {
  const intl = useIntl();

  const { user } = useContext(AuthContext);

  const { id } = useParams<{ id: string }>();

  const { data: contact, isLoading: loading1 } = useContact(
    user.company_id,
    id
  );

  const { data: addresses, isLoading: loading2 } = useAddresses(
    user.company_id
  );

  const {
    mutate: updateContact,
    reset,
    isSuccess,
    isError,
    isLoading: loading3,
  } = useUpdateContact();

  const methods = useForm();

  const { handleSubmit, watch, reset: resetForm } = methods;

  const onSubmit = ({
    company_address_id,
    assignToCompany,
  }: {
    company_address_id: string;
    assignToCompany: boolean;
  }) => {
    updateContact({
      company_id: user.company_id,
      contact_id: contact?.id,
      data: {
        company_address_id: assignToCompany ? company_address_id : null,
      },
    });
  };

  useEffect(() => {
    if (contact) {
      resetForm({
        assignToCompany: contact?.company_address_id ? true : false,
        company_address_id:
          contact?.company_address_id || addresses?.items[0]?.id,
      });
    }
  }, [contact, resetForm, addresses]);

  if (isError) {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    reset();
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
    }
  }, [isSuccess]);

  if (!contact) {
    return <Loader />;
  }

  return (
    <Section>
      <FormProvider {...methods}>
        <form>
          <Grid container lg={9} xl={6} spacing={6}>
            <Grid md={6} lg={6} item>
              <Row>
                <Checkbox
                  name='assignToCompany'
                  label={intl.formatMessage({
                    id: "contacts.addresses.assign",
                  })}
                  checked={false}
                />
                <Select
                  id='language_code'
                  name='company_address_id'
                  label={intl.formatMessage({
                    id: "contacts.addresses.select",
                  })}
                  disabled={watch("assignToCompany") === false}
                >
                  {addresses?.items?.map((address) => (
                    <MenuItem value={address.id} key={address.id}>
                      {address.name}
                    </MenuItem>
                  ))}
                </Select>
              </Row>
            </Grid>
          </Grid>
          <Button
            disabled={loading1 || loading2 || loading3}
            loading={loading1 || loading2 || loading3}
            onClick={handleSubmit(onSubmit)}
            type='submit'
          >
            <FormattedMessage id='general.forms.buttons.save' />
          </Button>
        </form>
      </FormProvider>
    </Section>
  );
};
