import en from "./en.json";
import sk from "./sk.json";
import cs from "./cz.json";

const messages = {
  en,
  sk,
  cs,
};

export default messages;
