import dayjs from "dayjs";

type sortByDateFiledProps = <T>(
  array: T[],
  field: keyof T,
  order: "desc" | "asc"
) => T[];

export const sortByDateFiled: sortByDateFiledProps = (
  array,
  field,
  order = "asc"
) => {
  if (!array) return null;
  const sortOrder = order === "desc" ? -1 : 1;
  return [...array].sort((a, b) => {
    const dateA = dayjs(a[field]);
    const dateB = dayjs(b[field]);

    if (dateA.isBefore(dateB)) {
      return -1 * sortOrder;
    }
    if (dateA.isAfter(dateB)) {
      return 1 * sortOrder;
    }
    return 0;
  });
};
