import React from "react";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/common";
import { COMMON } from "@constants/index";
import { formatMoney } from "@utils/common";
import { CircularProgress, Typography } from "@material-ui/core";
import { TransactionType } from "@types";
import OrderProcessImage from "@assets/icons/order-process.png";
import DownloadIcon from "@assets/icons/download.svg";
import * as S from "../styled";

export const WaitingForPaymentComponent = ({
  transaction,
}: {
  transaction: TransactionType;
}) => (
  <S.WaitingForPayment>
    <div>
      <img
        width='122'
        height='126'
        src={OrderProcessImage}
        alt='order processing'
      />
    </div>
    <div>
      <Typography variant='h4'>
        <FormattedMessage id='credit.waiting.message' />
      </Typography>
      <Typography style={{ marginBottom: 0 }}>
        {dayjs(transaction.created_at).format(COMMON.DEFAULT_DATE_FORMAT)}
        {", "}
        <FormattedMessage id='credit.waiting.type' />
        {", "}
        {transaction.order?.id?.toUpperCase() ||
          transaction.topup?.invoices[0]?.id?.toUpperCase()}{" "}
        <FormattedMessage
          id='credit.waiting.amount'
          values={{
            value: formatMoney(transaction.amount),
          }}
        />
      </Typography>
      <div>
        {!transaction.topup?.invoices[0]?.url ? (
          <Button disableFocusRipple variant='contained' color='primary'>
            <CircularProgress color='inherit' size={24} />
          </Button>
        ) : (
          <Button
            variant='contained'
            color='primary'
            disableRipple
            loading={!transaction.topup?.invoices[0]?.url}
            target='_blank'
            href={transaction.topup?.invoices?.[0]?.url}
            startIcon={<DownloadIcon />}
          >
            <FormattedMessage id='credit.download.platforma' />
          </Button>
        )}
      </div>
    </div>
  </S.WaitingForPayment>
);
