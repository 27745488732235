import React, { useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Loader, NoData, Pagination, Table, Error } from "@components/common";
import { TableBody, TableRow, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import EmptyListIcon from "@assets/icons/addresses-empty.png";
import { AuthContext } from "@providers/AuthProvider";
import { sortByDateFiled } from "@utils/sorting";
import { Order } from "../Order";
import { OrdersTableHead } from "../Components/OrdersTableHead";
import { useAutomationDeliveries } from "../../../hooks";
import * as S from "../../styled";
import { AutomationDeliveryDateType, DeliveryDetailsSourceType } from "@types";
import { MissingDetails } from "./MissingDetails";

const today = new Date().toISOString();

export const Upcoming: React.FC = ({
  deliveryDateType,
  deliverySourceType,
}: {
  deliveryDateType: AutomationDeliveryDateType;
  deliverySourceType: DeliveryDetailsSourceType;
}) => {
  const { user } = useContext(AuthContext);
  const intl = useIntl();
  const { automationId } = useParams<{ automationId?: string }>();
  const [paginationPage, setPaginationPage] = useState(1);
  const {
    data: orders,
    isLoading,
    isError,
  } = useAutomationDeliveries(user.company_id, automationId, {
    not_before: today,
    page: paginationPage,
  });

  const sortedOrders = useMemo(
    () => sortByDateFiled(orders?.items, "requested_delivery_datetime", "asc"),
    [orders]
  );

  if (isError) {
    return <Error />;
  }

  if (isLoading) return <Loader />;

  return (
    <S.Container>
      {(deliverySourceType === "contact_custom_address" ||
        deliverySourceType === "contact_office_address") && (
        <MissingDetails automationId={automationId} />
      )}
      <Typography variant='h3'>
        {intl.formatMessage({
          id: "orders.upcoming.title",
        })}
      </Typography>
      {sortedOrders?.length > 0 ? (
        <S.OrderSection>
          <Table>
            <OrdersTableHead type={deliveryDateType} />
            <TableBody>
              {sortedOrders.map((order) => (
                <TableRow key={order.id}>
                  <Order order={order} companyId={user.company_id} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </S.OrderSection>
      ) : (
        <NoData>
          <img width='76' src={EmptyListIcon} alt='' />
          <Typography component='h2' variant='h3'>
            <FormattedMessage id='orders.empty.heading' />
          </Typography>
        </NoData>
      )}
      <Pagination
        page={paginationPage}
        onChange={setPaginationPage}
        total={orders?.paging.total_records}
      />
    </S.Container>
  );
};
