import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, TextField } from "@components/common";
import { toast } from "react-toastify";
import { TopFormStyled, HeadingStyled } from "../../styled";
import cognitoUser from "../../../../../cognitoId";

interface VerifyFormProps {
  setIsEmailVerified: Dispatch<SetStateAction<boolean>>;
}

const VerifyForm: React.FC<VerifyFormProps> = ({ setIsEmailVerified }) => {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = methods;

  const onSubmit = ({ code }: { code: string }) => {
    if (!isDirty) {
      return;
    }

    cognitoUser.getCognitoUser().verifyAttribute("email", code, {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: "settings.account.form.verifySuccess",
          })
        );
        setIsEmailVerified(true);
      },
      onFailure: () => {
        toast.error(
          intl.formatMessage({
            id: "general.notifications.error",
          })
        );
      },
    });

    reset();
    setIsLoading(true);
  };

  return (
    <>
      <HeadingStyled variant='h3'>
        <FormattedMessage id='settings.verifyFormTitle' />
      </HeadingStyled>
      <FormProvider {...methods}>
        <TopFormStyled>
          <TextField
            name='code'
            placeholder={intl.formatMessage({
              id: "input.verifyCode.label",
            })}
            label={intl.formatMessage({
              id: "input.verifyCode.label",
            })}
            error={errors.code}
            required
          />
          <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            <FormattedMessage id='settings.account.form.submit' />
          </Button>
        </TopFormStyled>
      </FormProvider>
    </>
  );
};

export default VerifyForm;
