import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import {
  CompanyAddressType,
  ContactShortType,
  ContactType,
  OrderPayload,
} from "@types";
import CompletedIcon from "@assets/icons/completed.svg";
import CalendarIcon from "@assets/icons/calendar.svg";
import PinIcon from "@assets/icons/map-pin.svg";
import dayjs from "dayjs";
import { COMMON } from "@constants/index";
import * as S from "../../styled";
import { CreateStep4 } from "./Step4";
import { ContactsListModal } from "../Modals/ContactsListModal";
import { formatBoldTag } from "@utils/common";

export const DateAndDelivery: React.FC<{
  companyId: string;
  expanded: boolean;
  invalid: boolean;
  contacts: ContactType[];
  offices?: CompanyAddressType[];
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  payload: OrderPayload | null;
  setPayload: Dispatch<SetStateAction<OrderPayload>>;
  disabled: boolean;
  completed: boolean;
  invalidAddressContacts: ContactShortType[];
}> = ({
  companyId,
  expanded,
  invalid,
  handleShowStepContent,
  offices,
  contacts,
  payload,
  setPayload,
  disabled,
  completed,
  invalidAddressContacts,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [showMissingContactsModal, setShowMissingContactsModal] =
    useState(false);

  const statusMessages = {
    contact_office_address: (
      <FormattedMessage id='automations.create.step4.addressType.variant3' />
    ),
    contact_custom_address: (
      <FormattedMessage id='automations.create.step4.addressType.variant2' />
    ),
    requested_from_recipient: (
      <FormattedMessage id='automations.create.step4.resultFromAskMsg' />
    ),
  };

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {completed && <CompletedIcon />}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='orders.create.step4.title' />
            </Typography>
            {completed && payload?.delivery_details_source_type ? (
              <S.DateAndLocationRow>
                <div>
                  <CalendarIcon />
                  {dayjs(
                    payload.delivery_datetime ||
                      payload.delivery_notification_date
                  ).format(COMMON.DEFAULT_DATE_FORMAT_SHORT)}
                </div>
                <div>
                  <PinIcon />
                  {payload.delivery_details_source_type === "office_address" ? (
                    <>
                      {payload.delivery_address_id &&
                        offices.find(
                          (office) => office.id === payload.delivery_address_id
                        )?.name}
                    </>
                  ) : (
                    statusMessages[payload.delivery_details_source_type]
                  )}
                </div>
              </S.DateAndLocationRow>
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='orders.create.step4.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!expanded && (
          <Button
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(2, true);
            }}
            variant='outlined'
            color='primary'
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='orders.create.step.edit' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>
      {invalidAddressContacts?.length > 0 &&
        (payload.delivery_details_source_type === "contact_custom_address" ||
          payload.delivery_details_source_type ===
            "contact_office_address") && (
          <S.AlertStyled>
            <div>
              <FormattedMessage
                id='automations.create.step4.addressValidationMessage'
                values={{
                  count: invalidAddressContacts?.length,
                  b: formatBoldTag,
                  button: (chunks: string[]) => (
                    <button onClick={() => setShowMissingContactsModal(true)}>
                      {chunks}
                    </button>
                  ),
                }}
              />
            </div>
          </S.AlertStyled>
        )}
      {showMissingContactsModal && (
        <ContactsListModal
          contacts={invalidAddressContacts}
          onClose={() => setShowMissingContactsModal(false)}
        />
      )}
      {expanded && (
        <CreateStep4
          companyId={companyId}
          onClose={() => {
            setInProgress(false);
            handleShowStepContent(2, false);
          }}
          selectedAddress={payload?.delivery_address_id}
          disableAsk={!!payload?.volume}
          contacts={contacts}
          offices={offices}
          setPayload={setPayload}
          payload={payload}
        />
      )}
    </S.CreateCampaignStep>
  );
};
