import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import { TagType, AutomationType } from "@types";
import * as S from "../../../styled";
import { CreateStep1 } from "./Step1";
import { FormType } from "../types";
import { useAutomationContacts } from "../../../../hooks";

type RenderStep1TitlesProps = {
  type: "contacts" | "tag";
  tagName: string;
  selectedContactsCount: number;
};

const RenderStep1Titles = React.memo(
  ({ type, tagName, selectedContactsCount }: RenderStep1TitlesProps) => {
    switch (type) {
      case "contacts":
        return (
          <span>
            <u>
              <FormattedMessage
                id='automations.create.step1.recipients'
                values={{ count: selectedContactsCount }}
                tagName='b'
              />
            </u>
          </span>
        );
      case "tag":
        return (
          <span>
            <FormattedMessage
              tagName='b'
              id='automations.create.step1.allTagsTitle'
            />{" "}
            <FormattedMessage id='automations.create.step1.withTag' />
            <b>{tagName}. </b>
            <u>
              <FormattedMessage
                id='automations.create.step1.recipients'
                values={{ count: selectedContactsCount }}
                tagName='b'
              />
            </u>
          </span>
        );
      default:
        return null;
    }
  }
);

RenderStep1Titles.displayName = "RenderStep1Titles";

export const To: React.FC<{
  companyId: string;
  expanded: boolean;
  completed: boolean;
  // contacts: ContactType[];
  tags: TagType[];
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  automation: AutomationType | null;
  disabled: boolean;
  invalid: boolean;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
}> = ({
  companyId,
  expanded,
  tags,
  handleShowStepContent,
  // contacts,
  automation,
  disabled,
  invalid,
  completed,
  formState,
  setFormState,
}) => {
  const [inProgress, setInProgress] = useState(false);

  const { data: contacts } = useAutomationContacts(companyId, automation.id);

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {/* {completed && <CompletedIcon />} */}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='automations.create.step1.title' />
            </Typography>
            {completed ? (
              <Typography>
                <RenderStep1Titles
                  type={automation.tags.length > 0 ? "tag" : "contacts"}
                  tagName={automation?.tags?.[0]?.tag_string}
                  selectedContactsCount={contacts?.items.length}
                />
              </Typography>
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='automations.create.step1.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!expanded && (
          <Button
            variant='outlined'
            color='default'
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(0, true, false);
            }}
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='automations.create.step.overview' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>

      {expanded && (
        <CreateStep1
          companyId={companyId}
          onClose={() => {
            setInProgress(false);
            handleShowStepContent(0, false, false);
          }}
          tags={tags}
          automation={automation}
          formState={formState}
          setFormState={setFormState}
        />
      )}
    </S.CreateCampaignStep>
  );
};
