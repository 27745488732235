import styled from "styled-components";
import {
  DialogBodyStyled,
  DialogFooterStyled,
} from "@components/common/Modal/styled";

export const Body = styled(DialogBodyStyled)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Footer = styled(DialogFooterStyled)`
  display: flex;
  gap: 8px;

  > button {
    flex: 1 0 auto;
  }
`;
