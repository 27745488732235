import React from "react";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import styled from "styled-components";

const GridStyled = styled(Grid)`
  height: 100vh;
`;

export default function FullPageLoader() {
  return (
    <Container>
      <GridStyled container alignItems='center' justifyContent='center'>
        <CircularProgress />
      </GridStyled>
    </Container>
  );
}
