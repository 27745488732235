import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styled";
import HelpIcon from "@assets/icons/help-circled.svg";

export const Footer = () => (
  <S.Footer>
    <FormattedMessage id='recipient.page.footer.text' />
    {window.smartsupp && (
      <S.HelpButton
        type='button'
        onClick={() => {
          window.smartsupp("chat:open");
        }}
      >
        <HelpIcon />
        <FormattedMessage id='recipient.page.footer.help' />
      </S.HelpButton>
    )}
  </S.Footer>
);
