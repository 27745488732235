import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, MenuItem } from "@material-ui/core";
import { Error, Loader, Radio } from "@components/common";
import { useDispatch } from "react-redux";
import { TagType, AutomationType } from "@types";
import InfoIcon from "@assets/icons/info.svg";
import EyeIcon from "@assets/icons/eye.svg";
import { SelectContactsModal } from "@components/common/Order/Modals/SelectContactsModal";
import * as S from "../../../styled";
import { showModal } from "../../../../../modal/actions";
import { useAutomationContacts } from "../../../../hooks";
import { FormType } from "../types";

type CreateStep1Props = {
  companyId: string;
  onClose: () => void;
  tags: TagType[];
  automation: AutomationType | null;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
};

export const CreateStep1: React.FC<CreateStep1Props> = ({
  companyId,
  onClose,
  tags,
  automation,
  setFormState,
  formState,
}) => {
  const [sendTo, setSendTo] = useState<"contact_id" | "contact_tag">(
    automation?.tags?.length ? "contact_tag" : "contact_id"
  );
  const [selectedTag, setSelectedTag] = useState<string | null>(
    automation?.tags?.length ? automation.tags[0].id : null
  );
  const [selectedTagTitle, setSelectedTagTitle] = useState<string | null>(null);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  const [showContactsModal, setShowContactsModal] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    data: contacts,
    isLoading,
    isError,
  } = useAutomationContacts(companyId, automation.id);

  useEffect(() => {
    if (!automation?.tags?.length && tags) {
      setSelectedTag(tags[0]?.id);
      setSelectedTagTitle(tags[0]?.tag_string);
    }
    if (automation?.tags.length) {
      setSelectedTag(automation.tags[0]?.id);
      setSelectedTagTitle(automation.tags[0]?.tag_string);
    }
  }, []);

  useEffect(() => {
    setFormState((state) => ({
      ...state,
      selectedContacts: contacts?.items,
      selectedContactsCount: contacts?.items?.length,
    }));
  }, [contacts, sendTo, setFormState]);

  if (isError) return <Error />;

  return (
    <S.StepBody>
      <Radio
        value={sendTo}
        defaultValue={sendTo}
        // @ts-expect-error
        onChange={(e) => setSendTo(e.target.value)}
        name='type'
        disabled
        items={[
          {
            value: "contact_id",
            label: intl.formatMessage({
              id: "automations.create.step1.variantContacts",
            }),
          },
          {
            value: "contact_tag",
            label: intl.formatMessage({
              id: "automations.create.step1.variantTag",
            }),
          },
        ]}
      />
      {sendTo === "contact_id" && (
        <div>
          <Button
            color='primary'
            onClick={() => setShowContactsModal(true)}
            variant='outlined'
            disabled
          >
            <FormattedMessage id='automations.create.step1.variantContacts' />
          </Button>
          {showContactsModal && (
            <SelectContactsModal
              ctaHandle={setSelectedContacts}
              selectedContacts={selectedContacts}
              setSelectedContactsList={setFormState}
              companyId={companyId}
              hideModal={() => setShowContactsModal(false)}
            />
          )}
        </div>
      )}
      {sendTo === "contact_id" && formState?.selectedContacts?.length > 0 && (
        <S.InfoTextStyled>
          <Button
            onClick={() => {
              dispatch(
                showModal({
                  type: "CAMPAIGN_CONTACTS_MODAL",
                  data: {
                    title: intl.formatMessage({
                      id: "automations.create.recipients.modal.title",
                    }),
                    contacts: formState?.selectedContacts,
                  },
                })
              );
            }}
            startIcon={<EyeIcon />}
            disableRipple
            disabled={formState?.selectedContacts?.length === 0}
          >
            <u>
              <FormattedMessage
                id='automations.create.step1.recipients'
                values={{
                  count: formState?.selectedContacts?.length,
                }}
              />
            </u>
          </Button>{" "}
          <FormattedMessage id='automations.create.step1.matchCriteria' />
        </S.InfoTextStyled>
      )}
      {sendTo === "contact_tag" && (
        <>
          <S.StepSelectRow>
            <S.SelectStyled
              id='tag'
              name='tag-input'
              label={intl.formatMessage({
                id: "automations.create.step1.selectTagLabel",
              })}
              disabled
              value={selectedTag}
              defaultValue={selectedTag}
              hooked={false}
              // @ts-expect-error
              error={tags.length === 0}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedTag(e.target.value);
                setSelectedTagTitle(
                  tags.find((tag) => tag.id === e.target.value)?.tag_string
                );
              }}
            >
              {tags.length > 0 ? (
                tags.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.tag_string}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value='' selected>
                  <FormattedMessage id='automations.create.step1.noTags' />
                </MenuItem>
              )}
            </S.SelectStyled>
            <S.InfoTextStyled
              component='a'
              variant='h5'
              color='textSecondary'
              href='https://www.google.com'
            >
              <InfoIcon stroke='#A0A0A0' />
              <FormattedMessage id='automations.create.step1.aboutTags' />
            </S.InfoTextStyled>
          </S.StepSelectRow>

          <S.InfoTextStyled>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Button
                  onClick={() => {
                    dispatch(
                      showModal({
                        type: "CAMPAIGN_CONTACTS_MODAL",
                        data: {
                          title: intl.formatMessage({
                            id: "automations.create.recipients.modal.title",
                          }),
                          tag: selectedTagTitle,
                          contacts: formState.selectedContacts,
                          companyId,
                        },
                      })
                    );
                  }}
                  startIcon={<EyeIcon />}
                  disableRipple
                >
                  <u>
                    <FormattedMessage
                      id='automations.create.step1.recipients'
                      values={{ count: formState.selectedContactsCount }}
                    />{" "}
                  </u>
                </Button>
                <FormattedMessage id='automations.create.step1.matchCriteria' />
              </>
            )}
          </S.InfoTextStyled>
        </>
      )}
      <div>
        <Button color='default' variant='outlined' onClick={onClose}>
          <FormattedMessage id='general.close' />
        </Button>
      </div>
    </S.StepBody>
  );
};
