import { Button } from "@components/common";
import React from "react";
import { FormattedMessage } from "react-intl";
import Gleap from "gleap";
import * as S from "./styled";

export const GiftPromo: React.FC = () => (
  <S.GiftStyled className='promo'>
    <div>
      <img
        src='https://memo.thevendry.com/wp-content/uploads/2021/11/BoxFox-Hero.jpeg'
        alt=''
      />
      <S.GiftBodyStyled>
        <h3>
          <FormattedMessage id='gifts.gift.customRequest.title' />
        </h3>
        <p>
          <FormattedMessage id='gifts.gift.customRequest.description' />
        </p>
        <Button
          onClick={() => {
            Gleap.open();
          }}
          disabled={!Gleap.isUserIdentified()}
          variant='outlined'
        >
          <FormattedMessage id='gifts.gift.customRequest.cta' />
        </Button>
      </S.GiftBodyStyled>
      <S.Badge>Custom request</S.Badge>
      <S.RequestIcon />
    </div>
  </S.GiftStyled>
);
