import React, { Dispatch, SetStateAction, useState } from "react";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
  Error,
  Loader,
  Table,
  Modal,
  TextField,
  Pagination,
} from "@components/common";
import { FormattedMessage } from "react-intl";
import { ContactType, AutomationContactsFilterType } from "@types";
import { useContacts } from "../../../../../contacts/hooks";
import { CheckBoxComponentStyled, MenuEmpty } from "./styled";
import { useDebounce } from "@ui/src/utils/useDebounce";

export type FormType = {
  selectedContacts: ContactType[] | null;
  selectedTagId: string | null;
  selectedContactsCount: number;
  toType: AutomationContactsFilterType;
  automationStop: "date" | "manually";
};

interface SelectContactsModalProps {
  ctaHandle: (payload: string[]) => void;
  companyId: string;
  hideModal: () => void;
  selectedContacts: string[];
  setSelectedContactsList: Dispatch<SetStateAction<FormType>>;
}

export const SelectContactsModal: React.FC<SelectContactsModalProps> = ({
  ctaHandle,
  companyId,
  hideModal,
  selectedContacts,
  setSelectedContactsList,
}) => {
  const [selected, setSelected] = useState(
    () => new Set<string>(selectedContacts)
  );
  const [paginationPage, setPaginationPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const searchDebounced = useDebounce(searchValue);
  const [allSelected, setAllSelected] = useState(false);
  const {
    data: contacts,
    isLoading: loading1,
    isError: error1,
  } = useContacts(companyId, paginationPage, null, 10, searchDebounced);

  if (error1) {
    return <Error />;
  }

  return (
    <Modal
      show
      title='Confirm selection'
      handleClose={() => {
        hideModal();
      }}
      cta={() => {
        if (allSelected) {
          ctaHandle(contacts.items.map((contact) => contact.id));
        } else {
          ctaHandle(Array.from(selected));
        }
        hideModal();
      }}
      maxWidth='sm'
      showCloseBtn={false}
    >
      <TextField
        hooked={false}
        direction='row'
        placeholder='Search'
        value={searchValue}
        // @ts-expect-error
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <CheckBoxComponentStyled
                checked={allSelected}
                disabled
                onChange={(e) => {
                  if (e.target.checked) {
                    setAllSelected(true);
                    setSelectedContactsList((state) => ({
                      ...state,
                      selectedContacts: contacts.items,
                      selectedContactsCount: contacts.items.length,
                    }));
                  } else {
                    setAllSelected(false);
                    setSelectedContactsList((state) => ({
                      ...state,
                      selectedContacts: [],
                      selectedContactsCount: 0,
                    }));
                    setSelected(() => new Set());
                  }
                }}
                label=''
                name='name'
                hooked={false}
              />
            </TableCell>
            <TableCell>
              <FormattedMessage id='contacts.list.name' />
            </TableCell>
            <TableCell>
              <FormattedMessage id='contacts.list.email' />
            </TableCell>
            <TableCell>
              <FormattedMessage id='contacts.list.tags' />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* eslint-disable-next-line */}
          {loading1 ? (
            <Loader />
          ) : contacts?.items?.length > 0 ? (
            contacts.items.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>
                  <CheckBoxComponentStyled
                    checked={selected.has(contact.id) || allSelected}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelected((prev) => new Set(prev).add(contact.id));
                        setSelectedContactsList((state) => ({
                          ...state,
                          selectedContacts: [
                            ...state.selectedContacts,
                            contact,
                          ],
                          selectedContactsCount:
                            state.selectedContactsCount + 1,
                        }));
                      } else {
                        setSelected((prev) => {
                          const updated = new Set(prev);
                          updated.delete(contact.id);
                          return new Set(updated);
                        });
                        setSelectedContactsList((state) => ({
                          ...state,
                          selectedContacts: state.selectedContacts.filter(
                            (item) => item.id !== contact.id
                          ),
                          selectedContactsCount:
                            state.selectedContactsCount - 1,
                        }));
                      }
                    }}
                    label=''
                    name='name'
                    hooked={false}
                  />
                </TableCell>
                <TableCell>
                  {contact.first_name} {contact.last_name}
                </TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>
                  {contact.tags.map((tag) => tag.tag_string).join(", ")}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <MenuEmpty>
                  <div>
                    No results for <b>“{searchValue}”</b>
                  </div>
                </MenuEmpty>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        total={contacts?.paging?.total_records}
        page={paginationPage}
        onChange={setPaginationPage}
      />
    </Modal>
  );
};
