import React, { useState, useEffect, PropsWithChildren } from "react";
import { useLocation } from "react-router";
import { Button } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { MenuStyled, ButtonBaseStyled } from "./styled";

interface DropdownProps {
  toggleComponent: React.ReactNode;
  isExpanded?: boolean;
  isActive?: boolean;
  toggleButtonBase?: boolean;
  toggleCallback?: (state: boolean) => void;
  dropdownAlign?: number | "right" | "left" | "center";
  showArrow?: boolean;
  menuOverlapped?: "true" | "false";
}

const Dropdown: React.FC<DropdownProps & PropsWithChildren> = ({
  toggleComponent,
  toggleButtonBase,
  children,
  isExpanded,
  isActive = false,
  toggleCallback,
  dropdownAlign = "right",
  showArrow = true,
  menuOverlapped = "true",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(isExpanded || false);
  const location = useLocation();

  const handleClick = (event: React.FormEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setExpanded(true);
    if (toggleCallback) toggleCallback(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded(false);
    if (toggleCallback) toggleCallback(false);
  };

  useEffect(() => {
    if (isExpanded !== undefined) {
      setExpanded(isExpanded);
    }
  }, [isExpanded]);

  // close menu dropdown on route change
  useEffect(() => {
    setExpanded(false);
    if (toggleCallback) toggleCallback(false);
  }, [location]);

  return (
    <>
      {toggleButtonBase ? (
        <ButtonBaseStyled disableRipple onClick={handleClick}>
          {toggleComponent}
          {showArrow && <ExpandMore color={isActive ? "primary" : "inherit"} />}
        </ButtonBaseStyled>
      ) : (
        <Button onClick={handleClick}>{toggleComponent}</Button>
      )}
      <MenuStyled
        anchorEl={anchorEl}
        keepMounted
        open={expanded}
        onClose={handleClose}
        getContentAnchorEl={null}
        menuoverlapped={menuOverlapped}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: dropdownAlign === "left" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: dropdownAlign,
        }}
      >
        {children}
      </MenuStyled>
    </>
  );
};

export default Dropdown;
