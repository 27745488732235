import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, MenuItem } from "@material-ui/core";
import { Error, Loader, Radio, TextField } from "@components/common";
import { useDispatch } from "react-redux";
import { TagType, OrderPayload } from "@types";
import InfoIcon from "@assets/icons/info.svg";
import EyeIcon from "@assets/icons/eye.svg";
import { SelectContactsModal } from "@components/common/Order/Modals/SelectContactsModal";
import { TagsInfoModal } from "@components/common/Order/Modals/TagsInfoModal";
import * as S from "../../styled";
import { showModal } from "../../../../modal/actions";
import { useContacts } from "../../../../contacts/hooks";
import { FormType } from "../types";

type CreateStep1Props = {
  companyId: string;
  onClose: () => void;
  tags: TagType[];
  setPayload: Dispatch<SetStateAction<OrderPayload>>;
  payload: OrderPayload | null;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
};

export const CreateStep1: React.FC<CreateStep1Props> = ({
  companyId,
  onClose,
  tags,
  setPayload,
  payload,
  setFormState,
  formState,
}) => {
  const [sendTo, setSendTo] = useState<"contacts" | "tag" | "amount">(
    // eslint-disable-next-line
    payload?.volume ? "amount" : payload?.contact_tags ? "tag" : "contacts"
  );
  const [selectedTag, setSelectedTag] = useState<string | null>(
    payload?.contact_tags ? payload.contact_tags[0] : null
  );
  const [selectedTagTitle, setSelectedTagTitle] = useState<string | null>(null);
  const [selectedContacts, setSelectedContacts] = useState<string[]>(
    formState.selectedContacts?.map((contact) => contact.id)
  );
  const [showTagsAbout, setShowTagsAbout] = useState(false);

  const [showContactsModal, setShowContactsModal] = useState(false);
  const [quantity, setQuantity] = useState(payload?.volume?.toString() || "1");
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    data: contacts,
    isLoading,
    isError,
  } = useContacts(companyId, 1, selectedTagTitle);

  const submitDisabled = useMemo(
    () =>
      (tags?.length === 0 && sendTo === "tag") ||
      (!selectedContacts?.length && sendTo === "contacts") ||
      (sendTo === "tag" && contacts?.paging.total_records === 0),
    [tags, sendTo, selectedContacts, contacts]
  );

  const handleSubmit = useCallback(() => {
    setPayload((state) => {
      const newState: OrderPayload = {
        ...state,
        ...(sendTo === "amount" && {
          volume: Number(quantity),
        }),
        ...(sendTo === "amount" &&
          state?.delivery_details_source_type !== "office_address" && {
            delivery_details_source_type: null,
          }),
        ...(sendTo === "contacts" && {
          contacts: selectedContacts,
        }),
        ...(sendTo === "tag" && {
          contact_tags: [selectedTag],
        }),
        type: sendTo === "amount" ? "general" : "personalized",
        ...(sendTo === "tag" && {
          contact_tags: [selectedTag],
          // contacts: contacts.items.map((contact) => contact.id),
        }),
      };
      if (sendTo !== "tag") {
        delete newState.contact_tags;
      }
      if (sendTo !== "amount") {
        delete newState.volume;
      }
      if (sendTo === "amount") {
        delete newState.contacts;
        delete newState.contact_tags;
      }
      return newState;
    });
    setFormState((state) => ({
      ...state,
      ...(sendTo === "contacts" && {
        selectedContactsCount: selectedContacts.length,
      }),
      ...(sendTo === "amount" && {
        selectedContactsCount: null,
      }),
      toType: sendTo,
    }));
    onClose();
  }, [
    onClose,
    quantity,
    selectedContacts,
    selectedTag,
    sendTo,
    setFormState,
    setPayload,
  ]);

  useEffect(() => {
    if (!payload?.contact_tags && tags) {
      setSelectedTag(tags[0]?.id);
      setSelectedTagTitle(tags[0]?.tag_string);
    }
    if (payload?.contact_tags) {
      setSelectedTagTitle(
        tags.find((tag) => tag.id === payload.contact_tags[0]).tag_string
      );
    }
  }, []);

  useEffect(() => {
    if (sendTo === "tag" && contacts) {
      setFormState((state) => ({
        ...state,
        selectedContactsCount: contacts.paging.total_records,
      }));
    }
  }, [contacts, sendTo, setFormState]);

  if (isError) return <Error />;

  return (
    <S.StepBody>
      <Radio
        value={sendTo}
        defaultValue={sendTo}
        // eslint-disable-next-line
        onChange={(e: React.ChangeEvent) => setSendTo(e.target.value)}
        name='type'
        items={[
          {
            value: "contacts",
            label: intl.formatMessage({
              id: "orders.create.step1.variantContacts",
            }),
          },
          {
            value: "tag",
            label: intl.formatMessage({
              id: "orders.create.step1.variantTag",
            }),
          },
          {
            value: "amount",
            label: intl.formatMessage({
              id: "orders.create.step1.variantAmount",
            }),
            disabled:
              payload?.delivery_details_source_type ===
              "requested_from_recipient",
          },
        ]}
      />
      {sendTo === "contacts" && (
        <div>
          <Button
            color='primary'
            onClick={() => setShowContactsModal(true)}
            variant='outlined'
          >
            <FormattedMessage id='orders.create.step1.variantContacts' />
          </Button>
          {showContactsModal && (
            <SelectContactsModal
              ctaHandle={setSelectedContacts}
              selectedContacts={selectedContacts}
              setSelectedContactsList={setFormState}
              companyId={companyId}
              hideModal={() => setShowContactsModal(false)}
            />
          )}
        </div>
      )}
      {sendTo === "contacts" && formState?.selectedContacts?.length > 0 && (
        <S.InfoTextStyled>
          <Button
            onClick={() => {
              dispatch(
                showModal({
                  type: "CAMPAIGN_CONTACTS_MODAL",
                  data: {
                    title: intl.formatMessage({
                      id: "orders.create.recipients.modal.title",
                    }),
                    contacts: formState?.selectedContacts,
                  },
                })
              );
            }}
            startIcon={<EyeIcon />}
            disableRipple
            disabled={formState?.selectedContacts?.length === 0}
          >
            <u>
              <FormattedMessage
                id='orders.create.step1.recipients'
                values={{
                  count: formState?.selectedContacts?.length,
                }}
              />
            </u>
          </Button>{" "}
          <FormattedMessage id='orders.create.step1.matchCriteria' />
        </S.InfoTextStyled>
      )}
      {sendTo === "tag" && (
        <>
          <S.StepSelectRow>
            <S.SelectStyled
              id='tag'
              name='tag-input'
              label={intl.formatMessage({
                id: "orders.create.step1.selectTagLabel",
              })}
              value={selectedTag}
              defaultValue={selectedTag}
              hooked={false}
              disabled={tags.length === 0}
              error={tags.length === 0}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedTag(e.target.value);
                setSelectedTagTitle(
                  tags.find((tag) => tag.id === e.target.value)?.tag_string
                );
              }}
            >
              {tags.length > 0 ? (
                tags.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.tag_string}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value='' selected>
                  <FormattedMessage id='orders.create.step1.noTags' />
                </MenuItem>
              )}
            </S.SelectStyled>
            <S.TagInfoToggle onClick={() => setShowTagsAbout(true)}>
              <InfoIcon stroke='#A0A0A0' />
              <FormattedMessage id='orders.create.step1.aboutTags' />
            </S.TagInfoToggle>
          </S.StepSelectRow>
          {showTagsAbout && (
            <TagsInfoModal hideModal={() => setShowTagsAbout(false)} />
          )}

          <S.InfoTextStyled>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {contacts?.paging.total_records > 0 ? (
                  <Button
                    onClick={() => {
                      dispatch(
                        showModal({
                          type: "CAMPAIGN_CONTACTS_MODAL",
                          data: {
                            title: intl.formatMessage({
                              id: "orders.create.recipients.modal.title",
                            }),
                            tag: selectedTagTitle,
                            companyId,
                          },
                        })
                      );
                    }}
                    startIcon={<EyeIcon />}
                    disableRipple
                  >
                    <u>
                      <FormattedMessage
                        id='orders.create.step1.recipients'
                        values={{ count: contacts?.paging.total_records }}
                      />{" "}
                    </u>
                  </Button>
                ) : (
                  <FormattedMessage id='orders.noContacts' />
                )}
                <FormattedMessage id='orders.create.step1.matchCriteria' />
              </>
            )}
          </S.InfoTextStyled>
        </>
      )}
      {sendTo === "amount" && (
        <div>
          <TextField
            hooked={false}
            value={quantity}
            // @ts-expect-error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuantity(e.target.value)
            }
            type='number'
            label={intl.formatMessage({
              id: "orders.create.step1.selectQuantityLabel",
            })}
            name='quantity'
            direction='row'
            required
            fullWidth={false}
          />
        </div>
      )}
      <div>
        <Button
          variant='contained'
          color='primary'
          disabled={submitDisabled}
          onClick={handleSubmit}
        >
          <FormattedMessage id='general.save' />
        </Button>
        <Button color='secondary' onClick={onClose}>
          <FormattedMessage id='general.cancel' />
        </Button>
      </div>
    </S.StepBody>
  );
};
