import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Header as HeaderComponent } from "@ui/components";
import { AuthContext } from "@providers/AuthProvider";
import { SHOW_SUPPORT_CHAT } from "@utils/common";
import cognitoId from "../../../../cognitoId";
import { CompanyToolbar } from "@components/common/CompanyToolbar";
import Gleap from "gleap";

interface HeaderProps {
  profileFixed: boolean;
}

const MAIN_NAV_LINKS = [
  {
    link: "/gifts",
    icon: "gift",
    locale: "main.nav.gifts",
  },
  {
    link: "/people",
    icon: "people",
    locale: "main.nav.people",
  },
  {
    link: "/orders",
    icon: "clock",
    locale: "main.nav.orders",
  },
  {
    link: "/automations",
    icon: "automation",
    locale: "main.nav.campaigns",
  },
  {
    link: "/settings",
    icon: "settings",
    locale: "main.nav.settings",
  },
];

const Header: React.FC<HeaderProps> = ({ profileFixed }) => {
  const history = useHistory();
  const { user, setUser } = useContext(AuthContext);

  const handleSignOut = () => {
    if (SHOW_SUPPORT_CHAT) {
      Gleap.clearIdentity();
    }
    cognitoId.userSignOut();
    history.push("/");
    setUser(null);
  };

  return (
    <HeaderComponent
      profileFixed={profileFixed}
      handleSignOut={handleSignOut}
      user={user}
      navLinks={MAIN_NAV_LINKS}
      isAdmin={user.is_admin}
    >
      <CompanyToolbar />
    </HeaderComponent>
  );
};

export default Header;
