import CONSTANTS from "@constants/index";
import { CompanyType } from "@types";
import { baseApi } from "@utils/apiService";
import { AxiosPromise } from "axios";
import { QueryKey } from "react-query";

const { API } = CONSTANTS;

export const fetchCompanies = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: CompanyType[] }> =>
  baseApi
    .get(`${API.COMPANIES}`, {
      params: {
        per_page: 30,
        page: queryKey[1],
        search: queryKey[2],
      },
    })
    .then((res) => res.data);
