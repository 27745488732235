import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Loader, TextField, Error } from "@components/common";
import { Button, Grid, Typography, Drawer } from "@material-ui/core";
import { ContactType, OrderPayload } from "@types";
import { toast } from "react-toastify";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Summary } from "@components/common/Order/Summary/Summary";
import * as S from "../styled";
import { To } from "./To";
import { Gift } from "./Gift";
import { DateAndDelivery } from "./DateAndDelivery";
import { usePrefetchCreateOrderData } from "../usePrefetchCreateOrderData";
import { FormType } from "./types";
import { useCreateOrder } from "../../hooks";
import { useContactValidate } from "../../../../api/automation";
import { AuthContext } from "@providers/AuthProvider";
import { useSummaryCalculations } from "../../../../hooks/useSummaryCalculations";
import { PaymentMethod } from "./PaymentMethod";
import { Payment } from "./Payment";

const stepsDefault = [
  {
    show: false,
    completed: false,
  },
  {
    show: false,
    completed: false,
  },
  {
    show: false,
    completed: false,
  },
];

export const CreateOrder: React.FC = () => {
  const { user } = useContext(AuthContext);
  const intl = useIntl();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [orderName, setOrderName] = useState(
    intl.formatMessage({ id: "orders.create.name.default" })
  );
  const [isNameValid, setIsNameValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const { state } = useLocation<{ contact: ContactType }>();
  const { id } = useParams<{ id?: string }>();
  const selectedContacts = state?.contact ? [state.contact] : [];
  const [formState, setFormState] = useState<FormType | null>({
    selectedContacts,
    selectedContactsCount: selectedContacts.length,
    selectedTagId: null,
    toType: "contacts",
  });

  const [payLoad, setPayload] = useState<OrderPayload | null>(null);

  const { isError, isLoading, offices, tags, gifts, contacts } =
    usePrefetchCreateOrderData(user.company_id);

  const {
    mutate: createOrder,
    isLoading: createOrderLoading,
    status,
    reset,
    data,
  } = useCreateOrder();

  const { mutate: validateContacts, data: validationResponse } =
    useContactValidate();

  const [steps, setSteps] = useState(stepsDefault);

  const handleShowStepContent = useCallback(
    (stepKey: number, show: boolean, completed?: boolean) => {
      setSteps((prevState) =>
        prevState.map((step, index) => {
          if (index === stepKey) {
            return {
              completed,
              show,
            };
          }
          return step;
        })
      );
    },
    [setSteps]
  );

  const giftsQuantity = useMemo(() => {
    if (!payLoad) return 0;
    if (payLoad.volume) {
      return payLoad.volume;
    }
    return formState.selectedContactsCount;
  }, [payLoad, formState]);

  const selectedGift = useMemo(() => {
    if (!payLoad || !gifts) return null;
    return gifts.find((gift) => gift.id === payLoad.gift_id);
  }, [payLoad, gifts]);

  const [paymentMethod, setPaymentMethod] = useState<"card" | "credit">(
    selectedGift?.flags.instant_purchase_available ? "card" : "credit"
  );

  const handleSubmit = useCallback(() => {
    if (!orderName) {
      nameInputRef.current.focus();
      setIsNameValid(false);
    }
    if (
      !payLoad ||
      !payLoad.type ||
      !payLoad.gift_id ||
      !payLoad.delivery_details_source_type ||
      !payLoad.gift_note_type ||
      !orderName
    ) {
      setIsSubmitted(true);
      return;
    }
    createOrder({
      company_id: user.company_id,
      data: {
        name: orderName,
        payment_type: paymentMethod === "card" ? "stripe" : "credit_balance",
        ...payLoad,
      },
    });
    setIsSubmitted(true);
  }, [user.company_id, createOrder, orderName, payLoad, paymentMethod]);

  useEffect(() => {
    if (id && gifts) {
      const isValidId = gifts.some((gift) => gift.id === id);
      if (isValidId) {
        setPayload((payload) => ({
          ...payload,
          gift_id: id,
        }));
      }
    }
  }, [id, gifts]);

  useEffect(() => {
    if (
      (payLoad?.contacts?.length || payLoad?.contact_tags?.length) &&
      (payLoad?.delivery_details_source_type === "contact_custom_address" ||
        payLoad?.delivery_details_source_type === "contact_office_address")
    ) {
      validateContacts({
        company_id: user.company_id,
        data: {
          contacts_filter_type: payLoad.contact_tags
            ? "contact_tag"
            : "contact_id",
          ...(payLoad.contacts?.length && {
            contacts: payLoad.contacts,
          }),
          ...(payLoad.contact_tags && {
            tags: payLoad.contact_tags,
          }),
          // contact_date_type: null,
          contact_address_type: payLoad.delivery_details_source_type,
        },
      });
    }
  }, [
    payLoad?.contacts,
    payLoad?.contact_tags,
    payLoad?.delivery_details_source_type,
  ]);

  const invalidAddressContacts = useMemo(() => {
    return (
      validationResponse?.validations.find(
        (item) => item.type === "contact_address_type"
      )?.contacts || []
    );
  }, [validationResponse]);

  const shippingQty = useMemo(() => {
    if (!payLoad?.delivery_details_source_type) return 0;

    if (
      payLoad?.volume &&
      payLoad?.delivery_details_source_type === "office_address"
    )
      return Math.ceil(payLoad?.volume / 10);

    if (payLoad?.delivery_address_id)
      return Math.ceil(formState.selectedContactsCount / 10);

    return payLoad?.volume || formState.selectedContactsCount;
  }, [payLoad]);

  const { total } = useSummaryCalculations({
    giftsQty: giftsQuantity,
    giftPrice:
      selectedGift?.prices?.find(
        (price) => price.country_code === user.currency
      )?.amount || 0,
    vat: selectedGift?.prices[0]?.vat || 0,
    shippingQty: shippingQty,
    freeShipping: selectedGift?.flags?.price_includes_shipping,
  });

  useEffect(() => {
    if (state?.contact) {
      setPayload((payload) => ({
        ...payload,
        contacts: [state.contact.id],
        type: "personalized",
      }));
    }
  }, [state]);

  useEffect(() => {
    if (
      status === "success" &&
      data?.id &&
      !data.errors?.length &&
      !data.payment
    ) {
      toast.success(
        intl.formatMessage({
          id: "orders.create.success",
        })
      );
      reset();
      setPayload(null);
      setIsSubmitted(false);
      history.push({
        pathname: `/orders/details/${data?.id}`,
      });
    }
    if (status === "error" || data?.errors?.length) {
      if (data?.errors?.[0]?.code === "NOT_ENOUGH_BALANCE") {
        toast.error(
          "You don't have enough balance to create this order. Please top up your account."
        );
      }
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      reset();
      setPayload(null);
      setIsSubmitted(false);
      setFormState({
        selectedContacts,
        selectedContactsCount: 0,
        selectedTagId: null,
        toType: "contacts",
      });
    }
  }, [status, intl, history, reset, data]);

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <S.Container>
      <S.FormContainer>
        <S.HeaderTopStyled>
          <Typography variant='h2'>
            <FormattedMessage id='orders.create.title' />
          </Typography>
        </S.HeaderTopStyled>
        <div>
          <TextField
            label={intl.formatMessage({
              id: "orders.create.name",
            })}
            placeholder={intl.formatMessage({
              id: "orders.create.name",
            })}
            value={orderName}
            // @ts-expect-error ignore
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrderName(e.target.value)
            }
            hooked={false}
            error={!isNameValid && !orderName && {}}
            required
            direction='row'
            fullWidth={false}
            offsetbottom='false'
            inputRef={nameInputRef}
          />
        </div>
        <S.DetailsSection>
          <S.CreateCampaignSteps>
            <To
              companyId={user.company_id}
              tags={tags}
              formState={formState}
              setFormState={setFormState}
              handleShowStepContent={handleShowStepContent}
              payload={payLoad}
              setPayload={setPayload}
              expanded={steps[0].show}
              completed={
                !!payLoad?.contacts ||
                !!payLoad?.contact_tags ||
                !!payLoad?.volume
              }
              disabled={steps.find((step) => step.show)?.show}
              invalid={
                isSubmitted &&
                !(payLoad?.contacts || payLoad?.contact_tags || payLoad?.volume)
              }
            />
            <Gift
              companyId={user.company_id}
              gifts={gifts}
              handleShowStepContent={handleShowStepContent}
              setPayload={setPayload}
              expanded={steps[1].show}
              disabled={steps.find((step) => step.show)?.show}
              invalid={
                isSubmitted && (!payLoad?.gift_id || !payLoad?.gift_note_text)
              }
              selectedId={id}
              payload={payLoad}
            />
            <DateAndDelivery
              companyId={user.company_id}
              contacts={contacts}
              offices={offices}
              handleShowStepContent={handleShowStepContent}
              setPayload={setPayload}
              expanded={steps[2].show}
              disabled={steps.find((step) => step.show)?.show}
              completed={!!payLoad?.delivery_details_source_type}
              payload={payLoad}
              invalid={
                isSubmitted &&
                !(
                  payLoad?.delivery_datetime ||
                  payLoad?.delivery_notification_date
                )
              }
              invalidAddressContacts={invalidAddressContacts}
            />
          </S.CreateCampaignSteps>
          <Summary
            giftsQty={giftsQuantity || 0}
            giftPrice={
              selectedGift?.prices?.find(
                (price) => price.country_code === user.currency
              )?.amount || 0
            }
            vat={selectedGift?.prices[0]?.vat || 0}
            shippingQty={shippingQty}
            freeShipping={selectedGift?.flags?.price_includes_shipping}
            paymentMethod={paymentMethod}
          >
            <PaymentMethod
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              disableCard={
                selectedGift && !selectedGift?.flags.instant_purchase_available
              }
            />
          </Summary>
        </S.DetailsSection>

        {paymentMethod === "card" && isSubmitted && (
          <Drawer open anchor='right'>
            <Payment
              clientSecret={data?.payment.stripe_payment_secret}
              orderId={data?.id || "123"}
              amount={data?.payment?.amount}
              reset={() => {
                setIsSubmitted(false);
                reset();
              }}
            />
          </Drawer>
        )}
        <Grid container justifyContent='space-between'>
          <Button variant='text' component={Link} to='/orders'>
            <FormattedMessage id='general.forms.buttons.cancel' />
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={
              createOrderLoading ||
              (paymentMethod === "credit" && total > user.credit_balance)
            }
            onClick={handleSubmit}
          >
            {paymentMethod === "card" ? (
              <FormattedMessage id='orders.card.create.proceed' />
            ) : (
              <FormattedMessage id='orders.create.title' />
            )}
          </Button>
        </Grid>
      </S.FormContainer>
    </S.Container>
  );
};
