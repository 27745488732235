import { Button, Loader, TextField } from "@components/common";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { formatMoney } from "@utils/common";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "@constants/index";
import { ClickAwayListener } from "@material-ui/core";
import ContactIcon from "@assets/icons/user.svg";
import EmptyListIcon from "@assets/icons/sad.svg";
import DollarIcon from "@assets/icons/dollar.svg";
import GiftIcon from "@assets/icons/gift.svg";
import { AuthContext } from "@providers/AuthProvider";
import { useSearchContacts } from "../../../hooks";
import { useCompanyDetails } from "../../../../account/hooks";
import * as S from "./styled";
import { showModal } from "../../../../modal/actions";
import { useDebounce } from "@ui/src/utils/useDebounce";

const SearchEmpty = ({ searchValue }: { searchValue: string }) => (
  <S.MenuEmpty>
    <EmptyListIcon />
    <div>
      <div>
        <FormattedMessage id='general.noResults' /> <b>“{searchValue}”</b>
      </div>
      <Button
        variant='outlined'
        component={Link}
        to={ROUTES.CUSTOMERS_ADD_FORM}
      >
        <FormattedMessage id='contacts.main.add' />
      </Button>
    </div>
  </S.MenuEmpty>
);

export const Header: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { data: companyDetails, isSuccess } = useCompanyDetails(
    user.company_id
  );
  const location = useLocation();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue);
  const { data: searchedContacts, isLoading } = useSearchContacts(
    user.company_id,
    debouncedValue?.trim()
  );
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    setMenuExpanded(true);
  }, [searchedContacts]);

  useEffect(() => {
    setMenuExpanded(false);
    setSearchValue("");
  }, [location]);

  return (
    <S.HeaderStyled>
      <S.SearchContainer>
        <ClickAwayListener onClickAway={() => setMenuExpanded(false)}>
          <div>
            <TextField
              hooked={false}
              // @ts-expect-error
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
              onFocus={() => setMenuExpanded(true)}
              value={searchValue}
              name='search'
              type='search'
              placeholder={intl.formatMessage({
                id: "header.search.placeholder",
              })}
              offsetbottom='false'
              rounded='true'
            />
            {menuExpanded && searchValue?.length > 2 && (
              <S.MenuListStyled>
                {/* eslint-disable-next-line */}
                {isLoading ? (
                  <Loader />
                ) : searchedContacts?.items.length > 0 ? (
                  searchedContacts.items.map((contact) => (
                    <S.MenuItemStyled
                      key={contact.id}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Link to={`/people/${contact.id}/history`}>
                        <ContactIcon viewBox='0 0 42 42' />
                        {contact.first_name} {contact.last_name}
                        <S.TagsStyled>
                          {contact.tags.map((tag, key) =>
                            key === contact.tags.length - 1
                              ? tag.tag_string
                              : `${tag.tag_string}, `
                          )}
                        </S.TagsStyled>
                      </Link>
                    </S.MenuItemStyled>
                  ))
                ) : (
                  <SearchEmpty searchValue={searchValue} />
                )}
              </S.MenuListStyled>
            )}
          </div>
        </ClickAwayListener>
      </S.SearchContainer>
      <S.Credit>
        <DollarIcon />
        <span>
          <FormattedMessage
            id='header.credit.title'
            values={{
              credit: formatMoney(
                isSuccess ? companyDetails?.credit_balance : 0
              ),
            }}
          />
        </span>
        <Button
          onClick={() => {
            dispatch(
              showModal({
                type: "ADD_CREDIT_MODAL",
                data: {
                  title: intl.formatMessage({
                    id: "credit.modal.title",
                  }),
                  ctaTitle: intl.formatMessage({
                    id: "credit.modal.submit",
                  }),
                  user,
                  companyDetails,
                },
              })
            );
          }}
          disableTouchRipple
          variant='contained'
          color='secondary'
        >
          <FormattedMessage id='header.credit.add' />
        </Button>
      </S.Credit>
      <Button startIcon={<GiftIcon />} component={Link} to='/orders/create'>
        <FormattedMessage id='header.send.gift' />
      </Button>
    </S.HeaderStyled>
  );
};
