import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";
import BackIcon from "@assets/icons/back.svg";

const LinkBackStyled = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 30px;
  padding-top: 1rem;
  color: #000;
`;

interface LinkBackProps extends LinkProps {
  children: React.ReactNode;
}

const LinkBack: React.FC<LinkBackProps> = ({ children, to, ...props }) => (
  <LinkBackStyled to={to} {...props}>
    <BackIcon />
    {children}
  </LinkBackStyled>
);

export default LinkBack;
