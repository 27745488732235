import React, { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import CardLoadingImage from "@assets/icons/card-loading.png";
import * as S from "./styled";
import { Typography } from "@material-ui/core";
import { Alert, Button } from "@components/common";
import { FormattedMessage } from "react-intl";
import { formatMoney } from "@utils/common";
import { AuthContext } from "@providers/AuthProvider";

export const CheckoutForm: React.FC<{
  orderId: string;
  amount: number;
  reset: () => void;
}> = ({ orderId, amount, reset }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showLoader, setShowLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          toast.success("Your payment was successful.");
          break;
        case "processing":
          break;
        case "requires_payment_method":
          break;
        default:
          toast.error("An unknown error occurred.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/orders/details/${orderId}?payment=success`,
      },
    });

    if (error.type === "validation_error") {
      setIsLoading(false);
      return;
    }
    if (error.type === "card_error") {
      toast.error(error.message);
      setError(true);
    } else {
      toast.error("An unknown error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "accordion" as const,
  };

  return (
    <>
      {showLoader && (
        <S.LoadingWrapper>
          <S.LoadingContainer>
            <img width='129' src={CardLoadingImage} alt='' />
            <Typography variant='h6'>
              <FormattedMessage id='orders.card.create.loading' />
            </Typography>
          </S.LoadingContainer>
        </S.LoadingWrapper>
      )}
      <Typography variant='h6'>
        <FormattedMessage id='orders.card.create.form.title' />
      </Typography>
      <form id='payment-form' onSubmit={handleSubmit}>
        <PaymentElement
          id='payment-element'
          options={paymentElementOptions}
          onReady={() => setShowLoader(false)}
        />
      </form>
      <Button
        variant='contained'
        color='primary'
        disabled={isLoading || !stripe || !elements}
        id='submit'
        onClick={handleSubmit}
      >
        <FormattedMessage
          id='orders.card.create.submit'
          values={{
            amount: formatMoney(amount),
          }}
        />
      </Button>
      {error && (
        <Alert variant='warning'>
          <div>
            <Typography variant='body1'>
              <FormattedMessage id='orders.card.create.form.err' />
            </Typography>
          </div>
        </Alert>
      )}
      <S.Bottom style={{ marginBottom: user.is_admin ? "48px" : "0" }}>
        <Button
          variant='text'
          color='secondary'
          type='button'
          onClick={() => reset()}
        >
          <FormattedMessage id='orders.card.create.cancel' />
        </Button>
      </S.Bottom>
    </>
  );
};
