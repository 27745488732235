import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { OrderPayload, OrderPaymentPayload, OrderType, DeliveryType } from "@types";
import { QueryKey } from "react-query";

const { API } = CONSTANTS;

const today = new Date().toISOString();

export const fetchOrders = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: OrderType[] }> => {
  const params: { [key: string]: string } = {};

  if (queryKey[2] === "upcoming") {
    params.not_before = today;
  } else {
    params.not_after = today;
  }

  // @ts-expect-error
  // eslint-disable-next-line
  params.page = queryKey[3];

  return baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/orders`, {
      params,
    })
    .then((res) => res.data);
};

export const fetchOrderDeliveries = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: DeliveryType[] }> => {
  return baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/orders/${queryKey[2]}/deliveries`, {
      params: queryKey[3],
    })
    .then((res) => res.data);
};

export const fetchOrder= ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<OrderType> => {
  return baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/orders/${queryKey[2]}`)
    .then((res) => res.data?.items?.[0]);
};

export const createOrder = ({
  company_id,
  data,
}: {
  company_id: string;
  data: OrderPayload;
}): Promise<OrderPayload & OrderPaymentPayload & {
    errors: [
      {
        code: "NOT_ENOUGH_BALANCE";
        message: "Not enough balance to perform the selected operation. Current balance is 1405, requested amount is 123200.";
      }
    ];
  }
> =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/orders`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteOrder = ({
  company_id,
  order_id,
}: {
  company_id: string;
  order_id: string;
}): Promise<OrderType> =>
  baseApi
    .delete(`${API.COMPANIES}/${company_id}/orders/${order_id}`)
    .then((res) => res.data);
