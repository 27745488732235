import { UseQueryResult, useQuery } from "react-query";
import { ContactType, PagingType } from "@types";
import { searchContacts } from "./api";

export const useSearchContacts = (
  companyId: string,
  query: string,
  alwaysSearch?: boolean
): UseQueryResult<{
  items: ContactType[];
  paging: PagingType;
}> => useQuery(
    ["contactSearch", companyId, query, alwaysSearch],
    searchContacts,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: alwaysSearch || (!!companyId && query.length > 2),
    }
  );
