import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { DeliveryType, OrderStateType, BadgeTypes } from "@types";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";
import CancelIcon from "@assets/icons/remove.svg";
import EditIcon from "@assets/icons/edit.svg";
import ProfileIcon from "@assets/icons/profile.svg";
import { Tooltip } from "@material-ui/core";
import * as S from "./styled";
import { CancelModal } from "./CancelModal";
import { EditNoteModal } from "./EditNoteModal";
import ClockIcon from "@assets/icons/clock.svg";

const NoteTooltip = ({ onClick }: { onClick: () => void }) => (
  <Tooltip title='Has customised note' placement='top'>
    <IconButton onClick={onClick}>
      <EditIcon />
    </IconButton>
  </Tooltip>
);

export const Order: React.FC<{
  order: DeliveryType;
  companyId: string;
  missingInfo?: boolean;
}> = ({ order, companyId, missingInfo }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showCtaModal, setShowCtaModal] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const orderStatusTitle: { [K in OrderStateType]: string } = {
    scheduled: intl.formatMessage({
      id: "order.status.title.scheduled",
    }),
    confirmed: intl.formatMessage({
      id: "order.status.title.confirmed",
    }),
    fulfillment_in_progress: intl.formatMessage({
      id: "order.status.title.inProgress",
    }),
    delivered: intl.formatMessage({
      id: "order.status.title.delivered",
    }),
    canceled: intl.formatMessage({
      id: "order.status.title.canceled",
    }),
    draft: intl.formatMessage({
      id: "order.status.title.draft",
    }),
  };

  const statusBadge: { [K in OrderStateType]: BadgeTypes } = {
    scheduled: "default",
    confirmed: "primary",
    fulfillment_in_progress: "warning",
    delivered: "success",
    canceled: "danger",
    draft: "secondary",
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <TableCell>
        {dayjs(order.scheduled_for_date || order.created_at).format(
          DEFAULT_DATE_FORMAT_SHORT
        )}
      </TableCell>
      <TableCell>
        <span style={{ textTransform: "uppercase" }}>
          {order.order.readable_id}
        </span>
      </TableCell>
      <TableCell>
        <S.BadgeStyled type={statusBadge[order.order.state]}>
          {order.order.state === "scheduled" && <ClockIcon />}
          {orderStatusTitle[order.order.state]}
        </S.BadgeStyled>
      </TableCell>
      <TableCell>
        {order.order.name}{" "}
        {order.custom_gift_note_text ? (
          <NoteTooltip onClick={() => setShowEditNoteModal(true)} />
        ) : (
          ""
        )}
      </TableCell>
      <TableCell>
        <IconButton onClick={handleClick}>
          <MoreHoriz />
        </IconButton>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {/* <MenuItem disabled>
            <EyeIcon /> View order
          </MenuItem> */}
          {!missingInfo && (
            <MenuItem
              onClick={() => {
                setShowEditNoteModal(true);
                handleClose();
              }}
              disabled={order.state !== "scheduled" && order.state !== "draft"}
            >
              <EditIcon />
              <FormattedMessage id='orders.list.editNote' />
            </MenuItem>
          )}
          {missingInfo && (
            <MenuItem
              onClick={() => {
                window.open(
                  `${window.location.origin}/people/${order.contact.id}/overview`
                );
                handleClose();
              }}
            >
              <ProfileIcon />
              <FormattedMessage id='orders.list.updateLink' />
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setShowCtaModal(true);
              handleClose();
            }}
            disabled={order.state !== "scheduled" && order.state !== "draft"}
          >
            <CancelIcon />
            <FormattedMessage id='orders.list.cancel' />
          </MenuItem>
        </Menu>
        {showCtaModal && (
          <CancelModal
            companyId={companyId}
            order={order.order}
            handleClose={() => setShowCtaModal(false)}
          />
        )}
        {showEditNoteModal && (
          <EditNoteModal
            companyId={companyId}
            order={order.order}
            note={order?.custom_gift_note_text}
            handleClose={() => setShowEditNoteModal(false)}
          />
        )}
      </TableCell>
    </>
  );
};
