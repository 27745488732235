import { createTheme } from "@material-ui/core/styles";
import elinaLight from "../assets/fonts/elina/elina-light.woff2";
import elinaRegular from "../assets/fonts/elina/elina-regular.woff2";
import elinaSemibold from "../assets/fonts/elina/elina-semi-bold.woff2";
import elinaBold from "../assets/fonts/elina/elina-bold.woff2";

const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      main: "#E53761",
      light: "#000000",
      dark: "#E53761",
    },
    secondary: {
      main: "#E72076",
    },
    text: {
      primary: "#000000",
      secondary: "#A0A0A0",
    },
    error: {
      main: "#E53761",
    },
    grey: {
      50: "#F8F6F4", // butter
      100: "#E3E0DD",
      200: "#B9B9B9",
      300: "#ececec",
      400: "#c4c4c4",
    },
  },
  typography: {
    fontFamily: '"Elina", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontWeight: 700,
      fontSize: 44,
    },
    h2: {
      fontWeight: 700,
      fontSize: 28,
    },
    h3: {
      fontWeight: 700,
      fontSize: 22,
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
    },
    h5: {
      fontWeight: 600,
      fontSize: 14,
    },
    h6: {
      fontWeight: 700,
      fontSize: 18,
    },
    button: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "0.875rem",
      marginBottom: "16px",
    },
    subtitle2: {
      fontWeight: 600,
      marginBottom: "8px",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "Elina",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `url(${elinaRegular}) format('woff2')`,
          },
          {
            fontFamily: "Elina",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 300,
            src: `url(${elinaLight}) format('woff2')`,
          },
          {
            fontFamily: "Elina",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 500,
            src: `url(${elinaSemibold}) format('woff2')`,
          },
          {
            fontFamily: "Elina",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 700,
            src: `url(${elinaBold}) format('woff2')`,
          },
        ],
      },
    },
    MuiButton: {
      contained: {
        height: 42,
        borderRadius: 36,
        boxShadow: "none",
        fontWeight: 500,
        fontSize: "0.925rem",
        textTransform: "none",
      },
      outlined: {
        height: 42,
        borderRadius: 36,
        boxShadow: "none",
        fontWeight: 500,
        fontSize: "0.925rem",
        textTransform: "none",
      },
      textSecondary: {
        fontWeight: 500,
        color: "#A0A0A0",
      },
      textPrimary: {
        fontWeight: 500,
        height: "auto",
        padding: 0,

        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      containedSecondary: {
        backgroundColor: "#FFD575",
        color: "#E53761",

        "&:hover": {
          backgroundColor: "#FFD575",
        },
      },
      text: {
        height: 42,
        borderRadius: 36,
        boxShadow: "none",
        fontWeight: 400,
        fontSize: "0.925rem",
        textTransform: "none",
        padding: "0 16px",
      },
      containedSizeLarge: {
        height: 48,
        fontSize: 18,
      },
    },
    MuiInput: {
      root: {
        margin: 0,
        position: "relative",
        backgroundColor: "#F8F6F4",
        height: "40px",
        borderRadius: "12px",
        borderWidth: "1px",
        borderColor: "transparent",
        borderStyle: "solid",
        boxShadow: "none",
        padding: "0 12px",

        "&::placeholder": {
          color: "#A0A0A0",
        },
      },
      multiline: {
        padding: "12px 16px",
        minHeight: "auto",
        height: "auto",
      },
      input: {
        fontSize: "1rem",
        lineHeight: "38px",
        height: "38px",
        padding: "0 4px",
      },
    },
    MuiFilledInput: {
      root: {
        marginBottom: 0,
        position: "relative",
        backgroundColor: "#F4F4F4",
        height: "42px",
        padding: "0 12px",
        borderRadius: "4px",
        borderWidth: "1px",
        borderColor: "transparent",
        borderStyle: "solid",
        boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
      },
      input: {
        fontSize: "1rem",
        lineHeight: "1rem",
        padding: "0 4px",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
        color: "#263238",
        marginBottom: 10,
        fontWeight: 500,
        display: "inline-flex",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 0,
      },
      label: {
        marginBottom: 0,
      },
    },
    MuiCheckbox: {
      root: {
        color: "#F8F6F4",
        borderRadius: 4,
      },
      colorPrimary: {
        color: "#F8F6F4",
      },
    },
    MuiTable: {
      root: {
        position: "relative",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        fontSize: 15,
        paddingTop: 10,
        paddingBottom: 10,
      },
      head: {
        borderBottom: 0,
        fontSize: 14,
      },
    },
    MuiTableRow: {
      root: {
        border: "1px solid #ECECEC",
      },
      head: {
        border: 0,
      },
    },
    MuiSelect: {
      root: {
        padding: 0,
      },
      selectMenu: {
        height: "40px",
        width: "100%",
      },
      select: {
        border: "1px solid #5850EC",
        color: "#5850EC",
        borderRadius: "12px",
        lineHeight: "40px",
      },
    },
    MuiMenu: {
      paper: {
        minWidth: "200px",
        border: "1px solid #ececec",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      },
    },
    MuiPaper: {
      root: {},
      elevation1: {
        border: "1px solid #ECECEC",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: 12,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "12px",
        boxShadow: "none",
      },
      paperWidthSm: {
        maxWidth: 640,
      },
      paperScrollPaper: {
        maxHeight: "calc(100% - 104px)!important",
      },
    },
    MuiMenuItem: {
      root: {
        marginBottom: 0,
        display: "flex",
        gap: "8px",
      },
    },
    MuiChip: {
      root: {
        borderRadius: 8,
        fontWeight: 400,
        fontSize: 13,
        margin: 4,
        backgroundColor: "#FEE6CD",
        color: "#000",
      },
      label: {
        paddingLeft: 6,
        paddingRight: 8,
      },
      deleteIcon: {
        width: 16,
        height: 16,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(190, 190, 190, 0.4)",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 16,
      },
      fontSizeLarge: {
        fontSize: 20,
      },
    },
  },
});

export default theme;
