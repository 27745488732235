import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SectionNav } from "@components/common";
import { Button, Typography } from "@material-ui/core";
import { Link, Route, useLocation } from "react-router-dom";
import { Past } from "./Past";
import { Upcoming } from "./Upcoming";
import * as S from "../styled";

export const OrderList: React.FC = () => {
  const intl = useIntl();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const status = params.get("status") || "upcoming";

  const Component = status === "upcoming" ? Upcoming : Past;

  return (
    <S.Container>
      <S.SubHeading>
        <S.HeaderTopStyled>
          <Typography variant='h2'>
            {intl.formatMessage({
              id: "orders.title",
            })}
          </Typography>
          <Button
            to='/orders/create'
            variant='outlined'
            color='primary'
            component={Link}
          >
            <FormattedMessage id='orders.empty.button' />
          </Button>
        </S.HeaderTopStyled>
        <SectionNav
          navItems={[
            {
              title: "orders.upcoming.title",
              link: `/orders/list?status=upcoming`,
              isActive: status === "upcoming",
            },
            {
              title: "orders.past.title",
              link: `/orders/list?status=past`,
              isActive: status === "past",
            },
          ]}
        />
      </S.SubHeading>
      <Route exact component={Component} path='/orders/list' />
    </S.Container>
  );
};
