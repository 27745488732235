import { DeliverySlipDetails, SetDeliveryAddressPayloadType } from "@types";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import { getDeliverySlipDetails, setDeliverySlipAddress } from "./api";

export const useDeliverySlipDetails = (
  company_id: string,
  delivery_slip_id: string
): UseQueryResult<
  DeliverySlipDetails & {
    errors?: Array<{
      code: string;
      message: string;
    }>;
  }
> =>
  useQuery(
    ["deliverySlipDetails", company_id, delivery_slip_id],
    getDeliverySlipDetails,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!company_id,
    }
  );

export const useSetDeliverySlipAddress = () =>
  useMutation({
    mutationFn: async ({
      company_id,
      delivery_slip_id,
      data,
    }: {
      company_id: string;
      delivery_slip_id: string;
      data: SetDeliveryAddressPayloadType;
    }) =>
      setDeliverySlipAddress({
        company_id,
        delivery_slip_id,
        data,
      }),
  });
