import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as S from "./styled";

import { CheckoutForm } from "./CheckoutForm";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import CardLoadingImage from "@assets/icons/card-loading.png";

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

export const Payment: React.FC<{
  orderId: string;
  clientSecret: string;
  amount: number;
  reset: () => void;
}> = ({ orderId, clientSecret, amount, reset }) => {
  const appearance = {
    theme: "stripe" as const,
  };

  const options = {
    clientSecret,
    appearance,
  };

  if (!process.env.STRIPE_PUBLIC_KEY) {
    return <div>Stripe public key is missing</div>;
  }

  return (
    <S.Container>
      {!clientSecret ? (
        <S.LoadingWrapper>
          <S.LoadingContainer>
            <img width='129' src={CardLoadingImage} alt='' />
            <Typography variant='h6'>
              <FormattedMessage id='orders.card.create.loading' />
            </Typography>
          </S.LoadingContainer>
        </S.LoadingWrapper>
      ) : (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm orderId={orderId} amount={amount} reset={reset} />
        </Elements>
      )}
    </S.Container>
  );
};
