import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { QueryKey } from "react-query";
import { RequestTopupType } from "@types";

const { API } = CONSTANTS;

export const getCompanyTransactions = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/transactions`, {
      params: queryKey[2],
    })
    .then((res) => res.data);

export const generateTransactionExport = ({
  company_id,
  data,
}: {
  company_id: string;
  data: {
    period_month?: number;
    period_year?: number;
    not_after?: string;
    not_before?: string;
    transaction_type: "all" | "debit" | "credit";
    format_type: "csv";
  };
}): AxiosPromise<{ fileName: string; file: File }> =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/transactions/exports`, data, {
      responseType: "blob",
    })
    .then((res) => {
      const filename = res?.headers["content-disposition"]
        ?.split("filename=")[1]
        ?.split(".")[0];
      const extension = res?.headers["content-disposition"]
        ?.split(".")[1]
        ?.split(";")[0];

      return {
        fileName: `${filename}.${extension}`,
        file: res.data,
        ...res,
      };
    });

export const requestPopup = ({
  company_id,
  data,
}: {
  company_id: string;
  data: RequestTopupType;
}): AxiosPromise =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/topups`, data)
    .then((res) => res.data);

export const cancelTopUp = ({
  company_id,
  topup_id,
}: {
  company_id: string;
  topup_id: string;
}): AxiosPromise =>
  baseApi
    .patch(`${API.COMPANIES}/${company_id}/topups/${topup_id}`, {
      state: "canceled",
    })
    .then((res) => res.data);
