import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Loader, Select, Error } from "@components/common";
import { MenuItem, Typography } from "@material-ui/core";
import { AutomationType } from "@types";
import { useParams } from "react-router";
import * as S from "../../styled";
import { To } from "./To";
import { Gift } from "./Gift";
import { DateAndDelivery } from "./DateAndDelivery";
import { usePrefetchCreateOrderData } from "../../usePrefetchCreateOrderData";
import { FormType } from "./types";
// hooks
import { useAutomation } from "../../../hooks";
import { AdminSettings } from "./AdminSettings";

export const Settings: React.FC<{
  companyId: string;
}> = ({ companyId }) => {
  const intl = useIntl();
  const { automationId } = useParams<{ automationId: string }>();
  const [payLoad, setPayload] = useState<AutomationType>(null);
  const [formState, setFormState] = useState<FormType | null>({
    selectedContacts: [],
    selectedContactsCount: 0,
    selectedTagId: null,
    toType: "contact_id",
    automationStop: "date",
  });

  const { isError, isLoading, offices, tags, gifts, contacts } =
    usePrefetchCreateOrderData(companyId);

  const { data: automation, isLoading: automationLoading } = useAutomation(
    companyId,
    automationId
  );

  const [steps, setSteps] = useState([
    {
      show: false,
      completed: false,
    },
    {
      show: false,
      completed: false,
    },
    {
      show: false,
      completed: false,
    },
    {
      show: false,
      completed: false,
    },
  ]);

  const handleShowStepContent = useCallback(
    (stepKey: number, show: boolean, completed?: boolean) => {
      setSteps((state) =>
        state.map((step, index) => {
          if (index === stepKey) {
            return {
              completed,
              show,
            };
          }
          return step;
        })
      );
    },
    [setSteps]
  );

  useEffect(() => {
    if (automation) {
      setPayload((payload) => ({
        ...payload,
        gift_id: automation.gift.id,
        gift: automation.gift,
        contact_filter_type: automation.contact_filter_type,
      }));
    }
  }, [automation]);

  if (isError) {
    return <Error />;
  }

  if (isLoading || automationLoading || !automation) {
    return <Loader />;
  }

  return (
    <S.Container>
      <Typography variant='h4'>
        <FormattedMessage id='automations.nav.settings' />
      </Typography>
      <S.FormContainer>
        <S.FormHeroField>
          <Typography variant='h5'>
            <FormattedMessage id='automations.create.contactDateFieldQuestion' />
          </Typography>
          <Select
            value={automation?.delivery_date_type}
            name='contact-date-field'
            id='contactDateField'
            label={intl.formatMessage({
              id: "automations.create.contactDateField",
            })}
            hooked={false}
            disabled
          >
            <MenuItem value='birthday'>
              <FormattedMessage id='automations.create.contactDateField.option1' />
            </MenuItem>
            <MenuItem value='anniversary'>
              <FormattedMessage id='automations.create.contactDateField.option2' />
            </MenuItem>
          </Select>
        </S.FormHeroField>
        <S.DetailsSection>
          <S.CreateCampaignSteps>
            <To
              companyId={companyId}
              tags={tags}
              formState={formState}
              setFormState={setFormState}
              handleShowStepContent={handleShowStepContent}
              automation={automation}
              expanded={steps[0].show}
              completed
              disabled={steps.find((step) => step.show)?.show}
              invalid={false}
            />
            <Gift
              companyId={companyId}
              gifts={gifts}
              handleShowStepContent={handleShowStepContent}
              expanded={steps[1].show}
              disabled={steps.find((step) => step.show)?.show}
              invalid={false}
              payload={automation}
            />
            <DateAndDelivery
              companyId={companyId}
              contacts={contacts}
              offices={offices}
              handleShowStepContent={handleShowStepContent}
              expanded={steps[2].show}
              disabled={steps.find((step) => step.show)?.show}
              completed
              payload={automation}
              invalid={false}
            />
            <AdminSettings
              companyId={companyId}
              contacts={contacts}
              offices={offices}
              handleShowStepContent={handleShowStepContent}
              expanded={steps[3].show}
              disabled={steps.find((step) => step.show)?.show}
              completed
              payload={automation}
              invalid={false}
              formState={formState}
              setFormState={setFormState}
            />
          </S.CreateCampaignSteps>
        </S.DetailsSection>
      </S.FormContainer>
    </S.Container>
  );
};
