import React from "react";
import { Dialog, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import * as S from "./styled";
import ModalRoot from "../../ModalRoot";

export default function SimpleModal({
  type,
  hideModal,
  ...props
}: {
  type: string;
  hideModal: () => void;
  data?: {
    title: string;
  };
}) {
  return (
    <Dialog open={!!type} onClose={hideModal} maxWidth='sm' disableEnforceFocus>
      <S.DialogContentStyled>
        <S.IconButtonStyled
          aria-label='close modal'
          type='button'
          onClick={hideModal}
        >
          <Close fontSize='small' />
        </S.IconButtonStyled>
        {props.data?.title && (
          <S.DialogHeaderStyled size='lg'>
            <Typography variant='h5' component='h5'>
              {props.data.title}
            </Typography>
          </S.DialogHeaderStyled>
        )}
        <S.DialogBodyStyled>
          {/* @ts-expect-error */}
          <ModalRoot {...props} />
        </S.DialogBodyStyled>
      </S.DialogContentStyled>
    </Dialog>
  );
}
