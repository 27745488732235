import { CompanyType, CompanyUpdateType } from "@types";
import {
  useQuery,
  UseQueryResult,
  useQueryClient,
  useMutation,
} from "react-query";
import {
  fetchCompanyDetails,
  updateCompanyDetails,
  fetchCompaniesDetails,
} from "./api";

export const useCompanyDetails = (
  companyId: string
): UseQueryResult<CompanyType> =>
  useQuery(["companyDetails", companyId], fetchCompanyDetails, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useCompaniesDetails = (
  companyIds: string[],
  enabled: boolean
): UseQueryResult<CompanyType[]> =>
  // @ts-expect-error
  useQuery(["companiesDetails", companyIds], fetchCompaniesDetails, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyIds && enabled,
  });

export const useUpdateCompanyDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ company_id, data }: CompanyUpdateType) =>
      updateCompanyDetails({
        company_id,
        data,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["companyDetails"]);
    },
  });
};
