import React from "react";
import {
  useFormContext,
  Controller,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";
import { DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { COMMON } from "@constants/index";
import { Typography } from "@material-ui/core";
import * as S from "./styled";

const DatePicker: React.FC<
  DatePickerProps<Dayjs> & {
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    hooked?: boolean;
    name: string;
    direction?: "row" | "column";
    required?: boolean;
    offsetbottom?: "true" | "false";
    validate?: (value: string) => void;
  }
> = ({ required, ...props }) => (
  <LocalizationProvider
    dateAdapter={AdapterDayjs}
    adapterLocale={dayjs.locale()}
  >
    <S.WrapperStyled
      fullWidth
      error={!!props.error}
      direction={props.direction || "column"}
      offsetbottom={props.offsetbottom ? "true" : "false"}
    >
      {/* {label && <FieldLabel />} */}
      {props.hooked ? (
        <Controller
          render={({ field }) => (
            <S.DatePickerStyled
              {...field}
              {...props}
              onChange={(date: Dayjs) => field.onChange(dayjs(date))}
              format={COMMON.DEFAULT_DATE_FORMAT_SHORT}
            />
          )}
          name={props.name}
          defaultValue={props.defaultValue}
          // eslint-disable-next-line
          control={useFormContext().control}
          // @ts-expect-error
          rules={{
            required,
            ...(props.validate && {
              validate: (v) => props.validate(v),
            }),
          }}
        />
      ) : (
        <S.DatePickerStyled
          {...props}
          format={COMMON.DEFAULT_DATE_FORMAT_SHORT}
        />
      )}
      {props.error && (
        <Typography variant='body2' color='primary'>
          {/* @ts-expect-error */}
          {props.error.message}
        </Typography>
      )}
    </S.WrapperStyled>
  </LocalizationProvider>
);

export default DatePicker;
