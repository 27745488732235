import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router";
import { SectionNav } from "@components/common";
import { FormattedMessage } from "react-intl";
import { MyGifts } from "./MyGifts";
import { ExternalGifts } from "./ExternalGifts";
// import { GiftsCatalog } from "./GiftsCatalog";
import { GiftDetails } from "./GiftDetails";
import { AllGifts } from "./AllGifts";
import { HeaderTopStyled, SubHeading, LinkBack } from "./styled";
import { ROUTES } from "@constants/index";
import BackIcon from "@assets/icons/back.svg";
import GlobeIcon from "@assets/icons/globe.svg";

const RenderBackLinkTitle = ({
  tab,
}: {
  tab: "external" | "my-gifts" | "all";
}) => {
  switch (tab) {
    case "external":
      return <FormattedMessage id='externalGifts.backLink' />;
      break;
    case "my-gifts":
      return <FormattedMessage id='myGifts.backLink' />;
      break;
    case "all":
      return <FormattedMessage id='allGifts.backLink' />;
  }
};

const Gifts: React.FC = () => {
  const { id, tab } = useParams<{
    id: string;
    tab: "external" | "my-gifts" | "all";
  }>();

  return (
    <>
      <HeaderTopStyled>
        <SubHeading variant='h2'>
          <FormattedMessage id='gifts.title' />
        </SubHeading>
      </HeaderTopStyled>
      {id ? (
        <LinkBack to={`/gifts/${tab}`}>
          <BackIcon />
          <RenderBackLinkTitle tab={tab} />
        </LinkBack>
      ) : (
        <SectionNav
          navItems={[
            {
              title: "myGifts.title",
              link: ROUTES.GIFTS_MY_GIFTS,
            },
            {
              title: "allGifts.title",
              link: ROUTES.GIFTS_ALL,
              icon: <GlobeIcon />,
            },
            // {
            //   title: "externalGifts.title",
            //   link: ROUTES.GIFTS_EXTERNAL,
            // },
          ]}
        />
      )}
      <Switch>
        <Route exact path={ROUTES.GIFTS_MY_GIFTS} component={MyGifts} />
        {/* <Route exact path={ROUTES.GIFTS_EXTERNAL} component={ExternalGifts} /> */}
        <Route exact path={ROUTES.GIFTS_DETAILS} component={GiftDetails} />
        <Route exact path={ROUTES.GIFTS_ALL} component={AllGifts} />
        <Redirect to={ROUTES.GIFTS_MY_GIFTS} />
      </Switch>
    </>
  );
};

export default Gifts;
