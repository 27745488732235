import styled from "styled-components";

export const InvoiceTable = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 400px;

  > div {
    padding: 10px;
  }

  > div:last-of-type {
    border: 1px solid #eee;
    border-radius: 4px;
    margin-top: 8px;
  }
`;

export const InvoiceTableRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InvoiceTableCell = styled.div`
  flex: 1 0 50%;
  padding-right: 10px;

  h6 {
    font-size: 16px;
    font-weight: 500;
  }

  a {
    white-space: nowrap;
  }
`;
