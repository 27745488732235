// eslint-disable
import React, { useEffect, useState } from "react";
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext,
} from "react-hook-form";
import { FormControlStyled, ButtonStyled, ButtonRowStyled } from "./styled";

interface InputFileProps {
  label?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  accept: string;
  defaultView?: boolean;
  file: File;
  open?: () => void;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
}

const InputFile: React.FC<InputFileProps> = ({
  label,
  name = "file-input",
  required,
  disabled = false,
  accept,
  file,
  defaultView = false,
  error,
  open,
  ...props
}) => {
  // eslint-disable-next-line
  const [inputFile, setInputFile] = useState<File | null>(null);
  const { control } = useFormContext();

  useEffect(() => {
    if (file) {
      setInputFile(file);
    }
  }, [file]);

  return (
    <FormControlStyled>
      <ButtonRowStyled error={!!error}>
        {defaultView ? (
          <>
            <label htmlFor={name}>{label}</label>
            <Controller
              control={control}
              // @ts-expect-error
              render={({ field, value, name: inputName }) => (
                <input
                  type='file'
                  onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    open && open();
                    // @ts-expect-error
                    if (event.target.files.length) {
                      // @ts-expect-error
                      setInputFile(event.target.files[0]);
                      // @ts-expect-error
                      field.onChange(event.target.files[0]);
                    }
                  }}
                  accept={accept}
                  id={inputName}
                  name={inputName}
                  value={value}
                  // hidden
                  disabled={disabled}
                  multiple={false}
                  {...props}
                />
              )}
              defaultValue=''
              name={name}
              rules={{
                required,
              }}
            />
          </>
        ) : (
          <ButtonStyled
            fullWidth={false}
            variant='outlined'
            component='label'
            disabled={disabled}
            error={!!error}
          >
            {label}
            <Controller
              control={control}
              // @ts-expect-error
              render={({ field, value, name: inputName }) => (
                <input
                  type='file'
                  onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    // @ts-expect-error
                    if (event.target.files.length) {
                      // @ts-expect-error
                      setInputFile(event.target.files[0]);
                      // @ts-expect-error
                      field.onChange(event.target.files[0]);
                    }
                  }}
                  accept={accept}
                  id={inputName}
                  name={inputName}
                  value={value}
                  // hidden
                  disabled={disabled}
                  multiple={false}
                  {...props}
                />
              )}
              defaultValue=''
              name={name}
              rules={{
                required,
              }}
            />
          </ButtonStyled>
        )}
      </ButtonRowStyled>
    </FormControlStyled>
  );
};

export default InputFile;
