import { Button, Checkbox } from "@components/common";
import styled from "styled-components";

export const SelectGiftStyled = styled(Button)`
  width: 230px;
  height: 84px;
  border: 2px solid #f8f6f4;
  border-radius: 12px;
  color: #000;
  margin-bottom: 12px;

  .MuiButton-label {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .MuiButton-startIcon {
    margin: 0;
  }

  path {
    stroke: currentColor;
  }
`;

export const SelectGiftListStyled = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 30vh;
  overflow-y: auto;
`;

export const SelectGiftListItemStyled = styled.li`
  list-style: none;

  > button {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    img {
      border-radius: 12px;
      object-fit: cover;
    }
  }
`;

export const SelectedGiftStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  border-radius: 12px;
  text-decoration: none;
  color: #263238;

  img {
    border-radius: 12px;
    object-fit: cover;
  }
`;

export const SelectGiftInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SelectGiftInfoSRowStyled = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #263238;
  align-items: center;
`;

export const SelectGiftFlagStyled = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const CheckBoxComponentStyled = styled(Checkbox)`
  label {
    margin: 0;
  }
`;

export const MenuEmpty = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 14px;

  a {
    margin-top: 16px;
  }
`;

export const InfoText = styled.div`
  padding-right: 50px;
`;
