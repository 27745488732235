import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-width: 480px;
  gap: 16px;

  h6 {
    margin-bottom: 8px;
  }

  > button {
    width: 100%;
  }
`;

export const Bottom = styled.div`
  margin-top: auto;
  width: 100%;

  > button {
    width: 100%;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  background-color: #fff;
  z-index: 100;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: auto;

  @keyframes rubberBand {
    0% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }

    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }

    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }

    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }

    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }

    to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  h6 {
    animation: rubberBand 1s;
  }
`;
