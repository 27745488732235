import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ButtonBase, Typography } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, InputFile } from "@components/common";
import UploadFileIcon from "@assets/icons/upload-file.png";
import RemoveIcon from "@assets/icons/remove.svg";
import DownloadIcon from "@assets/icons/download.svg";
// import { S3_ASSETS_URL } from "@constants/common";
import { FieldValue, UseFormSetValue } from "react-hook-form";
import {
  ButtonStyled,
  FormFileLabelStyled,
  FormDraggableStyled,
  FormDraggableTooltipStyled,
} from "./styled";

// const fileNameEnv = process.env.NODE_ENV === "development" ? "test" : "prod";

const ImportDraggable = ({
  fileName,
  reset,
  disabled,
  onSubmit,
  setValue,
  isLoading,
}: {
  fileName: string;
  reset: () => void;
  onSubmit: () => void;
  // @ts-expect-error
  setValue: UseFormSetValue<FieldValue<"csvFile">>;
  disabled: boolean;
  isLoading: boolean;
}) => {
  const intl = useIntl();
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0]);
      // @ts-expect-error
      setValue("csvFile", acceptedFiles[0]);
    },
    [setValue]
  );

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    noClick: true,
    accept: {
      "file/*": [".csv", ".txt", ".xls", ".xlsx"],
    },
  });

  return (
    <>
      <FormDraggableStyled {...getRootProps()} isDragAccept={isDragAccept}>
        <img width={42} src={UploadFileIcon} alt='...' />
        {fileName ? (
          <FormFileLabelStyled>
            <Typography variant='h4'>{fileName}</Typography>
            <ButtonBase
              onClick={(e) => {
                e.stopPropagation();
                reset();
              }}
            >
              <RemoveIcon>X</RemoveIcon>
            </ButtonBase>
          </FormFileLabelStyled>
        ) : (
          <Typography variant='h4'>
            {/* <FormattedMessage id='contacts.import.draggable.title' /> */}
          </Typography>
        )}
        {!fileName ? (
          <>
            <InputFile
              name='csvFile'
              accept='.csv,.txt,.xls,.xlsx'
              label={intl.formatMessage({
                id: "input.file.cvv.label",
              })}
              disabled={disabled}
              file={file}
              open={open}
              {...getInputProps()}
            />
          </>
        ) : (
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={onSubmit}
            type='submit'
          >
            <FormattedMessage id='general.uploadFile.button' />
          </Button>
        )}
      </FormDraggableStyled>
      <FormDraggableTooltipStyled>
        {/* <svg width='27' height='27'>
          <path
            fill='#E53761'
            d='M0 13.4355 13.43502884.00047116 26.8700577 13.4355 13.43502884 26.87052884z'
          />
        </svg> */}
        <Typography variant='h4'>
          <FormattedMessage id='contacts.import.tooltip.title' />
        </Typography>
        <Typography variant='h5'>
          <FormattedMessage id='contacts.import.tooltip.template' />
        </Typography>
        {/* @ts-expect-error */}
        <ButtonStyled
          href='https://docs.google.com/spreadsheets/d/1PhS4zgWU4BdDV9G4NQTFdiTYTdVZ8e5xfz5Dlm_-tTU/copy?usp=sharing'
          target='_blank'
          rel='noreferrer'
          as={Button}
          variant='outlined'
          startIcon={<DownloadIcon />}
        >
          <FormattedMessage id='contacts.import.tooltip.download' />
        </ButtonStyled>
      </FormDraggableTooltipStyled>
    </>
  );
};

export default ImportDraggable;
