import styled from "styled-components";

export const Container = styled.div`
  padding: 0;
  width: 680px;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 20px 24px;
  border-bottom: 1px solid #ececec;
  margin: 0 0 24px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;

  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 22px;
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  button {
    margin-right: -16px;
  }

  svg {
    color: #263238;
  }
`;

export const HeadingContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 80px;
  padding: 0 24px 24px;
`;

export const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 24px 24px 24px 40px;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
`;

export const Content = styled.div`
  padding: 24px 24px 24px 40px;
  border-bottom: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: #000;

    svg {
      path {
        stroke: currentColor;
      }
    }
  }
`;

export const GiftContent = styled(Content)`
  max-width: 620px;
  h5 {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #a0a0a0;
  margin-bottom: 6px;
`;

export const Value = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const Pickup = styled.div`
  padding: 16px;
  background: #fff7e6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PickupTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #eba000;
`;

export const PickupSub = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #263238;
  display: flex;
  align-items: center;

  svg {
    color: #263238;
  }
`;

export const DeliveryAlert = styled.div`
  grid-column: 1 / 3;

  & + & {
    margin-top: -18px;
  }

  svg {
    display: none;
  }

  div {
    font-weight: normal;
    display: block;
  }
`;
