import React from "react";

import {
  CompanyType,
  CountryCodeType,
  LanguageCodeType,
  GiftPriceType,
  GiftDescriptionType,
} from "@types";
import dayjs, { Dayjs } from "dayjs";

// for search filter component
export const getHighlightedText = (text: string, highlight: string) => {
  if (!highlight) return text;
  const textToString = text?.toString();
  const parts = textToString.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          // eslint-disable-next-line
          key={i.toString()}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: "bold" }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export const formatMoney = (money: number, currency?: "EUR") => {
  const formatter = new Intl.NumberFormat("sk-SK", {
    style: "currency",
    currency: currency || "EUR",
  });

  return formatter.format(money);
};

export const getGiftDescriptionByLanguageCode = (
  descriptions: GiftDescriptionType[],
  code: LanguageCodeType
) => {
  const d = descriptions?.find((dscr) => dscr?.language_code === "sk" || code);

  if (!d) return null;

  return d.text;
};

export const getGiftPriceByCountryCode = (
  prices: GiftPriceType[],
  code: CountryCodeType
) => {
  const price = prices?.find((price) => price?.country_code === code);

  if (!price) return "";

  return formatMoney(
    Number((Number(price.amount) + Number(price.vat)).toFixed(2)),
    price.currency
  );
};

export const getGiftPriceWithoutVatByCountryCode = (
  prices: GiftPriceType[],
  code: CountryCodeType
) => {
  const price = prices?.find((price) => price?.country_code === code);

  if (!price) return "";

  return formatMoney(price.amount, price.currency);
};

export const termsAndCondText = (lang: "sk" | "cs", company: CompanyType) => {
  switch (lang) {
    case "sk":
      return `SK: This DATA PROCESSING AGREEMENT concluded in accordance with Article
            28(3) of Regulation (EU) No 2016/679 of the European Parliament and
            of the Council on the protection of natural persons with regard to
            the processing of personal data and on the free movement of such
            data, repealing Directive 95/46/EC (General Data Protection
            Regulation) (the "GDPR") and Section 34(3) of Act No 18/2018 Coll.
            on the Protection of Personal Data and on Amendment of Certain Acts
            as amended (the "Personal Data Protection Act") (the "Agreement") <br/> <br/>
            BETWEEN:  <br/> <br/> ${company?.business_name}, with its registered seat at ${company?.city} ${company?.street}, Identification no.registered in the Commercial Register
            maintained by the District Cou, acting through [person with the
            authority to sign], em(the "Controller"); and <br/> <br/> [business name],
            with its registered seat at [business address], Identification no],
            registered in the Commercial Register maintained by the District Cou
            acting through [person with the authority to sign], email: [●] (the
            "Processor")  <br/> <br/> (The Controller and the Processor together
            hereinafter as the “Parties” and individually as the “Party”) THE
            PARTIES HAVE AGREED AS FOLLOWS: Object and Purpose of the Agreement
            This DATA PROCESSING AGREEMENT concluded in accordance with Article
            28(3) of Regulation (EU) No 2016/679 of the European Parliament and
            of the Council on the protection of natural persons with regard to
            the processing of personal data and on the free movement of such
            data, repealing Directive 95/46/EC (General Data Protection
            Regulation) (the "GDPR") and Section 34(3) of Act No 18/2018 Coll.
            on the Protection of Personal Data and on Amendment of Certain Acts
            as amended (the "Personal Data Protection Act") (the "Agreement")
            BETWEEN: [business name], with its registered seat at [business
            address], Identification no.registered in the Commercial Register
            maintained by the District Cou, acting through [person with the
            authority to sign], em(the "Controller"); and <br/> <br/> [business name],
            with its registered seat at [business address], Identification no],
            registered in the Commercial Register maintained by the District Cou
            acting through [person with the authority to sign], email: [●] (the
            "Processor") <br/> <br/> (The Controller and the Processor together
            hereinafter as the “Parties” and individually as the “Party”) THE
            PARTIES HAVE AGREED AS FOLLOWS: Object and Purpose of the Agreement
            This DATA PROCESSING AGREEMENT concluded in accordance with Article
            28(3) of Regulation (EU) No 2016/679 of the European Parliament and
            of the Council on the protection of natural persons with regard to
            the processing of personal data and on the free movement of such
            data, repealing Directive 95/46/EC (General Data Protection
            Regulation) (the "GDPR") and Section 34(3) of Act No 18/2018 Coll.
            on the Protection of Personal Data and on Amendment of Certain Acts
            as amended (the "Personal Data Protection Act") (the "Agreement")
            BETWEEN: [business name], with its registered seat at [business
            address], Identification no.registered in the Commercial Register
            maintained by the District Cou, acting through [person with the
            authority to sign], em(the "Controller"); and <br/> <br/> [business name],
            with its registered seat at [business address], Identification no],
            registered in the Commercial Register maintained by the District Cou
            acting through [person with the authority to sign], email: [●] (the
            "Processor") <br/> <br/> (The Controller and the Processor together
            hereinafter as the “Parties” and individually as the “Party”) THE
            PARTIES HAVE AGREED AS FOLLOWS: Object and Purpose of the Agreement`;
    case "cs": {
      return `CZ: This DATA PROCESSING AGREEMENT concluded in accordance with Article
                    28(3) of Regulation (EU) No 2016/679 of the European Parliament and
                    of the Council on the protection of natural persons with regard to
                    the processing of personal data and on the free movement of such
                    data, repealing Directive 95/46/EC (General Data Protection
                    Regulation) (the "GDPR") and Section 34(3) of Act No 18/2018 Coll.
                    on the Protection of Personal Data and on Amendment of Certain Acts
                    as amended (the "Personal Data Protection Act") (the "Agreement") <br/> <br/>
                    BETWEEN:  <br/> <br/> ${company?.business_name}, with its registered seat at ${company?.city} ${company?.street}, Identification no.registered in the Commercial Register
                    maintained by the District Cou, acting through [person with the
                    authority to sign], em(the "Controller"); and <br/> <br/> [business name],
                    with its registered seat at [business address], Identification no],
                    registered in the Commercial Register maintained by the District Cou
                    acting through [person with the authority to sign], email: [●] (the
                    "Processor")  <br/> <br/> (The Controller and the Processor together
                    hereinafter as the “Parties” and individually as the “Party”) THE
                    PARTIES HAVE AGREED AS FOLLOWS: Object and Purpose of the Agreement
                    This DATA PROCESSING AGREEMENT concluded in accordance with Article
                    28(3) of Regulation (EU) No 2016/679 of the European Parliament and
                    of the Council on the protection of natural persons with regard to
                    the processing of personal data and on the free movement of such
                    data, repealing Directive 95/46/EC (General Data Protection
                    Regulation) (the "GDPR") and Section 34(3) of Act No 18/2018 Coll.
                    on the Protection of Personal Data and on Amendment of Certain Acts
                    as amended (the "Personal Data Protection Act") (the "Agreement")
                    BETWEEN: [business name], with its registered seat at [business
                    address], Identification no.registered in the Commercial Register
                    maintained by the District Cou, acting through [person with the
                    authority to sign], em(the "Controller"); and <br/> <br/> [business name],
                    with its registered seat at [business address], Identification no],
                    registered in the Commercial Register maintained by the District Cou
                    acting through [person with the authority to sign], email: [●] (the
                    "Processor") <br/> <br/> (The Controller and the Processor together
                    hereinafter as the “Parties” and individually as the “Party”) THE
                    PARTIES HAVE AGREED AS FOLLOWS: Object and Purpose of the Agreement
                    This DATA PROCESSING AGREEMENT concluded in accordance with Article
                    28(3) of Regulation (EU) No 2016/679 of the European Parliament and
                    of the Council on the protection of natural persons with regard to
                    the processing of personal data and on the free movement of such
                    data, repealing Directive 95/46/EC (General Data Protection
                    Regulation) (the "GDPR") and Section 34(3) of Act No 18/2018 Coll.
                    on the Protection of Personal Data and on Amendment of Certain Acts
                    as amended (the "Personal Data Protection Act") (the "Agreement")
                    BETWEEN: [business name], with its registered seat at [business
                    address], Identification no.registered in the Commercial Register
                    maintained by the District Cou, acting through [person with the
                    authority to sign], em(the "Controller"); and <br/> <br/> [business name],
                    with its registered seat at [business address], Identification no],
                    registered in the Commercial Register maintained by the District Cou
                    acting through [person with the authority to sign], email: [●] (the
                    "Processor") <br/> <br/> (The Controller and the Processor together
                    hereinafter as the “Parties” and individually as the “Party”) THE
                    PARTIES HAVE AGREED AS FOLLOWS: Object and Purpose of the Agreement`;
    }
    default:
      return null;
  }
};

export const filterOrdersByMonth = <T,>(orders: T[], field?: string) => {
  const filtered = orders.reduce((acc: { [date: string]: T[] }, item: T) => {
    const date = new Date(
      // @ts-expect-error TODO: align with API
      item[field] || item.requested_delivery_datetime
    );
    const month = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;

    if (acc[month]) {
      acc[month].push(item);
    } else {
      acc[month] = [item];
    }

    return acc;
  }, {});
  return filtered;
};

export const SHOW_SUPPORT_CHAT =
  process.env.ENABLE_SUPPORT_CHAT === "true" && window.smartsupp;

export const formatBoldTag = (chunks: string[]) => <b>{chunks}</b>;

export const isWeekend = (date: Dayjs) => {
  const day = date.day();

  const isBeforeToday = date.isBefore(dayjs().startOf("date"));

  return day === 6 || day === 0 || isBeforeToday;
};

export const handleWeekends = (date: Dayjs) => {
  const day = date.day();

  if (day === 6) {
    return date.add(2, "days");
  }

  if (day === 0) {
    return date.add(1, "days");
  }

  return date;
};
