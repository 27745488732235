import styled, { css } from "styled-components";
import { FormControl, FormLabel, TextField } from "@material-ui/core";

interface TextFieldProps {
  secondary?: boolean;
  error?: boolean;
  rounded?: "true" | "false";
}

interface WrapperProps {
  offsetbottom?: "true" | "false";
  direction?: "column" | "row";
}

export const TextFieldStyled = styled(TextField)<TextFieldProps>`
  > div {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.error.main : "transparent"};
    border-radius: ${({ rounded }) => (rounded === "true" ? "60px" : "12px")};

    textarea {
      line-height: 22px;
      font-size: 15px;
    }
  }

  .MuiInputAdornment-root {
    p {
      margin-bottom: 0;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f8f6f4 inset !important;
  }

  ${({ secondary }) =>
    secondary &&
    `
    background: #f4f4f4;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 48px;

    input {
      background-color: transparent;
      height: 48px;
    }
  `}
`;

export const LabelStyled = styled(FormLabel)`
  span {
    color: #b9b9b9;
    padding-left: 6px;
  }
`;

export const WrapperStyled = styled(FormControl)<WrapperProps>`
  display: flex;
  ${({ offsetbottom }) =>
    offsetbottom === "true" &&
    `
    margin-bottom: 24px;
  `}
  flex-direction: ${({ direction }) => direction};

  ${({ direction }) =>
    direction === "row" &&
    css`
      align-items: center;

      ${LabelStyled} {
        white-space: nowrap;
        margin-bottom: 0;
        margin-right: 8px;
      }
    `}
`;
