import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "@providers/AuthProvider";
import cognitoUser from "../../../../../cognitoId";
import AccountForm from "./AccountForm";
import VerifyForm from "./VerifyForm";
import ResetPassForm from "./ResetPassForm";
import { HeadingStyled } from "../../styled";

const AccountSettings: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  useEffect(() => {
    cognitoUser.getCognitoUser()?.getUserAttributes((err, result) => {
      if (err) {
        throw err;
      }
      const isVerified = result.find(
        (attr) => attr.Name === "email_verified"
      )?.Value;
      setIsEmailVerified(isVerified === "true");
    });
  }, []);

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={6} lg={4}>
        <HeadingStyled variant='h3'>
          <FormattedMessage id='settings.myAccount' />
        </HeadingStyled>
        <AccountForm user={user} setIsEmailVerified={setIsEmailVerified} />
        {!isEmailVerified && (
          <VerifyForm setIsEmailVerified={setIsEmailVerified} />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <HeadingStyled variant='h3'>
          <FormattedMessage id='settings.changePassword' />
        </HeadingStyled>
        <ResetPassForm />
      </Grid>
    </Grid>
  );
};

export default AccountSettings;
