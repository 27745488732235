import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import { TagType, AutomationPayload, ContactShortType } from "@types";
import { AutomationSendToTitles } from "@components/common/Order/AutomationSendToTitles/AutomationSendToTitles";
import CompletedIcon from "@assets/icons/completed.svg";
import * as S from "../../styled";
import { CreateStep1 } from "./Step1";
import { FormType } from "../types";
import { ContactsListModal } from "../../Modals/ContactsListModal";
import { formatBoldTag } from "@utils/common";

export const To: React.FC<{
  companyId: string;
  expanded: boolean;
  completed: boolean;
  // contacts: ContactType[];
  tags: TagType[];
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  payload: AutomationPayload | null;
  setPayload: Dispatch<SetStateAction<AutomationPayload>>;
  disabled: boolean;
  invalid: boolean | number;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
  missingInfoContacts: ContactShortType[];
  contactDateType: "birthday" | "anniversary";
}> = ({
  companyId,
  expanded,
  tags,
  handleShowStepContent,
  // contacts,
  payload,
  setPayload,
  disabled,
  invalid,
  completed,
  formState,
  setFormState,
  missingInfoContacts,
  contactDateType,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [showMissingContactsModal, setShowMissingContactsModal] =
    useState(false);

  return (
    <S.CreateCampaignStep invalid={!!invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {completed && <CompletedIcon />}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='automations.create.step1.title' />
            </Typography>
            {completed ? (
              <Typography>
                <AutomationSendToTitles
                  payload={payload}
                  type={payload.tags ? "tag" : "contacts"}
                  tagName={
                    tags.find((tag) => tag.id === payload?.tags?.[0])
                      ?.tag_string
                  }
                  selectedContactsCount={formState.selectedContactsCount}
                />
              </Typography>
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='automations.create.step1.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!expanded && (
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(0, true, false);
            }}
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='automations.create.step.edit' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>

      {missingInfoContacts?.length > 0 && (
        <S.AlertStyled>
          <div>
            {contactDateType === "anniversary" ? (
              <FormattedMessage
                id='automations.create.step1.contactAnniversaryValidationMessage'
                values={{
                  count: missingInfoContacts?.length,
                  b: formatBoldTag,
                  button: (chunks: string[]) => (
                    <button onClick={() => setShowMissingContactsModal(true)}>
                      {chunks}
                    </button>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id='automations.create.step1.contactBirthdayValidationMessage'
                values={{
                  count: missingInfoContacts?.length,
                  b: formatBoldTag,
                  button: (chunks: string[]) => (
                    <button onClick={() => setShowMissingContactsModal(true)}>
                      {chunks}
                    </button>
                  ),
                }}
              />
            )}
          </div>
        </S.AlertStyled>
      )}
      {showMissingContactsModal && (
        <ContactsListModal
          contacts={missingInfoContacts}
          onClose={() => setShowMissingContactsModal(false)}
        />
      )}

      {expanded && (
        <CreateStep1
          companyId={companyId}
          onClose={() => {
            setInProgress(false);
            handleShowStepContent(0, false, false);
          }}
          tags={tags}
          setPayload={setPayload}
          payload={payload}
          formState={formState}
          setFormState={setFormState}
          contactDateType={contactDateType}
        />
      )}
    </S.CreateCampaignStep>
  );
};
