import { AxiosPromise } from "axios";
import { GiftCategoryType } from "@types";
import { UseQueryResult, useQuery } from "react-query";
import { baseApi } from "@utils/apiService";

export const fetchGiftCategories = (): AxiosPromise<{ items: GiftCategoryType[] }> => {
  return baseApi
    .get(`/api/gifts/categories`)
    .then((res) => res.data);
};

export const useGiftsFilter = (
  company_id: string,
): UseQueryResult<{ items: GiftCategoryType[] }> =>
  useQuery(["gifts-categories", company_id], fetchGiftCategories, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!company_id,
    staleTime: Infinity,
  });
