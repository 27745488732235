import React, { StrictMode } from "react";
import { Provider } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import IntlProvider from "./i18n/IntlProvider";
import { AuthProvider } from "./providers/AuthProvider";
import store from "./utils/store";
import theme from "./styles/theme";
import Routes from "./routes";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

setLogger({
  // eslint-disable-next-line
  log: console.log,
  // eslint-disable-next-line
  warn: console.warn,
  // eslint-disable-next-line
  error: console.log,
});

const App: React.FC = () => (
  <StrictMode>
    <Provider store={store} key='provider'>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <CssBaseline>
              <AuthProvider>
                <IntlProvider>
                  <Routes />
                </IntlProvider>
              </AuthProvider>
            </CssBaseline>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
);

export default App;
