import React from "react";
import { FormattedMessage } from "react-intl";
import { AutomationPayload } from "@types";

type AutomationSendToTitlesProps = {
  type: "contacts" | "tag";
  payload: AutomationPayload;
  tagName: string;
  selectedContactsCount: number;
};

export const AutomationSendToTitles = React.memo(
  ({
    type,
    tagName,
    payload,
    selectedContactsCount,
  }: AutomationSendToTitlesProps) => {
    switch (type) {
      case "contacts":
        return (
          <span>
            <u>
              <FormattedMessage
                id='automations.create.step1.recipients'
                values={{ count: payload.contacts.length }}
                tagName='b'
              />
            </u>
          </span>
        );
      case "tag":
        return (
          <span>
            <FormattedMessage
              tagName='b'
              id='automations.create.step1.allTagsTitle'
            />{" "}
            <FormattedMessage id='automations.create.step1.withTag' />
            <b>{tagName}. </b>
            <u>
              <FormattedMessage
                id='automations.create.step1.recipients'
                values={{ count: selectedContactsCount }}
                tagName='b'
              />
            </u>
          </span>
        );
      default:
        return null;
    }
  }
);

AutomationSendToTitles.displayName = "AutomationSendToTitles";
