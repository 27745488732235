import { UserProfileType } from "@types";
import React, { memo, useEffect, useState } from "react";
import Gleap from "gleap";

const SupportChat = memo(
  ({ apiKey, user }: { user?: UserProfileType; apiKey: string }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
      if (process.env.ENABLE_SUPPORT_CHAT === "true") {
        Gleap.initialize(apiKey);
        setIsLoaded(true);
      }
      return () => {
        if (process.env.ENABLE_SUPPORT_CHAT === "true") {
          Gleap.destroy();
        }
      };
    }, []);

    useEffect(() => {
      if (process.env.ENABLE_SUPPORT_CHAT === "true" && isLoaded && user) {
        Gleap.identify(user.id, {
          email: user.email,
          name: user.company_name,
          customData: {
            currency: user.currency,
            company: user.company_name,
          },
        });
      }
      if (process.env.ENABLE_SUPPORT_CHAT === "true" && isLoaded && !user) {
        Gleap.identify("delivery slip user", {});
      }
    }, [user, isLoaded]);

    return null;
  }
);

SupportChat.displayName = "SupportChat";

export default SupportChat;
