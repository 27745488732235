import React from "react";
import { Box, Grid, MenuItem } from "@material-ui/core";
import { Button, Select, TextField } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { CompanyAddressType } from "@types";
import { VALIDATION } from "@constants/index";
import { toast } from "react-toastify";
import { useCreateAddress, useUpdateAddress } from "../hooks";
import { DialogFooterStyled } from "../../../../modal/components/Modal/components/styled";

type AddModalDataType = {
  ctaTitle: string;
  address: CompanyAddressType;
  edit?: boolean;
  companyId: string;
};

interface AddAddressModalProps {
  data: AddModalDataType;
  hideModal: () => void;
}

const AddAddressModal: React.FC<AddAddressModalProps> = ({
  data: { ctaTitle, address, edit, companyId },
  hideModal,
}) => {
  const methods = useForm();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;
  const { mutate: createAddress } = useCreateAddress(
    intl.formatMessage({
      id: "general.notifications.success",
    }),
    intl.formatMessage({
      id: "general.notifications.error",
    })
  );
  const { mutate: updateAddress } = useUpdateAddress(
    intl.formatMessage({
      id: "general.notifications.success",
    }),
    intl.formatMessage({
      id: "general.notifications.error",
    })
  );

  const onSubmit = ({
    name,
    street,
    zip,
    city,
    country,
    person,
    phone,
    email,
  }: {
    name: string;
    street: string;
    zip: string;
    city: string;
    country: string;
    person: string;
    phone: string;
    email: string;
  }) => {
    if (!isDirty) {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
      hideModal();

      return;
    }
    const formData: {
      name: string;
      street: string;
      zip: string;
      city: string;
      country_code: string;
      contact_name: string;
      contact_phone: string;
      contact_email: string;
      type: "office";
    } = {
      name,
      street,
      zip,
      city,
      country_code: country,
      contact_name: person,
      contact_phone: phone?.replace(/\s/g, ""),
      contact_email: email,
      type: "office",
    };
    if (edit) {
      updateAddress({
        companyId,
        addressId: address.id,
        formData,
      });
    } else {
      createAddress({
        companyId,
        formData,
      });
    }
    hideModal();
  };

  return (
    <Box display='flex' flexDirection='column'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name='name'
                placeholder={intl.formatMessage({
                  id: "input.address.custom.name.label",
                })}
                label={intl.formatMessage({
                  id: "input.address.custom.name.label",
                })}
                error={errors.name}
                required
                defaultValue={address?.name}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name='street'
                placeholder={intl.formatMessage({
                  id: "input.address.label",
                })}
                label={intl.formatMessage({
                  id: "input.address.label",
                })}
                error={errors.street}
                required
                defaultValue={address?.street}
              />
              <TextField
                name='zip'
                placeholder={intl.formatMessage({
                  id: "input.zip.label",
                })}
                label={intl.formatMessage({
                  id: "input.zip.label",
                })}
                error={errors.zip}
                required
                defaultValue={address?.zip}
              />
              <TextField
                name='city'
                placeholder={intl.formatMessage({
                  id: "input.city.label",
                })}
                label={intl.formatMessage({
                  id: "input.city.label",
                })}
                error={errors.city}
                required
                defaultValue={address?.city}
              />
              <Select
                id='country'
                name='country'
                label={intl.formatMessage({
                  id: "input.country.label",
                })}
                defaultValue={address?.country_code || 'sk'}
              >
                <MenuItem value='sk'>
                  <FormattedMessage id='shared.country.sk' />
                </MenuItem>
                <MenuItem value='cz'>
                  <FormattedMessage id='shared.country.cz' />
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name='person'
                placeholder={intl.formatMessage({
                  id: "input.address.contact.person.label",
                })}
                label={intl.formatMessage({
                  id: "input.address.contact.person.label",
                })}
                error={errors.person}
                required
                defaultValue={address?.contact_name}
              />
              <TextField
                name='phone'
                placeholder={intl.formatMessage({
                  id: "input.address.contact.phone.label",
                })}
                label={intl.formatMessage({
                  id: "input.address.contact.phone.label",
                })}
                error={errors.phone}
                required
                defaultValue={address?.contact_phone}
                pattern={{
                  value: VALIDATION.PHONE_VALIDATION_REGEXP,
                  message: intl.formatMessage({
                    id: "input.phone.pattern.error",
                  }),
                }}
              />
              <TextField
                name='email'
                placeholder={intl.formatMessage({
                  id: "input.address.contact.email.label",
                })}
                label={intl.formatMessage({
                  id: "input.address.contact.email.label",
                })}
                error={errors.email}
                required
                defaultValue={address?.contact_email}
              />
            </Grid>
          </Grid>
          <DialogFooterStyled>
            <Button onClick={handleSubmit(onSubmit)}>{ctaTitle}</Button>
          </DialogFooterStyled>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AddAddressModal;
