import {
  AutomationType,
  AutomationPayload,
  AutomationUpdatePayload,
  PagingType,
  OrderType,
  ContactType,
  DeliveryType,
} from "@types";
import {
  useQuery,
  UseQueryResult,
  useQueryClient,
  useMutation,
} from "react-query";
import {
  fetchAutomations,
  createAutomation,
  updateOrder,
  fetchAutomationOrders,
  fetchAutomationDeliveries,
  fetchAutomationContacts,
  fetchAutomation,
  updateAutomation,
  updateOrderGiftNote,
  fetchOrderDeliveries,
  validateAutomationContacts,
} from "./api";

import { AutomationContactValidateDataType } from "./types";

export const useAutomations = (
  companyId: string,
  type: "upcoming" | "past",
  page: number
): UseQueryResult<{ items: AutomationType[]; paging: PagingType }> =>
  useQuery(["automations", companyId, type, page], fetchAutomations, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useAutomation = (
  companyId: string,
  automation_id: string
): UseQueryResult<AutomationType> =>
  useQuery(["automation", companyId, automation_id], fetchAutomation, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useAutomationOrders = (
  companyId: string,
  automation_id: string,
  type: "upcoming" | "past",
  page: number
): UseQueryResult<{ items: OrderType[]; paging: PagingType }> =>
  useQuery(
    ["automationOrders", companyId, automation_id, type, page],
    fetchAutomationOrders,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );

export const useAutomationDeliveries = (
  company_id: string,
  automation_id: string,
  params: {
    page: number;
    not_before?: string;
    not_after?: string;
  }
): UseQueryResult<{ items: DeliveryType[]; paging: PagingType }> =>
  useQuery(
    ["automationDeliveries", company_id, automation_id, params],
    fetchAutomationDeliveries,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!company_id,
    }
  );

export const useAutomationContacts = (
  companyId: string,
  automation_id: string
): UseQueryResult<{ items: ContactType[] }> =>
  useQuery(
    ["automationContacts", companyId, automation_id],
    fetchAutomationContacts,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );

export const useCreateAutomation = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      data,
    }: {
      company_id: string;
      data: AutomationPayload;
    }) =>
      createAutomation({
        company_id,
        data,
      }),
  });
};

export const useUpdateAutomation = () =>
  useMutation({
    mutationFn: async ({
      company_id,
      automation_id,
      data,
    }: {
      company_id: string;
      automation_id: string;
      data: AutomationUpdatePayload;
    }) =>
      updateAutomation({
        company_id,
        automation_id,
        data,
      }),
  });

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      order_id,
      data,
    }: {
      company_id: string;
      order_id: string;
      data: { state: "canceled" | "confirmed" };
    }) =>
      updateOrder({
        company_id,
        order_id,
        data,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["automationOrders"]);
    },
  });
};

export const useUpdateOrderGiftNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      order_id,
      delivery_id,
      data,
    }: {
      company_id: string;
      order_id: string;
      delivery_id: string;
      data: { custom_gift_note_text: string };
    }) =>
      updateOrderGiftNote({
        company_id,
        order_id,
        delivery_id,
        data,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["automationDeliveries"]);
    },
  });
};

export const useOrderDeliveries = ({
  company_id,
  order_id,
}: {
  company_id: string;
  order_id: string;
}): UseQueryResult<{ items: DeliveryType[]; paging: PagingType }> =>
  useQuery(["orderDeliveries", company_id, order_id], fetchOrderDeliveries, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!company_id,
  });
