import styled from "styled-components";
import { FormControl, FormControlProps } from "@material-ui/core";

export const FormControlStyled = styled(FormControl)<FormControlProps>`
  label {
    &.Mui-focused {
      color: inherit;
    }
  }
`;
