import React, { useEffect, useState } from "react";
import { S3_ASSETS_URL } from "@constants/common";
import axios from "axios";

const env = process.env.NODE_ENV === "development" ? "test" : "prod";

export const CompanyLogo: React.FC<{
  company_id: string;
}> = ({
  company_id,
}) => {
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    const checkAsset = async () => {
      try {
        const res = await axios.get(
          `${S3_ASSETS_URL}-${env}.s3.eu-west-1.amazonaws.com/${company_id}.png`
        );

        if (res) {
          setShowLogo(true);
        }
      } catch (e) {
        setShowLogo(false);
      }
    };
    checkAsset();
  }, [company_id]);

  if (!company_id) {
    return null;
  }

  return showLogo ? (
    <img
      src={`${S3_ASSETS_URL}-${env}.s3.eu-west-1.amazonaws.com/${company_id}.png`}
      onError={() => {
        setShowLogo(false);
      }}
    />
  ) : null;
};
