import { Alert } from "@ui/components";
import styled from "styled-components";

export const Container = styled(Alert)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 0;
  border-radius: 16px;

  h2 {
    font-size: 18px;
    margin: 0 0 8px;
  }
`;

export const PaymentRadios = styled.div`
  fieldset > div {
    display: flex;
    flex-direction: column;
  }
`;
