// @ts-nocheck
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormLabel,
  Radio,
  RadioProps,
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlStyled } from "./styled";

interface RadioItemProps {
  value: string;
  label: string;
  disabled?: boolean;
}

interface RadioButtonsProps {
  label?: string;
  items: RadioItemProps[];
}

const useStyles = (theme) => ({
  icon: {
    position: "relative",
    borderRadius: "50%",
    width: 14,
    height: 14,
    backgroundColor: "#fff",
    border: "1px solid #d9d9d9",
  },
  checkedIcon: {
    backgroundColor: "#fff",
    borderColor: theme.palette.primary.main,
    "&:before": {
      display: "block",
      width: 8,
      height: 8,
      borderRadius: "50%",
      position: "absolute",
      left: 2,
      top: 2,
      content: '""',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const CustomizedRadios = React.forwardRef(
  (
    {
      onChange,
      defaultValue,
      label,
      items,
      color = "primary",
      name,
      classes,
      disabled,
      hooked = false,
      value,
    }: RadioProps & RadioButtonsProps & RadioGroupProps & { hooked?: boolean },
    ref
  ) => (
    <FormControlStyled component='fieldset'>
      {label && <FormLabel>{label}</FormLabel>}
      {hooked ? (
        <Controller
          render={({ field, value, name: inputName }) => (
            <RadioGroup
              name={inputName}
              row
              onChange={field.onChange}
              value={value}
              defaultValue={defaultValue}
            >
              {items.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={
                    <Radio
                      color={color}
                      checkedIcon={
                        <span
                          className={`${classes?.icon} ${classes?.checkedIcon}`}
                        />
                      }
                      disabled={disabled}
                      icon={<span className={classes?.icon} />}
                    />
                  }
                  label={item.label}
                  disabled={item.disabled}
                  ref={ref}
                />
              ))}
            </RadioGroup>
          )}
          name={name}
          defaultValue={defaultValue}
          // eslint-disable-next-line
          control={useFormContext().control}
        />
      ) : (
        <RadioGroup
          name={name}
          row
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
        >
          {items.map((item, key) => (
            <FormControlLabel
              key={item.value + key}
              value={item.value}
              control={
                <Radio
                  color={color}
                  checkedIcon={
                    <span
                      className={`${classes?.icon} ${classes?.checkedIcon}`}
                    />
                  }
                  disabled={disabled}
                  icon={<span className={classes?.icon} />}
                />
              }
              label={item.label}
              disabled={item.disabled}
              ref={ref}
            />
          ))}
        </RadioGroup>
      )}
    </FormControlStyled>
  )
);

CustomizedRadios.displayName = "CustomizedRadios";

export default withStyles(useStyles)(CustomizedRadios);
