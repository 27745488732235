import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import {
  AutomationPayload,
  AutomationType,
  PagingType,
  AutomationUpdatePayload,
  DeliveryType,
  ContactType,
} from "@types";
import { QueryKey } from "react-query";
import { AutomationContactValidateDataType } from "./types";

const { API } = CONSTANTS;

const today = new Date().toISOString();

export const fetchAutomation = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<AutomationType> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/automations/${queryKey[2]}`)
    .then((res) => res.data.items?.[0]);

export const fetchAutomations = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: AutomationType[]; paging: PagingType }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/automations?page=${queryKey[3]}`)
    .then((res) => res.data);

export const fetchAutomationContacts = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: AutomationType[]; paging: PagingType }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/automations/${queryKey[2]}/contacts`)
    .then((res) => res.data);

export const fetchAutomationOrders = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: AutomationType[]; paging: PagingType }> => {
  const params: { [key: string]: string } = {};

  if (queryKey[3] === "upcoming") {
    params.not_before = today;
  } else {
    params.not_after = today;
  }

  // @ts-expect-error
  // eslint-disable-next-line
  params.page = queryKey[4];

  return baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/automations/${queryKey[2]}/orders`, {
      params,
    })
    .then((res) => res.data);
};

export const fetchAutomationDeliveries = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: DeliveryType[]; paging: PagingType }> =>
  baseApi
    .get(
      `${API.COMPANIES}/${queryKey[1]}/automations/${queryKey[2]}/deliveries`,
      {
        params: queryKey[3],
      }
    )
    .then((res) => res.data);

export const createAutomation = ({
  company_id,
  data,
}: {
  company_id: string;
  data: AutomationPayload;
}): Promise<AutomationType> =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/automations`, data)
    .then((res) => res.data);

export const updateAutomation = ({
  company_id,
  automation_id,
  data,
}: {
  company_id: string;
  automation_id: string;
  data: AutomationUpdatePayload;
}): AxiosPromise<AutomationType> =>
  baseApi
    .patch(`${API.COMPANIES}/${company_id}/automations/${automation_id}`, data)
    .then((res) => res.data);

export const updateOrder = ({
  company_id,
  order_id,
  data,
}: {
  company_id: string;
  order_id: string;
  data: { state: "canceled" | "confirmed" };
}): AxiosPromise<{ items: AutomationType }> =>
  baseApi
    .patch(`${API.COMPANIES}/${company_id}/orders/${order_id}`, data)
    .then((res) => res.data);

export const fetchOrderDeliveries = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: DeliveryType }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/orders/${queryKey[2]}/deliveries`)
    .then((res) => res.data);

export const updateOrderGiftNote = ({
  company_id,
  order_id,
  delivery_id,
  data,
}: {
  company_id: string;
  order_id: string;
  delivery_id: string;
  data: { custom_gift_note_text: string };
}): AxiosPromise<{ items: AutomationType }> =>
  baseApi
    .patch(
      `${API.COMPANIES}/${company_id}/orders/${order_id}/deliveries/${delivery_id}`,
      data
    )
    .then((res) => res.data);

export const validateAutomationContacts = ({
  company_id,
  data,
}: {
  company_id: string;
  data: AutomationContactValidateDataType;
}): Promise<{
  total_count: number;
  validations: [
    {
      contacts: ContactType[];
      missing_info_count: number;
      type: "delivery_date_type" | "delivery_details_source_type";
    }
  ];
}> =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/automations/contacts/validate`, data)
    .then((res) => res.data);
