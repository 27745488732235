import { StatusType } from "../../types";

export type AccountStateType = {
  language: string;
  status: StatusType;
  error: boolean;
};

export const AccountTypes = {
  CHANGE_LANGUAGE: "account/CHANGE_LANGUAGE",
} as const;

// Change Language
export type changeLanguageActionType = {
  type: typeof AccountTypes.CHANGE_LANGUAGE;
  payload: string;
};

export type accountActionTypes = changeLanguageActionType;
