import React, { useContext } from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { Error, Loader, NoData } from "@components/common";
import { FormattedMessage } from "react-intl";
import { getGiftPriceByCountryCode } from "@utils/common";
import TickIcon from "@assets/icons/gift-tick.svg";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import { GiftType } from "../../../../../gifts/types";
import {
  SelectGiftListStyled,
  SelectGiftListItemStyled,
  SelectGiftInfoStyled,
  SelectGiftInfoSRowStyled,
  SelectGiftFlagStyled,
} from "./styled";
import { useGifts } from "../../../../../gifts/hooks";
import { AuthContext } from "@providers/AuthProvider";

type AddModalDataType = {
  ctaTitle: string;
  ctaHandle: (gift: GiftType) => void;
  companyId: string;
};

interface AddAddressModalProps {
  data: AddModalDataType;
  hideModal: () => void;
}

const SelectGiftModal: React.FC<AddAddressModalProps> = ({
  data: { ctaHandle, companyId },
  hideModal,
}) => {
  const {
    data: gifts,
    isLoading,
    isError,
  } = useGifts(companyId, {
    origin_type: "boxday_merchant",
    is_company_specific: true,
    page: 1,
    per_page: 100,
  });

  const { user } = useContext(AuthContext);

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box display='flex' flexDirection='column'>
      <SelectGiftListStyled>
        {gifts.items?.length === 0 ? (
          <NoData />
        ) : (
          gifts.items
            ?.filter((gift) => gift.availability_state === "in_stock")
            ?.map((gift) => (
              <SelectGiftListItemStyled key={gift.id}>
                <ButtonBase
                  type='button'
                  onClick={() => {
                    ctaHandle(gift);
                    hideModal();
                  }}
                >
                  <img
                    width='56'
                    height='56'
                    src={gift.images?.[0]?.url || GiftPlaceholder}
                    alt=''
                  />
                  <SelectGiftInfoStyled>
                    <Typography variant='h5'>{gift.name}</Typography>
                    <SelectGiftInfoSRowStyled>
                      {gift.flags.is_branded ? (
                        <SelectGiftFlagStyled>
                          <TickIcon />{" "}
                          <FormattedMessage id='gifts.gift.branded' />
                        </SelectGiftFlagStyled>
                      ) : null}
                      {gift.flags.has_handwritten_note ? (
                        <SelectGiftFlagStyled>
                          <TickIcon />
                          <FormattedMessage id='gifts.gift.handNote' />
                        </SelectGiftFlagStyled>
                      ) : null}
                      {getGiftPriceByCountryCode(gift.prices, user.currency)}
                    </SelectGiftInfoSRowStyled>
                  </SelectGiftInfoStyled>
                </ButtonBase>
              </SelectGiftListItemStyled>
            ))
        )}
      </SelectGiftListStyled>
    </Box>
  );
};

export default SelectGiftModal;
