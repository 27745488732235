import styled from "styled-components";

interface AlertProps {
  variant?: "success" | "warning" | "error";
  sticky?: boolean;
  fullWidth?: boolean;
  hideAlert?: boolean;
}

export const AlertStyled = styled.div<AlertProps>`
  border-radius: 12px;
  font-weight: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 18px;
  margin-bottom: 16px;
  align-self: center;
  justify-content: center;
  justify-content: ${({ fullWidth }) => (fullWidth ? "flex-start" : "center")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  display: ${({ hideAlert }) => (hideAlert ? "none" : "inline-flex")};
  ${({ sticky }) => `
    position: ${sticky ? "fixed" : "static"};
    bottom: 32px;
    left: 32px;
    z-index: 100;
  `};

  svg {
    margin-right: 8px;
  }

  ${({ variant }) => {
    switch (variant) {
      case "success":
        return `
          background-color: #B8FFEA;
          color: #263238;
        `;
      case "warning":
        return `
          background-color: #FFF9EA;
          color: #000;
        `;
      case "error":
        return `
          background-color: #FED8CB;
          color: #000000;
        `;
      default:
        return `
          background-color: #B8FFEA;
          color: #263238;
        `;
    }
  }};
`;

export const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;
