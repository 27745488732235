import React, { useCallback, useContext, useMemo } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Loader, NoData, Table, Error } from "@components/common";
import { CompanyAddressType } from "@types";

import EmptyListIcon from "@assets/icons/addresses-empty.png";
import EditIcon from "@assets/icons/edit.svg";

import { AuthContext } from "@providers/AuthProvider";
import { HeadingStyled, TableWrapper } from "../../styled";
import { useAddresses } from "../hooks";

interface AddressesProps {
  showModal: ({ type, data }: { type: string; data: any }) => void;
}

const Addresses: React.FC<AddressesProps & RouteComponentProps> = ({
  showModal,
}) => {
  const { user } = useContext(AuthContext);
  const intl = useIntl();
  const { data, isLoading, isError } = useAddresses(user.company_id);

  const handleAddOfficeClick = useCallback(() => {
    showModal({
      type: "ADD_OFFICE_ADDRESS_MODAL",
      data: {
        title: intl.formatMessage({
          id: "settings.addresses.add.heading",
        }),
        ctaTitle: intl.formatMessage({
          id: "settings.addresses.add.heading",
        }),
        companyId: user.company_id,
      },
    });
  }, []);

  const handleEditOfficeClick = useCallback((address: CompanyAddressType) => {
    showModal({
      type: "ADD_OFFICE_ADDRESS_MODAL",
      data: {
        title: intl.formatMessage({
          id: "settings.addresses.edit.heading",
        }),
        ctaTitle: intl.formatMessage({
          id: "settings.addresses.edit.submit",
        }),
        companyId: user.company_id,
        address,
        edit: true,
      },
    });
  }, []);

  const addresses = useMemo(
    () => data?.items.filter((address) => address.type === "office"),
    [data]
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <HeadingStyled variant='h3'>
        <FormattedMessage id='settings.office.addresses' />
      </HeadingStyled>
      <Typography>
        <FormattedMessage id='settings.addresses.empty.intro' />
      </Typography>
      {addresses?.length > 0 ? (
        <>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id='settings.addresses.table.name' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='settings.addresses.table.address' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='settings.addresses.table.zip' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='settings.addresses.table.city' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='settings.addresses.table.person' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='settings.addresses.table.actions' />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.map((address) => (
                  <TableRow key={address.id}>
                    <TableCell>{address.name}</TableCell>
                    <TableCell>{address.street}</TableCell>
                    <TableCell>{address.zip}</TableCell>
                    <TableCell>{address.city}</TableCell>
                    <TableCell>{address.contact_name}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          handleEditOfficeClick(
                            data.items.find((item) => item.id === address.id)
                          )
                        }
                        variant='text'
                        startIcon={<EditIcon />}
                      >
                        <FormattedMessage id='settings.addresses.table.edit' />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
          <Button variant='outlined' onClick={handleAddOfficeClick}>
            <FormattedMessage id='settings.addresses.add.new' />
          </Button>
        </>
      ) : (
        <NoData>
          <img width='76' src={EmptyListIcon} alt='' />
          <Typography component='h2' variant='h3'>
            <FormattedMessage id='settings.addresses.empty.heading' />
          </Typography>
          <Button variant='outlined' onClick={handleAddOfficeClick}>
            <FormattedMessage id='settings.addresses.add.new' />
          </Button>
        </NoData>
      )}
    </>
  );
};

export default Addresses;
