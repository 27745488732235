import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  img {
    border-radius: 4px;
    border: 1px solid #ececec;
    width: 160px;
    display: block;
  }
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 45px 0 30px;
  flex: 1;
`;

export const SubHeading = styled(Typography)`
  margin-bottom: 44px;
`;

export const HeadingStyled = styled(Typography)`
  margin-bottom: 24px;
`;

export const SecondaryHeadingStyled = styled(Typography)`
  margin-bottom: 16px;
`;

export const TopFormStyled = styled.form`
  margin-bottom: 24px;
`;

export const TableWrapper = styled.div`
  margin-bottom: 24px;
`;

export const CompanySettingsTop = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 32px;

  p {
    width: 200%;
  }
`;
