import React from "react";
import { SelectProps } from "@material-ui/core";
import { useFormContext, Controller, FieldError } from "react-hook-form";
import ChevronDown from "@assets/icons/chevron-down.svg";
import {
  WrapperStyled,
  FormLabelStyled,
  SelectStyled,
  RequiredTitleStyled,
} from "./styled";

type SelectComponentProps = {
  label: React.ReactNode;
  error?: FieldError;
  name: string;
  hooked?: boolean;
  required?: boolean;
  id: string;
};

const SelectIcon = () => <ChevronDown />;

const SelectComponent = ({
  disabled,
  label,
  name,
  error,
  required = false,
  defaultValue = "",
  hooked = true,
  children,
  id,
  className,
  fullWidth,
  ...props
}: SelectComponentProps & SelectProps) => (
  <WrapperStyled className={className}>
    {hooked ? (
      <>
        {!!label && (
          <FormLabelStyled invalid={error ? 1 : 0} htmlFor={id}>
            {label}{" "}
            {required && <RequiredTitleStyled>(required)</RequiredTitleStyled>}
          </FormLabelStyled>
        )}
        <Controller
          render={({ field, fieldState }) => (
            <SelectStyled
              defaultValue={defaultValue}
              id={id}
              disableUnderline
              displayEmpty
              invalid={fieldState.error ? 1 : 0}
              // empty={!value ? 1 : 0}
              IconComponent={SelectIcon}
              disabled={disabled}
              fullWidth={fullWidth}
              {...field}
            >
              {children}
            </SelectStyled>
          )}
          name={name}
          defaultValue={defaultValue}
          // eslint-disable-next-line
          control={useFormContext().control}
          rules={{ required }}
        />
      </>
    ) : (
      <>
        {!!label && <FormLabelStyled htmlFor={id}>{label}</FormLabelStyled>}
        <SelectStyled
          {...props}
          defaultValue={defaultValue}
          id={id}
          disableUnderline
          displayEmpty
          disabled={disabled}
          // @ts-expect-error
          invalid={!!error}
          IconComponent={SelectIcon}
          fullWidth={fullWidth}
        >
          {children}
        </SelectStyled>
      </>
    )}
  </WrapperStyled>
);

export default SelectComponent;
