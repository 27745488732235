import { Typography } from "@material-ui/core";
import { DeliverySlipDetails } from "@types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import BoxImageSrc from "@assets/icons/box.png";
import * as S from "../styled";
import { Page } from "../../styled";
import { Header } from "../Header";
import { Footer } from "../Footer";

type DeliveredProps = {
  delivery: DeliverySlipDetails;
  customMessage?: string;
};

export const Delivered: React.FC<DeliveredProps> = ({
  delivery,
  customMessage,
}) => {
  const intl = useIntl();
  return (
    <Page>
      <Header />
      <S.Container>
        <div>
          <Typography variant='h6'>
            <FormattedMessage
              id='recipient.form.deliveryTitle'
              values={{ id: `#${delivery.readable_id?.toUpperCase()}` }}
            />
          </Typography>
          {customMessage || (
            <Typography variant='h2'>
              <FormattedMessage id='recipient.form.delivered' />
            </Typography>
          )}
        </div>

        <div>
          <Typography>
            <span
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {
                    id: "recipient.form.help",
                  },
                  {
                    email: `<a href='mailto:support@boxday.app'>support@boxday.app</a>`,
                  }
                ),
              }}
            />
          </Typography>
          <img src={BoxImageSrc} alt='' width='200px' />
        </div>
      </S.Container>
      <Footer />
    </Page>
  );
};
