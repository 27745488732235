import React from "react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as S from "../styled";
import LinkIcon from "@assets/icons/link.svg";
import { baseApi } from "@utils/apiService";

export const TrackingLink = ({ id }: { id: string }) => {
  const [trackingUrl, setTrackingUrl] = useState<string | null>(null);
  useEffect(() => {
    baseApi
      .get(`https://api.ahacookies.com`, { params: { delivery_id: id } })
      .then((response) => {
        setTrackingUrl(response.data?.delivery_tracking_url || "");
      })
      .catch(() => {
        setTrackingUrl(null);
      });
  }, [id]);

  return (
    <>
      <S.Label>
        <FormattedMessage id='deliveries.details.label6' />
      </S.Label>
      {trackingUrl ? (
        <a href={trackingUrl} target='_blank' rel='noreferrer'>
          <LinkIcon />
          <FormattedMessage id='deliveries.details.trackingLink' />
        </a>
      ) : (
        <FormattedMessage id='general.notAvailable' />
      )}
    </>
  );
};
