import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { CompanyType, CompanyUpdateType } from "@types";
import { QueryKey } from "react-query";

const { API } = CONSTANTS;

export const fetchCompanyDetails = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: CompanyType[] }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}`, queryKey[2])
    .then((res) => res.data?.items?.[0]);

export const fetchCompaniesDetails = ({
  queryKey,
}: {
  queryKey: string[][];
}): Promise<{ items: CompanyType[] }> => {
  // @ts-expect-error
  return Promise.all(
    queryKey[1]?.map((companyId: string) =>
      baseApi
        .get(`${API.COMPANIES}/${companyId.trim()}`)
        .then((res) => res.data?.items[0])
    )
  );
};

export const updateCompanyDetails = ({
  company_id,
  data,
}: CompanyUpdateType): AxiosPromise<{ items: CompanyType[] }> =>
  baseApi.patch(`${API.COMPANIES}/${company_id}`, data).then((res) => res.data);
