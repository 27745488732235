import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "../utils/history";
// -- start importing reducers
import { reducer as auth } from "./auth";
import { AuthTypes } from "./auth/types";
import { reducer as modal } from "./modal";
import { reducer as account } from "./account";
// -- end importing reducers

const router = connectRouter(history);

const appReducer = combineReducers({
  router,
  // -- start connecting reducers
  auth,
  modal,
  account,
  // -- end connecting reducers
});

// @ts-expect-error
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === AuthTypes.USER_SIGNED_OUT_SUCCEEDED) {
    // eslint-disable-next-line
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootStateType = ReturnType<typeof rootReducer>;

export default rootReducer;
