import React, { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ButtonBase } from "@material-ui/core";
import { useDispatch } from "react-redux";
import AddIcon from "@assets/icons/circle-plus.svg";
import LinkIcon from "@assets/icons/link.svg";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import RemoveIcon from "@assets/icons/remove.svg";
import { GiftType } from "@types";
import * as S from "./styled";
import { showModal } from "../../../../../../modal/actions";

type CreateStep2Props = {
  companyId: string;
  selectedGift: GiftType;
  setSelectedGift: Dispatch<SetStateAction<GiftType>>;
};

export const CreateStep2: React.FC<CreateStep2Props> = ({
  companyId,
  selectedGift,
  setSelectedGift,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <S.StepBody>
      {selectedGift ? (
        <S.SelectedGiftWrapperStyled>
          <S.SelectedGiftStyled
            as='a'
            href={`/gifts/my-gifts/${selectedGift.id}`}
            target='_blank'
          >
            <img
              width='56'
              height='56'
              src={selectedGift.images?.[0]?.url || GiftPlaceholder}
              alt=''
            />
            <div>{selectedGift.name}</div>
            <LinkIcon />
          </S.SelectedGiftStyled>
          <ButtonBase onClick={() => setSelectedGift(null)}>
            <RemoveIcon />
          </ButtonBase>
        </S.SelectedGiftWrapperStyled>
      ) : (
        <S.SelectGiftStyled
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              showModal({
                type: "SELECT_GIFT_MODAL",
                data: {
                  title: intl.formatMessage({
                    id: "orders.create.gifts.modal.title",
                  }),
                  companyId,
                  ctaHandle: setSelectedGift,
                },
              })
            );
          }}
          startIcon={<AddIcon />}
          variant='text'
          color='secondary'
          role='button'
        >
          <FormattedMessage id='orders.create.step2.description' />
        </S.SelectGiftStyled>
      )}
    </S.StepBody>
  );
};
