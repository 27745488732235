import React, { useEffect, useState } from "react";
import { Alert, TextField } from "@components/common";
import { VALIDATION, ROUTES } from "@constants/index";
import { Typography, Grid } from "@material-ui/core";
import {
  FormattedMessage,
  injectIntl,
  useIntl,
  WrappedComponentProps,
} from "react-intl";
import { RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { AuthRouterStateType } from "@types";
import cognitoId from "../../../../cognitoId";
import * as S from "../styled";

const SetupPassword = ({
  history,
  location,
}: RouteComponentProps<{}, {}, AuthRouterStateType> &
  WrappedComponentProps) => {
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!location.state?.email) {
      setShowEmailInput(true);
    }
  }, [location.state?.email]);

  const onSubmit = ({
    newPass,
    email = location.state?.email,
  }: {
    newPass: string;
    email: string;
  }) => {
    setIsLoading(true);

    cognitoId.completeNewPasswordChallenge(
      email,
      location.state?.password,
      newPass,
      null,
      {
        onFailure: () => {
          history.push({
            pathname: "./",
            state: {
              errorMsg: intl.formatMessage({
                id: "general.notifications.error",
              }),
            },
          });
        },
        onSuccess: () => {
          history.push({
            pathname: "./",
            state: {
              errorMsg: intl.formatMessage({
                id: "reset.setupSuccess",
              }),
            },
          });
        },
      }
    );
  };

  return (
    <S.WrapperStyled
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <S.LogoStyled />
      <FormProvider {...methods}>
        <S.FormStyled>
          <S.TitleStyled variant='h2'>
            <FormattedMessage id='reset.heading' />
          </S.TitleStyled>
          {showEmailInput && (
            <TextField
              name='email'
              type='email'
              placeholder={intl.formatMessage({
                id: "input.email.placeholder",
              })}
              pattern={{
                value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                message: intl.formatMessage({
                  id: "input.email.pattern.error",
                }),
              }}
              label={intl.formatMessage({ id: "input.email.label" })}
              error={errors.email}
              offsetbottom='false'
              required
            />
          )}
          <TextField
            name='newPass'
            type='password'
            placeholder={intl.formatMessage({
              id: "input.password.new.label",
            })}
            label={intl.formatMessage({ id: "input.password.new.label" })}
            error={errors.newPass}
            offsetbottom='false'
            // pattern={{
            //   value: VALIDATION.PASSWORD_VALIDATION_REGEXP,
            //   message: intl.formatMessage({
            //     id: "input.password.pattern.error",
            //   }),
            // }}
            // minLength={8}
            required
          />
          <TextField
            name='repeatPass'
            type='password'
            placeholder={intl.formatMessage({
              id: "input.password.new.label",
            })}
            label={intl.formatMessage({ id: "input.password.new.label" })}
            error={errors.repeatPass}
            offsetbottom='false'
            // pattern={{
            //   value: VALIDATION.PASSWORD_VALIDATION_REGEXP,
            //   message: intl.formatMessage({
            //     id: "input.password.pattern.error",
            //   }),
            // }}
            validate={(val: string) => {
              if (watch("newPass") !== val) {
                return false;
              }
              return true;
            }}
            validateMessage={intl.formatMessage({
              id: "change.doNotMatch",
            })}
            // minLength={8}
            required
          />
          <S.SubmitButtonStyled
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            loading={isLoading}
            type='submit'
          >
            <FormattedMessage id='reset.submit' />
          </S.SubmitButtonStyled>
          <Grid
            container
            justifyContent='center'
            direction='column'
            alignItems='center'
          >
            <Typography variant='body1' paragraph>
              <S.LinkStyled color='secondary' to={ROUTES.USER_SIGN_IN}>
                <FormattedMessage id='forgot.link' />
              </S.LinkStyled>
            </Typography>
          </Grid>
          {errorMsg && <Alert variant='warning'>{errorMsg}</Alert>}
        </S.FormStyled>
      </FormProvider>
    </S.WrapperStyled>
  );
};

export default injectIntl(SetupPassword);
