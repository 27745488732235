import { NavLink, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Avatar, Divider, IconButton, MenuItem } from "@material-ui/core";

export const HeaderWrapperStyled = styled.div<{
  isOpened: boolean;
  isAdmin: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.grey[50]};

  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 100vh;
  }

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      padding-bottom: 90px;
    `}

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 8px 16px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    height: ${({ isOpened }) => (isOpened ? "100vh" : "inherit")};
  }
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 44px;
  margin-bottom: 32px;
`;

export const LogoLinkStyled = styled(Link)`
  display: inline-flex;
`;

export const LogOutButton = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  margin-top: auto;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-decoration: underline;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0;
  }
`;

export const NavItemLinkStyled = styled(NavLink)`
  font-size: 15px;
  font-weight: 500;
  flex: 1;
  min-height: auto;
  color: ${({ theme }) => theme.palette.common.black};
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 0;
  flex: 1 1 100%;
  width: 100%;

  &.active,
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
    transition: 250ms;

    svg path {
      stroke: ${({ theme }) => theme.palette.primary.main};
      transition: 250ms;
    }
  }
`;

export const IconButtonStyled = styled(IconButton)``;

export const DividerStyled = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const UserNameStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 6px;
  margin-bottom: 8px;
`;

export const SubMenuItemStyled = styled(MenuItem)`
  display: flex;
  gap: 8px;

  a {
    display: flex;
    gap: 8px;
    color: inherit;
    align-items: center;
    text-decoration: none;
  }
`;

export const AvatarStyled = styled(Avatar)`
  width: 46px;
  height: 46px;
  background-color: #fff;
  font-size: 16px;
  line-height: 22px;
  color: #000;
`;
