import React from "react";

import { Modal, TextField } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { OrderType } from "@types";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateOrderGiftNote, useOrderDeliveries } from "../../../../hooks";
import { ResetNoteButton } from "../styled";

export const EditNoteModal: React.FC<{
  handleClose: () => void;
  order: OrderType;
  note: string;
  companyId: string;
}> = ({ handleClose, order, companyId, note }) => {
  const intl = useIntl();
  const { mutate: updateOrderGiftNote, status } = useUpdateOrderGiftNote();

  const { data: deliveries, isLoading: loadingDeliveries } = useOrderDeliveries(
    {
      company_id: companyId,
      order_id: order.id,
    }
  );

  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  // eslint-disable-next-line
  const onSubmit = ({ note }: { note: string }) => {
    updateOrderGiftNote({
      order_id: order.id,
      company_id: companyId,
      delivery_id: deliveries?.items[0].id,
      data: {
        custom_gift_note_text: note,
      },
    });
  };

  if (status === "success") {
    toast.success(
      intl.formatMessage({
        id: "general.notifications.success",
      })
    );
    handleClose();
  }
  if (status === "error") {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    handleClose();
  }

  return (
    <Modal
      show
      title={intl.formatMessage(
        {
          id: "orders.modal.editNote.title",
        },
        {
          name: order.name,
        }
      )}
      ctaTitle={intl.formatMessage({
        id: "general.save",
      })}
      cancelTitle={intl.formatMessage({
        id: "general.cancel",
      })}
      maxWidth='xs'
      cta={handleSubmit(onSubmit)}
      handleClose={handleClose}
      isLoading={status === "loading" || loadingDeliveries}
    >
      <FormProvider {...methods}>
        <TextField
          label={intl.formatMessage({
            id: "orders.modal.editNote.inputLabel",
          })}
          name='note'
          multiline
          minRows={3}
          required
          defaultValue={note}
          error={errors.note}
        />
        <ResetNoteButton
          disableRipple
          type='button'
          variant='text'
          color='secondary'
          onClick={() => {
            updateOrderGiftNote({
              order_id: order.id,
              company_id: companyId,
              delivery_id: deliveries?.items[0].id,
              data: {
                custom_gift_note_text: null,
              },
            });
          }}
        >
          <FormattedMessage id='orders.modal.editNote.reset' />
        </ResetNoteButton>
      </FormProvider>
    </Modal>
  );
};
