import React from "react";
import { FormattedMessage } from "react-intl";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

export const OrdersTableHead = ({
  type,
}: {
  type: "birthday" | "anniversary";
}) => (
  <TableHead>
    <TableRow>
      <TableCell>
        {type === "birthday" ? (
          <FormattedMessage id='orders.list.table.birthday' />
        ) : (
          <FormattedMessage id='orders.list.table.anniversary' />
        )}
      </TableCell>
      <TableCell>#</TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.status' />
      </TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.name' />
      </TableCell>
      <TableCell>
        <FormattedMessage id='orders.list.table.actions' />
      </TableCell>
    </TableRow>
  </TableHead>
);
