import { useQuery, UseQueryResult } from "react-query";
import { CompanyType, PagingType } from "@types";

import { fetchCompanies } from "./api";

export const useCompanies = (
  page: number,
  searchValue: string,
  enabled: boolean
): UseQueryResult<{
  items: CompanyType[];
  paging: PagingType;
  enabled: boolean;
}> =>
  useQuery(["companies", page, searchValue], fetchCompanies, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled,
  });
