import styled from "styled-components";
import { STYLE } from "@constants/index";
import { Alert } from "@components/common";

export const Container = styled.div`
  display: grid;
  align-items: center;
  gap: 24px;
  position: relative;
  padding-bottom: 50px;
  width: 100%;

  @media (${STYLE.device.tablet}) {
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
    gap: 100px;
    align-items: flex-start;
  }

  img {
    width: 100%;
    border-radius: 12px;
    max-width: 442px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    position: sticky;
    top: 20px;
  }
`;

export const MerchantInfo = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  gap: 10px;
  border: 0;
  background: transparent;
  cursor: pointer;
  text-align: left;
  align-self: flex-start;

  u {
    text-decoration: underline;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    svg path {
      stroke: currentColor;
    }
  }
`;

export const SubRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BadgeStyled = styled.span`
  font-weight: 600;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-weight: 400;

  svg path {
    stroke: #00ca81;
  }
`;

export const NetPrice = styled.div`
  font-size: 15px;
  margin-top: 6px;
`;

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  position: sticky;
  top: 20px;
  max-width: 440px;
  margin-left: auto;

  img {
    display: block;
    object-fit: cover;
  }

  .hero {
    grid-column: 1 / 5;
    height: 440px;
  }

  button {
    border: 0;
    padding: 0;
    border-radius: 8px;
    background-color: transparent;
    display: block;
    position: relative;
    cursor: pointer;

    &:disabled {
      cursor: default;
      :after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }
`;

export const AlertStyled = styled(Alert)`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  font-weight: 400;
  padding: 18px 32px;

  > div {
    flex-direction: row;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }
`;
