import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import { AutomationType, CompanyAddressType, ContactType } from "@types";
// import CompletedIcon from "@assets/icons/completed.svg";
import CalendarIcon from "@assets/icons/calendar.svg";
import PinIcon from "@assets/icons/map-pin.svg";
import * as S from "../../../styled";
import { CreateStep4 } from "./Step4";

export const DateAndDelivery: React.FC<{
  companyId: string;
  expanded: boolean;
  invalid: boolean;
  contacts: ContactType[];
  offices: CompanyAddressType[];
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  payload: AutomationType | null;
  disabled: boolean;
  completed: boolean;
}> = ({
  companyId,
  expanded,
  invalid,
  handleShowStepContent,
  offices,
  contacts,
  payload,
  disabled,
  completed,
}) => {
  const [inProgress, setInProgress] = useState(false);

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {/* {completed && <CompletedIcon />} */}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='automations.create.step4.title' />
            </Typography>
            {completed && payload?.delivery_details_source_type ? (
              <S.DateAndLocationRow>
                <div>
                  <CalendarIcon />
                  {payload.delivery_date_type === "birthday" ? (
                    <FormattedMessage id='automations.create.step4.whenNotify.title1' />
                  ) : (
                    <FormattedMessage id='automations.create.step4.whenNotify.title2' />
                  )}
                </div>
                <div>
                  <PinIcon />
                  {payload.delivery_details_source_type === "predefined" ? (
                    <>
                      <FormattedMessage id='automations.create.step4.resultFromOfficeMsg' />{" "}
                      {payload.delivery_address && payload.delivery_address?.name}
                    </>
                  ) : (
                    <FormattedMessage id='automations.create.step4.resultFromAskMsg' />
                  )}
                </div>
              </S.DateAndLocationRow>
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='automations.create.step4.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!expanded && (
          <Button
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(2, true);
            }}
            variant='outlined'
            color='default'
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='automations.create.step.overview' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>
      {expanded && (
        <CreateStep4
          companyId={companyId}
          onClose={() => {
            setInProgress(false);
            handleShowStepContent(2, false);
          }}
          selectedAddress={payload?.delivery_address?.id}
          contacts={contacts}
          offices={offices}
          payload={payload}
        />
      )}
    </S.CreateCampaignStep>
  );
};
