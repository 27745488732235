import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Loader, NoData, Pagination, Error } from "@components/common";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import EmptyListIcon from "@assets/icons/addresses-empty.png";
import AddIcon from "@assets/icons/circle-plus.svg";
import { AuthContext } from "@providers/AuthProvider";
import { Item } from "./Item";
import { useAutomations } from "../../hooks";
import * as S from "../styled";
import { Container, ItemAdd } from "./styled";

export const List: React.FC = () => {
  const { user } = useContext(AuthContext);
  const intl = useIntl();
  const [paginationPage, setPaginationPage] = useState(1);
  const {
    data: automations,
    isLoading,
    isError,
  } = useAutomations(user.company_id, "upcoming", paginationPage);

  if (isError) {
    return <Error />;
  }

  if (isLoading) return <Loader />;

  return (
    <S.Container>
      <S.HeaderTopStyled>
        <Typography variant='h2'>
          {intl.formatMessage({
            id: "automations.title",
          })}
        </Typography>
      </S.HeaderTopStyled>
      {automations?.items.length > 0 ? (
        <Container>
          {automations.items.map((automation) => (
            <Item automation={automation} key={automation.id} />
          ))}
          <ItemAdd to='/automations/create'>
            <AddIcon />
            <FormattedMessage id='automations.empty.button' />
          </ItemAdd>
        </Container>
      ) : (
        <NoData>
          <img width='76' src={EmptyListIcon} alt='' />
          <Typography component='h2' variant='h3'>
            <FormattedMessage id='automations.empty.heading' />
          </Typography>
          <Button
            variant='outlined'
            color='primary'
            component={Link}
            to='/automations/create'
          >
            <FormattedMessage id='automations.empty.button' />
          </Button>
        </NoData>
      )}
      <Pagination
        page={paginationPage}
        onChange={setPaginationPage}
        total={automations?.paging.total_records}
      />
    </S.Container>
  );
};
