import React, { useEffect, useState, useMemo } from "react";
import {
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  SvgIcon,
  MenuItem,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { getHighlightedText } from "@utils/common";
import { ICONS } from "@constants/index";
import { Table, Link } from "../";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  ButtonBaseStyled,
  SelectStyled,
  TableWrapperStyled,
  LinearProgressStyled,
} from "./styled";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";

type TableBodyItem = {
  [key: string]: {
    title: string;
    link?: string;
    component?: () => JSX.Element;
    type?: string;
  };
};

interface useTableProps {
  tableHeadData: {
    field: string;
    title: string;
    sortable?: boolean;
  }[];
  tableBodyData: TableBodyItem[];
  isLoading: boolean;
  searchValue?: string;
  EmptyList?: React.FC<{ searchValue: string }>;
}

// @ts-expect-error
const FilterIcon = (props, ref) => (
  <SvgIcon
    {...props}
    aria-label='Filter'
    fill='none'
    viewBox='0 0 15 14'
    fontSize='small'
    // eslint-disable-next-line
    component={(componentProps) => (
      <svg {...componentProps}>
        <path
          d={ICONS.FILTER}
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke='#000'
          strokeWidth='1'
          fill='none'
        />
      </svg>
    )}
    ref={ref}
  />
);

export default function useTable({
  tableHeadData,
  tableBodyData,
  searchValue,
  isLoading,
  EmptyList,
}: useTableProps) {
  const [sortedField, setSortedField] = useState("");
  const [sortedDirection, setSortedDirection] = useState("asc");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCell, setShowCell] = useState("");
  const [firstCell] = useState(tableHeadData[0].field);

  const filteredHeading = useMemo(() => {
    if (showCell) {
      return [
        tableHeadData.find((filteredItem) => filteredItem.field === firstCell),
      ];
    }

    return [...tableHeadData];
  }, [showCell, tableHeadData, firstCell]);

  const sortedItems = useMemo(() => {
    let sorted = [...tableBodyData];

    if (sortedField) {
      sorted = sorted.sort((a, b) => {
        if (a[sortedField].type === "date") {
          if (sortedDirection === "asc") {
            return dayjs(
              a[sortedField].title,
              DEFAULT_DATE_FORMAT_SHORT
            ).isAfter(
              dayjs(b[sortedField].title, DEFAULT_DATE_FORMAT_SHORT),
              "date"
            )
              ? 1
              : -1;
          } else {
            return dayjs(
              a[sortedField].title,
              DEFAULT_DATE_FORMAT_SHORT
            ).isAfter(
              dayjs(b[sortedField].title, DEFAULT_DATE_FORMAT_SHORT),
              "date"
            )
              ? -1
              : 1;
          }
        }
        if (a[sortedField].title > b[sortedField].title) {
          return sortedDirection === "asc" ? -1 : 1;
        }
        if (a[sortedField].title < b[sortedField].title) {
          return sortedDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    if (searchValue) {
      sorted = sorted.filter((item) =>
        Object.keys(item)
          .map((key) => {
            let exact = false;
            if (
              item[key]?.title
                ?.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
              exact = true;
            }
            return exact;
          })
          .find((sortedItem) => sortedItem)
      );
    }

    if (showCell) {
      sorted = sorted.reduce(
        (acc, item) => [
          ...acc,
          {
            [firstCell]: item[firstCell],
            [showCell]: item[showCell],
          },
        ],
        []
      );
    }
    return sorted;
  }, [
    sortedField,
    tableBodyData,
    sortedDirection,
    searchValue,
    showCell,
    firstCell,
  ]);

  const handleSortClick = (field: string) => {
    if (field === sortedField) {
      setSortedDirection(sortedDirection === "asc" ? "des" : "asc");
    } else {
      setSortedDirection("asc");
    }
    setSortedField(field);
  };

  useEffect(() => {
    if (isMobile && tableHeadData) {
      setShowCell(tableHeadData[1].field);
    } else {
      setShowCell("");
    }
  }, [isMobile, tableHeadData]);

  const RenderTable = () => (
    <TableWrapperStyled>
      {isLoading && <LinearProgressStyled />}
      <Table>
        <TableHead>
          <TableRow>
            {filteredHeading.map((item) => (
              <TableCell key={uuid()}>
                {item.sortable ? (
                  <ButtonBaseStyled
                    type='button'
                    onClick={() => handleSortClick(item.field)}
                    disableRipple
                  >
                    {sortedField === item.field && sortedDirection === "asc" ? (
                      <svg width='15' height='16' fill='none'>
                        <path
                          d='M15 8L13.6781 6.67812L8.4375 11.9094V0.5H6.5625V11.9094L1.33125 6.66875L0 8L7.5 15.5L15 8Z'
                          fill='#263238'
                          id='svg_1'
                          transform='rotate(-180 7.500000000000001,7.999999999999999) '
                        />
                      </svg>
                    ) : (
                      <svg
                        width='15'
                        height='16'
                        viewBox='0 0 15 16'
                        fill='none'
                      >
                        <path
                          d='M15 8L13.6781 6.67812L8.4375 11.9094V0.5H6.5625V11.9094L1.33125 6.66875L0 8L7.5 15.5L15 8Z'
                          fill='#263238'
                        />
                      </svg>
                    )}
                    {item.title}
                  </ButtonBaseStyled>
                ) : (
                  item.title
                )}
              </TableCell>
            ))}
            {showCell ? (
              <TableCell align='right'>
                <SelectStyled
                  IconComponent={React.forwardRef(FilterIcon)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setShowCell(e.target.value)
                  }
                  value={showCell}
                  displayEmpty
                  disableUnderline
                >
                  {tableHeadData.slice(1).map((headItem) => (
                    <MenuItem key={uuid()} value={headItem.field}>
                      {headItem.title}
                    </MenuItem>
                  ))}
                </SelectStyled>
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            opacity: isLoading ? "0.3" : "1",
          }}
        >
          {sortedItems.map((item) => (
            <TableRow hover key={uuid()}>
              {Object.keys(item).map((key) => {
                if (item[key].link) {
                  return (
                    <TableCell key={uuid()}>
                      <Link to={item[key].link}>
                        {getHighlightedText(item[key]?.title, searchValue)}
                      </Link>
                    </TableCell>
                  );
                }
                if (item[key].component) {
                  return (
                    <TableCell key={uuid()}>{item[key].component()}</TableCell>
                  );
                }
                return (
                  <TableCell key={uuid()}>
                    {getHighlightedText(item[key]?.title, searchValue)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {sortedItems.length === 0 && searchValue && (
            <TableRow>
              <TableCell colSpan={tableHeadData.length + 1}>
                <EmptyList searchValue={searchValue} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableWrapperStyled>
  );

  return [RenderTable];
}
