import React from "react";
import styled from "styled-components";

const LogoStyled = styled.svg`
  fill: ${({ theme }) => theme.palette.primary.main};
`;

const Logo: React.FC = (props) => (
  <LogoStyled {...props} width='85' height='24' viewBox='0 0 85 24' fill='none'>
    <path
      d='M8.15204 19.4162C11.584 19.4162 13.82 16.7642 13.82 12.3442C13.82 8.34016 11.584 5.29816 8.12604 5.29816C6.30604 5.29816 5.16204 6.05216 4.33004 7.19616H4.25204V0.410156H0.0140381V19.0002H4.07004V17.3102H4.12204C4.95404 18.6882 6.38404 19.4162 8.15204 19.4162ZM6.95604 16.0622C5.21404 16.0622 4.12204 14.5542 4.12204 12.3442C4.12204 10.1602 5.03204 8.52216 6.90404 8.52216C8.62004 8.52216 9.53004 10.0302 9.53004 12.3182C9.53004 14.6322 8.62004 16.0622 6.95604 16.0622Z'
      fill='#E63761'
    />
    <path
      d='M21.7484 16.4002C19.9024 16.4002 18.9404 14.7882 18.9404 12.3702C18.9404 9.95216 19.9024 8.31416 21.7484 8.31416C23.5944 8.31416 24.5824 9.95216 24.5824 12.3702C24.5824 14.7882 23.5944 16.4002 21.7484 16.4002ZM21.7744 19.4162C26.0644 19.4162 28.8724 16.3742 28.8724 12.3702C28.8724 8.36616 26.0644 5.32416 21.7744 5.32416C17.5104 5.32416 14.6504 8.36616 14.6504 12.3702C14.6504 16.3742 17.5104 19.4162 21.7744 19.4162Z'
      fill='#E63761'
    />
    <path
      d='M28.3051 19.0002H32.9331L35.3511 15.0222H35.4031L37.5871 19.0002H42.5011L37.9771 11.9542L41.9291 5.68816H37.4831L35.6111 9.12016H35.5591L33.5831 5.68816H28.7471L32.9331 12.0062L28.3051 19.0002Z'
      fill='#E63761'
    />
    <path
      d='M47.9979 19.4162C49.7399 19.4162 51.1179 18.5842 51.9239 17.2062H51.9758V19.0002H56.0319V0.410156H51.7938V7.19616H51.7159C50.9359 6.07816 49.8699 5.29816 47.9979 5.29816C44.5659 5.29816 42.1739 8.18416 42.1739 12.3442C42.1739 16.7902 44.5919 19.4162 47.9979 19.4162ZM49.0899 16.0622C47.4519 16.0622 46.4639 14.6062 46.4639 12.2922C46.4639 10.0562 47.4519 8.49616 49.1418 8.49616C50.9879 8.49616 51.8979 10.1082 51.8979 12.3442C51.8979 14.5282 50.8579 16.0622 49.0899 16.0622Z'
      fill='#E63761'
    />
    <path
      d='M61.983 19.3382C63.985 19.3382 65.155 18.6362 65.935 17.5182H65.987C66.091 18.1942 66.221 18.7142 66.429 19.0002H70.537V18.8182C70.173 18.5842 70.069 17.9862 70.069 16.9202V10.2122C70.069 8.54816 69.523 7.24816 68.379 6.41616C67.417 5.68816 66.065 5.32416 64.063 5.32416C60.033 5.32416 58.135 7.43016 58.031 9.90016H61.931C62.061 8.78216 62.737 8.18416 64.089 8.18416C65.363 8.18416 65.909 8.75616 65.909 9.61416C65.909 10.5242 65.025 10.7842 62.529 11.0962C59.773 11.4602 57.433 12.3442 57.433 15.2822C57.433 17.9082 59.331 19.3382 61.983 19.3382ZM63.335 16.6342C62.295 16.6342 61.515 16.2182 61.515 15.1522C61.515 14.1382 62.191 13.7222 63.829 13.3582C64.687 13.1502 65.467 12.9422 66.013 12.6562V14.2422C66.013 15.6722 64.921 16.6342 63.335 16.6342Z'
      fill='#E63761'
    />
    <path
      d='M72.3115 23.3942H75.0675C77.7455 23.3942 79.0195 22.3022 80.0855 19.1822L84.6875 5.68816H80.4495L78.7075 11.3562C78.2915 12.6562 77.9275 14.4242 77.9275 14.4242H77.8755C77.8755 14.4242 77.4595 12.6562 77.0435 11.3562L75.2495 5.68816H70.7775L74.7035 15.9322C75.2495 17.3362 75.5095 18.1162 75.5095 18.6882C75.5095 19.5982 75.0155 20.0922 73.7675 20.0922H72.3115V23.3942Z'
      fill='#E63761'
    />
  </LogoStyled>
);

export default Logo;
