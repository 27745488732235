import { Alert, Select, Checkbox } from "@components/common";
import { Typography } from "@material-ui/core";
import styled, { css } from "styled-components";
import BG from "@assets/icons/contactDateIllustration.png";

export const Container = styled.div`
  padding: 0 0 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 894px;
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 0 32px;
  flex: 1;
  border-bottom: 1px solid #ececec;

  h2 {
    display: inline-flex;
    align-items: center;
    gap: 6px;
  }
`;

export const CreateCampaignSteps = styled.div`
  border: 1px solid #ececec;
  border-radius: 12px;
  overflow: hidden;
`;

export const CreateCampaignStep = styled.div<{
  invalid?: boolean;
  expanded: boolean;
}>`
  padding: 28px 32px;
  background-color: #fcfcfc;

  ${({ expanded = false }) =>
    expanded &&
    css`
      background-color: #fff;
    `}

  ${({ invalid = false, theme }) =>
    invalid &&
    css`
      h4,
      h4 + p {
        color: ${theme.palette.primary.main};
      }
    `}

  & + & {
    border-top: 1px solid #ececec;
  }
`;

export const CreateCampaignStepHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 18px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const SelectedGiftWrapperStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
`;

export const CreateCampaignStepHeaderLeft = styled.div`
  display: flex;
  gap: 16px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  ${SelectedGiftWrapperStyled} {
    margin-top: 12px;
  }
`;

export const CreateCampaignStepHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StepBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 0;
  align-items: flex-start;
  gap: 14px;

  hr {
    width: 100%;
  }
`;

export const StepSelectRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    margin-left: -18px;
  }
`;

export const SelectStyled = styled(Select)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0;

  label {
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: 400;
  }
`;

export const AskRecipient = styled.div`
  padding: 16px 24px;
  background: #fff9ea;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 674px;

  .MuiSelect-selectMenu {
    background-color: #fff;
  }

  p {
    margin-bottom: 0;
  }
`;

export const InfoTextStyled = styled(Typography)`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  display: flex;
  max-width: 538px;
  margin-bottom: 0;

  svg {
    flex-shrink: 0;
  }

  button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

// STEP 3 Personalization

export const DynamicFieldsWrapper = styled.div`
  max-width: 670px;
  width: 100%;

  label {
    font-weight: 400;
    font-size: 15px;
  }

  .MuiInput-input {
    line-height: 22px;
    font-size: 15px;
    width: 100%;
  }
`;

export const DynamicFieldsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const DynamicFieldsDemo = styled.div`
  padding: 16px 24px;
  background-color: #fff9ea;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 12px;
`;

export const DynamicFieldsDemoLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }

  p {
    margin-bottom: 0;
  }
`;

export const DateAndLocationRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  align-items: center;

  > div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export const AlertStyled = styled(Alert)`
  align-self: flex-start;
  display: block;
  max-width: 630px;
  background-color: #fed8cb;
  font-weight: normal;
  margin-top: 12px;
  font-size: 15px;

  a {
    color: inherit;
    font-size: 15px;
  }

  button {
    border: 0;
    background-color: transparent;
    padding: 0;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15px;
    font-family: inherit;
  }
`;

export const OrderList = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const Order = styled.div`
  max-width: 300px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  border-radius: 12px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #ececec;

  img {
    height: 208px;
    width: 100%;
    object-fit: cover;
    display: block;
  }
`;

export const CheckBoxComponentStyled = styled(Checkbox)`
  label {
    margin: 0;
  }
`;

export const MenuEmpty = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 14px;

  a {
    margin-top: 16px;
  }
`;

export const AlertWrapper = styled.div`
  max-width: 630px;

  > div {
    margin-bottom: 0;
    font-weight: 400;
    svg {
      display: none;
    }
  }
`;

export const MissingDetailsWrapper = styled.div`
  table {
    border: 0;
    td {
      background-color: #fff9ea;
      border-color: #ececec;
    }
    tr {
      border-left: 0;
      border-right: 0;
    }

    div[type="secondary"] {
      background-color: transparent;
      border-color: #c6c6c6;
      color: #c6c6c6;
    }
  }
`;

export const OrderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 48px;
`;

export const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
// FormHeroField

export const FormHeroField = styled.div`
  padding: 1rem 1.625rem;
  background-color: #fff9ea;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-image: url(${BG});
  background-position: top right;
  background-repeat: no-repeat;

  .MuiSelect-selectMenu {
    background-color: #fff;
  }

  > div {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    label {
      margin: 0;
    }
  }
`;

export const TagInfoToggle = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #a0a0a0;
  margin-left: 0 !important;
  font-weight: 600;
`;
