import React from "react";
import { MenuList } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import CheckIcon from "@assets/icons/gift-tick.svg";
import { TagMenuItemStyled, TagsPaperStyled } from "../../../styled";

export const RenderTagsDropdown = ({
  tags,
  newTags,
  userTags,
  value,
  resetField,
  addUserTag,
  addNewTag,
}: {
  tags: string[];
  newTags: Set<string>;
  userTags: Set<string>;
  value: string;
  resetField: (field: string) => void;
  addUserTag: (tag: string) => void;
  addNewTag: (tag: string) => void;
}) => {
  const filter = (item: string) =>
    item?.toLowerCase().includes(value.toLowerCase());

  if (!value) return null;

  return (
    <TagsPaperStyled id='form-input-tags'>
      <MenuList>
        {[...Array.from(newTags)].filter(filter).map((tag) => (
          <TagMenuItemStyled disabled key={tag}>
            <CheckIcon />
            {tag}
          </TagMenuItemStyled>
        ))}
        {[...Array.from(userTags)].filter(filter).map((tag) => (
          <TagMenuItemStyled
            disabled
            key={tag}
            onClick={() => {
              addUserTag(tag);
              resetField("tags");
            }}
          >
            <CheckIcon />
            {tag}
          </TagMenuItemStyled>
        ))}
        {[...tags].filter(filter).map((tag) => {
          if (!userTags.has(tag)) {
            return (
              <TagMenuItemStyled
                key={tag}
                onClick={() => {
                  addUserTag(tag);
                  resetField("tags");
                }}
              >
                {tag}
              </TagMenuItemStyled>
            );
          }
          return null;
        })}
        {![...Array.from(newTags), ...tags]?.find(
          (item) => item.toLowerCase() === value.toLocaleLowerCase()
        ) ? (
          <TagMenuItemStyled
            onClick={() => {
              addNewTag(value);
              resetField("tags");
            }}
          >
            <span>
              <FormattedMessage id='contacts.add.form.tagAdd' />
            </span>{" "}
            <b>&quot;{value}&quot;</b>
          </TagMenuItemStyled>
        ) : null}
      </MenuList>
    </TagsPaperStyled>
  );
};
