import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import { AutomationPayload } from "@types";
import CompletedIcon from "@assets/icons/completed.svg";
import * as S from "../../styled";
import { CreateStep5 } from "./Step5";
import { FormType } from "../types";

export const AdminSettings: React.FC<{
  expanded: boolean;
  invalid: boolean;
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  payload: AutomationPayload | null;
  setPayload: Dispatch<SetStateAction<AutomationPayload>>;
  disabled: boolean;
  completed: boolean;
  formState: FormType;
  setFormState: Dispatch<SetStateAction<FormType>>;
}> = ({
  expanded,
  invalid,
  handleShowStepContent,
  payload,
  setPayload,
  disabled,
  completed,
  formState,
  setFormState,
}) => {
  const [inProgress, setInProgress] = useState(false);

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {completed && <CompletedIcon />}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='automations.create.step5.title' />
            </Typography>
            <Typography color='textSecondary'>
              <FormattedMessage id='automations.create.step5.description' />
            </Typography>
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!expanded && (
          <Button
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(3, true);
            }}
            variant='outlined'
            color='primary'
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='automations.create.step.edit' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>
      {expanded && (
        <CreateStep5
          onClose={() => {
            setInProgress(false);
            handleShowStepContent(3, false);
          }}
          setPayload={setPayload}
          payload={payload}
          formState={formState}
          setFormState={setFormState}
        />
      )}
    </S.CreateCampaignStep>
  );
};
