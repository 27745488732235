import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Loader, Select, TextField, Error } from "@components/common";
import { Button, Grid, MenuItem, Typography } from "@material-ui/core";
import { AutomationPayload } from "@types";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import RepeatIcon from "@assets/icons/repeat.svg";
import { Summary } from "@components/common/Order/Summary/Summary";
import * as S from "../styled";
import { To } from "./To";
import { Gift } from "./Gift";
import { DateAndDelivery } from "./DateAndDelivery";
import { usePrefetchCreateOrderData } from "../usePrefetchCreateOrderData";
import { FormType } from "./types";
// hooks
import { useCreateAutomation } from "../../hooks";
import { useContactValidate } from "../../../../api/automation";
import { AdminSettings } from "./AdminSettings";
import { AuthContext } from "@providers/AuthProvider";
import { useSummaryCalculations } from "../../../../hooks/useSummaryCalculations";

const initialState = new Array(4).fill({ show: false, completed: false });

export const CreateAutomation: React.FC = () => {
  const { user } = useContext(AuthContext);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [orderName, setOrderName] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [formState, setFormState] = useState<FormType | null>({
    selectedContacts: [],
    selectedContactsCount: 0,
    selectedTagId: null,
    toType: "contact_id",
    automationStop: "date",
  });
  const [contactDateField, setContactDateField] = useState<
    "birthday" | "anniversary"
  >("birthday");

  const [payLoad, setPayload] = useState<AutomationPayload | null>(null);

  const { isError, isLoading, offices, tags, gifts } =
    usePrefetchCreateOrderData(user.company_id);

  const {
    mutate: createAutomation,
    isLoading: createOrderLoading,
    status,
    data: createdAutomationData,
    reset,
  } = useCreateAutomation();

  const { mutate: validateContactsAddress, data: validationAddressResponse } =
    useContactValidate();

  const { mutate: validateContactsDate, data: validationDateResponse } =
    useContactValidate();

  const [steps, setSteps] = useState(initialState);

  const handleShowStepContent = useCallback(
    (stepKey: number, show: boolean, completed?: boolean) => {
      setSteps((state) =>
        state.map((step, index) => {
          if (index === stepKey) {
            return {
              completed,
              show,
            };
          }
          return step;
        })
      );
    },
    [setSteps]
  );

  const giftsQuantity = useMemo(
    () => formState.selectedContactsCount,
    [formState]
  );

  const selectedGift = useMemo(() => {
    if (!payLoad || !gifts) return null;
    return gifts.find((gift) => gift.id === payLoad.gift_id);
  }, [payLoad, gifts]);

  const { total } = useSummaryCalculations({
    giftsQty: giftsQuantity,
    giftPrice:
      selectedGift?.prices?.find(
        (price) => price.country_code === user.currency
      )?.amount || 0,
    vat: selectedGift?.prices[0]?.vat || 0,
    shippingQty: formState.selectedContactsCount,
    freeShipping: selectedGift?.flags?.price_includes_shipping,
  });

  useEffect(() => {
    if (id && gifts) {
      const isValidId = gifts.some((gift) => gift.id === id);
      if (isValidId) {
        setPayload((payload) => ({
          ...payload,
          gift_id: id,
        }));
      }
    }
  }, [id, gifts]);

  useEffect(() => {
    if (status === "success") {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
      reset();
      setPayload(null);
      history.push({
        pathname: `/automations/${createdAutomationData?.id}/upcoming-orders`,
      });
    }
    if (status === "error") {
      toast.error(
        intl.formatMessage({
          id: "general.notifications.error",
        })
      );
      reset();
      setPayload(null);
    }
  }, [status]);

  useEffect(() => {
    if (
      (payLoad?.contacts?.length || payLoad?.tags) &&
      (payLoad.delivery_details_source_type === "contact_custom_address" ||
        payLoad.delivery_details_source_type === "contact_office_address")
    ) {
      validateContactsAddress({
        company_id: user.company_id,
        data: {
          contacts_filter_type: payLoad.contacts_filter_type,
          ...(payLoad.contacts_filter_type === "contact_id" && {
            contacts: payLoad.contacts,
          }),
          ...(payLoad.contacts_filter_type === "contact_tag" && {
            tags: payLoad.tags,
          }),
          contact_address_type: payLoad.delivery_details_source_type,
        },
      });
    }
  }, [payLoad?.contacts, payLoad?.tags, payLoad?.delivery_details_source_type]);

  useEffect(() => {
    if (payLoad?.contacts || payLoad?.tags) {
      validateContactsDate({
        company_id: user.company_id,
        data: {
          contacts_filter_type: payLoad.contacts_filter_type,
          ...(payLoad.contacts_filter_type === "contact_id" && {
            contacts: payLoad.contacts,
          }),
          ...(payLoad.contacts_filter_type === "contact_tag" && {
            tags: payLoad.tags,
          }),
          contact_date_type: contactDateField,
        },
      });
    }
  }, [payLoad?.contacts, payLoad?.tags, contactDateField]);

  const invalidDateContacts = useMemo(() => {
    return (
      validationDateResponse?.validations.find(
        (item) => item.type === "contact_date_type"
      )?.contacts || []
    );
  }, [validationDateResponse]);

  const invalidAddressContacts = useMemo(() => {
    return (
      validationAddressResponse?.validations.find(
        (item) => item.type === "contact_address_type"
      )?.contacts || []
    );
  }, [validationAddressResponse]);

  useEffect(() => {
    setPayload((state) => ({
      ...state,
      delivery_date_type: contactDateField,
    }));
  }, [contactDateField]);

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <S.Container>
      <S.FormContainer>
        <S.HeaderTopStyled>
          <Typography variant='h2'>
            <RepeatIcon width={24} height={24} />
            <FormattedMessage id='automations.create.title' />
          </Typography>
        </S.HeaderTopStyled>
        <div>
          <TextField
            label={intl.formatMessage({
              id: "automations.create.name",
            })}
            placeholder={intl.formatMessage({
              id: "automations.create.name",
            })}
            value={orderName}
            // @ts-expect-error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrderName(e.target.value)
            }
            hooked={false}
            error={!isNameValid && !orderName && {}}
            required
            direction='row'
            fullWidth={false}
            offsetbottom='false'
            inputRef={nameInputRef}
          />
        </div>
        <S.FormHeroField>
          <Typography variant='h5'>
            <FormattedMessage id='automations.create.contactDateFieldQuestion' />
          </Typography>
          <Select
            value={contactDateField}
            // @ts-expect-error
            onChange={(e) => setContactDateField(e.target.value)}
            name='contact-date-field'
            id='contactDateField'
            label={intl.formatMessage({
              id: "automations.create.contactDateField",
            })}
            hooked={false}
          >
            <MenuItem value='birthday'>
              <FormattedMessage id='automations.create.contactDateField.option1' />
            </MenuItem>
            <MenuItem value='anniversary'>
              <FormattedMessage id='automations.create.contactDateField.option2' />
            </MenuItem>
          </Select>
        </S.FormHeroField>
        <S.DetailsSection>
          <S.CreateCampaignSteps>
            <To
              companyId={user.company_id}
              tags={tags}
              formState={formState}
              setFormState={setFormState}
              handleShowStepContent={handleShowStepContent}
              payload={payLoad}
              setPayload={setPayload}
              expanded={steps[0].show}
              completed={!!payLoad?.contacts || !!payLoad?.tags}
              disabled={steps.find((step) => step.show)?.show}
              invalid={isSubmitted && !(payLoad?.contacts || payLoad?.tags)}
              missingInfoContacts={invalidDateContacts}
              contactDateType={contactDateField}
            />
            <Gift
              companyId={user.company_id}
              gifts={gifts}
              handleShowStepContent={handleShowStepContent}
              setPayload={setPayload}
              expanded={steps[1].show}
              disabled={steps.find((step) => step.show)?.show}
              invalid={
                isSubmitted && (!payLoad?.gift_id || !payLoad?.gift_note_type)
              }
              selectedId={id}
              payload={payLoad}
            />
            <DateAndDelivery
              companyId={user.company_id}
              missingInfoContacts={invalidAddressContacts}
              offices={offices}
              handleShowStepContent={handleShowStepContent}
              setPayload={setPayload}
              expanded={steps[2].show}
              disabled={steps.find((step) => step.show)?.show}
              completed={!!payLoad?.delivery_details_source_type}
              payload={payLoad}
              invalid={
                isSubmitted &&
                !(
                  payLoad?.delivery_details_source_type ||
                  payLoad?.delivery_non_office_hours_strategy
                )
              }
            />
            <AdminSettings
              handleShowStepContent={handleShowStepContent}
              setPayload={setPayload}
              expanded={steps[3].show}
              disabled={steps.find((step) => step.show)?.show}
              completed={payLoad?.notifications_enabled !== undefined}
              payload={payLoad}
              invalid={
                isSubmitted && payLoad?.notifications_enabled === undefined
              }
              formState={formState}
              setFormState={setFormState}
            />
          </S.CreateCampaignSteps>
          <Summary
            giftsQty={giftsQuantity || 0}
            giftPrice={
              selectedGift?.prices?.find(
                (price) => price.country_code === user.currency
              )?.amount || 0
            }
            vat={selectedGift?.prices[0]?.vat || 0}
            shippingQty={formState.selectedContactsCount}
            freeShipping={selectedGift?.flags?.price_includes_shipping}
          />
        </S.DetailsSection>
        <Grid container justifyContent='space-between'>
          <Button variant='text' component={Link} to='/automations'>
            <FormattedMessage id='general.forms.buttons.cancel' />
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={createOrderLoading || total > user.credit_balance}
            onClick={() => {
              if (!orderName) {
                nameInputRef.current.focus();
                setIsNameValid(false);
              }
              if (
                !payLoad ||
                !(payLoad.contacts || payLoad.tags) ||
                !payLoad.gift_id ||
                !payLoad.delivery_details_source_type ||
                payLoad.notifications_enabled === undefined ||
                !orderName
              ) {
                setIsSubmitted(true);
                return;
              }
              createAutomation({
                company_id: user.company_id,
                data: {
                  name: orderName,
                  delivery_date_type: contactDateField,
                  ...payLoad,
                },
              });
            }}
          >
            <FormattedMessage id='automations.create.title' />
          </Button>
        </Grid>
      </S.FormContainer>
    </S.Container>
  );
};
