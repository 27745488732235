import { UserProfileType } from "@types";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  FC,
  PropsWithChildren,
  useState,
  useMemo,
} from "react";

interface AuthContextProps {
  user: UserProfileType;
  setUser: Dispatch<SetStateAction<UserProfileType>>;
}

export const AuthContext = createContext<AuthContextProps>({
  user: {} as UserProfileType,
  setUser: () => {},
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<UserProfileType>();

  const providerMemo = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={providerMemo}>{children}</AuthContext.Provider>
  );
};
