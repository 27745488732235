import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, MenuItem, Typography } from "@material-ui/core";
import { Radio } from "@components/common";
import { useDispatch } from "react-redux";
import {
  CompanyAddressType,
  AutomationPayload,
  AutomationNonOfficeHoursType,
  DeliveryDetailsSourceType,
  DeliveryNotificationType,
} from "@types";
import AddIcon from "@assets/icons/circle-plus.svg";
import { showModal } from "../../../../modal/actions";
import * as S from "../../styled";
import { formatBoldTag } from "@utils/common";

type CreateStep4Props = {
  companyId: string;
  onClose: () => void;
  selectedAddress?: string;
  offices: CompanyAddressType[];
  setPayload: Dispatch<SetStateAction<AutomationPayload>>;
  payload: AutomationPayload | null;
};

export const CreateStep4: React.FC<CreateStep4Props> = ({
  companyId,
  onClose,
  selectedAddress,
  offices,
  setPayload,
  payload,
}) => {
  const [deliveryDetailsType, setDeliveryDetailsType] = useState<
    "predefined" | "requested"
  >(
    payload?.delivery_details_source_type === "requested_from_recipient"
      ? "requested"
      : "predefined"
  );
  const [deliveryNonOfficeHours, setDeliveryNonOfficeHours] =
    useState<AutomationNonOfficeHoursType>(
      payload?.delivery_non_office_hours_strategy || "next_working_day"
    );
  const [deliveryAddress, setDeliveryAddress] = useState<string | null>(
    payload?.delivery_address_id || ""
  );
  const [deliveryNotifyType, setDeliveryNotifyType] =
    useState<DeliveryNotificationType>(
      payload?.delivery_notification_type || "email"
    );

  // const [deliveryNotifyWhen, setDeliveryNotifyWhen] = useState<number>(
  //   payload?.delivery_notification_number_of_days_in_advance || 0
  // );
  const [deliveryAddressType, setDeliveryAddressType] =
    useState<DeliveryDetailsSourceType>(
      payload?.delivery_details_source_type === "requested_from_recipient"
        ? "office_address"
        : payload?.delivery_details_source_type || "office_address"
    );

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (offices) {
      if (selectedAddress) {
        setDeliveryAddress(
          offices.find((office) => office.id === selectedAddress).id
        );
      } else {
        setDeliveryAddress(offices[0]?.id);
      }
    }
  }, [offices, deliveryAddressType, selectedAddress]);

  return (
    <S.StepBody>
      <Radio
        value={deliveryDetailsType}
        defaultValue={deliveryDetailsType}
        onChange={(e) => setDeliveryDetailsType(e.target.value)}
        name='type'
        items={[
          {
            value: "predefined",
            label: intl.formatMessage({
              id: "automations.create.step4.variant1",
            }),
          },
          {
            value: "requested",
            label: intl.formatMessage({
              id: "automations.create.step4.variant2",
            }),
          },
        ]}
      />
      {deliveryDetailsType === "predefined" && (
        <>
          <div>
            <S.SelectStyled
              value={deliveryNonOfficeHours}
              onChange={(e) => setDeliveryNonOfficeHours(e.target.value)}
              label={
                payload.delivery_date_type === "birthday"
                  ? intl.formatMessage(
                      {
                        id: "automations.create.step4.nonOfficeHours.birthdayLabel",
                      },
                      {
                        b: (chunks) => formatBoldTag(chunks),
                      }
                    )
                  : intl.formatMessage(
                      {
                        id: "automations.create.step4.nonOfficeHours.anniversaryLabel",
                      },
                      {
                        b: (chunks) => formatBoldTag(chunks),
                      }
                    )
              }
              id='non-office-hours'
              name='non-office-hours'
              hooked={false}
            >
              <MenuItem value='next_working_day'>
                <FormattedMessage id='automations.create.step4.nonOfficeHours.variant1' />
              </MenuItem>
              <MenuItem value='previous_working_day'>
                <FormattedMessage id='automations.create.step4.nonOfficeHours.variant2' />
              </MenuItem>
              <MenuItem value='same_day'>
                <FormattedMessage id='automations.create.step4.nonOfficeHours.variant3' />
              </MenuItem>
            </S.SelectStyled>
          </div>

          <S.StepSelectRow>
            <S.SelectStyled
              id='addressType'
              name='addressType'
              label={intl.formatMessage({
                id: "automations.create.step4.predefined.addressLabel",
              })}
              defaultValue={deliveryAddressType}
              value={deliveryAddressType}
              hooked={false}
              onChange={(e) => setDeliveryAddressType(e.target.value)}
            >
              <MenuItem value='office_address' disabled={offices.length === 0}>
                <FormattedMessage id='automations.create.step4.addressType.variant1' />
              </MenuItem>
              <MenuItem value='contact_custom_address'>
                <FormattedMessage id='automations.create.step4.addressType.variant2' />
              </MenuItem>
              <MenuItem value='contact_office_address'>
                <FormattedMessage id='automations.create.step4.addressType.variant3' />
              </MenuItem>
            </S.SelectStyled>
            {deliveryAddressType === "office_address" && (
              <S.SelectStyled
                id='offices'
                name='offices-input'
                label=''
                defaultValue={deliveryAddress}
                value={deliveryAddress}
                hooked={false}
                onChange={(e) => setDeliveryAddress(e.target.value)}
              >
                {offices.map((office) => (
                  <MenuItem key={office.id} value={office.id}>
                    {office.name}
                  </MenuItem>
                ))}
              </S.SelectStyled>
            )}
          </S.StepSelectRow>

          {offices.length === 0 && (
            <Button
              onClick={() => {
                dispatch(
                  showModal({
                    type: "ADD_OFFICE_ADDRESS_MODAL",
                    data: {
                      title: intl.formatMessage({
                        id: "settings.addresses.add.heading",
                      }),
                      ctaTitle: intl.formatMessage({
                        id: "settings.addresses.add.heading",
                      }),
                      companyId,
                    },
                  })
                );
              }}
              startIcon={<AddIcon />}
              variant='text'
              color='primary'
            >
              <FormattedMessage id='automations.create.createOfficeTitle' />
            </Button>
          )}
        </>
      )}
      <div>
        {deliveryDetailsType === "requested" && (
          <S.AskRecipient>
            <Typography>
              <FormattedMessage
                tagName='b'
                id='automations.create.step4.askRecipient.notify'
              />
            </Typography>
            <S.SelectStyled
              id='notify-type-input'
              name='notify-type-input'
              label={intl.formatMessage({
                id: "automations.create.step4.askRecipient.howLabel",
              })}
              defaultValue={deliveryNotifyType}
              value={deliveryNotifyType}
              hooked={false}
              onChange={(e) => setDeliveryNotifyType(e.target.value)}
            >
              <MenuItem value='email'>
                <FormattedMessage id='automations.create.step4.notify.option1' />
              </MenuItem>
              <MenuItem disabled value='sms'>
                <FormattedMessage id='automations.create.step4.notify.option2' />
              </MenuItem>
            </S.SelectStyled>
          </S.AskRecipient>
        )}
      </div>
      <div>
        <Button
          variant='contained'
          color='primary'
          disabled={!deliveryAddress && deliveryDetailsType === "predefined"}
          onClick={() => {
            setPayload((state: AutomationPayload) => {
              const newPayload = {
                ...state,
                delivery_details_source_type:
                  deliveryDetailsType === "predefined"
                    ? deliveryAddressType
                    : "requested_from_recipient",
                delivery_notification_type: deliveryNotifyType,
                ...(deliveryAddressType === "office_address" && {
                  delivery_address_id: deliveryAddress,
                }),
                delivery_non_office_hours_strategy: deliveryNonOfficeHours,
              };

              if (deliveryDetailsType === "requested") {
                delete newPayload.delivery_address_id;
              }

              if (deliveryDetailsType === "predefined") {
                delete newPayload.delivery_notification_type;
              }

              return newPayload;
            });
            onClose();
          }}
        >
          <FormattedMessage id='general.save' />
        </Button>
        <Button color='secondary' onClick={onClose}>
          <FormattedMessage id='general.cancel' />
        </Button>
      </div>
    </S.StepBody>
  );
};
