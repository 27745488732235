import styled from "styled-components";

export const SummaryTableWrapper = styled.div`
  max-width: 400px;

  table {
    tr,
    td {
      border: 0;
      padding: 4px 21px 4px 0;
      vertical-align: top;
    }

    td {
      &:first-child {
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
`;

export const FinSummaryWrapper = styled.div`
  margin: 24px 0;
  > div {
    border: 0;
    padding: 0;
    width: 70%;
  }

  h4 {
    margin-bottom: 12px;
  }

  h4 + p {
    display: none;
  }
`;
