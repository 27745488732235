import {
  UseQueryResult,
  useQuery,
  useQueryClient,
  useMutation,
} from "react-query";
import { TransactionType, RequestTopupType, PagingType } from "@types";
import {
  getCompanyTransactions,
  requestPopup,
  cancelTopUp,
  generateTransactionExport,
} from "./api";

export const useGetCompanyTransactions = (
  companyId: string,
  params: {
    page: number;
  }
): UseQueryResult<{ items: TransactionType[]; paging: PagingType }> =>
  useQuery(["companyTransaction", companyId, params], getCompanyTransactions, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    enabled: !!companyId,
  });

export const useGetCompanyTransactionsPool = (
  companyId: string,
  params: {
    page: number;
  }
): UseQueryResult<{ items: TransactionType[]; paging: PagingType }> =>
  useQuery(
    ["companyTransactionPool", companyId, params],
    getCompanyTransactions,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 2,
      enabled: !!companyId,
    }
  );

export const useRequestTopUp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      data,
    }: {
      company_id: string;
      data: RequestTopupType;
    }) =>
      requestPopup({
        company_id,
        data,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["companyTransaction"]);
    },
  });
};

export const useCancelTopUp = () => {

  return useMutation({
    mutationFn: async ({
      company_id,
      topup_id,
    }: {
      company_id: string;
      topup_id: string;
    }) =>
      cancelTopUp({
        company_id,
        topup_id,
      }),
  });
};

export const useGenerateTransactionExport = () =>
  useMutation({
    mutationFn: async ({
      company_id,
      data,
    }: {
      company_id: string;
      data: {
        period_month?: number;
        period_year?: number;
        not_after?: string;
        not_before?: string;
        transaction_type: "all" | "debit" | "credit";
        format_type: "csv";
      };
    }) =>
      generateTransactionExport({
        company_id,
        data,
      }),
  });
