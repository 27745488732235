import { ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 32px;
  background-color: #000;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  color: #fff;
  gap: 50px;

  span {
    font-weight: 400;
  }

  ul {
    display: flex;
    align-items: center;
    gap: 32px;
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1;
  }

  ul li {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    white-space: nowrap;

    svg {
      min-width: 24px;
    }
  }

  > ul button {
    display: flex;
    gap: 8px;
  }
`;

export const Title = styled.div`
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

export const CompanyList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const CompanySwitchButton = styled(ButtonBase)<{
  isActive: boolean;
  isSelected: boolean;
}>`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-family: inherit;

  span {
    color: #000;
    font-weight: 400;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: #a0a0a0;
    `}

  > div {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid transparent;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg path {
      stroke: #000;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        border-color: #a0a0a0;

        svg path {
          stroke: #a0a0a0;
        }
      `}

    ${({ isSelected }) =>
      isSelected &&
      css`
        border-color: #000;
      `}

    svg {
      width: 24px;
    }
  }
`;
