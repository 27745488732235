import { Badge } from "@components/common";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 310px));
  gap: 1.5rem;
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  min-height: 304px;
  border: 1px solid #ececec;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  position: relative;

  img {
    width: 100%;
    display: block;
    height: 208px;
    object-fit: cover;
  }
`;

export const ItemAdd = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-height: 304px;
  border: 1px solid #ececec;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  position: relative;
  font-weight: 500;

  svg path {
    stroke: #000;
  }
`;

export const ItemName = styled.div`
  font-weight: 500;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0 1rem;
  text-align: center;

  > div:first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 224px;
    overflow: hidden;
    width: 100%;
  }
`;

export const BadgeStyled = styled(Badge)`
  white-space: nowrap;
  border-radius: 40px;
  background: #fee6cd !important;
  color: #000 !important;
  font-weight: 400 !important;
`;

export const BadgeStatusStyled = styled(Badge)`
  position: absolute;
  left: 16px;
  top: 8px;
`;
