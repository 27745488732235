import { MenuList, MenuItem } from "@material-ui/core";
import styled, { css } from "styled-components";

export const Container = styled.main<{ isAdmin: boolean }>`
  padding: 32px 74px;
  overflow: auto;
  max-height: 100vh;

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      padding-bottom: 96px;
    `}

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 32px 16px;
    max-height: inherit;
  }
`;

export const Credit = styled.div`
  min-width: 309px;
  height: 42px;
  border: 1px solid #eaeaea;
  border-radius: 60px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 16px;
  font-weight: bold;
  margin-left: auto;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    order: 2;
    margin: 0;
  }

  button {
    border-radius: 40px;
    height: 30px;
    padding: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
`;

export const HeaderStyled = styled.header`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 48px;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-wrap: wrap;
  }
`;

export const SearchContainer = styled.div`
  max-width: 420px;
  flex: 1;
  position: relative;

  .MuiInputBase-root {
    padding-left: 22px;
  }

  .MuiInputBase-root.Mui-focused {
    border-color: #000;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    order: 0;
  }
`;

export const MenuListStyled = styled(MenuList)`
  position: absolute;
  z-index: 100;
  left: 0;
  top: calc(100% + 8px);
  background-color: #fff;
  border: 1px solid #ececec;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;

  max-height: 400px;
  overflow: auto;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0;
  a {
    padding: 9px 16px;
    display: flex;
    gap: 9px;
    color: inherit;
    text-decoration: none;
    width: 100%;
  }
`;

export const TagsStyled = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 50%;
  color: #a0a0a0;
`;

export const MenuEmpty = styled.div`
  padding: 20px;
  display: flex;
  gap: 14px;

  a {
    margin-top: 16px;
  }
`;
