import React, { useContext } from "react";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router";
import { WrappedComponentProps, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Loader, SectionNav } from "@components/common";
import { COMMON, ROUTES } from "@constants/index";
import { AuthContext } from "@providers/AuthProvider";
import AddCustomer from "../../AddContact";
import CustomerList from "../../ContactList";
import ImportContacts from "../../ImportContacts";
import Customer from "../../Contact";
import { SubHeadingStyled, HeaderTopStyled, Counter } from "../../../styled";
import { useContacts, useAllTags } from "../../../hooks";

const ContactRoutes = () => {
  const { user } = useContext(AuthContext);

  const { data: tags, isLoading: loading1 } = useAllTags(user.company_id);
  const { data: contacts, isLoading: loading2 } = useContacts(
    user.company_id,
    1
  );

  if (loading1 && loading2) {
    return <Loader />;
  }

  return (
    <div>
      <HeaderTopStyled>
        <SubHeadingStyled variant='h2'>
          <FormattedMessage id='contacts.main.title' />
          <Counter>{contacts?.paging?.total_records}</Counter>
        </SubHeadingStyled>
        <Button
          variant='outlined'
          component={Link}
          to={ROUTES.CUSTOMERS_ADD_FORM}
        >
          <FormattedMessage id='contacts.main.add' />
        </Button>
      </HeaderTopStyled>
      <SectionNav navItems={COMMON.CONTACTS_NAV} />
      <Switch>
        <Route
          exact
          path={ROUTES.CUSTOMERS_OVERVIEW}
          component={CustomerList}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERS_ADD_FORM}
          // eslint-disable-next-line
          component={() => (
            <AddCustomer
              contacts={contacts?.items}
              companyTags={tags}
              userInfo={user}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERS_IMPORT}
          component={ImportContacts}
        />
        <Redirect to={ROUTES.CUSTOMERS_OVERVIEW} />
      </Switch>
    </div>
  );
};

const Contacts: React.FC<RouteComponentProps & WrappedComponentProps> = () => {
  return (
    <>
      <Switch>
        <Route exact path={ROUTES.CUSTOMERS} component={ContactRoutes} />
        <Route exact path={ROUTES.CUSTOMERS_PROFILE} component={Customer} />
        <Redirect to={ROUTES.CUSTOMERS_OVERVIEW} />
      </Switch>
    </>
  );
};

export default Contacts;
