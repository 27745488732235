import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { QueryKey } from "react-query";
import { ContactType, TagType, DeliveryType, ContactAddressType } from "@types";

const { API } = CONSTANTS;

// CONTACTS
export const fetchContacts = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise => {
  // @ts-expect-error
  const params = new URLSearchParams({
    page: queryKey[2] || "1",
    ...(queryKey[3] && { tag: queryKey[3] || "" }),
    ...(queryKey[5] && { search: queryKey[5] || "" }),
  }).toString();

  return baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/contacts?${params}`)
    .then((res) => res.data);
};

export const fetchContact = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/contacts/${queryKey[2]}`)
    .then((res) => res.data.items?.[0]);

export const fetchContactAddress = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/contacts/${queryKey[2]}/addresses`)
    .then((res) => res.data.items?.[0]);

export const updateContact = ({
  company_id,
  contact_id,
  data,
}: {
  company_id: string;
  contact_id: string;
  data: Omit<ContactType, "id" | "created_at">;
}): AxiosPromise =>
  baseApi
    .patch(`${API.COMPANIES}/${company_id}/contacts/${contact_id}`, data)
    .then((res) => res.data);

export const updateContactAddress = ({
  company_id,
  contact_id,
  data,
}: {
  company_id: string;
  contact_id: string;
  data: ContactAddressType;
}): AxiosPromise =>
  baseApi
    .post(
      `${API.COMPANIES}/${company_id}/contacts/${contact_id}/addresses`,
      data
    )
    .then((res) => res.data);

export const createContact = ({
  company_id,
  data,
}: {
  company_id: string;
  data: Omit<ContactType, "id" | "created_at">;
}): AxiosPromise =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/contacts`, data)
    .then((res) => res.data);

export const deleteContact = ({
  company_id,
  contact_id,
}: {
  company_id: string;
  contact_id: string;
}): AxiosPromise =>
  baseApi
    .delete(`${API.COMPANIES}/${company_id}/contacts/${contact_id}`)
    .then((res) => res.data);

// TAGS
export const getTags = ({ queryKey }: { queryKey: QueryKey }): AxiosPromise =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/tags`)
    .then((res) => res.data?.items);

export const getAllTags = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/tags?per_page=100`)
    .then((res) => res.data?.items);

export const createTag = (company_id: string, tag: string): AxiosPromise =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/tags`, {
      tag_string: tag,
    })
    .then((res) => res.data);

export const createTags = ({
  company_id,
  data,
}: {
  company_id: string;
  data: string[];
}): AxiosPromise<TagType> =>
  Promise.all<AxiosPromise>(data.map((tag) => createTag(company_id, tag)));

export const getContactsImports = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/contacts/imports`)
    .then((res) => res.data?.items);

export const importContacts = ({
  company_id,
  data,
}: {
  company_id: string;
  data: {
    file: File;
  };
}): AxiosPromise =>
  baseApi
    .post(`${API.COMPANIES}/${company_id}/contacts/imports`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);

export const fetchContactDeliveries = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise => {
  // @ts-expect-error
  const params = new URLSearchParams({
    page: queryKey[3] || "1",
    per_page: queryKey[4] || "10",
  }).toString();
  return baseApi
    .get(
      `${API.COMPANIES}/${queryKey[1]}/contacts/${queryKey[2]}/deliveries?${params}`
    )
    .then((res) => res.data);
};

export const cancelContactDelivery = ({
  company_id,
  order_id,
  delivery_id,
}: {
  company_id: string;
  order_id: string;
  delivery_id: string;
}): AxiosPromise =>
  baseApi
    .delete(
      `${API.COMPANIES}/${company_id}/orders/${order_id}/deliveries/${delivery_id}`
    )
    .then((res) => res.data);

export const createGiftDelivery = ({
  company_id,
  contact_id,
  data,
}: {
  company_id: string;
  contact_id: string;
  data: {
    gift_id: string;
    delivered_at: string;
    signature_external_id: string;
  };
}): AxiosPromise<DeliveryType> =>
  baseApi
    .post(
      `${API.COMPANIES}/${company_id}/contacts/${contact_id}/deliveries`,
      data
    )
    .then((res) => res.data);
