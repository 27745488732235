import styled, { css } from "styled-components";

export const SelectGiftListStyled = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SelectGiftListItemStyled = styled.li<{selected: boolean}>`
  list-style: none;

  > button {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    ${({selected}) => selected && css`
      background-color: rgba(0, 0, 0, 0.05);
    `}

    img {
      border-radius: 12px;
      object-fit: cover;
    }
  }
`;

export const SelectedGiftStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  border-radius: 12px;
  text-decoration: none;
  color: #263238;

  img {
    border-radius: 12px;
    object-fit: cover;
  }
`;

export const SelectGiftInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SelectGiftInfoSRowStyled = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #263238;
  align-items: center;
`;

export const SelectGiftFlagStyled = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;