import { Grid, Typography } from "@material-ui/core";
import { Button, Link, Logo } from "@components/common";
import styled from "styled-components";

export const WrapperStyled = styled(Grid)({
  padding: "40px 16px 46px",
  backgroundColor: "#F8F6F4",
  minHeight: "100vh",
  justifyContent: "flex-start",

  "@media (min-width:768px)": {
    paddingBottom: "80px",
    justifyContent: "center",
  },
});

export const LogoStyled = styled(Logo)({
  marginBottom: 24,
});

export const FormStyled = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  // min-width: 410px;
  padding: 32px;
  border-radius: 12px;
  gap: 24px;

  @media (min-width: 768px) {
    max-width: 384px;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 24px;
  }
`;

export const TitleStyled = styled(Typography)({
  textAlign: "center",
});

export const SubmitButtonStyled = styled(Button)`
  align-self: center;
  width: 100%;
`;

export const SecondaryButtonStyled = styled(Button)({
  alignSelf: "center",
  width: "auto",
  margin: "0 auto 16px",

  "@media (min-width:768px)": {
    margin: "0 0 46px 0",
  },
});

export const ContactLinkStyled = styled.a`
  margin-left: 6px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const LinkStyled = styled(Link)`
  margin-bottom: 0;
`;
