import * as API from "./api";
import * as COMMON from "./common";
import * as ROUTES from "./routes";
import * as ICONS from "./icons";
import * as VALIDATION from "./validation";
import * as STYLE from "./style";
import * as CUSTOM_COUNTRY_LISTS from "./customCountries";

export * as ROUTES from "./routes";
export * as VALIDATION from "./validation";
export * as COMMON from "./common";
export * as ICONS from "./icons";
export * as STYLE from "./style";
export * as CUSTOM_COUNTRY_LISTS from "./customCountries";

export default {
  API,
  COMMON,
  ROUTES,
  ICONS,
  VALIDATION,
  STYLE,
  CUSTOM_COUNTRY_LISTS,
};
