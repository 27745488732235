import { useContext, useEffect, useState } from "react";
import { baseApi } from "@utils/apiService";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import cognitoId from "../cognitoId";
import { useGifts } from "../modules/gifts/hooks";
import { useCompanyDetails } from "../modules/account/hooks";
import { AuthContext } from "../providers/AuthProvider";

export const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);
  const [synced, setSynced] = useState(false);

  useEffect(() => {
    const cognitoUser = cognitoId.getCognitoUser();
    if (!cognitoUser) {
      setUser(null);
      setSynced(true);
      return;
    }

    cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
      if (err) {
        setUser(null);
        setSynced(true);
        return;
      }
      const { email, name, sub } = session.getIdToken().payload;
      // @ts-expect-error
      const { jwtToken } = session.getIdToken();
      baseApi.defaults.headers.common.Authorization = jwtToken;

      setUser({
        id: sub,
        email,
        name: name || "N/A",
        company_id: session?.getIdToken()?.payload["custom:organization_id"],
        company_ids: session?.getIdToken()?.payload["custom:organization_ids"],
        locale: session.getIdToken()?.payload.locale || "sk",
        currency: "sk",
        is_admin: session?.getIdToken()?.payload["custom:is_admin"] === "true",
      });
      setSynced(true);
    });
  }, [setUser]);

  return {
    user,
    synced,
  };
};

export const usePrefetchedData = (companyId: string) => {
  const { isLoading: loading1, isError: error1 } = useGifts(companyId);
  const {
    data: companyData,
    isLoading: loading2,
    isError: error2,
  } = useCompanyDetails(companyId);

  return {
    isLoading: loading1 || loading2,
    companyData,
    isError: error1 || error2,
  };
};
